import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import DefaultSidebar from '../../sidebar/DefaultSidebar';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import SidebarLayout from '../SidebarLayout';

/**
 * This layout is used on at least on HomePage, Manufacturer, OnSale and NewProducts pages
 * that do not have any specific layout requirements or sidebar content.
 */
@observer
export class GenericLayout extends Component {
  render() {
    const { configStore, content } = this.props;

    return configStore.navigation.sidebar.enabled ? (
      <SidebarLayout
        className="GenericLayout GenericLayout--with-sidebar"
        sidebar={<DefaultSidebar />}
        content={content}
      />
    ) : (
      <div className="GenericLayout GenericLayout--full-width">{content}</div>
    );
  }
}

GenericLayout.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  content: PropTypes.node,
};

export default inject('configStore')(GenericLayout);
