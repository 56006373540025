import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual, omit } from 'lodash';
import classNames from 'classnames';
import LazyLoad from 'react-lazyload';
import { observer } from 'mobx-react';

// "import" jQuery from global scope.
const $ = window.$;

const SLICK_EVENTS = {
  afterChange: 'afterChange',
  beforeChange: 'beforeChange',
  breakpoint: 'breakpoint',
  destroy: 'destroy',
  edge: 'edge',
  init: 'init',
  reInit: 'reInit',
  setPosition: 'setPosition',
  swipe: 'swipe',
  lazyLoaded: 'lazyLoaded',
  lazyLoadError: 'lazyLoadError',
};

@observer
class Slider extends Component {
  constructor(props) {
    super(props);

    this.element = null;
  }

  componentDidMount() {
    this.maybeSlickify();
    this.registerEventListeners();
  }

  componentDidUpdate(prevProps) {
    // We have to exclude children, because it changes so often.
    const excludeFromCompare = ['children', 'className'];
    if (
      !isEqual(
        omit(prevProps, excludeFromCompare),
        omit(this.props, excludeFromCompare)
      )
    ) {
      this.unslick();
      this.slickify();
    }
  }

  componentWillUnmount() {
    this.unslick();
    if (this.element) {
      Object.values(SLICK_EVENTS).forEach((eventName) =>
        $(this.element).off(eventName)
      );
    }
  }

  registerEventListeners = () => {
    const { events } = this.props;
    if (this.element && events) {
      Object.keys(events).forEach((eventName) => {
        if (SLICK_EVENTS[eventName]) {
          $(this.element).on(eventName, events[eventName]);
        }
      });
    }
  };

  maybeSlickify = () => {
    if (!this.element || this.slickIsActive()) {
      return;
    }

    this.slickify();
  };

  slickify = () => {
    const { events, ...rest } = this.props;
    const settings = rest.dots
      ? {
          appendDots: this.navElement,
          appendArrows: this.navElement,
          ...rest,
        }
      : rest;
    $(this.element).slick(settings);
    this.makeSureDimensionsCorrectOnImageLoad();
  };

  makeSureDimensionsCorrectOnImageLoad = () => {
    // If the images have not been loaded Slick might calculate dimensions wrong.
    // We need to class resize on the slick element to force recalculate.
    $(this.element)
      .find('img')
      .off('load')
      .on('load', () => {
        $(this.element).resize();
      });
  };

  unslick = () => this.slickMethod('unslick');

  slickGoTo = (index, dontAnimate) =>
    this.slickMethod('slickGoTo', index, dontAnimate);

  slickNext = () => this.slickMethod('slickNext');

  slickPause = () => this.slickMethod('slickPause');

  slickPlay = () => this.slickMethod('slickPlay');

  slickPrev = () => this.slickMethod('slickPrev');

  slickRefresh = () => this.slickMethod('refresh');

  //Accepts html or DOM object, index, addBefore
  slickAdd = (slide) => this.slickMethod('slickAdd', slide);

  //Accepts html or DOM object, index, addBefore
  slickRemove = (slide) => this.slickMethod('slickRemove', slide);

  slickCurrentSlide = () => this.slickMethod('slickCurrentSlide');

  slickMethod = (method, ...args) => {
    if (this.slickIsActive()) {
      $(this.element).slick(method, ...args);
    }
  };

  slickIsActive = () => this.element && this.element.slick;

  render() {
    return (
      <div
        className={classNames(
          'Slider',
          {
            'Slider--with-dots': this.props.dots,
          },
          this.props.className
        )}
      >
        <div ref={(element) => (this.element = element)}>
          {this.props.children}
        </div>
        <div
          ref={(element) => (this.navElement = element)}
          className="Slider__nav"
        />
      </div>
    );
  }
}

// Sliders are forced to rerender completely if the number of slides changes,
// as React's element reuse algorithm doesn't cope well with slick also
// touching those same slide DOM elements.
const SliderWrapper = ({ sliderRef, lazyLoad, lazyLoadOptions, ...rest }) => {
  const key = rest.children
    ? rest.children.length +
      rest.children
        .filter((child) => child !== null)
        .map((child) => {
          return child.key;
        })
    : '';

  const slider = <Slider key={key} ref={sliderRef} {...rest} />;

  if (lazyLoad) {
    const options = {
      once: true,
      offset: 50,
      ...lazyLoadOptions,
    };

    return <LazyLoad {...options}>{slider}</LazyLoad>;
  } else {
    return slider;
  }
};

SliderWrapper.propTypes = {
  className: PropTypes.string,
  events: PropTypes.object,
  sliderRef: PropTypes.func,
  lazyLoad: PropTypes.bool,
  lazyLoadOptions: PropTypes.object,
};

export default SliderWrapper;
