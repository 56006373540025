import React, { Component } from 'react';
import { NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import PropTypes from 'prop-types';
import AccountStore from '../../../store/AccountStore';
import TranslationStore from '../../../store/TranslationStore';
import TranslatedImageType from '../../../types/TranslatedImageType';

@observer
export class NavigationLogo extends Component {
  getLogoUrl = (translatedImageType) => {
    const { translationStore } = this.props;
    const logo = translationStore.translatedImages.find(
      (image) => image.image_key === translatedImageType
    );
    return logo && logo.image_path;
  };

  getScrollLogoUrl = () =>
    this.getLogoUrl(TranslatedImageType.STORE_SCROLL_LOGO);

  getStoreLogoUrl = () => this.getLogoUrl(TranslatedImageType.STORE_LOGO);

  render() {
    const {
      accountStore,
      configStore,
      sticky,
      useStickyHeaderOnStartup
    } = this.props;
    let logoUrl =
      accountStore.account && accountStore.account.logo
        ? accountStore.account.logo
        : (useStickyHeaderOnStartup || sticky) && this.getScrollLogoUrl()
          ? this.getScrollLogoUrl()
          : this.getStoreLogoUrl();
    return logoUrl ? (
      <NavbarBrand tag={Link} className="NavigationLogo" to="/">
        <img src={logoUrl} alt={configStore.store.name} />
      </NavbarBrand>
    ) : null;
  }
}

NavigationLogo.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  accountStore: modelOf(AccountStore).isRequired,
  translationStore: modelOf(TranslationStore).isRequired,
  sticky: PropTypes.bool,
  useStickyHeaderOnStartup: PropTypes.bool
};

export default inject('accountStore', 'configStore', 'translationStore')(
  NavigationLogo
);
