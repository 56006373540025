import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';

import Page from '../../components/layout/Page';
import SectionStore from '../../store/SectionStore';
import { modelOf } from '../../prop-types';
import HelperNavigation from '../../components/common/HelperNavigation';
import WysiwygContent from '../../components/common/WysiwygContent';
import ProductTabs from '../../components/product/ProductTabs';
import SidebarLayout from '../../components/layout/SidebarLayout';
import PageTitle from '../../components/common/PageTitle';
import ContentForState from '../../components/loader/ContentForState';
import SEOTitle from '../../components/head/SEOTitle';
import SEODescription from '../../components/head/SEODescription';
import CanonicalLink from '../../components/head/CanonicalLink';
import RouteService from '../../services/RouteService';
import DefaultSidebar from '../../components/sidebar/DefaultSidebar';
import ContentPopup from '../../components/ad/ContentPopup';
import ScrollReset from '../../components/common/ScrollReset';
import MainBanner from '../../components/ad/MainBanner';
import ConfigStore from '../../store/ConfigStore';
import LoadBeeSectionContentWidget from '../../integrations/loadBee/LoadBeeSectionContentWidget';
import ArinetWidget from '../../integrations/arinet/ArinetWidget';
import MainProductList from '../../components/product-list/MainProductList';
import SingleRowProductTabs from '../../components/product-list/SingleRowProductTabs';
import SectionIntegrationType from '../../types/SectionIntegrationType';
import CommonPage from '../../types/CommonPage';
import ProductPdf from '../../components/product-pdf/ProductPdf';
import ProductPdfType from '../../types/ProductPdfType';
import AccountStore from '../../store/AccountStore';

@observer
class SectionPage extends Component {
  constructor(props) {
    super(props);
    this.maybeLoadSection();

    this.yotpoTimeout = null;
  }

  componentDidUpdate(prevProps) {
    const { configStore } = this.props;

    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.maybeLoadSection();
    }

    if (prevProps.match.path !== this.props.match.path) {
      if (configStore.integrations.yotpo.enabled && window && window.yotpo) {
        this.yotpoTimeout = setTimeout(() => {
          window.yotpo.refreshWidgets();
        }, 800);
      }
    }
  }

  componentWillUnmount() {
    this.yotpoTimeout && clearTimeout(this.yotpoTimeout);
  }

  maybeLoadSection = () => {
    const { sectionStore } = this.props;

    if (
      !sectionStore.sectionStates.get(sectionStore.activeSection.id) &&
      sectionStore.activeSection.content_html === null
    ) {
      sectionStore.loadSection(sectionStore.activeSection.id).catch((e) => {
        console.error(e);
      });
    }
  };

  getSingleRowTabs = () => {
    const { configStore, sectionStore } = this.props;

    if (
      !configStore.sectionPage.singleRowTabs ||
      !configStore.sectionPage.useProductList ||
      (configStore.integrations.arinet.enabled &&
        configStore.integrations.arinet.appKey)
    ) {
      return null;
    }

    return (
      <SingleRowProductTabs
        searchParams={{
          sections: [sectionStore.activeSection.id],
        }}
      />
    );
  };

  renderProductPdfButton = () => {
    const { sectionStore } = this.props;

    return (
      <ProductPdf
        activeId={sectionStore.activeSection.id.toString()}
        pdfType={ProductPdfType.SECTION}
        showEmail={true}
        requireEmail
      />
    );
  };

  getProductListing = () => {
    const { configStore, sectionStore } = this.props;
    const useMainProductList = configStore.sectionPage.useProductList;

    if (
      configStore.integrations.arinet.enabled &&
      sectionStore.activeSection.integration_type ===
        SectionIntegrationType.ARINET &&
      sectionStore.activeSection.integration_api_key
    ) {
      return <ArinetWidget />;
    }

    if (useMainProductList) {
      return (
        <MainProductList
          fixedParams={{
            cross_categories: [],
            sections: [sectionStore.activeSection.id],
          }}
          listId="section_list"
          name="Section List"
        />
      );
    } else {
      return (
        <ProductTabs
          searchParams={{
            sections: [sectionStore.activeSection.id],
          }}
        />
      );
    }
  };

  pdfCatalogEnabled = () => {
    const { accountStore, configStore } = this.props;
    const { showPdfButton } = configStore.sectionPage;

    if (showPdfButton === '0') {
      return false;
    }
    if (
      showPdfButton === '1' ||
      (showPdfButton === '2' && accountStore.loggedIn)
    ) {
      return true;
    }
    return showPdfButton === '3' && accountStore.isRetailer;
  };

  render() {
    const { configStore, sectionStore, routeService, match } = this.props;

    const useIntegrationContent =
      configStore.integrations.zoovu.enabled &&
      !!sectionStore.activeSection.integration_type &&
      !!sectionStore.activeSection.integration_feed_url &&
      !!sectionStore.activeSection.integration_api_key;

    if (useIntegrationContent) {
      return <LoadBeeSectionContentWidget />;
    }

    const adSearchParams = {
      section: sectionStore.activeSection && sectionStore.activeSection.id,
    };

    const mainBanner = <MainBanner searchParams={adSearchParams} />;
    const mainBannerWithinContent = configStore.mainBanner.withinContent;

    return (
      <Page name={CommonPage.SECTION} className="SectionPage">
        <ScrollReset key={match.params.id} />
        {!mainBannerWithinContent && mainBanner}
        <Container className="SectionPage__container">
          <SidebarLayout
            sidebar={
              <DefaultSidebar
                bannerSearchParams={
                  sectionStore.activeSection
                    ? {
                        section: sectionStore.activeSection.id,
                      }
                    : null
                }
              />
            }
            content={
              <ContentForState
                state={sectionStore.sectionStates.get(
                  sectionStore.activeSection.id
                )}
                error={sectionStore.lastError}
                forLoaded={() => (
                  <Fragment>
                    <SEOTitle
                      title={
                        sectionStore.activeSection.SEO_title ||
                        sectionStore.activeSection.display_name
                      }
                      noSuffix={!!sectionStore.activeSection.SEO_title}
                    />
                    {sectionStore.activeSection.SEO_description && (
                      <SEODescription
                        content={sectionStore.activeSection.SEO_description}
                      />
                    )}
                    <CanonicalLink
                      path={routeService.getPath(
                        sectionStore.activeSection.path
                      )}
                    />
                    {mainBannerWithinContent && mainBanner}
                    <HelperNavigation
                      breadcrumbsPath={sectionStore.activeSection.breadcrumbs}
                    />
                    <PageTitle>
                      {sectionStore.activeSection.display_name}
                    </PageTitle>
                    {this.pdfCatalogEnabled() && this.renderProductPdfButton()}
                    <div className="SectionPage__section-content">
                      <WysiwygContent
                        html={sectionStore.activeSection.content_html}
                      />
                    </div>
                    {this.getSingleRowTabs()}
                    {!configStore.siteConfig.isHomePage &&
                      this.getProductListing()}
                    <div className="SectionPage__section-footer-content">
                      <WysiwygContent
                        html={
                          sectionStore.activeSection.footer_description_html
                        }
                      />
                    </div>
                    <ContentPopup searchParams={adSearchParams} />
                  </Fragment>
                )}
              />
            }
          />
        </Container>
      </Page>
    );
  }
}

SectionPage.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  match: RouterPropTypes.match.isRequired,
  history: RouterPropTypes.history.isRequired,
  location: RouterPropTypes.location.isRequired,
};

export default inject(
  'accountStore',
  'configStore',
  'sectionStore',
  'routeService'
)(withRouter(SectionPage));
