import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AdZones from '../../../types/AdZones';
import BannerSlider from '../../ad/BannerSlider';

@observer
export class NavigationMegaMenuBanner extends Component {
  render() {
    const { className, searchParams } = this.props;

    return (
      <BannerSlider
        searchParams={{
          bannerZone: AdZones.MEGA_MENU_BANNER,
          ...searchParams
        }}
        className={classNames(className)}
        aspectRatio={6.85}
        maximumCrossAxisSize={200}
      />
    );
  }
}

NavigationMegaMenuBanner.propTypes = {
  className: PropTypes.string,
  searchParams: PropTypes.object
};

export default NavigationMegaMenuBanner;
