import { types, flow, getEnv } from 'mobx-state-tree';

import Ad from '../models/banner/Ad';
import { paramsToQueryIdentifier } from '../util/query';
import StatefulStore from '../models/StatefulStore';
import Error from '../models/Error';
import { createErrorModel } from '../util/error';
import RequestState, { RequestStateType } from '../types/RequestState';

const AdStore = StatefulStore.named('AdStore')
  .props({
    adQueries: types.optional(types.map(types.array(Ad)), {}),
    adQueryStates: types.optional(types.map(RequestStateType), {}),
    adQueryError: types.maybeNull(Error),
  })
  .actions((self) => {
    const loadAds = flow(function* loadAds(searchParameters) {
      const queryIdentifier = paramsToQueryIdentifier(searchParameters);

      // Skip the API call if we already have the data
      if (self.adQueries.get(queryIdentifier)) {
        return;
      }

      self.setLoading(true);
      self.adQueryStates.set(queryIdentifier, RequestState.LOADING);

      try {
        const ads = yield getEnv(self).apiWrapper.request(
          `banners`,
          { params: searchParameters },
          { active_section: null }
        );

        self.adQueries.set(queryIdentifier, ads);
      } catch (error) {
        self.setError(error);
        self.adQueryError = createErrorModel(error);
        self.adQueryStates.set(queryIdentifier, RequestState.ERROR);
        throw error;
      }

      self.setLoading(false);
      self.adQueryStates.set(queryIdentifier, RequestState.LOADED);
    });

    return {
      loadAds,
    };
  })
  .views((self) => {
    return {
      getAdsBySearchParameters: (searchParameters) => {
        const queryIdentifier = paramsToQueryIdentifier(searchParameters);
        const ads = self.adQueries.get(queryIdentifier);
        return ads && ads.length > 0 && ads;
      },
      getAdQueryStateBySearchParameters: (searchParameters) => {
        const queryIdentifier = paramsToQueryIdentifier(searchParameters);
        return self.adQueryStates.get(queryIdentifier);
      },
    };
  });

export default AdStore;
