import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import { Button, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import ManufacturerStore from '../../../store/ManufacturerStore';
import SectionStore from '../../../store/SectionStore';
import Autosuggest from '../../common/Autosuggest';
import ManufacturerLink from '../../manufacturer/ManufacturerLink';
import ConfigStore from '../../../store/ConfigStore';
import RouteService from '../../../services/RouteService';
import RequestState from '../../../types/RequestState';
import Paths from '../../../types/Paths';
import Icon from '../../common/Icon';
import UIStore from '../../../store/UIStore';

const messages = defineMessages({
  'manufacturer.chooseManufacturer': {
    id: 'manufacturer.chooseManufacturerPlaceholder',
    defaultMessage: 'Choose brand...',
  },
});

const STYLES = {
  DROPDOWN: '0',
  LIST: '1',
};

@observer
export class ManufacturerBox extends Component {
  state = {
    value: '',
    suggestions: [],
  };

  componentDidMount() {
    this.maybeLoadManufacturers();
  }

  componentDidUpdate() {
    const { manufacturerStore } = this.props;
    if (
      manufacturerStore.state === RequestState.LOADED &&
      !this.state.manufacturers
    ) {
      this.updateManufacturers();
    }
  }

  maybeLoadManufacturers = () => {
    const { manufacturerStore } = this.props;

    if (
      !manufacturerStore.loadedWithContent &&
      manufacturerStore.state !== RequestState.LOADING
    ) {
      manufacturerStore.loadManufacturers(false).then(() => {
        this.updateManufacturers();
      });
    }

    if (manufacturerStore.state === RequestState.LOADED) {
      this.updateManufacturers();
    }
  };

  updateManufacturers = () => {
    const { manufacturerStore, twoLayerMegaMenuEnabled } = this.props;

    let manufacturers = manufacturerStore.manufacturersWithProducts;

    if (twoLayerMegaMenuEnabled) {
      manufacturers = this.getMegaMenuManufacturers(manufacturers);
    } else {
      manufacturers = this.getManufacturersByActiveSection(manufacturers);
    }

    this.setState({ manufacturers });
    twoLayerMegaMenuEnabled && this.onManufacturersUpdates(manufacturers);
  };

  getMegaMenuManufacturers = (manufacturers) => {
    const { configStore, uiStore } = this.props;

    if (
      configStore.activateSections &&
      configStore.manufacturerBox.limitByActiveSection &&
      uiStore.megaMenu.activeSection
    ) {
      return this.filterManufactursByActiveSectionId();
    }

    return manufacturers;
  };

  getManufacturersByActiveSection = (manufacturers) => {
    const { configStore, sectionStore } = this.props;

    if (
      configStore.activateSections &&
      configStore.manufacturerBox.limitByActiveSection &&
      sectionStore.activeSection
    ) {
      return this.filterManufactursByActiveSectionId();
    }

    return manufacturers;
  };

  filterManufactursByActiveSectionId = () => {
    const { manufacturerStore } = this.props;
    return manufacturerStore.manufacturersArray.filter((manufacturer) =>
      manufacturer.belongsToSection(this.getActiveSectionId())
    );
  };

  getActiveSectionId = () => {
    const { sectionStore, uiStore, twoLayerMegaMenuEnabled } = this.props;

    if (twoLayerMegaMenuEnabled) {
      return uiStore.megaMenu.activeSection.id;
    }

    return sectionStore.activeSection.id;
  };

  onManufacturersUpdates(manufacturers) {
    const { onManufacturersUpdate } = this.props;

    onManufacturersUpdate && onManufacturersUpdate(manufacturers);
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  getManufacturers = (value) => {
    const { manufacturers } = this.state;
    const searchValue = value.trim().toLowerCase();

    return searchValue === ''
      ? manufacturers
      : manufacturers.filter((manufacturer) =>
          manufacturer.name.toLowerCase().startsWith(searchValue)
        );
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getManufacturers(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.getManufacturers(''),
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    const { history, routeService, onLinkClick, twoLayerMegaMenuEnabled } =
      this.props;
    history.push(routeService.getPath(suggestion.path));

    if (twoLayerMegaMenuEnabled) {
      onLinkClick();
    }
  };

  renderManufacturerList = () => {
    const { twoLayerMegaMenuEnabled } = this.props;

    if (twoLayerMegaMenuEnabled) {
      return this.renderMegaMenuList();
    }

    return this.renderList();
  };

  renderList = () => {
    const { manufacturers } = this.state;
    return (
      <ul className="ManufacturerBox__list">
        {manufacturers.map((manufacturer) => (
          <li key={manufacturer.id}>
            <ManufacturerLink manufacturer={manufacturer} />
          </li>
        ))}
      </ul>
    );
  };

  renderMegaMenuList = () => {
    const { manufacturers } = this.state;
    return (
      <Row className="ManufacturerBox__list-two-layer-megamenu">
        {manufacturers.map((manufacturer) => (
          <Col sm={12} key={manufacturer.id} onClick={this.props.onLinkClick}>
            <ManufacturerLink manufacturer={manufacturer} />
          </Col>
        ))}
      </Row>
    );
  };

  renderDropdown = () => {
    const { intl } = this.props;
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: intl.formatMessage(
        messages['manufacturer.chooseManufacturer']
      ),
      value,
      onChange: this.onChange,
      className: 'form-control',
    };

    return (
      <Fragment>
        <Autosuggest
          id="ManufacturerBox__Autosuggest"
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={(manufacturer) => manufacturer.id}
          shouldRenderSuggestions={() => true}
          renderSuggestion={(manufacturer) => <div>{manufacturer.name}</div>}
          inputProps={inputProps}
          renderInputComponent={this.renderInputComponent}
        />
        <div className="ManufacturerBox__all-link">
          {this.renderLinkButton()}
        </div>
      </Fragment>
    );
  };

  renderLinkButton = () => {
    const {
      routeService,
      linkButtonClassName,
      onLinkClick,
      twoLayerMegaMenuEnabled,
    } = this.props;

    if (twoLayerMegaMenuEnabled) {
      return (
        <Link to={routeService.getPath(Paths.ManufacturerList)}>
          <Button
            className={linkButtonClassName}
            color="link"
            onClick={onLinkClick}
          >
            <FormattedMessage
              id="manufacturer.seeAllBrands"
              defaultMessage="See all brands"
            />
          </Button>
        </Link>
      );
    }

    return (
      <Link to={routeService.getPath(Paths.ManufacturerList)}>
        <FormattedMessage
          id="manufacturer.seeAllBrands"
          defaultMessage="See all brands"
        />
      </Link>
    );
  };

  renderInputComponent = (inputProps) => {
    const { twoLayerMegaMenuEnabled } = this.props;

    if (twoLayerMegaMenuEnabled) {
      return (
        <div className="ManufacturerBox__two-layer-megamenu-brand-dropdown">
          <input {...inputProps} value={this.state.value} />
          <Icon name="caret-down" />
        </div>
      );
    }

    return <input {...inputProps} value={this.state.value} />;
  };

  render() {
    const {
      configStore,
      manufacturerStore,
      className,
      minManufacturersForDropdown,
      title,
    } = this.props;

    const { manufacturers } = this.state;

    if (
      !manufacturerStore.manufacturersWithProducts ||
      !manufacturers ||
      manufacturers.length === 0
    ) {
      return null;
    }

    return (
      <div className={classNames('ManufacturerBox', className)}>
        <Card>
          <CardHeader>{title}</CardHeader>
          <CardBody>
            {manufacturers.length > minManufacturersForDropdown &&
            configStore.manufacturerBox.style === STYLES.DROPDOWN
              ? this.renderDropdown()
              : this.renderManufacturerList()}
          </CardBody>
        </Card>
      </div>
    );
  }
}

ManufacturerBox.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  manufacturerStore: modelOf(ManufacturerStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  history: RouterPropTypes.history.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  linkButtonClassName: PropTypes.string,
  intl: intlShape.isRequired,
  minManufacturersForDropdown: PropTypes.number,
  twoLayerMegaMenuEnabled: PropTypes.bool,
  onLinkClick: PropTypes.func,
  onManufacturersUpdate: PropTypes.func,
};

ManufacturerBox.defaultProps = {
  minManufacturersForDropdown: 1,
  twoLayerMegamenuEnabled: false,
};

export default inject(
  'configStore',
  'manufacturerStore',
  'routeService',
  'sectionStore',
  'uiStore'
)(withRouter(injectIntl(ManufacturerBox)));
