import { keyBy } from 'lodash';

import {
  CommonEventNames,
  GoogleAnalytics4EventNames,
  UniversalAnalyticsEventNames,
} from '../AnalyticsEventNames';
import ProductClass from '../../types/ProductClass';
import OrderTotalClass from '../../types/OrderTotalClass';

export default class FacebookEventHandler {
  constructor() {
    document.addEventListener('analytics', this.handleEvents, true);
  }

  handleEvents = (event) => {
    switch (event.detail.name) {
      case CommonEventNames.setPage:
        this.pageView();
        break;
      case GoogleAnalytics4EventNames.viewItem:
      case UniversalAnalyticsEventNames.productDetail:
        this.viewContent(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.addToCart:
      case UniversalAnalyticsEventNames.addToCart:
        this.addToCart(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.purchase:
      case UniversalAnalyticsEventNames.purchase:
        this.purchase(event.detail.payload);
        break;
      default:
    }
  };

  pageView() {
    this.pushEvent('PageView');
  }

  /**
   * @param {Array} currencyCode
   * @param {Array} productList An array with objects in the format { product, activeProductId }.
   */
  viewContent({ currencyCode, productList }) {
    const { product } = productList[0];

    const productType =
      product.class === ProductClass.MULTI ? 'product_group' : 'product';
    let data = {
      content_type: productType,
      content_ids: [String(product.id)],
      value: product.price_info
        ? product.price_info.getPrice(true).toFixed(2)
        : 0.0,
      currency: currencyCode,
    };

    this.pushEvent('ViewContent', data);
  }

  /**
   * @param {Array} productList An array with objects in the format { position, product }.
   * @param {string} listName
   */
  addToCart({ currencyCode, productList }) {
    if (productList.length > 0) {
      const contentIds = productList.map(
        (productData) => productData.product.id
      );

      const value = productList.reduce((sum, productData) => {
        const quantity = productData.newQuantity || productData.quantity;
        return sum + quantity * productData.product.price_info.getPrice(true);
      }, 0);
      let data = {
        content_type: 'product',
        content_ids: contentIds,
        value: value.toFixed(2),
        currency: currencyCode,
      };

      this.pushEvent('AddToCart', data);
    }
  }

  /**
   * @param {CurrentOrder} order data.
   */
  purchase({ currentOrder }) {
    const orderTotalsByClass = keyBy(currentOrder.totals, 'class');

    const getTotalByClass = (totalClass) => {
      const total = orderTotalsByClass[totalClass];

      // The totals we use should always be there, but...
      return total ? total.value : null;
    };

    const orderProducts = currentOrder.products.map((product) => ({
      id: product.product_id,
      quantity: product.quantity,
      item_price: product.final_unit_price,
    }));

    let data = {
      content_type: 'product',
      contents: orderProducts,
      value: getTotalByClass(OrderTotalClass.TOTAL),
      currency: currentOrder.currency,
    };

    this.pushEvent('Purchase', data);
  }

  /**
   * @param {string} event Event name.
   * @param {object} data Object containing data for the event.
   */
  pushEvent(event, data = {}) {
    if (window.fbq) {
      window.fbq('track', event, data);
    }
  }
}
