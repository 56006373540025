import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Container,
  Col,
  Row,
  Button,
  Table,
  Tooltip,
  Input,
  Label,
  FormFeedback,
  FormGroup,
} from 'reactstrap';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { DateTime } from 'luxon';
import { every } from 'lodash';

import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import ProductImage from '../ProductImage';
import UIStore from '../../../store/UIStore';
import CartStore from '../../../store/CartStore';
import Analytics from '../../../analytics/Analytics';
import AccountStore from '../../../store/AccountStore';
import ImageLightbox from '../../common/ImageLightbox';
import Icon from '../../common/Icon';
import { DEFAULT_MAX_QUANTITY } from '../ProductAddToCart';
import ProductAvailabilityType from '../../../types/ProductAvailabilityType';
import { productCountValid, quantityValid } from '../../../util/formValidators';
import ConfigStore from '../../../store/ConfigStore';
import ProductMatrix from '../../../models/ProductMatrix';
import RequestState from '../../../types/RequestState';
import CustomCol from '../../bootstrap/CustomCol';
import globalTranslations from '../../../i18n/globalTranslations';
import { calculateTotal, roundWithPrecision } from '../../../util/number';
import CurrencyStore from '../../../store/CurrencyStore';

const messages = defineMessages({
  quantityInput: {
    id: 'product.quantityInput',
    defaultMessage: 'Quantity',
  },
  inventoryQuantity: {
    id: 'product.inventoryQuantity',
    defaultMessage: 'Inventory Quantity: {inventoryQuantity} {stockUnit}',
  },
  incomingQuantity: {
    id: 'product.incomingQuantity',
    defaultMessage: 'Incoming: {freeQuantity} {stockUnit} {date}',
  },
  noDateAvailable: {
    id: 'product.noDateAvailable',
    defaultMessage: '(no date available)',
  },
  incomingQuantityNoQuantities: {
    id: 'product.incomingQuantityNoQuantities',
    defaultMessage: 'Incoming: {date}',
  },
  productsHasBeenOrdered: {
    id: 'product.productsHasBeenOrdered',
    defaultMessage: '(Products has been ordered)',
  },
  notAvailable: {
    id: 'product.notAvailable',
    defaultMessage: '(Not available)',
  },
});

@observer
export class ProductCollectionMatrix extends Component {
  constructor(props) {
    super(props);

    props.product.sortProductImages('for_color_id');

    this.state = {
      selectedProducts: [],
      analyticsProducts: [],
      mainImageIndex: null,
      lightboxIsOpen: false,
      formError: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.product.id !== this.props.product.id) {
      this.setState({
        selectedProducts: null,
        analyticsProducts: null,
      });
    }
  }

  getImageLightbox = () => {
    const { product } = this.props;
    const { mainImageIndex, lightboxIsOpen } = this.state;

    if (
      product.images.length === 0 ||
      !lightboxIsOpen ||
      mainImageIndex === null
    ) {
      return null;
    }

    return (
      <ImageLightbox
        images={product.images}
        mainImageIndex={mainImageIndex}
        onClick={this.toggleLightbox.bind(this, false)}
      />
    );
  };

  toggleLightbox = (lightboxIsOpen) => this.setState({ lightboxIsOpen });

  getCollectionRowElements = () => this.props.productMatrix.getRowElements();

  addSelectedProducts = () => {
    const { cartStore, analytics, accountStore, currencyStore } = this.props;
    const { selectedProducts, analyticsProducts } = this.state;
    selectedProducts &&
      analyticsProducts &&
      selectedProducts.length > 0 &&
      analyticsProducts.length > 0 &&
      cartStore
        .populate(selectedProducts, accountStore.showCartMatrix)
        .then(() => {
          const totalValue = this.calculateTotalAnalyticsValue(
            currencyStore,
            analyticsProducts
          );
          analytics.addToCart(analyticsProducts, totalValue);
          analytics.cartStatus({
            cart: cartStore.cart,
          });
        });
  };

  calculateTotalAnalyticsValue() {
    const { currencyStore } = this.props;
    const { analyticsProducts } = this.state;
    const precision = currencyStore.activeCurrency.precision;
    return roundWithPrecision(
      calculateTotal(
        analyticsProducts.map((product) => product.price * product.quantity),
        precision
      ),
      precision
    );
  }

  renderProductMatrixTitles = () => {
    const titles = this.props.productMatrix.getNames();
    return (
      <Col>
        <Label className="ProductCollectionMatrix__column-row-label">
          <span>
            {titles.columnName}
            {titles.columnName && titles.rowName && ' / '}
            {titles.rowName}
          </span>
        </Label>
      </Col>
    );
  };

  renderProductElementRows = () => {
    const productRows = this.getCollectionRowElements();

    return (
      productRows && (
        <thead>
          <tr>
            {productRows[0].name && <th />}
            {productRows.map(
              (rowElement, index) =>
                rowElement.name && (
                  <th
                    key={index}
                    className="ProductCollectionMatrix__row-element-title"
                    dangerouslySetInnerHTML={{ __html: rowElement.name }}
                  />
                )
            )}
          </tr>
        </thead>
      )
    );
  };

  renderProductColumns = () => {
    const { product, productMatrix, uiStore, ifProductRowImages } = this.props;
    return (
      <tbody>
        {productMatrix.getColumnElements().map((element) => {
          const productRowImage = product.images.find(
            (image) => image.for_color_id === element.id
          );
          const columnTitle = productMatrix.getNames();
          ifProductRowImages && ifProductRowImages(productRowImage);
          const productImage = (
            <Fragment>
              <ProductImage
                product={product}
                size={'small'}
                productImage={productRowImage}
                forceAspectRatio={false}
                lazyLoading={false}
                className="ProductCollectionMatrix__product-image"
                wrapperClassName="ProductCollectionMatrix__product-image-wrapper"
                overlayIcon={
                  productRowImage && (
                    <span className="ProductCollectionMatrix__product-image-overlay-icon">
                      <Icon name="expand" />
                    </span>
                  )
                }
              />
              <p className="ProductCollectionMatrix__product-column-title">
                {columnTitle.columnName}: {element.name}
              </p>
            </Fragment>
          );

          return (
            element && (
              <tr
                key={element.id}
                className="ProductCollectionMatrix__product-row"
              >
                {!uiStore.isMobile && (
                  <th
                    className="ProductCollectionMatrix__product-row-header"
                    scope="row"
                    onClick={
                      productRowImage &&
                      this.setMainImageIndex.bind(this, productRowImage)
                    }
                  >
                    {productImage}
                  </th>
                )}
                {uiStore.isMobile && (
                  <td
                    className="ProductCollectionMatrix__font-label-weight"
                    onClick={
                      productRowImage &&
                      this.setMainImageIndex.bind(this, productRowImage)
                    }
                  >
                    {productImage}
                  </td>
                )}
                {element && this.renderProductQuantityInputs(element.id)}
              </tr>
            )
          );
        })}
      </tbody>
    );
  };

  setMainImageIndex = (productRowImage) => {
    const { product } = this.props;
    const mainImageIndex = product.images.findIndex(
      (image) => image.for_color_id === productRowImage.for_color_id
    );
    this.setState({ mainImageIndex }, () => this.toggleLightbox(true));
  };

  renderProductQuantityInputs = (productCollectionElementId) => {
    const { product, productMatrix, uiStore, intl } = this.props;
    const rowElements = this.getCollectionRowElements();

    return (
      rowElements &&
      rowElements.map((rowElement) => {
        const elementIds = productMatrix.shouldSwapCollectionOrder()
          ? { rowId: productCollectionElementId, columnId: rowElement.id }
          : { columnId: productCollectionElementId, rowId: rowElement.id };

        const productCollectionElementItem =
          this.findProductCollectionElementItem(
            elementIds.columnId,
            elementIds.rowId
          );

        const element =
          uiStore.isMobile && this.findRowElementById(rowElement.id);

        const incomingQuantities =
          productCollectionElementItem.getIncomingQuantities();
        const ifProductAvailable = this.checkProductAvailability(
          productCollectionElementItem
        );

        return (
          <td
            key={`${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}`}
            className="ProductCollectionMatrix__quantity-display-and-selection-column"
          >
            {uiStore.isMobile && (
              <Label
                className="ProductCollectionMatrix__font-label-weight"
                dangerouslySetInnerHTML={{ __html: element.name }}
              />
            )}
            <Fragment>
              <Row className="ProductCollectionMatrix__quantity-selection-row">
                <Col xs={12} className="align-self-center">
                  <Label
                    className="ProductCollectionMatrix__quantity-and-stock-unit"
                    for={`qty--${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}`}
                  >
                    {this.getFreeQuantity(productCollectionElementItem)}{' '}
                    {product.stock_unit}
                  </Label>
                </Col>
                {(incomingQuantities.length > 0 || !ifProductAvailable) && (
                  <Col xs={12}>
                    <Label className="ProductCollectionMatrix__exceptionalAvailability">
                      {ifProductAvailable ? (
                        <FormattedMessage
                          {...globalTranslations.exceptionalAvailability}
                        />
                      ) : (
                        <FormattedMessage {...messages.notAvailable} />
                      )}
                    </Label>
                  </Col>
                )}
                <Col xs={12}>
                  <FormGroup>
                    <Input
                      disabled={!ifProductAvailable}
                      id={`qty--${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}`}
                      name={`qty--${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}`}
                      className="ProductCollectionMatrix__quantity-input"
                      type="number"
                      onChange={this.setProduct.bind(
                        this,
                        productCollectionElementItem,
                        productCollectionElementItem.row_id,
                        productCollectionElementItem.column_id
                      )}
                      value={
                        this.state[
                          `qty--${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}`
                        ] || ''
                      }
                      placeholder={intl.formatMessage(messages.quantityInput)}
                      onClick={this.openTooltip.bind(
                        this,
                        `${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}--tooltip`
                      )}
                      onBlur={this.hideTooltip.bind(
                        this,
                        `${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}--tooltip`
                      )}
                      invalid={
                        !!this.state[
                          `qty--${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}--error`
                        ]
                      }
                    />
                    {this.quantityError(
                      productCollectionElementItem,
                      productCollectionElementItem.column_id
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Tooltip
                className="ProductCollectionMatrix__product-quantity-tooltip"
                innerClassName="ProductCollectionMatrix__product-quantity-inner-tooltip"
                arrowClassName="ProductCollectionMatrix__product-quantity-tooltip-arrow"
                placement="top"
                isOpen={
                  this.state[
                    `${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}--tooltip`
                  ]
                }
                target={`qty--${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}`}
              >
                {this.renderTooltipContent(productCollectionElementItem)}
              </Tooltip>
            </Fragment>
          </td>
        );
      })
    );
  };

  findProductCollectionElementItem = (
    productCollectionColumnElementId,
    productCollectionRowElementId
  ) =>
    this.props.productMatrix.getItem(
      productCollectionColumnElementId,
      productCollectionRowElementId
    );

  findRowElementById = (rowElementId) =>
    this.props.productMatrix
      .getRowElements()
      .find((rowElement) => rowElement.id === rowElementId);

  getFreeQuantity = (productCollectionElementItem) => {
    const { product } = this.props;

    const freeQuantity = product.getFreeQuantity(
      productCollectionElementItem.product.id
    );
    return freeQuantity > 0 ? freeQuantity : 0;
  };

  checkProductAvailability = (productCollectionElementItem) => {
    const { product } = this.props;
    const quantity = productCollectionElementItem.getAvailableAmount() > 0;
    const ifCanBeOrderedOutOfStock =
      productCollectionElementItem.canBeOrderedOutOfStock();
    const allowBackOrder =
      product.availability_type === ProductAvailabilityType.ALLOW_BACKORDER;
    return quantity || ifCanBeOrderedOutOfStock || allowBackOrder;
  };

  ifOrderedProducts = (productCollectionElementItem) => {
    const incomingQuantities =
      productCollectionElementItem.getIncomingQuantities();

    if (!incomingQuantities || incomingQuantities.length === 0) {
      return null;
    }

    const predicate = {
      date: null,
      free_quantity: null,
      quantity: null,
    };

    return every(incomingQuantities, predicate);
  };

  renderTooltipContent = (productCollectionElementItem) => {
    const { product, productMatrix } = this.props;
    const inStockQuantity = productCollectionElementItem.getInStockQuantity();
    const freeQuantity = this.getFreeQuantity(productCollectionElementItem);
    const incomingQuantities =
      productCollectionElementItem.getIncomingQuantities();
    const rowId = productCollectionElementItem.row_id;
    const columnId = productCollectionElementItem.column_id;
    const elementName = productMatrix.getProductElementNamesByItemElementId(
      rowId,
      columnId
    );
    const ifOrderedProducts = this.ifOrderedProducts(
      productCollectionElementItem
    );
    return (
      <Container>
        <Row className="ProductCollectionMatrix__tooltip">
          <Col sm={12} className="ProductCollectionMatrix__tooltip-label">
            <Label>
              <span>
                {elementName.columnName}
                {elementName.columnName && elementName.rowName && ' / '}
                {elementName.rowName}
              </span>
            </Label>
          </Col>
          <Col
            sm={12}
            className="ProductCollectionMatrix__tooltip-stock-quantity"
          >
            <FormattedMessage
              {...messages.inventoryQuantity}
              values={{
                inventoryQuantity: inStockQuantity,
                stockUnit: product.stock_unit,
              }}
            />
          </Col>
          {ifOrderedProducts ? (
            <FormattedMessage {...messages.productsHasBeenOrdered} />
          ) : (
            this.renderIncomingQuantities(incomingQuantities)
          )}
          <Col sm={12} className="ProductCollectionMatrix__tooltip-separator" />
          <Col
            sm={12}
            className="ProductCollectionMatrix__tooltip-free-quantity"
          >
            <FormattedMessage
              {...globalTranslations.freeQuantity}
              values={{
                freeQuantity,
                stockUnit: product.stock_unit,
              }}
            />
          </Col>
        </Row>
      </Container>
    );
  };

  renderIncomingQuantities = (incomingQuantities) => {
    const { product } = this.props;
    if (!incomingQuantities || incomingQuantities.length === 0) {
      return null;
    }

    return incomingQuantities.map((incomingQuantity, index) => {
      const incomingQuantityDate =
        incomingQuantity.date &&
        DateTime.fromISO(incomingQuantity.date).toLocaleString();
      const freeQuantity = incomingQuantity.free_quantity;

      return (
        <Col
          key={index}
          sm={12}
          className="ProductCollectionMatrix__tooltip-incoming-quantity"
        >
          {freeQuantity ? (
            <FormattedMessage
              {...messages.incomingQuantity}
              values={{
                date: incomingQuantityDate ? (
                  incomingQuantityDate
                ) : (
                  <FormattedMessage {...messages.noDateAvailable} />
                ),
                freeQuantity,
                stockUnit: product.stock_unit,
              }}
            />
          ) : (
            <FormattedMessage
              {...messages.incomingQuantityNoQuantities}
              values={{
                date: incomingQuantityDate ? (
                  incomingQuantityDate
                ) : (
                  <FormattedMessage {...messages.noDateAvailable} />
                ),
              }}
            />
          )}
        </Col>
      );
    });
  };

  setProduct = (
    productCollectionElementItem,
    productCollectionRowElementId,
    productCollectionColumnElementId,
    event
  ) => {
    const name = event.target.name;
    const selectedProducts = this.state.selectedProducts;
    const id = productCollectionElementItem.product.id;
    const maxQuantity = this.getMaxQuantity(id);
    const qty = this.getQuantity(Number(event.target.value), maxQuantity);

    this.setState({ [name]: qty }, () => {
      const quantity =
        this.state[
          `qty--${productCollectionRowElementId}--${productCollectionColumnElementId}`
        ];
      this.validateProductQuantity(
        productCollectionRowElementId,
        productCollectionColumnElementId,
        maxQuantity,
        name
      );

      this.findSelectedProduct(selectedProducts, id, quantity);
      this.modifySelectedProductsForAnalytics();
    });
  };

  getMaxQuantity = (activeProductId) => {
    const { accountStore, configStore, product } = this.props;

    const maxAccountQuantity =
      accountStore.account && accountStore.account.max_product_quantity > 0
        ? accountStore.account.max_product_quantity
        : DEFAULT_MAX_QUANTITY;

    const maxBackorderQuantity =
      product.availability_type === ProductAvailabilityType.ALLOW_BACKORDER
        ? configStore.product.backorderLimit > 0
          ? configStore.product.backorderLimit
          : DEFAULT_MAX_QUANTITY
        : DEFAULT_MAX_QUANTITY;

    const maxNormalProductQuantity =
      product.availability_type !== ProductAvailabilityType.ALLOW_BACKORDER
        ? product.canBeOrderedOutOfStock
          ? DEFAULT_MAX_QUANTITY
          : this.getMaxByPackage(product, activeProductId)
        : DEFAULT_MAX_QUANTITY;

    return Math.min(
      maxAccountQuantity,
      maxBackorderQuantity,
      maxNormalProductQuantity,
      DEFAULT_MAX_QUANTITY
    );
  };

  getQuantity = (qty, maxQuantity) => {
    const { product } = this.props;

    const freeQuantity = product.getFreeQuantity(product.id);

    const cannotBeOrdered =
      freeQuantity <= 0 && !product.canBeOrderedOutOfStock && qty < 0;
    const canBeOrdered = freeQuantity > 0 && qty > 0 && qty <= maxQuantity;
    const exceedsMaxQuantity = qty >= maxQuantity;
    const nonNegativeQuantity = qty > 0 && qty;

    return cannotBeOrdered
      ? 0
      : canBeOrdered
      ? nonNegativeQuantity
      : exceedsMaxQuantity
      ? maxQuantity
      : nonNegativeQuantity;
  };

  getMaxByPackage = (product, activeProductId) => {
    return product.sellInPackage
      ? Math.floor(
          product.getFreeQuantity(activeProductId) / product.package_size
        ) * product.package_size
      : product.getFreeQuantity(activeProductId);
  };

  validateProductQuantity(
    productCollectionRowElementId,
    productCollectionColumnElementId,
    maxQuantity,
    name
  ) {
    const quantityStep = this.getQuantityStep();
    const currentQuantity =
      this.state[
        `qty--${productCollectionRowElementId}--${productCollectionColumnElementId}`
      ];

    if (quantityStep > 1) {
      const validQuantity = quantityValid(maxQuantity, quantityStep);
      const validProductCount = productCountValid(quantityStep);
      const invalidQuantity =
        validQuantity(currentQuantity) || validProductCount(currentQuantity);

      if (invalidQuantity) {
        const error = { error: invalidQuantity };
        this.setState({
          [name + '--error']: error,
          formError: true,
        });
      }

      if (!invalidQuantity || !currentQuantity) {
        this.setState({
          [name + '--error']: null,
          formError: false,
        });
      }
    }
  }

  getQuantityStep = () => {
    const { product } = this.props;
    return product && product.sellInPackage ? product.package_size : 1;
  };

  findSelectedProduct = (selectedProducts, id, qty) => {
    const selectedProduct = selectedProducts.find(
      (product) => product.id === id
    );
    if (selectedProduct) {
      this.updateOrRemoveSelectedProductQuantity(
        selectedProducts,
        selectedProduct,
        qty
      );
    } else {
      qty > 0 && this.addSelectedProduct(selectedProducts, id, qty);
    }
  };

  updateOrRemoveSelectedProductQuantity = (
    selectedProducts,
    selectedProduct,
    qty
  ) => {
    const productIndex = selectedProducts.findIndex(
      (product) => product.id === selectedProduct.id
    );
    qty > 0 &&
      this.updateSelectedProductQuantity(
        selectedProducts,
        selectedProduct,
        qty,
        productIndex
      );
    (!qty || qty === 0) &&
      this.removeSelectedProduct(selectedProducts, productIndex);
  };

  updateSelectedProductQuantity = (
    selectedProducts,
    selectedProduct,
    qty,
    productIndex
  ) => {
    selectedProduct.qty = qty;
    selectedProducts.splice(productIndex, 1, selectedProduct);
    this.setState({ selectedProducts });
  };

  removeSelectedProduct = (selectedProducts, productIndex) => {
    selectedProducts.splice(productIndex, 1);
    this.setState({ selectedProducts });
  };

  addSelectedProduct = (selectedProducts, id, qty) => {
    selectedProducts.push({
      id,
      qty,
    });
    this.setState({ selectedProducts });
  };

  modifySelectedProductsForAnalytics = () => {
    const { accountStore, product, activeProductId } = this.props;
    const withTax = accountStore.showPricesWithTax;
    const analyticsProducts = this.state.selectedProducts.map(
      (selectedProduct) => {
        const analyticsProduct = {};
        analyticsProduct.product = product;
        analyticsProduct.quantity = selectedProduct.qty;
        analyticsProduct.activeProductId =
          activeProductId || selectedProduct.id;
        analyticsProduct.price = product.getPrice(withTax, selectedProduct.id);
        return analyticsProduct;
      }
    );

    this.setState({
      analyticsProducts,
    });
  };

  openTooltip = (inputTooltip) =>
    this.setState(() => ({ [inputTooltip]: true }));

  hideTooltip = (inputTooltip) =>
    this.setState(() => ({ [inputTooltip]: false }));

  quantityError = (
    productCollectionElementItem,
    productCollectionColumnElementId
  ) => {
    const quantity =
      this.state[
        `qty--${productCollectionElementItem.row_id}--${productCollectionColumnElementId}--error`
      ];

    const formError = quantity && quantity.error;

    if (!formError) {
      return null;
    }

    return (
      <FormFeedback className="ProductCollectionMatrix__invalid-feedback">
        {formError}
      </FormFeedback>
    );
  };

  renderAddSelectedProductsButton = (className) => {
    const { cartStore } = this.props;
    const loading = cartStore.state === RequestState.LOADING;
    return (
      <Button
        className={className}
        onClick={this.addSelectedProducts}
        color="primary"
        disabled={this.state.formError || loading}
        block
      >
        {loading ? (
          <Icon name="circle-o-notch" spin={loading} />
        ) : (
          <FormattedMessage
            id="productCollectionMatrix.addSelectedProducts"
            defaultMessage="Add Selected Products"
          />
        )}
      </Button>
    );
  };

  render() {
    const { accountStore, uiStore } = this.props;
    const size = accountStore.isViewOnly ? 12 : { size: 8, offset: 2 };
    const productRows = this.getCollectionRowElements();
    const styles = {
      tableLayout:
        !uiStore.isMobile && productRows.length >= 4 ? 'fixed' : 'unset',
    };
    return (
      <div className="ProductCollectionMatrix">
        <Row className="ProductCollectionMatrix__info-row">
          {!accountStore.isViewOnly && (
            <CustomCol md={3} xxl={2}>
              {this.renderAddSelectedProductsButton(
                'ProductCollectionMatrix__info-row--add-selected-products-button'
              )}
            </CustomCol>
          )}
          <CustomCol md={{ size: accountStore.isViewOnly ? 12 : 9 }} xxl={size}>
            <p className="ProductCollectionMatrix__product-matrix-info">
              <FormattedMessage {...globalTranslations.productMatrixInfo} />
            </p>
          </CustomCol>
        </Row>
        <Row>{this.renderProductMatrixTitles()}</Row>
        <Row>
          <Col xs={12}>
            <Table
              striped
              className="ProductCollectionMatrix__product-table"
              style={styles}
            >
              {!uiStore.isMobile && this.renderProductElementRows()}
              {this.renderProductColumns()}
            </Table>
          </Col>
        </Row>
        {!accountStore.isViewOnly && (
          <Row className="ProductCollectionMatrix__info-row-footer">
            <CustomCol
              md={{ size: 3, offset: 9 }}
              xxl={{ size: 2, offset: 10 }}
            >
              {this.renderAddSelectedProductsButton(
                'ProductCollectionMatrix__info-row-footer--add-selected-products-button'
              )}
            </CustomCol>
          </Row>
        )}
        {this.getImageLightbox()}
      </div>
    );
  }
}

ProductCollectionMatrix.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  cartStore: modelOf(CartStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  product: modelOf(Product).isRequired,
  productMatrix: modelOf(ProductMatrix).isRequired,
  activeProductId: PropTypes.string,
};

export default injectIntl(
  inject(
    'analytics',
    'accountStore',
    'cartStore',
    'configStore',
    'currencyStore',
    'uiStore'
  )(ProductCollectionMatrix)
);
