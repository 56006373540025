import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { observer } from 'mobx-react';

@observer
export class RobotsMeta extends Component {
  render() {
    const { noindex, nofollow } = this.props;
    const tags = [];
    if (noindex) {
      tags.push('noindex');
    }
    if (nofollow) {
      tags.push('nofollow');
    }
    return (
      <Helmet>
        <meta name="robots" content={tags.join(', ')} />
      </Helmet>
    );
  }
}

RobotsMeta.propTypes = {
  noindex: PropTypes.bool,
  nofollow: PropTypes.bool
};

export default RobotsMeta;
