import React, { Component } from 'react';
import { Row, Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import NavigationEntity from '../../../types/NavigationEntity';
import NavigationFirstLevelCategory from '../NavigationFirstLevelCategory';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import NavigationSecondLevelCategory from '../NavigationSecondLevelCategory';
import NavigationManufacturerBox from '../NavigationManufacturerBox';
import NavigationMegaMenuBanner from '../NavigationMegaMenuBanner';
import UIStore from '../../../store/UIStore';
import { allowScroll, blockScroll } from '../../../util/dom';
import AdStore from '../../../store/AdStore';
import AdZones from '../../../types/AdZones';
import ManufacturerStore from '../../../store/ManufacturerStore';

@observer
class NavigationTwoLevelMegaMenu extends Component {
  constructor(props) {
    super(props);
    const { configStore } = props;
    this.showManufacturers = configStore.navigation.megaMenu.showManufacturers;

    this.state = {
      manufacturers: [],
      hasActiveManufacturers: true,
    };
  }

  componentDidMount() {
    blockScroll();
  }

  componentWillUnmount() {
    allowScroll();
  }

  renderMegamenu = () => {
    const { configStore, uiStore, entityTree, onClick } = this.props;
    const { hasActiveManufacturers } = this.state;
    const hasManufacturers = this.hasManufacturers();
    const maximumSubCategoryCount =
      configStore.navigation.megaMenu.maximumSubCategoryCount;

    return entityTree.map((entity, index) => {
      uiStore.megaMenu.countEntities(entity);
      const ifTwoColumns =
        configStore.navigation.megaMenu.forceTwoSubCategoryColumns ||
        uiStore.megaMenu.getEntityCount(entity.id) <= maximumSubCategoryCount;

      return (
        <NavigationFirstLevelCategory
          key={entity.id}
          entityIndex={index}
          entity={entity}
          onClick={onClick}
          hasManufacturers={hasActiveManufacturers && hasManufacturers}
        >
          <Row
            className={classNames(
              'NavigationTwoLevelMegaMenu__second-level-items-row',
              {
                'NavigationTwoLevelMegaMenu__second-level-items-row--two-columns':
                  ifTwoColumns,
              }
            )}
          >
            {this.renderSecondLevelCategories(entity)}
          </Row>
        </NavigationFirstLevelCategory>
      );
    });
  };

  renderSecondLevelCategories = (entity) => {
    const { onClick } = this.props;

    return entity.children && entity.children.length > 0
      ? entity.children.map((subEntity) => {
          return (
            <NavigationSecondLevelCategory
              key={subEntity.id}
              entity={subEntity}
              onClick={onClick}
            />
          );
        })
      : null;
  };

  getAds = () => {
    const { adStore } = this.props;

    const searchParams = {
      bannerZone: AdZones.MEGA_MENU_BANNER,
    };

    return adStore.getAdsBySearchParameters(searchParams);
  };

  hasManufacturers = () => {
    const { manufacturerStore } = this.props;
    return manufacturerStore.hasManufacturers;
  };

  updateManufacturers = (manufacturers) => {
    if (!manufacturers || manufacturers.length === 0) {
      this.setState({
        manufacturers,
        hasActiveManufacturers: false,
      });
    }

    if (manufacturers && manufacturers.length > 0) {
      this.setState({
        manufacturers,
        hasActiveManufacturers: true,
      });
    }
  };

  renderManufacturerBox = () => {
    const { hasActiveManufacturers } = this.state;

    if (hasActiveManufacturers) {
      return (
        <div className="NavigationTwoLevelMegaMenu__manufacturers-row">
          <NavigationManufacturerBox
            onManufacturersUpdate={this.updateManufacturers}
          />
        </div>
      );
    }

    return null;
  };

  render() {
    const { hasActiveManufacturers } = this.state;
    const hasAds = this.getAds();
    const hasManufacturers = this.hasManufacturers();

    return (
      <div className="NavigationTwoLevelMegaMenu">
        <Container
          className={classNames('NavigationTwoLevelMegaMenu__wrapper', {
            'NavigationTwoLevelMegaMenu__wrapper--manufacturer-box-enabled':
              this.showManufacturers &&
              hasManufacturers &&
              hasActiveManufacturers,
          })}
        >
          <div
            className={classNames(
              'NavigationTwoLevelMegaMenu__first-level-items-row',
              {
                'NavigationTwoLevelMegaMenu__first-level-items-row--manufacturer-box-enabled':
                  this.showManufacturers &&
                  hasManufacturers &&
                  hasActiveManufacturers,
              }
            )}
          >
            {this.renderMegamenu()}
          </div>
          {this.showManufacturers &&
            hasManufacturers &&
            this.renderManufacturerBox()}
          <div
            className={classNames('NavigationTwoLevelMegaMenu__banner-row', {
              'NavigationTwoLevelMegaMenu__banner-row--banners-loaded': hasAds,
            })}
          >
            <NavigationMegaMenuBanner />
          </div>
        </Container>
      </div>
    );
  }
}

NavigationTwoLevelMegaMenu.propTypes = {
  adStore: modelOf(AdStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  manufacturerStore: modelOf(ManufacturerStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  entityTree: PropTypes.arrayOf(NavigationEntity).isRequired,
  onClick: PropTypes.func,
};

export default inject(
  'adStore',
  'configStore',
  'manufacturerStore',
  'uiStore'
)(NavigationTwoLevelMegaMenu);
