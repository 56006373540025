import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { observer } from 'mobx-react';

@observer
export class GenericMeta extends Component {
  render() {
    const { name, property, content } = this.props;
    const inputProps = {};

    if (name) {
      inputProps.name = name;
    }
    if (property) {
      inputProps.property = property;
    }
    if (content) {
      inputProps.content = content;
    }

    return (
      <Helmet>
        <meta {...inputProps} />
      </Helmet>
    );
  }
}

GenericMeta.propTypes = {
  name: PropTypes.string,
  property: PropTypes.string,
  content: PropTypes.string
};

export default GenericMeta;
