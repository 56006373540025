import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';

import NavigationDropdownDrawer from '../NavigationDropdownDrawer';
import Icon from '../../common/Icon';
import CouponText from '../../coupon/CouponText';
import globalTranslations from '../../../i18n/globalTranslations';
import NewsletterSubscriptionForm from '../../newsletter/NewsletterSubscriptionForm';
import CouponStore from '../../../store/CouponStore';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';

@observer
export class NavigationNewsletterDropdown extends Component {
  render() {
    const { configStore, couponStore } = this.props;

    if (!configStore.newsletter.enabled) {
      return null;
    }

    return (
      <NavigationDropdownDrawer
        className="NavigationNewsletterDropdown"
        label={
          <span>
            <Icon name="envelope" />
            <FormattedMessage
              {...globalTranslations.newsletterSubscriptionSentence}
            />{' '}
            {couponStore.newsletterCoupon && (
              <span>
                – <CouponText coupon={couponStore.newsletterCoupon} short />
              </span>
            )}
          </span>
        }
        right
      >
        <div className="NewsletterDropdown__inner">
          <p className="NewsletterDropdown__title">
            <FormattedMessage
              id="newsletter.popdownTitleWithoutDiscount"
              defaultMessage="Subscribe to our free newsletter!"
            />
          </p>
          {couponStore.newsletterCoupon && (
            <p className="NewsletterDropdown__coupon-incentive-box">
              <CouponText coupon={couponStore.newsletterCoupon} short />
            </p>
          )}
          <NewsletterSubscriptionForm />
        </div>
      </NavigationDropdownDrawer>
    );
  }
}

NavigationNewsletterDropdown.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  couponStore: modelOf(CouponStore).isRequired
};

export default inject('configStore', 'couponStore')(
  NavigationNewsletterDropdown
);
