import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Container } from 'reactstrap';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import Page from '../../components/layout/Page';
import MainProductList from '../../components/product-list/MainProductList';
import HelperNavigation from '../../components/common/HelperNavigation';
import GenericLayout from '../../components/layout/GenericLayout';
import ProductSortOrderBy from '../../types/ProductSortOrderBy';
import SortOrder from '../../types/SortOrder';
import globalTranslations from '../../i18n/globalTranslations';
import PageTitle from '../../components/common/PageTitle';
import Paths from '../../types/Paths';
import SEOTitle from '../../components/head/SEOTitle';
import CanonicalLink from '../../components/head/CanonicalLink';
import ContentPopup from '../../components/ad/ContentPopup';
import MainBanner from '../../components/ad/MainBanner';
import ScrollReset from '../../components/common/ScrollReset';
import AdPages from '../../types/AdPages';
import CommonPage from '../../types/CommonPage';
import { modelOf } from '../../prop-types';
import ConfigStore from '../../store/ConfigStore';
import SectionStore from '../../store/SectionStore';
import RouteService from '../../services/RouteService';

@observer
export class NewProductsPage extends Component {
  getBreadcrumbsPath = () => {
    const { intl, routeService } = this.props;
    return routeService.transformBreadCrumbs([
      {
        text: intl.formatMessage(globalTranslations.newProductsTitle),
        url: Paths.NewProducts,
      },
    ]);
  };

  getSearchParams = () => {
    return {
      bannerPage: AdPages.NEW_PRODUCTS,
    };
  };

  render() {
    const listId = 'new_products_list';
    const listName = 'New Products List';
    const { configStore, sectionStore, routeService } = this.props;
    const fixedParams = {
      orderBy: ProductSortOrderBy.DATE_ADDED,
      order: SortOrder.DESC,
      dateAdded: {
        // We set second and millisecond to 0 in order for the query key not to
        // change every (milli)second
        min: DateTime.fromObject({
          second: 0,
          millisecond: 0,
        })
          .minus({ seconds: configStore.product.isNewForSeconds })
          .toISO(),
      },
    };
    if (sectionStore.activeSection) {
      fixedParams.sections = [sectionStore.activeSection.id];
    }

    const mainBanner = <MainBanner searchParams={this.getSearchParams()} />;
    const mainBannerWithinContent =
      configStore.mainBanner.withinContent && !configStore.mainBanner.fullWidth;

    return (
      <Page name={CommonPage.NEW_PRODUCTS} className="NewProductsPage">
        <ScrollReset />
        <SEOTitle
          title={{
            ...globalTranslations.newProductsTitle,
          }}
        />
        <CanonicalLink path={routeService.getPath(Paths.NewProducts)} />

        {!mainBannerWithinContent && mainBanner}
        <Container className="NewProductsPage__container">
          <GenericLayout
            content={
              <Fragment>
                {mainBannerWithinContent && mainBanner}
                <HelperNavigation breadcrumbsPath={this.getBreadcrumbsPath()} />
                <PageTitle>
                  <FormattedMessage {...globalTranslations.newProductsTitle} />
                </PageTitle>
                <MainProductList
                  fixedParams={fixedParams}
                  listId={listId}
                  name={listName}
                  showSort={false}
                />
                <ContentPopup searchParams={this.getSearchParams()} />
              </Fragment>
            }
          />
        </Container>
      </Page>
    );
  }
}

NewProductsPage.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  intl: intlShape.isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
};

export default injectIntl(
  inject('configStore', 'sectionStore', 'routeService')(NewProductsPage)
);
