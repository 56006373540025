import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, matchPath, withRouter } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import RouterPropTypes from 'react-router-prop-types';
import { injectIntl, intlShape } from 'react-intl';

import ContentSlots from '../../ad/ContentSlots';
import AdZones from '../../../types/AdZones';
import MobileNavigationButton from '../MobileNavigationButton';
import MobileNavigationTab from '../../../types/MobileNavigationTab';
import AccountStore from '../../../store/AccountStore';
import UIStore from '../../../store/UIStore';
import ConfigStore from '../../../store/ConfigStore';
import NavigationLogo from '../NavigationLogo';
import QuickSearch from '../../search/QuickSearch';
import NavigationCart from '../NavigationCart';
import { modelOf } from '../../../prop-types';
import Icon from '../../common/Icon';
import Hamburger from '../../common/Hamburger';
import RouteService from '../../../services/RouteService';
import generatePath from '../../../util/generatePath';
import Paths from '../../../types/Paths';
import LicensePlateInput from '../../vehicle/LicensePlateInput';
import { addParametersToPath } from '../../../util/queryString';
import TranslatedImageType from '../../../types/TranslatedImageType';
import TranslationStore from '../../../store/TranslationStore';
import globalTranslations from '../../../i18n/globalTranslations';
import useNavigationEntityConverter from '../../../hooks/navigation/useNavigationEntityConverter';
import NavigationEntityItemButton from '../../navigation/NavigationEntityItemButton';
import NavigationEntityPosition from '../../../types/NavigationEntityPosition';
import ViewBreakpoint from '../../../types/ViewBreakpoint';
import CustomNavigationLinkStore from '../../../store/CustomNavigationLinkStore';

export const CommonNavigationMiddle = ({
  accountStore,
  configStore,
  customNavigationLinkStore,
  translationStore,
  uiStore,
  routeService,
  intl,
  history,
  location,
  ifShouldShowFullHeader,
  inputBlur,
  inputFocus,
  isSticky,
  onSearchKeyPress,
  onShowSearchOnMobile,
  shouldShowSearchOnMobile,
}) => {
  let hideCheckout =
    !configStore.topNavigation.hideCheckout && uiStore.isDesktop;
  const moveShoppingCartLoginRight =
    !configStore.topNavigation.moveShoppingCartLoginRight;
  const useStickyHeaderOnStartup =
    uiStore.isDesktop && configStore.topNavigation.useStickyHeaderOnStartup;

  const renderNavigationEntityItemButton = (navigationLinks) => {
    hideCheckout =
      !configStore.topNavigation.hideCheckout &&
      uiStore.activeBreakpoint === ViewBreakpoint.XXL;

    const navigationEntityItems = useNavigationEntityConverter(navigationLinks);

    return navigationEntityItems.map((entity) => (
      <div key={entity.id} className="NavigationEntityButton__button-wrapper">
        <NavigationEntityItemButton
          entity={entity}
          buttonContentClassName={'NavigationEntityButton__button-content'}
        />
      </div>
    ));
  };

  const renderNavigationHeader = () => {
    const showCart = !accountStore.isViewOnly;
    const centerShopLogo = configStore.topNavigation.centerShopLogo;

    return (
      <Fragment>
        {centerShopLogo && <div />}
        <NavigationLogo
          sticky={isSticky}
          useStickyHeaderOnStartup={useStickyHeaderOnStartup}
          ifShouldShowFullHeader={ifShouldShowFullHeader}
        />
        {!configStore.siteConfig.isHomePage && !centerShopLogo && (
          <div
            className={classNames('CommonNavigation__middle-right-content', {
              'CommonNavigation__middle-only-content': !showCart,
              'CommonNavigation__middle-center-content':
                configStore.topNavigation.center,
            })}
          >
            <div className="CommonNavigation__middle-right-content-top">
              {!configStore.topNavigation.moveSearchbarTopNavigation && (
                <QuickSearch
                  handleFocus={inputFocus}
                  handleBlur={inputBlur}
                  toggleSearch={onSearchKeyPress}
                />
              )}
              {getNavigationEntityButtons()}
              {showCart &&
                moveShoppingCartLoginRight &&
                configStore.siteConfig.isWebStore && (
                  <NavigationCart withCheckoutButton={hideCheckout} />
                )}
            </div>
          </div>
        )}
      </Fragment>
    );
  };

  const renderVehicleSearchPartHeader = () => {
    const showCart = !accountStore.isViewOnly;
    const vehiclePartSearchEnabled =
      configStore.vehiclePartSearch.enabled && uiStore.isHD;
    const centerShopLogo = configStore.topNavigation.centerShopLogo;

    return !centerShopLogo ? (
      <Row className="CommonNavigation__middle-content-row">
        <Col
          md={centerShopLogo ? 8 : 3}
          xl={centerShopLogo ? 8 : 4}
          className="pl-0"
        >
          {centerShopLogo && <div />}
          <NavigationLogo
            sticky={isSticky}
            useStickyHeaderOnStartup={useStickyHeaderOnStartup}
          />
        </Col>
        <Col
          md={9}
          xl={8}
          className={classNames('CommonNavigation__middle-right-content', {
            'CommonNavigation__middle-only-content': !showCart,
            'CommonNavigation__middle-center-content':
              configStore.topNavigation.center,
          })}
        >
          <div className="CommonNavigation__license-plate-input">
            {!configStore.topNavigation.moveSearchbarTopNavigation && (
              <LicensePlateInput
                handleOnSubmit={handleVehicleSearch}
                clearAfterSubmit
                hideSearchButtonText={vehiclePartSearchEnabled}
              />
            )}
          </div>
          <div className="CommonNavigation__middle-right-content-top">
            {!configStore.topNavigation.moveSearchbarTopNavigation && (
              <QuickSearch
                handleFocus={inputFocus}
                handleBlur={inputBlur}
                toggleSearch={onSearchKeyPress}
                vehiclePartSearchEnabled={vehiclePartSearchEnabled}
              />
            )}
            {getNavigationEntityButtons()}
            {showCart &&
              moveShoppingCartLoginRight &&
              configStore.siteConfig.isWebStore && (
                <NavigationCart
                  withCheckoutButton={hideCheckout}
                  className={'NavigationCart__vehicle-search-part'}
                  wrapperClassName={
                    'NavigationCart__vehicle-search-part-cart-button-wrapper'
                  }
                />
              )}
          </div>
        </Col>
      </Row>
    ) : (
      renderNavigationHeader()
    );
  };

  const desktopCommonNavigation = () => {
    const centerShopLogo = configStore.topNavigation.centerShopLogo;
    const showBanner = configStore.topNavigation.moveSearchbarTopNavigation;
    const vehiclePartSearchEnabled = configStore.vehiclePartSearch.enabled;
    return (
      <div
        className={classNames('CommonNavigation__middle-content', {
          'CommonNavigation__middle-content--centered-logo': centerShopLogo,
        })}
      >
        {vehiclePartSearchEnabled
          ? renderVehicleSearchPartHeader()
          : renderNavigationHeader()}
        {showBanner && getBannerZone()}
      </div>
    );
  };

  const getNavigationEntityButtons = () => {
    const navigationLinks = customNavigationLinkStore.getNavigationEntities();

    if (!navigationLinks.length) {
      return;
    }

    return renderNavigationEntityItemButton(navigationLinks);
  };

  const getNavigationEntityButtonsByPosition = (position) => {
    const navigationLinks =
      customNavigationLinkStore.getNavigationEntitiesByPosition(position);

    if (!navigationLinks.length) {
      return;
    }

    return renderNavigationEntityItemButton(navigationLinks);
  };

  const getBannerZone = () => (
    <div className="CommonNavigation__content-slots CommonNavigation__content-slots__container">
      <ContentSlots
        searchParams={{
          bannerZone: AdZones.HEADER_CONTENT,
        }}
        className="CommonNavigation__content-slots"
      />
    </div>
  );

  const mobileCommonNavigation = () => {
    const showCart = !accountStore.isViewOnly;
    const vehiclePartSearchEnabled = configStore.vehiclePartSearch.enabled;
    const mobileSearchbarVisibility =
      configStore.topNavigation.headerMobileSearchbarVisibility;
    const visibleMobileSearchbar =
      shouldShowSearchOnMobile || mobileSearchbarVisibility;
    const hideMobileContainer = uiStore.searchIsActive;

    return (
      <div className="CommonNavigation__middle-content">
        <Container className="CommonNavigation__middle-content-container">
          {!hideMobileContainer && (
            <Row
              className={classNames('CommonNavigation__mobile-container-row', {
                'CommonNavigation__mobile-container-row--hidden':
                  hideMobileContainer,
              })}
            >
              <Col xs={12} className="CommonNavigation__mobile-container">
                <MobileNavigationButton
                  tab={getDefaultMobileMenuTab()}
                  withBorder
                  forceClose={uiStore.mobileNavigation.isOpen}
                  ariaLabel={intl.formatMessage(
                    globalTranslations.seeCategoriesNavi
                  )}
                >
                  <Hamburger active={uiStore.mobileNavigation.isOpen} />
                </MobileNavigationButton>
                <div className="CommonNavigation__mobile-right">
                  {getNavigationEntityButtonsByPosition(
                    NavigationEntityPosition.LEFT
                  )}
                  <Link to="/">
                    <Button
                      color="plain"
                      className="CommonNavigation__icons"
                      aria-label={intl.formatMessage(
                        globalTranslations.homeTitle
                      )}
                    >
                      {getMobileLogoUrl() ? (
                        <img
                          alt={configStore.store.name}
                          src={getMobileLogoUrl()}
                        />
                      ) : (
                        <Icon name="home" className="fa-2x" />
                      )}
                    </Button>
                  </Link>
                  {getNavigationEntityButtonsByPosition(
                    NavigationEntityPosition.RIGHT
                  )}
                  {!configStore.siteConfig.isHomePage &&
                    !mobileSearchbarVisibility && (
                      <Button
                        color="plain"
                        className="CommonNavigation__icons"
                        onClick={onShowSearchOnMobile}
                        aria-label={intl.formatMessage(
                          globalTranslations.search
                        )}
                      >
                        <Icon name="search" className="fa-2x" />
                      </Button>
                    )}
                  {getMobileIconForAccountIfWebStore()}
                  {getMobileIconForWishListIfShoppingCentreAndEnabled()}
                  {configStore.storeInfoPageId > 0 && (
                    <Button
                      color="plain"
                      className="CommonNavigation__icons"
                      onClick={navigateToStoreInfoPage}
                      aria-label={intl.formatMessage(
                        globalTranslations.infoTitle
                      )}
                    >
                      <Icon name="map-marker" className="fa-2x" />
                    </Button>
                  )}
                  {showCart && configStore.siteConfig.isWebStore && (
                    <NavigationCart withCheckoutButton={hideCheckout} />
                  )}
                </div>
              </Col>
            </Row>
          )}
          {visibleMobileSearchbar && (
            <Row
              className={classNames('CommonNavigation__mobile-search-row', {
                'CommonNavigation__mobile-search-row--no-padding':
                  hideMobileContainer,
                'CommonNavigation__mobile-search-row--show':
                  visibleMobileSearchbar,
              })}
            >
              {vehiclePartSearchEnabled && (
                <Col sm={{ size: 6, offset: 6 }} className="mb-2">
                  <LicensePlateInput
                    handleOnSubmit={handleVehicleSearch}
                    clearAfterSubmit
                    hideSearchButtonText={!uiStore.isTablet}
                  />
                </Col>
              )}
              <Col sm={12}>
                <QuickSearch
                  handleFocus={inputFocus}
                  handleBlur={inputBlur}
                  toggleSearch={onSearchKeyPress}
                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    );
  };

  const getDefaultMobileMenuTab = () => {
    if (
      matchPath(location.pathname, {
        path: '/:locale?' + Paths.InfoPage,
      })
    ) {
      return MobileNavigationTab.INFO;
    }

    return MobileNavigationTab.PRODUCTS;
  };

  const getMobileLogoUrl = () => {
    const logo = translationStore.translatedImages.find(
      (image) => image.image_key === TranslatedImageType.STORE_MOBILE_LOGO
    );
    return logo && logo.image_path;
  };

  const getMobileIconForAccountIfWebStore = () => {
    return (
      configStore.siteConfig.isWebStore && (
        <MobileNavigationButton
          tab={MobileNavigationTab.ACCOUNT}
          className="ml-auto text-uppercase CommonNavigation__icons"
          ariaLabel={intl.formatMessage(globalTranslations.myAccountTitle)}
        >
          <Icon name="user" className="fa-2x" />
        </MobileNavigationButton>
      )
    );
  };

  const getMobileIconForWishListIfShoppingCentreAndEnabled = () => {
    return (
      configStore.wishlist.enabled &&
      configStore.siteConfig.isShoppingCenter && (
        <MobileNavigationButton
          tab={MobileNavigationTab.ACCOUNT}
          className="ml-auto text-uppercase"
          ariaLabel={intl.formatMessage(globalTranslations.seeCategoriesNavi)}
        >
          <Icon name="star" className="fa-2x" />
        </MobileNavigationButton>
      )
    );
  };

  const navigateToStoreInfoPage = () => {
    const storeInfoPagePath = generatePath(Paths.InfoPage, {
      slug: '-',
      id: configStore.storeInfoPageId,
    });
    history.push(routeService.getPath(storeInfoPagePath));
  };

  const handleVehicleSearch = (licensePlate) => {
    const licensePlateUrl = licensePlate
      ? addParametersToPath(Paths.VehiclePartSearch, {
          id: licensePlate,
        })
      : Paths.VehiclePartSearch;
    const url = routeService.getPath(licensePlateUrl);

    history.push(url);
  };

  return uiStore.isDesktop
    ? desktopCommonNavigation()
    : mobileCommonNavigation();
};

CommonNavigationMiddle.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  customNavigationLinkStore: modelOf(CustomNavigationLinkStore).isRequired,
  translationStore: modelOf(TranslationStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  intl: intlShape.isRequired,
  history: RouterPropTypes.history.isRequired,
  location: RouterPropTypes.location.isRequired,
  isSticky: PropTypes.bool.isRequired,
  shouldShowSearchOnMobile: PropTypes.bool.isRequired,
  inputBlur: PropTypes.func.isRequired,
  inputFocus: PropTypes.func.isRequired,
  onSearchKeyPress: PropTypes.func.isRequired,
  onShowSearchOnMobile: PropTypes.func.isRequired,
  ifShouldShowFullHeader: PropTypes.bool,
};

export default inject(
  'accountStore',
  'configStore',
  'customNavigationLinkStore',
  'translationStore',
  'uiStore',
  'routeService'
)(withRouter(injectIntl(observer(CommonNavigationMiddle))));
// observer HOC needs to be used as innermost until we have implemented MST as a hook.
//https://mobx.js.org/react-integration.html#wrap-order
