import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Container } from 'reactstrap';
import RouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import Page from '../../components/layout/Page';
import HelperNavigation from '../../components/common/HelperNavigation';
import { modelOf } from '../../prop-types';
import CategoryStore from '../../store/CategoryStore';
import MainProductList from '../../components/product-list/MainProductList';
import RouteService from '../../services/RouteService';
import SectionStore from '../../store/SectionStore';
import ProductTabs from '../../components/product/ProductTabs';
import ConfigStore from '../../store/ConfigStore';
import CategoryGrid from '../../components/category/CategoryGrid';
import WysiwygContent from '../../components/common/WysiwygContent';
import SidebarLayout from '../../components/layout/SidebarLayout';
import PageTitle from '../../components/common/PageTitle';
import ContentForState from '../../components/loader/ContentForState';
import SEOTitle from '../../components/head/SEOTitle';
import SEODescription from '../../components/head/SEODescription';
import CanonicalLink from '../../components/head/CanonicalLink';
import DefaultSidebar from '../../components/sidebar/DefaultSidebar';
import ContentPopup from '../../components/ad/ContentPopup';
import ScrollReset from '../../components/common/ScrollReset';
import MainBanner from '../../components/ad/MainBanner';
import SuitableProductFinder from '../../components/suitable-product/SuitableProductFinder';
import { pathFragmentMatchesLocation } from '../../util/url';
import UIStore from '../../store/UIStore';
import Paths from '../../types/Paths';
import CommonPage from '../../types/CommonPage';
import ProductPdf from '../../components/product-pdf/ProductPdf';
import ProductPdfType from '../../types/ProductPdfType';

@observer
class CategoryPage extends Component {
  constructor(props) {
    super(props);
    this.yotpoTimeout = null;
    this.ensureCorrectActiveCategorySet();

    this.state = {
      productCount: 0,
    };
  }

  componentDidMount() {
    // Another component from the previous DOM may fire its componentWillUnmount
    // and clear the category in between the initial getDerivedStateFromProps
    // and componentDidMount. To compensate for that, check whether the category
    // needs to be loaded again.
    this.ensureCorrectActiveCategorySet();
  }

  componentDidUpdate() {
    const { configStore } = this.props;
    this.ensureCorrectActiveCategorySet();
    if (configStore.integrations.yotpo.enabled && window && window.yotpo) {
      this.yotpoTimeout = setTimeout(() => window.yotpo.refreshWidgets(), 800);
    }
  }

  componentWillUnmount() {
    const { categoryStore, match } = this.props;
    // Clean-up active category, if it was set by this component
    if (
      categoryStore.activeCategory &&
      categoryStore.activeCategory.id === Number(match.params.id)
    ) {
      categoryStore.setActiveCategory(null);
    }

    this.yotpoTimeout && clearTimeout(this.yotpoTimeout);
  }

  updateProductCount = (count) => {
    if (count !== this.state.productCount) {
      this.setState({
        productCount: count,
      });
    }
  };

  ensureCorrectActiveCategorySet = () => {
    const { categoryStore, match } = this.props;

    if (
      !categoryStore.activeCategory ||
      categoryStore.activeCategory.id !== Number(match.params.id)
    ) {
      this.updateActiveCategory();
    }
  };

  updateActiveCategory = () => {
    const { categoryStore, match } = this.props;
    categoryStore.setActiveCategory(match.params.id);

    // If active category did not get set, return 404 to user, because it means it was not found.
    if (!categoryStore.activeCategory) {
      categoryStore.setSingleStateError({ response: { status: 404 } });
      return;
    }

    if (categoryStore.activeCategory.description_html === null) {
      categoryStore.loadCategory(match.params.id).catch((e) => {
        console.error(e);
      });
    }
  };

  getBreadcrumbsPath = () => {
    const { categoryStore, routeService } = this.props;

    if (!categoryStore.activeCategory) {
      return [];
    }

    return routeService.transformBreadCrumbs(
      categoryStore.activeCategory.breadcrumbs
    );
  };

  pdfCatalogEnabled = () => {
    const { accountStore, configStore } = this.props;
    const { showPdfButton } = configStore.productList;

    if (showPdfButton === '0') {
      return false;
    }
    if (
      showPdfButton === '1' ||
      (showPdfButton === '2' && accountStore.loggedIn)
    ) {
      return true;
    }
    return showPdfButton === '3' && accountStore.isRetailer;
  };

  renderProductPdfButton = () => {
    const { categoryStore, configStore } = this.props;
    const { productCount } = this.state;

    const isMainCategory = categoryStore.activeCategory.ancestors.length === 0;
    let requireEmail = true;
    const children = this.getChildren();
    const hasChildren = children.length > 0;

    const hasProductList =
      !isMainCategory ||
      (isMainCategory &&
        (configStore.mainCategoryPage.showMainProductList || !hasChildren));

    if (
      hasProductList &&
      productCount <= configStore.productList.productCountForEmail
    ) {
      requireEmail = false;
    }

    return (
      <ProductPdf
        activeId={categoryStore.activeCategory.id.toString()}
        pdfType={ProductPdfType.CATEGORY}
        requireEmail={requireEmail}
      />
    );
  };

  getChildren = () => {
    const { categoryStore, sectionStore } = this.props;
    return sectionStore.activeSection
      ? categoryStore.activeCategory.getNonBlockedChildren(
          sectionStore.activeSection.blockedCategoriesDict
        )
      : categoryStore.activeCategory.children;
  };

  getCategoryContent = () => {
    const listId = 'category_list';
    const listName = 'Category List';
    const { match, sectionStore, categoryStore, configStore, uiStore } =
      this.props;

    const fixedParams = {
      categories: [match.params.id],
      cross_categories: [],
    };
    if (sectionStore.activeSection) {
      fixedParams.sections = [sectionStore.activeSection.id];
    }
    const isMainCategory = categoryStore.activeCategory.ancestors.length === 0;
    const showCategoryFilter =
      categoryStore.activeCategory.show_category_filter;

    if (
      configStore.suitableProducts.enabledOnCategories.indexOf(
        categoryStore.activeCategory.id
      ) !== -1
    ) {
      return (
        <SuitableProductFinder
          categoryId={Number(match.params.id)}
          listId={listId}
          listName={listName}
        />
      );
    } else if (isMainCategory) {
      let maybeCategoryGrid, hasChildren, drawCategoryGrid;
      const children = this.getChildren();
      hasChildren = children.length > 0;

      if (configStore.mainCategoryPage.showCategoryGrid) {
        maybeCategoryGrid = <CategoryGrid categories={children} />;
        drawCategoryGrid = true;
      }
      let maybeProductList;
      if (configStore.mainCategoryPage.showMainProductList || !hasChildren) {
        maybeProductList = (
          <MainProductList
            fixedParams={fixedParams}
            listId={listId}
            name={listName}
            showCategoryFilter={showCategoryFilter}
            updateProductCount={this.updateProductCount}
          />
        );
        if (uiStore.isMobile) {
          drawCategoryGrid = false;
        }
      } else if (configStore.mainCategoryPage.showProductTabs) {
        maybeProductList = (
          <ProductTabs
            searchParams={{
              ...fixedParams,
            }}
          />
        );
      }

      return (
        <div>
          {drawCategoryGrid && maybeCategoryGrid}
          {maybeProductList}
        </div>
      );
    } else {
      return (
        <MainProductList
          fixedParams={fixedParams}
          listId={listId}
          name={listName}
          showCategoryFilter={showCategoryFilter}
          updateProductCount={this.updateProductCount}
        />
      );
    }
  };

  render() {
    const {
      configStore,
      categoryStore,
      routeService,
      sectionStore,
      match,
      location,
    } = this.props;

    if (categoryStore.activeCategory === null) {
      return <Redirect to={routeService.getPath(Paths.NotFoundPage)} />;
    }

    const adSearchParams = {
      category: categoryStore.activeCategory && categoryStore.activeCategory.id,
    };

    const mainBanner = <MainBanner searchParams={adSearchParams} />;
    const mainBannerWithinContent = configStore.mainBanner.withinContent;

    const maybeRedirectTo = `${routeService.getCategoryPath(
      categoryStore.activeCategory
    )}${location.search}${location.hash}`;

    // Fix the URL when section store is missing activeSection
    if (configStore.activateSections && sectionStore.activeSection === null) {
      // Unless the category has no section
      if (
        routeService.getCategoryPath(categoryStore.activeCategory) !==
        categoryStore.activeCategory.path
      ) {
        return <Redirect to={maybeRedirectTo} />;
      }
    }

    // Fix the URL if it contains unexpected parts
    if (
      categoryStore.activeCategory &&
      String(categoryStore.activeCategory.id) === match.params.id &&
      !pathFragmentMatchesLocation(
        categoryStore.activeCategory.path,
        location.pathname
      )
    ) {
      return <Redirect to={maybeRedirectTo} />;
    }

    return (
      <Page name={CommonPage.CATEGORY} className="CategoryPage">
        <ScrollReset key={match.params.id} />
        {!mainBannerWithinContent && mainBanner}
        <Container className="CategoryPage__container">
          <SidebarLayout
            sidebar={<DefaultSidebar bannerSearchParams={adSearchParams} />}
            content={
              <ContentForState
                state={categoryStore.singleState}
                error={categoryStore.lastError}
                forLoaded={() =>
                  categoryStore.activeCategory && (
                    <Fragment>
                      <SEOTitle
                        title={
                          categoryStore.activeCategory.SEO_title ||
                          categoryStore.activeCategory.name
                        }
                        noSuffix={!!categoryStore.activeCategory.SEO_title}
                      />
                      {categoryStore.activeCategory.SEO_description && (
                        <SEODescription
                          content={categoryStore.activeCategory.SEO_description}
                        />
                      )}
                      <CanonicalLink
                        path={routeService.getCanonicalCategoryPath(
                          categoryStore.activeCategory
                        )}
                      />
                      {mainBannerWithinContent && mainBanner}
                      <HelperNavigation
                        breadcrumbsPath={this.getBreadcrumbsPath()}
                      />
                      <PageTitle>{categoryStore.activeCategory.name}</PageTitle>
                      <div className="CategoryPage__category-content">
                        <WysiwygContent
                          html={categoryStore.activeCategory.description_html}
                        />
                      </div>
                      {this.pdfCatalogEnabled() &&
                        this.renderProductPdfButton()}
                      {this.getCategoryContent()}
                      <div className="CategoryPage__category-footer-content">
                        <WysiwygContent
                          html={
                            categoryStore.activeCategory.footer_description_html
                          }
                        />
                      </div>
                      <ContentPopup searchParams={adSearchParams} />
                    </Fragment>
                  )
                }
              />
            }
          />
        </Container>
      </Page>
    );
  }
}

CategoryPage.propTypes = {
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  configStore: modelOf(ConfigStore),
  categoryStore: modelOf(CategoryStore),
  sectionStore: modelOf(SectionStore),
  uiStore: modelOf(UIStore),
  match: RouterPropTypes.match.isRequired,
  location: RouterPropTypes.location.isRequired,
};

export default inject(
  'configStore',
  'categoryStore',
  'routeService',
  'sectionStore',
  'uiStore'
)(CategoryPage);
