import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import CartProduct from '../../../models/cart/CartProduct';
import ProductImage from '../../product/ProductImage';
import Price from '../../product/Price';
import ProductImageModel from '../../../models/ProductImage';
import Icon from '../../common/Icon';
import CartStore from '../../../store/CartStore';
import Analytics from '../../../analytics/Analytics';
import { roundWithPrecision } from '../../../util/number';
import CurrencyStore from '../../../store/CurrencyStore';

const CartProductRow = ({ cartStore, currencyStore, analytics, product }) => {
  const removeProduct = () => {
    const params = {
      id: product.id,
    };

    if (product.bundle_id) {
      params.bundleId = product.bundle_id;
    }

    cartStore.remove(params).then(sendRemoveCartAnalyticsEvent);
  };

  const sendRemoveCartAnalyticsEvent = () => {
    const removedProducts = cartStore.getCartRemovedProducts();
    const removedProductsValue = removedProducts.reduce((sum, cartProduct) => {
      return sum + cartProduct.analytics.price;
    }, 0);

    analytics.removeFromCart({
      productList: removedProducts,
      value: roundWithPrecision(
        removedProductsValue,
        currencyStore.activeCurrency.precision
      ),
    });
  };

  const createProductImage = (url) => {
    return url
      ? ProductImageModel.create({
          id: 0,
          product_id: '',
          sizes: {
            small: url,
          },
        })
      : null;
  };

  return (
    <Row
      className={classNames('CartProductRow', 'CartModal__product-list--item')}
    >
      <Col
        className="CartModal__left-column-left d-flex align-content-center"
        xs={4}
      >
        <div className="CartModal__column-image d-flex align-content-center">
          <ProductImage
            product={product}
            productImage={createProductImage(product.image)}
            size="small"
            forceAspectRatio={false}
            lazyLoading={false}
          />
        </div>
      </Col>
      <Col xs={8}>
        <div className="CartModal__column-info">
          <div className="CartModal__column-row">
            <span className="CartModal__column-quantity">
              {product.totalQuantity} {product.stock_unit}
            </span>
            <Button
              className="CartModal__remove-button"
              outline
              color="danger"
              onClick={removeProduct}
              disabled={cartStore.cartButtonDisabled}
            >
              <Icon name="trash" />
            </Button>
          </div>
          <div className="CartModal__column-name">
            <span>{product.name}</span>
            <span>{product.productCode}</span>
          </div>
          {product.variations.length > 0 && (
            <div className="CartModal__column-variations">
              {product.variations.map((variation) => (
                <span key={variation} className="CartModal__column-variation">
                  {variation}
                </span>
              ))}
            </div>
          )}
          {product.total_price && (
            <div className="CartModal__column-price">
              <Price price={product.total_price} />
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

CartProductRow.propTypes = {
  cartStore: modelOf(CartStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  product: modelOf(CartProduct).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
};

export default inject(
  'cartStore',
  'currencyStore',
  'analytics'
)(observer(CartProductRow));
