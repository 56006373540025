import React, { Component, Fragment } from 'react';
import { ListGroup, ListGroupItem, Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import { last, get } from 'lodash';
import { produce } from 'immer';
import classNames from 'classnames';

import AdZones from '../../../types/AdZones';
import ContentSlots from '../../ad/ContentSlots';
import Icon from '../../common/Icon';
import NavigationEntity from '../../../types/NavigationEntity';
import safeCloneDeep from '../../../util/safeCloneDeep';
import ButtonLink from '../../common/ButtonLink';
import MobileNavigationRegionalSettingsSummary from '../../common/MobileNavigationRegionalSettingsSummary';
import {
  isNotHandledByReactRouter,
  stripHostIfMatches,
  transformInternalLink,
} from '../../../util/url';
import MobileNavigationTab from '../../../types/MobileNavigationTab';
import {
  getElementRelativeBottom,
  getElementRelativeTop,
} from '../../../util/dom';
import NavigationHiddenContentHelper from '../NavigationHiddenContentHelper';
import LinkType from '../../../types/LinkType';
import CustomNavigationLinkLocationType from '../../../types/CustomNavigationLinkLocation';

const ENTRY_LEVEL_FOR_HOME_LINK = 3;
const ENTRY_LEVEL_FOR_HEADER = 2;
const MAYBE_SECTION_FRONTPAGE = 2;

@observer
export class MobileNavigationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entityTree: safeCloneDeep(props.entities),
      relativeTop: null,
      helperIsSeen: false,
      showHelper: false,
      updated: false,
    };

    this.listElement = null;
  }

  componentDidMount() {
    if (this.listElement) {
      window.addEventListener('scroll', this.handleScroll, true);
      this.setState({
        relativeTop: getElementRelativeTop(this.listElement),
      });
      this.shouldShowHelper();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.helperIsSeen !== this.state.helperIsSeen ||
      (this.state.helperIsSeen === false && prevState.helperIsSeen === false) ||
      prevProps.activeTabId !== this.props.activeTabId
    ) {
      this.shouldShowHelper(prevProps.activeTabId !== this.props.activeTabId);
    }
  }

  componentWillUnmount() {
    if (this.listElement) {
      window.removeEventListener('scroll', this.handleScroll, true);
    }
  }

  shouldShowHelper = (tabChanged = false) => {
    if (this.state.helperIsSeen === false || tabChanged) {
      if (this.state.updated === false || tabChanged) {
        this.setState({
          showHelper:
            getElementRelativeBottom(this.listElement) > window.innerHeight,
          updated: true,
        });
      }
    }
  };

  handleScroll = (e) => {
    e.preventDefault();
    if (this.state.relativeTop > getElementRelativeTop(this.listElement)) {
      this.setState({
        helperIsSeen: true,
        showHelper: false,
      });
    }
  };

  getHomeButton = (path) => {
    return (
      <ListGroupItem className="d-sm-none MobileNavigationList__item MobileNavigationList__home-menu-link">
        <Button
          color="plain"
          className="MobileNavigationList__button-back"
          onClick={() => this.selectItem(path.slice(0, 1))}
        >
          <Icon className="d-sm-none" name="angle-double-left" />
          <span className="d-sm-none">
            <FormattedMessage
              id="navigation.backToBegin"
              defaultMessage="Back to the begin"
            />
          </span>
        </Button>
      </ListGroupItem>
    );
  };

  getMenuBackButton = (menu, path) => {
    return (
      <ListGroupItem className="MobileNavigationList__item MobileNavigationList__previous-menu-link-option">
        <Button
          color="plain"
          className="MobileNavigationList__button-back"
          onClick={() => this.unselectChildren(path)}
        >
          <Icon name="angle-left" />
          <span className="d-block d-sm-none">
            <FormattedMessage
              id="navigation.backToItem"
              defaultMessage="Back to {name}"
              values={{ name: menu.label }}
            />
          </span>
          <span className="d-none d-sm-block">{menu.label}</span>
        </Button>
      </ListGroupItem>
    );
  };

  getNavigateToMessage = (path) => {
    const { activeTabId, hasSections } = this.props;
    let message = null;

    switch (activeTabId) {
      case MobileNavigationTab.PRODUCTS:
        if (hasSections && path.length === MAYBE_SECTION_FRONTPAGE) {
          message = (
            <FormattedMessage
              id="navigation.navigateToSectionItem"
              defaultMessage="See section frontpage here"
            />
          );
        } else {
          message = (
            <FormattedMessage
              id="navigation.navigateToCategoryItem"
              defaultMessage="See all products here"
            />
          );
        }
        break;
      case MobileNavigationTab.INFO:
        message = (
          <FormattedMessage
            id="navigation.navigateToInfoItem"
            defaultMessage="See page content here"
          />
        );
        break;
      default:
    }

    return message;
  };

  getNavigateToButton = (menu, path) => {
    return (
      <ListGroupItem className="MobileNavigationList__item MobileNavigationList__navigate-to-link-option">
        <Button
          color="plain"
          className="MobileNavigationList__button-navigate"
          onClick={() => this.navigateTo(menu.path)}
        >
          {path.length >= ENTRY_LEVEL_FOR_HEADER && (
            <span className="d-block d-sm-none text-uppercase MobileNavigationList__header">
              {menu.label}
            </span>
          )}
          <span className="d-block d-sm-none">
            {this.getNavigateToMessage(path)}
            <Icon
              className="MobileNavigationList__header-icon"
              name="angle-double-right"
            />
          </span>
          <span className="d-none d-sm-block">{menu.label}</span>
        </Button>
      </ListGroupItem>
    );
  };

  getChildListGroupItem(menu, path) {
    return menu.children.map((child, index) => {
      const currentPath = path.concat([index]);
      const { customNavLinks } = child;
      const image = child.image ? (
        <img src={child.image} alt={child.image} loading="lazy" />
      ) : null;
      const type = child.type ? child.type : null;
      const childPath = this.getChildPath(child, type);
      const linkOrLabel = this.getLinkOrLabel(
        child,
        childPath,
        currentPath,
        type,
        image
      );
      return (
        <Fragment key={child.id}>
          {customNavLinks &&
            customNavLinks.map(
              (customNavLink, idx) =>
                customNavLink.location ===
                  CustomNavigationLinkLocationType.ABOVE &&
                customNavLink.parent_sibling_id.parent_sibling_id ===
                  child.id &&
                this.getCustomNavItemProperties(
                  customNavLink,
                  idx,
                  currentPath,
                  image
                )
            )}
          <ListGroupItem
            active={child.active}
            className={classNames('MobileNavigationList__item', {
              'MobileNavigationList__item--empty': !child.label,
            })}
          >
            {linkOrLabel}
          </ListGroupItem>
          {customNavLinks &&
            customNavLinks.map(
              (customNavLink, idx) =>
                customNavLink.location ===
                  CustomNavigationLinkLocationType.BELOW &&
                customNavLink.parent_sibling_id.parent_sibling_id ===
                  child.id &&
                this.getCustomNavItemProperties(
                  customNavLink,
                  idx,
                  currentPath,
                  image
                )
            )}
        </Fragment>
      );
    });
  }

  getCustomNavItemProperties = (customNavLink, index, currentPath, image) => {
    return (
      <Fragment key={index}>
        {customNavLink.customNavLinks &&
          customNavLink.customNavLinks.map(
            (subCustomNavLink, idx) =>
              subCustomNavLink.location ===
                CustomNavigationLinkLocationType.ABOVE &&
              subCustomNavLink.parent_sibling_id.parent_sibling_id ===
                customNavLink.id &&
              this.getCustomNavItemProperties(
                subCustomNavLink,
                idx,
                currentPath,
                image
              )
          )}
        <ListGroupItem key={index} className={'MobileNavigationList__item'}>
          <ButtonLink
            href={customNavLink.path}
            onClick={() => {
              this.navigateTo(customNavLink.path);
            }}
          >
            <div>{customNavLink.label}</div>
          </ButtonLink>
        </ListGroupItem>
        {customNavLink.customNavLinks &&
          customNavLink.customNavLinks.map(
            (subCustomNavLink, idx) =>
              subCustomNavLink.location ===
                CustomNavigationLinkLocationType.BELOW &&
              subCustomNavLink.parent_sibling_id.parent_sibling_id ===
                customNavLink.id &&
              this.getCustomNavItemProperties(
                subCustomNavLink,
                idx,
                currentPath,
                image
              )
          )}
      </Fragment>
    );
  };

  getLinkOrLabel = (child, childPath, currentPath, type, image) => {
    const buttonImage = image ? (
      <div className="image-grouper">
        {image}
        {child.label}
      </div>
    ) : (
      child.label
    );
    return child.path || (child.children && child.children.length > 0) ? (
      <ButtonLink
        href={childPath}
        onClick={() => {
          if (
            (child.children && child.children.length > 0) ||
            (child.path && child.path === '#')
          ) {
            this.selectItem(currentPath);
          } else {
            this.navigateTo(childPath);
          }
        }}
        type={type}
      >
        {buttonImage}
        {child.children && child.children.length > 0 && (
          <Icon name="angle-right" />
        )}
      </ButtonLink>
    ) : (
      <Fragment>
        {image}
        <span>{child.label}</span>
      </Fragment>
    );
  };

  getChildPath = (child, type) => {
    let childPath = child.path;
    if (type && type === LinkType.INTERNAL_LINK) {
      return transformInternalLink(childPath);
    }

    if (type && type === LinkType.PAGE_LINK) {
      return stripHostIfMatches(childPath);
    }

    return childPath;
  };

  getNextMenuLevel = (menu, path, parent) => {
    const activeChildIndex = menu.children.findIndex(
      (submenu) => submenu.active
    );
    const activeChild = menu.children[activeChildIndex];
    const isNested = !!parent;

    return (
      <Fragment>
        <div className="MobileNavigationList__menu-container" key={menu.id}>
          <ListGroup>
            {path.length >= ENTRY_LEVEL_FOR_HOME_LINK &&
              this.getHomeButton(path)}
            {isNested && this.getMenuBackButton(parent, path.slice(0, -1))}
            {isNested && this.getNavigateToButton(menu, path)}
            {this.getChildListGroupItem(menu, path)}
          </ListGroup>
        </div>
        {activeChild &&
          activeChild.children &&
          activeChild.children.length > 0 &&
          this.getNextMenuLevel(
            activeChild,
            path.concat([activeChildIndex]),
            menu
          )}
      </Fragment>
    );
  };

  getBody = (activeTab, path) => (
    <div className="MobileNavigationList__body">
      {this.getNextMenuLevel(activeTab, path)}
    </div>
  );

  getFooter = () => (
    <div className="MobileNavigationList__footer">
      {this.getBannerZone()}
      <MobileNavigationRegionalSettingsSummary />
    </div>
  );

  getBannerZone = () => (
    <ContentSlots
      searchParams={{
        bannerZone: AdZones.HEADER_CONTENT,
      }}
      className="MobileNavigation__content-slots"
    />
  );

  getActualEntityTreePath = (path) =>
    'entityTree' + path.map((index) => `[${index}]`).join('.children');

  unselectChildren = (path) => this.selectItem(path);

  selectItem = (path) => {
    this.setState((currentState) => {
      return produce(currentState, (draftState) => {
        const parentIndexes = path.slice(0, -1);
        const indexToSelect = last(path);

        let childrenOfParentNode;
        if (parentIndexes.length > 0) {
          childrenOfParentNode = get(
            draftState,
            this.getActualEntityTreePath(parentIndexes)
          ).children;
        } else {
          childrenOfParentNode = draftState.entityTree;
        }

        // First, unselect all children of the parent node.
        childrenOfParentNode.forEach((child) => {
          child.active = false;
        });

        // Then, select the requested node if it exists.
        const itemToSelect = childrenOfParentNode[indexToSelect];

        if (itemToSelect) {
          itemToSelect.active = true;

          // Also clean up the active states of the selected node's children.
          if (itemToSelect.children) {
            itemToSelect.children.forEach((child) => {
              child.active = false;
            });
          }
        }
      });
    });

    this.setState({
      helperIsSeen: false,
      updated: false,
    });
  };

  navigateTo = (url) => {
    const { host, history, close } = this.props;
    const maybeRelativeUrl = stripHostIfMatches(url, host);
    if (isNotHandledByReactRouter(maybeRelativeUrl)) {
      return window.open(maybeRelativeUrl, '_blank', 'noopener');
    } else {
      history.push(maybeRelativeUrl);
    }
    close();
  };

  render() {
    const { className, activeTabId } = this.props;
    const { entityTree, showHelper } = this.state;

    if (!entityTree) {
      return null;
    }

    let openedTabIndex = entityTree.findIndex((tab) => tab.id === activeTabId);
    if (openedTabIndex === -1) {
      openedTabIndex = 0;
    }
    const openedTab = entityTree[openedTabIndex];
    const openedPathRoot = [openedTabIndex];

    return (
      <div
        ref={(ref) => (this.listElement = ref)}
        className={classNames('MobileNavigationList', className)}
      >
        {this.getBody(openedTab, openedPathRoot)}
        {this.getFooter(entityTree)}
        <NavigationHiddenContentHelper visible={showHelper} />
      </div>
    );
  }
}

MobileNavigationList.propTypes = {
  /**
   * The entity tree is expected to be at least two levels deep.
   * - First level: "Tabs"
   * - Second and below levels: actual menu content
   *
   * Tabs should be considered pure containers; you can't link to
   * anywhere with a tab directly even if you pass a path on that
   * level.
   */
  entities: PropTypes.arrayOf(NavigationEntity).isRequired,
  close: PropTypes.func.isRequired,
  hasSections: PropTypes.bool.isRequired,
  history: RouterPropTypes.history.isRequired,
  host: PropTypes.string.isRequired,
  activeTabId: PropTypes.string,
  className: PropTypes.string,
};

export default inject((stores) => ({
  host: stores.locationContext.host,
}))(withRouter(MobileNavigationList));
