import React from 'react';
import { inject, observer } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';

export const FooterCopyright = ({ configStore }) => {
  return (
    <div
      className="FooterCopyright"
      dangerouslySetInnerHTML={{ __html: configStore.copyright }}
    />
  );
};

FooterCopyright.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
};

export default inject('configStore')(observer(FooterCopyright));
