import { getParentOfType, types } from 'mobx-state-tree';

import ProductProperty from './ProductProperty';
import ProductCollectionItem from './ProductCollectionItem';
import ProductPropertyType from '../types/ProductPropertyType';
import Product from './Product';
import ProductAvailabilityType from '../types/ProductAvailabilityType';

const ProductMatrix = types
  .model('ProductMatrix', {
    row: ProductProperty,
    column: ProductProperty,
    items: types.array(ProductCollectionItem)
  })
  .views((self) => {
    return {
      get product() {
        return getParentOfType(self, Product);
      },
      shouldSwapCollectionOrder() {
        // Default order is: column, row. If later collection (row) is COLOR, collections are swapped.
        return self.row.type === ProductPropertyType.COLOR;
      },
      getElementIds(elementId, selectedElementId, isFirstCollection) {
        const elementIds = {
          columnId: null,
          rowId: null
        };

        // Check if collections are swapped
        let firstProperty = 'columnId';
        let secondProperty = 'rowId';
        if (self.shouldSwapCollectionOrder()) {
          firstProperty = 'rowId';
          secondProperty = 'columnId';
        }

        if (isFirstCollection) {
          elementIds[firstProperty] = elementId;
        } else {
          elementIds[firstProperty] = selectedElementId;
          elementIds[secondProperty] = elementId;
        }

        // If a property is empty, we know that the element ID is 0.
        if (self.column.isEmpty()) {
          elementIds.columnId = 0;
        }
        if (self.row.isEmpty()) {
          elementIds.rowId = 0;
        }

        return elementIds;
      },
      getItemStatus(columnId, rowId, availabilityType) {
        const status = {
          exists: false,
          available: false
        };

        const item = self.getItem(columnId, rowId);
        if (item) {
          status.exists = true;
        }

        if (
          item &&
          availabilityType !== ProductAvailabilityType.CLOSEOUT &&
          (item.canBeOrderedOutOfStock() ||
            item.getAvailableAmount() > self.product.sellInPackage)
        ) {
          status.available = true;
        }

        if (
          item &&
          availabilityType === ProductAvailabilityType.CLOSEOUT &&
          item.getAvailableAmount() > self.product.sellInPackage
        ) {
          status.available = true;
        }

        return status;
      },
      getRowStatus(rowId) {
        return self.getPropertyStatus('row_id', rowId);
      },
      getColumnStatus(columnId) {
        return self.getPropertyStatus('column_id', columnId);
      },
      getPropertyStatus(propertyName, elementId, availabilityType) {
        let status = {
          exists: false,
          available: false
        };

        // Check if we have any items for the property and if they are available
        const matchingItems = self.items.filter(
          (currentItem) => currentItem[propertyName] === elementId
        );

        if (matchingItems.length > 0) {
          status.exists = true;
        }

        status.available = matchingItems.some((currentItem) => {
          if (
            currentItem &&
            availabilityType !== ProductAvailabilityType.CLOSEOUT &&
            (currentItem.canBeOrderedOutOfStock() ||
              currentItem.getAvailableAmount() > self.product.sellInPackage)
          ) {
            return true;
          }

          if (
            currentItem &&
            availabilityType === ProductAvailabilityType.CLOSEOUT &&
            currentItem.getAvailableAmount() > self.product.sellInPackage
          ) {
            return true;
          }

          return false;
        }
        );

        return status;
      },
      getItem(columnId, rowId) {
        return self.items.find((item) => {
          return item.column_id === columnId && item.row_id === rowId;
        });
      },
      getItemWithProductId(productId) {
        return self.items.find((item) => {
          return item.product.id === productId;
        });
      },
      propertyIsSelectable(property) {
        return !property.isEmpty() && property.elements.length > 1;
      },
      columnIsSelectable() {
        return self.propertyIsSelectable(self.column);
      },
      getDefaultColumnElement() {
        return !self.columnIsSelectable() ? self.column.elements[0] : null;
      },
      rowIsSelectable() {
        return self.propertyIsSelectable(self.row);
      },
      getDefaultRowElement() {
        return !self.rowIsSelectable() ? self.row.elements[0] : null;
      },
      getNames() {
        return self.shouldSwapCollectionOrder()
          ? { columnName: self.row.name, rowName: self.column.name }
          : { columnName: self.column.name, rowName: self.row.name };
      },
      getRowElements() {
        return self.shouldSwapCollectionOrder()
          ? self.column.elements
          : self.row.elements;
      },
      getColumnElements() {
        return self.shouldSwapCollectionOrder()
          ? self.row.elements
          : self.column.elements;
      },
      getProductElementNamesByItemElementId(rowId, columnId) {
        const rowElement =
          self.row &&
          self.row.elements &&
          self.row.elements.find((row) => row.id === rowId);

        const columnElement =
          self.column &&
          self.column.elements &&
          self.column.elements.find((column) => column.id === columnId);

        // Element names can contain non-breaking space character as a string
        const trimmedRowName = rowElement.name.replace(/&nbsp;/g, ' ').trim();
        const trimmedColumnName = columnElement.name
          .replace(/&nbsp;/g, ' ')
          .trim();

        return self.shouldSwapCollectionOrder()
          ? { columnName: trimmedRowName, rowName: trimmedColumnName }
          : { columnName: trimmedColumnName, rowName: trimmedRowName };
      },
      isElementAvailable(elementId, selectedElementId, isFirstProperty) {
        const { columnId, rowId } = self.getElementIds(elementId, selectedElementId, isFirstProperty);
        let status;

        if (columnId !== null && rowId !== null) {
          status = self.getItemStatus(columnId, rowId);
        } else if (columnId !== null) {
          status = self.getColumnStatus(columnId);
        } else {
          status = self.getRowStatus(rowId);
        }

        return status.available;
      },
    };
  });

export default ProductMatrix;
