import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import Ad from '../../../models/banner/Ad';
import MaybeExternalLink from '../../common/MaybeExternalLink';
import Analytics from '../../../analytics/Analytics';
import ProductCode from '../../product/ProductCode';
import ProductPrice from '../../product/ProductPrice';
import ProductSigns from '../../product/ProductSigns';
import { ViewBreakpointMinWidth } from '../../../types/ViewBreakpoint';
import ConfigStore from '../../../store/ConfigStore';

@observer
export class Banner extends Component {
  UNSAFE_componentWillMount() {
    const { configStore, banner } = this.props;
    !configStore.analytics.ga4.enabled && banner && this.sendView();
  }

  sendView = () => {
    const { analytics, bannerZone, banner } = this.props;
    analytics.promoView([
      {
        bannerZone,
        banner,
      },
    ]);
  };

  sendPromotionClick = () => {
    const { analytics, bannerZone, banner } = this.props;
    analytics.promotionClick([
      {
        bannerZone,
        banner,
      },
    ]);
  };

  renderBannerWrapper = () => {
    const { banner, isMobileBanner } = this.props;
    const bannerWrapper = isMobileBanner
      ? 'Banner__wrapper-mobile'
      : 'Banner__wrapper';
    const Wrapper = ({ children }) => (
      <div className={bannerWrapper}>
        {banner.link_url ? (
          <MaybeExternalLink to={banner.link_url}>{children}</MaybeExternalLink>
        ) : (
          children
        )}
      </div>
    );

    return (
      <Wrapper>
        {this.getBannerImage(banner)}
        {this.getBannerOverlay(banner)}
      </Wrapper>
    );
  };

  getBannerImage = (banner) => this.renderImage(banner);

  renderImage = () => {
    const { banner, isMobileBanner } = this.props;
    return (
      <picture>
        {banner.mobile_image && (
          <source
            media={`(max-width:${ViewBreakpointMinWidth.SM}px)`}
            srcSet={encodeURI(banner.mobile_image)}
          />
        )}
        <img
          className={
            isMobileBanner && banner.mobile_image
              ? 'Banner__background-mobile-image'
              : 'Banner__background-image'
          }
          src={banner.image}
          alt={banner.title}
        />
      </picture>
    );
  };

  getBannerOverlay = (banner) => {
    const title = this.getBannerTitle(banner);
    const content = this.getBannerContent(banner);
    const priceInformation = this.getBannerPriceInformation(banner);

    return (
      (title || content) && (
        <div
          className="Banner__overlay"
          style={{
            background: banner.overlay_background_color,
            color: banner.text_color || undefined,
          }}
        >
          {banner.overlay_image && (
            <img src={banner.overlay_image} alt={banner.title} />
          )}

          {this.getBannerContentWrapper(
            title,
            banner,
            priceInformation,
            content
          )}
        </div>
      )
    );
  };

  getBannerTitle = (banner) =>
    banner.title && (
      <div className="Banner__title">
        {banner.manufacturer && (
          <div className="Banner__manufacturer">{banner.manufacturer}</div>
        )}
        <h3>{banner.title}</h3>
      </div>
    );

  getBannerContent = (banner) =>
    banner.content && <div className="Banner__content">{banner.content}</div>;

  getBannerPriceInformation = (banner) =>
    banner.price_info && (
      <div className="Banner__price-info">
        <ProductPrice priceInfo={banner.price_info} />
        <ProductSigns
          hasDiscount={banner.price_info.is_discount}
          dateAdded={banner.date_added}
          discountProps={{
            percent: banner.price_info.discount_percentage,
            withUpTo: false,
            singlePriceLabel: false,
          }}
        />
      </div>
    );

  getBannerContentWrapper = (title, banner, priceInformation, content) => (
    <div className="Banner__content_wrapper">
      <div className="Banner__texts">
        {title}
        {banner.product_id && <ProductCode code={banner.productCode} />}
        {priceInformation}
        {content}
      </div>
    </div>
  );

  getBannerScent = (banner) =>
    banner.scent && (
      <div
        className="Banner__scent"
        style={{
          backgroundImage: `url(${banner.scent})`,
        }}
      />
    );

  render() {
    const { banner, className } = this.props;

    return (
      <div
        className={classNames(
          'Banner',
          {
            'Banner--default-width': !banner.image,
            'Banner--text-color-override': !!banner.text_color,
          },
          className
        )}
        onClick={this.sendPromotionClick}
      >
        {this.renderBannerWrapper()}
        {this.getBannerScent(banner)}
      </div>
    );
  }
}

Banner.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  banner: modelOf(Ad).isRequired,
  bannerZone: PropTypes.string.isRequired,
  className: PropTypes.string,
  defaultImage: PropTypes.string,
  isMobileBanner: PropTypes.bool,
};

export default inject('configStore', 'analytics')(Banner);
