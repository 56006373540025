import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import ProductImage from '../../../models/ProductImage';
import { modelOf } from '../../../prop-types';

// Z-index above sticky navigation.
const LIGHTBOX_Z_INDEX = '1040';
const LIGHTBOX_SETTINGS = {
  reactModalStyle: {
    overlay: {
      zIndex: LIGHTBOX_Z_INDEX
    },
    content: {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      background: '#fff'
    }
  }
};

@observer
export class ImageLightbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImageIndex: null
    };
  }

  componentDidMount() {
    this.setState({
      currentImageIndex: this.props.mainImageIndex
    });
  }

  getImageSrc = (target) => {
    const { images } = this.props;
    const targetImage = images.filter((image, index) => index === target);

    return (
      targetImage[0].sizes.full ||
      targetImage[0].sizes.xlarge ||
      targetImage[0].sizes.large
    );
  };

  getSettings = () => {
    const { images } = this.props;
    const options = {
      ...LIGHTBOX_SETTINGS
    };

    if (images.length > 1) {
      options.prevSrc = this.getImageSrc(this.getPreviousIndex());
      options.nextSrc = this.getImageSrc(this.getNextIndex());
    }
    options.mainSrc = this.getImageSrc(this.state.currentImageIndex);
    options.imageLoadErrorMessage = (
      <FormattedMessage
        id="global.imageLoadFailed"
        defaultMessage="Image failed to load"
      />
    );

    return options;
  };

  getPreviousIndex = () => {
    const { currentImageIndex } = this.state;
    const { images } = this.props;
    return (currentImageIndex + images.length - 1) % images.length;
  };

  getNextIndex = () => {
    const { currentImageIndex } = this.state;
    const { images } = this.props;
    return (currentImageIndex + 1) % images.length;
  };

  toPrevIndex = () => {
    this.setState({
      currentImageIndex: this.getPreviousIndex()
    });
  };

  toNextIndex = () => {
    this.setState({
      currentImageIndex: this.getNextIndex()
    });
  };

  render() {
    if (this.state.currentImageIndex === null) {
      return null;
    }

    return (
      <div className="ImageLightbox">
        <Lightbox
          onCloseRequest={this.props.onClick}
          onMovePrevRequest={this.toPrevIndex}
          onMoveNextRequest={this.toNextIndex}
          {...this.getSettings()}
        />
      </div>
    );
  }
}

ImageLightbox.propTypes = {
  mainImageIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  images: PropTypes.oneOfType([
    MobxPropTypes.observableArrayOf(modelOf(ProductImage)),
    PropTypes.arrayOf(modelOf(ProductImage))
  ])
};

export default ImageLightbox;
