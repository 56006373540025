import { types } from 'mobx-state-tree';
import Filter from './Filter';
import Product from './Product';

const ProductSearchPaginator = types.model('ProductSearchPaginator', {
  available_filters: types.maybeNull(types.array(Filter)),
  current_page: types.number,
  last_page: types.number,
  total: types.number,
  data: types.optional(types.array(types.reference(Product)), [])
});

export default ProductSearchPaginator;
