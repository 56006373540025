import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';

import Page from '../../components/layout/Page';
import MainProductList from '../../components/product-list/MainProductList';
import HelperNavigation from '../../components/common/HelperNavigation';
import GenericLayout from '../../components/layout/GenericLayout';
import globalTranslations from '../../i18n/globalTranslations';
import PageTitle from '../../components/common/PageTitle';
import Paths from '../../types/Paths';
import SEOTitle from '../../components/head/SEOTitle';
import CanonicalLink from '../../components/head/CanonicalLink';
import ContentPopup from '../../components/ad/ContentPopup';
import MainBanner from '../../components/ad/MainBanner';
import ScrollReset from '../../components/common/ScrollReset';
import AdPages from '../../types/AdPages';
import CommonPage from '../../types/CommonPage';
import { modelOf } from '../../prop-types';
import ConfigStore from '../../store/ConfigStore';
import SectionStore from '../../store/SectionStore';
import RouteService from '../../services/RouteService';

@observer
class SoldProductsPage extends Component {
  getBreadcrumbsPath = () => {
    const { intl, routeService } = this.props;
    return routeService.transformBreadCrumbs([
      {
        text: intl.formatMessage(globalTranslations.soldProductsTitle),
        url: Paths.SoldProducts,
      },
    ]);
  };

  getSearchParams = () => {
    return {
      bannerPage: AdPages.SOLD_PRODUCTS,
    };
  };

  getConfigSettings() {
    const { configStore } = this.props;
    const settings = [];

    if (configStore.soldProductsPage.showProductCards) {
      settings.disableLinks = false;
    } else {
      settings.disableLinks = true;
    }

    return settings;
  }

  render() {
    const listId = 'sold_products_list';
    const listName = 'Sold Products List';
    const { configStore, sectionStore, routeService } = this.props;
    const fixedParams = {
      purchaseHistory: 1,
      includeFilters: 1,
      inStock: 0,
    };

    const cardSettings = {
      hideAddToCartButton: true,
      hidePrice: true,
      hideQuantity: true,
      showAsSold: true,
      ...this.getConfigSettings(),
    };

    const productParams = {
      showOutOfStock: 1,
    };

    if (sectionStore.activeSection) {
      fixedParams.sections = [sectionStore.activeSection.id];
    }

    const mainBanner = <MainBanner searchParams={this.getSearchParams()} />;
    const mainBannerWithinContent =
      configStore.mainBanner.withinContent && !configStore.mainBanner.fullWidth;

    return (
      <Page name={CommonPage.SOLD_PRODUCTS} className="SoldProductsPage">
        <ScrollReset />
        <SEOTitle
          title={{
            ...globalTranslations.soldProductsTitle,
          }}
        />
        <CanonicalLink path={routeService.getPath(Paths.SoldProducts)} />

        {!mainBannerWithinContent && mainBanner}
        <Container className="SoldProductsPage__container">
          <GenericLayout
            content={
              <Fragment>
                {mainBannerWithinContent && mainBanner}
                <HelperNavigation breadcrumbsPath={this.getBreadcrumbsPath()} />
                <PageTitle>
                  <FormattedMessage {...globalTranslations.soldProductsTitle} />
                </PageTitle>
                <MainProductList
                  fixedParams={fixedParams}
                  listId={listId}
                  name={listName}
                  showSort={false}
                  cardSettings={cardSettings}
                  productParams={productParams}
                />
                <ContentPopup />
              </Fragment>
            }
          />
        </Container>
      </Page>
    );
  }
}

SoldProductsPage.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  intl: intlShape.isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
};

export default injectIntl(
  inject('configStore', 'sectionStore', 'routeService')(SoldProductsPage)
);
