import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import ProductPropertyElement from '../../../models/ProductPropertyElement';
import ProductImage from '../ProductImage';
import Slider from '../../common/Slider';

const SLIDER_SETTINGS = {
  draggable: false,
  swipeToSlide: true,
  variableWidth: true,
  arrows: true,
  infinite: false,
  slidesToScroll: 1,
  easing: 'ease-out',
  speed: 200,
};

@observer
export class ProductCardPropertySelect extends Component {
  getComponentsForElements = () => {
    const {
      product,
      propertyId,
      elements,
      selectedElementId,
      asThumbnails,
      onElementClick,
      onElementHover,
      isFirstProperty,
      filterNotInStockElements,
    } = this.props;

    return elements.map((element) => {
      const content = <span>{element.name}</span>;
      const isElementAvailable =
        !filterNotInStockElements &&
        !product.canBeOrderedOutOfStock &&
        product.collection.isElementAvailable(
          element.id,
          selectedElementId,
          isFirstProperty
        );

      let productImage;
      if (asThumbnails) {
        const imageDataForElement = product.images.find(
          (image) => image.for_color_id === element.id
        );
        if (imageDataForElement) {
          productImage = (
            <ProductImage
              product={product}
              productImage={imageDataForElement}
              size={'small'}
            />
          );
        }
      }

      return (
        <div key={element.id}>
          <div
            className={classNames('ProductCardPropertySelect__item', {
              'ProductCardPropertySelect__item--image': asThumbnails,
              'ProductCardPropertySelect__item--text': !asThumbnails,
              'ProductCardPropertySelect__item--selected':
                selectedElementId === element.id,
              'ProductCardPropertySelect__item--empty-image':
                asThumbnails && !productImage,
              'ProductCardPropertySelect_item--not-available':
                !isElementAvailable &&
                !filterNotInStockElements &&
                !product.canBeOrderedOutOfStock,
            })}
            onClick={() => onElementClick(propertyId, element.id)}
            onMouseOver={() => onElementHover(element.id)}
          >
            {productImage || content}
          </div>
        </div>
      );
    });
  };

  render() {
    const { asThumbnails, product } = this.props;
    return (
      <div
        className={classNames('ProductCardPropertySelect', {
          'ProductCardPropertySelect--thumbnails': asThumbnails,
        })}
      >
        <Slider key={product.id} {...SLIDER_SETTINGS}>
          {this.getComponentsForElements()}
        </Slider>
      </div>
    );
  }
}

ProductCardPropertySelect.propTypes = {
  product: modelOf(Product).isRequired,
  propertyId: PropTypes.number.isRequired,
  elements: PropTypes.oneOfType([
    MobxPropTypes.observableArrayOf(modelOf(ProductPropertyElement)),
    PropTypes.arrayOf(modelOf(ProductPropertyElement)),
  ]).isRequired,
  onElementClick: PropTypes.func.isRequired,
  onElementHover: PropTypes.func,
  filterNotInStockElements: PropTypes.bool,
  selectedElementId: PropTypes.number,
  asThumbnails: PropTypes.bool,
  isFirstProperty: PropTypes.bool,
};

export default ProductCardPropertySelect;
