import React, { Fragment, Component } from 'react';
import {
  Form,
  Input,
  Alert,
  Button,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import Icon from '../../common/Icon';
import globalTranslations from '../../../i18n/globalTranslations';
import { createForm } from '../../../models/Form';
import { createFormField } from '../../../models/FormField';
import { isEmail, notBlank } from '../../../util/formValidators';
import RequestState from '../../../types/RequestState';
import UnexpectedError from '../../loader/UnexpectedError';
import Field from '../../form/Field';
import FieldError from '../../form/FieldError';
import ApiWrapper from '../../../services/ApiWrapper';
import { modelOf } from '../../../prop-types';
import Analytics from '../../../analytics/Analytics';
import ConfigStore from '../../../store/ConfigStore';

@observer
export class ProductWatcherForm extends Component {
  form = null;
  state = {
    status: RequestState.NONE,
  };

  constructor(props) {
    super(props);
    this.form = createForm({
      email: createFormField({}, [
        notBlank(<FormattedMessage {...globalTranslations.emailTitle} />),
        isEmail(<FormattedMessage {...globalTranslations.emailTitle} />),
      ]),
    });

    this.form.fields.get('email').setValue(this.props.initialEmail);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.form.validate();
    if (this.form.valid) {
      const { configStore, analytics, apiWrapper, activeProductId, price } =
        this.props;
      this.setState({ status: RequestState.LOADING });
      apiWrapper
        .apiAxios()
        .post(`product-watchers`, {
          id: activeProductId,
          ...this.form.getFieldValues(),
        })
        .then(() => {
          this.setState({ status: RequestState.LOADED });
          configStore.analytics.ga4.enabled &&
            price &&
            analytics.generateLead(price);
        })
        .catch(() => {
          this.setState({ status: RequestState.ERROR });
        });
    }
  };

  render() {
    const { status } = this.state;
    return (
      <div className="ProductWatcherForm">
        {status !== RequestState.LOADED && (
          <Fragment>
            {status === RequestState.ERROR && <UnexpectedError />}
            <Form
              className="ProductWatcherForm__form"
              noValidate
              onSubmit={this.handleSubmit}
            >
              <InputGroup>
                <Field
                  component={Input}
                  field={this.form.fields.get('email')}
                  type="text"
                  name="email"
                  placeholder={this.props.intl.formatMessage(
                    globalTranslations.yourEmailSentence
                  )}
                />
                <InputGroupAddon addonType="append">
                  <Button
                    color="primary"
                    onClick={this.handleSubmit}
                    disabled={
                      this.form.valid === false ||
                      status === RequestState.LOADING
                    }
                  >
                    <Icon name="envelope" />{' '}
                    <FormattedMessage {...globalTranslations.sendTitle} />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
              <FieldError field={this.form.fields.get('email')} />
            </Form>
          </Fragment>
        )}
        {status === RequestState.LOADED && (
          <Alert color="success">
            <FormattedMessage
              {...globalTranslations.newsletterSuccessSentence}
            />
          </Alert>
        )}
      </div>
    );
  }
}

ProductWatcherForm.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  apiWrapper: PropTypes.instanceOf(ApiWrapper).isRequired,
  intl: intlShape.isRequired,
  price: PropTypes.number,
  activeProductId: PropTypes.string,
  initialEmail: PropTypes.string,
};

ProductWatcherForm.defaultProps = {
  initialEmail: '',
};

export default inject(
  'configStore',
  'analytics',
  'apiWrapper'
)(injectIntl(ProductWatcherForm));
