import React, { Component } from 'react';
import { Alert, Button, Form, FormGroup, Tooltip } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { autorun } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { has, get, keyBy } from 'lodash';
import PropTypes from 'prop-types';
import cookies from 'js-cookie';

import { createForm } from '../../../models/Form';
import globalTranslations from '../../../i18n/globalTranslations';
import FormGroupField from '../../form/FormGroupField';
import {
  finnishBusinessId,
  mustMatch,
  notBlank,
} from '../../../util/formValidators';
import ConfigStore from '../../../store/ConfigStore';
import { modelOf } from '../../../prop-types';
import RadioField from '../../form/RadioField';
import { createFormField } from '../../../models/FormField';
import LanguageStore from '../../../store/LanguageStore';
import Icon from '../../common/Icon';
import CheckboxField from '../../form/CheckboxField';
import AccountStore from '../../../store/AccountStore';
import RequestState from '../../../types/RequestState';
import ErrorHandler from '../../loader/ErrorHandler';
import { createErrorModel } from '../../../util/error';
import PrivacyStatementLink from '../../privacy-statement/PrivacyStatementLink';
import FormValidator from '../../../services/FormValidator';
import SelectField from '../../form/SelectField';
import CountryStore from '../../../store/CountryStore';
import { getStorageAndParse, deleteStorageItem } from '../../../util/storage';
import { storageKeys } from '../../../util/constants';
import RecaptchaField from '../../form/RecaptchaField';
import { labels } from '../../../util/formLabels';
import Analytics from '../../../analytics/Analytics';

function createOption(value, label) {
  return {
    value,
    label,
  };
}

@observer
export class RegisterForm extends Component {
  form = null;
  isCompanyAutorunDisposer = null;
  formName = 'RegisterForm';
  state = {
    businessIdHelpVisible: false,
    error: null,
    requestState: RequestState.LOADED,
    // This is used to know, if we've made a new request so we can re-animate errors
    requestId: 0,
    recaptcha: null,
  };

  constructor(props) {
    super(props);
    const { configStore, countryStore, formValidator } = this.props;

    const validateBusinessId =
      countryStore.countries.length === 1 &&
      has(keyBy(countryStore.countries, 'iso_code_2'), 'FI');

    this.form = createForm({
      isCompany: createFormField(
        {
          value: configStore.registration.isCompanyDefault,
        },
        [],
        () => configStore.registration.companyEnabled
      ),
      company: createFormField(
        {},
        [notBlank(labels.company)],
        () => this.form.fields.get('isCompany').value === '1'
      ),
      businessId: createFormField(
        {},
        [
          validateBusinessId
            ? finnishBusinessId()
            : notBlank(labels.businessId),
        ],
        () =>
          configStore.registration.requireBusinessId &&
          this.form.fields.get('isCompany').value === '1'
      ),
      firstName: createFormField({}, formValidator.firstName(labels.firstName)),
      lastName: createFormField({}, formValidator.lastName(labels.lastName)),
      email: createFormField({}, formValidator.email(labels.email)),
      telephone: createFormField(
        {},
        formValidator.telephone(labels.telephone),
        () =>
          !configStore.registration.quickRegistrationEnabled ||
          (configStore.registration.quickRegistrationEnabled &&
            configStore.registration.quickRegistrationRequirePhone)
      ),
      streetAddress: createFormField(
        {},
        formValidator.streetAddress(labels.streetAddress),
        () => !configStore.registration.quickRegistrationEnabled
      ),
      postalCode: createFormField(
        {},
        formValidator.postalCode(labels.postalCode),
        () => !configStore.registration.quickRegistrationEnabled
      ),
      city: createFormField(
        {},
        formValidator.city(labels.city),
        () => !configStore.registration.quickRegistrationEnabled
      ),
      country: createFormField({
        value: this.getCountryId(),
      }),
      username: createFormField(
        {},
        formValidator.username(labels.username),
        () => !configStore.registration.quickRegistrationEnabled
      ),
      password: createFormField({}, formValidator.password(labels.password)),
      confirmPassword: createFormField({}, [
        mustMatch(() => this.form.fields.get('password').value),
      ]),
      newsletter: createFormField({}, [], () => configStore.newsletter.enabled),
      privacyStatement: createFormField({}, [
        (value) => {
          return !value ? (
            <FormattedMessage
              key="mustApprove"
              {...globalTranslations.privacyStatementMustApproveSentence}
            />
          ) : null;
        },
      ]),
    });

    // Reactstrap Tooltip throws if it is open when rendered first time, so we
    // need to explicitly close it in case it was left open when toggling
    // "isCompany".
    this.isCompanyAutorunDisposer = autorun(() => {
      if (
        this.form.fields.get('isCompany').value === '0' &&
        this.state.businessIdHelpVisible
      ) {
        this.setState({ businessIdHelpVisible: false });
      }
    });
  }

  componentWillUnmount() {
    if (this.isCompanyAutorunDisposer) {
      this.isCompanyAutorunDisposer();
    }
  }

  getCountryId = () => {
    const { accountStore, countryStore } = this.props;

    const defaultId = accountStore.account && accountStore.account.country_id;

    return defaultId || get(countryStore, 'countries[0].id');
  };

  onRecaptchaChange = (value) => {
    this.setState({ recaptcha: value });
  };

  getProductListProducts = (products) => {
    if (!products) {
      return;
    }
    const productListProducts = [];
    products.forEach((product) => {
      productListProducts.push({
        extendedId: product.id,
        quantity: product.quantity,
      });
    });
    return productListProducts;
  };

  isRecaptchaValid = () => {
    const { recaptcha } = this.state;
    const { configStore } = this.props;
    return !!recaptcha || !configStore.registration.enableCaptcha;
  };

  handleSubmit = (e) => {
    const { accountStore, configStore } = this.props;
    const { recaptcha } = this.state;

    e.preventDefault();
    this.form.validate();
    if (this.form.valid && this.isRecaptchaValid()) {
      const storageProductListProducts = getStorageAndParse(
        storageKeys.wishlist
      );
      const productListProducts = this.getProductListProducts(
        storageProductListProducts
      );

      const customerHash = cookies.get('customer_hash');

      this.setState({ requestState: RequestState.LOADING });
      const values = this.form.getFieldValues();
      values.recaptcha = recaptcha;
      accountStore
        .register(values, productListProducts, customerHash)
        .then((response) => {
          configStore.analytics.ga4.enabled && this.sendAnalyticsSignupEvent();
          deleteStorageItem(storageKeys.wishlist);
          cookies.remove('customer_hash');
          window.location.reload();
          this.redirectToPostLoginPath(response);
        })
        .catch((error) => {
          this.setState({
            requestState: RequestState.ERROR,
            error: error,
            requestId: this.state.requestId + 1,
          });
        });
    }
  };

  sendAnalyticsSignupEvent = () => {
    const { analytics } = this.props;
    analytics.signup('Webstore');
  };

  redirectToPostLoginPath = (response) => {
    if (
      response.data &&
      response.data.user &&
      response.data.user.post_login_redirect_path &&
      response.data.user.post_login_redirect_path.length > 0
    ) {
      window.location.assign(response.data.user.post_login_redirect_path);
    }
  };

  field = (fieldName, props) => {
    const field = this.form.fields.get(fieldName);
    return (
      field.active && (
        <FormGroupField
          field={field}
          formName={this.formName}
          fieldName={fieldName}
          label={labels[fieldName]}
          {...props}
        />
      )
    );
  };

  radio = (fieldName, props) => {
    const field = this.form.fields.get(fieldName);
    return (
      field.active && (
        <RadioField
          field={field}
          formName={this.formName}
          fieldName={fieldName}
          label={labels[fieldName]}
          {...props}
        />
      )
    );
  };

  checkbox = (fieldName, props) => {
    const field = this.form.fields.get(fieldName);
    return (
      field.active && (
        <CheckboxField
          field={field}
          formName={this.formName}
          fieldName={fieldName}
          {...props}
        />
      )
    );
  };

  toggleBusinessIdHelp = (e) => {
    e.preventDefault();
    this.setState({
      businessIdHelpVisible: !this.state.businessIdHelpVisible,
    });
  };

  businessIdTooltip() {
    const { languageStore, countryStore } = this.props;

    const validateBusinessId =
      countryStore.countries.length === 1 &&
      has(keyBy(countryStore.countries, 'iso_code_2'), 'FI');

    if (!validateBusinessId) {
      return null;
    }

    const ytjLanguageIdDict = {
      fi: 1,
      sv: 2,
      en: 3,
    };
    const languageId =
      ytjLanguageIdDict[languageStore.activeLocale] || ytjLanguageIdDict.en;
    const url = `https://tietopalvelu.ytj.fi/yrityshaku.aspx?kielikoodi=${languageId}`;
    return (
      <span>
        <Tooltip
          placement="right"
          isOpen={this.state.businessIdHelpVisible}
          target="BusinessIdTooltip"
          toggle={this.toggle}
        >
          <FormattedMessage
            {...globalTranslations.businessIdHelp}
            values={{
              link: (
                <a href={url} target="_blank" rel="noopener">
                  <FormattedMessage {...globalTranslations.hereSentence} />
                </a>
              ),
            }}
          />
        </Tooltip>
        <span id="BusinessIdTooltip" onClick={this.toggleBusinessIdHelp}>
          <Icon name="question-circle" />
        </span>
      </span>
    );
  }

  renderCountrySelect = () => {
    const { countryStore } = this.props;

    if (countryStore.countries.length <= 1) {
      return null;
    }

    const fieldName = 'country';
    const field = this.form.fields.get(fieldName);

    return (
      field.active && (
        <SelectField
          field={field}
          formName={this.formName}
          fieldName={fieldName}
          label={labels[fieldName]}
          options={countryStore.countries.map((country) =>
            createOption(String(country.id), country.name)
          )}
        />
      )
    );
  };

  getErrorMessages = () => {
    const { error } = this.state;
    return get(error, 'response.data.messages');
  };

  render() {
    const { configStore } = this.props;
    const { requestState, requestId, error } = this.state;
    const isCompany = this.form.fields.get('isCompany').value === '1';
    return (
      <Form className={this.formName} noValidate onSubmit={this.handleSubmit}>
        {this.radio('isCompany', {
          options: [
            createOption('1', labels.yes),
            createOption('0', labels.no),
          ],
        })}
        {this.field('company', { autoComplete: 'organization' })}
        {this.field('businessId', {
          label: (
            <span>
              {labels.businessId} {this.businessIdTooltip()}
            </span>
          ),
          // We don't want autocomplete here, so we put in a non-existent string
          autoComplete: 'businessId',
        })}
        {this.field('firstName', {
          label: isCompany ? labels.companyFirstName : labels.firstName,
          autoComplete: 'given-name',
        })}
        {this.field('lastName', {
          label: isCompany ? labels.companyLastName : labels.lastName,
          autoComplete: 'family-name',
        })}
        {this.field('email', {
          type: 'email',
          autoComplete: 'email',
          autoCorrect: 'off',
          autoCapitalize: 'none',
        })}
        {this.field('telephone', { type: 'tel', autoComplete: 'tel-national' })}
        {this.field('streetAddress', { autoComplete: 'street-address' })}
        {this.field('postalCode', { autoComplete: 'postal-code' })}
        {this.field('city', { autoComplete: 'address-level2' })}
        {this.renderCountrySelect()}
        {this.form.fields.get('username').active && (
          <div>
            <FormGroup>
              <strong>
                <FormattedMessage
                  {...globalTranslations.loginInformationTitle}
                />
              </strong>
            </FormGroup>
            <FormGroup>
              <FormattedMessage
                id="account.loginInformationHint"
                defaultMessage="Create a username (you can use e.g. your e-mail address) and a password for your account."
              />
            </FormGroup>
          </div>
        )}
        {this.field('username', {
          autoComplete: 'username',
          autoCorrect: 'off',
          autoCapitalize: 'none',
        })}
        {this.field('password', {
          type: 'password',
          autoComplete: 'new-password',
        })}
        {this.field('confirmPassword', {
          type: 'password',
          autoComplete: 'new-password',
        })}
        {this.checkbox('newsletter', {
          options: createOption('1', labels.newsletter),
        })}
        {this.checkbox('privacyStatement', {
          options: createOption('1', labels.privacyStatement),
        })}
        <div className="RegisterForm__privacy-statement-link-wrapper">
          (<PrivacyStatementLink />)
        </div>
        {error && get(error, 'response.status') !== 400 && (
          <ErrorHandler error={createErrorModel(error)} />
        )}
        {this.getErrorMessages() && (
          <Alert color="danger" key={requestId}>
            {this.getErrorMessages().map((message) => (
              <div key={message}>{message}</div>
            ))}
          </Alert>
        )}
        {configStore.registration.enableCaptcha && (
          <RecaptchaField
            ref={(recaptcha) => (this.recaptcha = recaptcha)}
            onChange={this.onRecaptchaChange}
          />
        )}
        <Button
          color="primary"
          block
          disabled={
            this.form.valid === false ||
            this.isRecaptchaValid() === false ||
            requestState === RequestState.LOADING
          }
        >
          <FormattedMessage {...globalTranslations.registerTitle} />
        </Button>
      </Form>
    );
  }
}

RegisterForm.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  countryStore: modelOf(CountryStore).isRequired,
  languageStore: modelOf(LanguageStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  formValidator: PropTypes.instanceOf(FormValidator).isRequired,
};

export default inject(
  'accountStore',
  'configStore',
  'countryStore',
  'languageStore',
  'analytics',
  'formValidator'
)(RegisterForm);
