import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import MobileNavigationButton from '../../header/MobileNavigationButton';
import MobileNavigationTab from '../../../types/MobileNavigationTab';
import Icon from '../Icon';
import classNames from 'classnames';
import globalTranslations from '../../../i18n/globalTranslations';

@observer
export class NavigationAssistButton extends Component {
  render() {
    const { bottom, tab, intl } = this.props;
    return (
      <div
        className={classNames('NavigationAssistButton', {
          NavigationAssistButton__bottom: bottom
        })}
      >
        <MobileNavigationButton
          tab={tab}
          ariaLabel={intl.formatMessage(globalTranslations.seeCategoriesNavi)}
        >
          <Icon name="bars" className="NavigationAssistButton__icon" />
          <FormattedMessage {...globalTranslations.seeCategoriesNavi} />
        </MobileNavigationButton>
      </div>
    );
  }
}

NavigationAssistButton.propTypes = {
  intl: intlShape.isRequired,
  bottom: PropTypes.bool,
  tab: PropTypes.oneOf(Object.values(MobileNavigationTab))
};

export default injectIntl(NavigationAssistButton);
