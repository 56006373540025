import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { isNil } from 'lodash';
import classNames from 'classnames';

import ButtonLink from '../../common/ButtonLink';
import RequestState from '../../../types/RequestState';
import globalTranslations from '../../../i18n/globalTranslations';
import Price from '../Price';
import Icon from '../../common/Icon';
import { modelOf } from '../../../prop-types';
import CountryStore from '../../../store/CountryStore';

@observer
export class ProductAvailabilityShipping extends Component {
  renderActiveCountry = () => {
    const { countryStore } = this.props;
    if (countryStore.countryCount > 1) {
      return ' (' + countryStore.activeCountry.name + ')';
    }
  };

  renderCampaignPromotion = (options) => {
    const campaigns = [];
    options
      .filter((option) => option.campaign_info && option.campaign_info.name)
      .sort(
        (a, b) => a.campaign_info.id - b.campaign_info.id
      )
      .map((option) => campaigns.push(option.campaign_info));

    if (campaigns.length === 0 || !campaigns[0].name) {
      return null;
    }

    if (campaigns[0].name.length > 0) {
      return (
        <div className="ProductAvailabilityShipping__campaign-promotion">
          {campaigns[0].name}
        </div>
      );
    }
    return null;
  };

  renderCheapestOption = (details) => {
    const { withTax } = this.props;
    const cheapest_option = details.cheapest_option;

    if (!cheapest_option) {
      return null;
    }

    const lowOrderFeeSentence =
      cheapest_option.includes_low_order_fee &&
      (withTax ? (
        <div className="ProductAvailabilityShipping__cheapest-option-low-fee">
          {'('}
          <FormattedMessage
            {...globalTranslations.lowOrderFeeNoTaxSentence}
            values={{
              amount: (
                <Price
                  price={cheapest_option.includes_low_order_fee.getPrice(
                    withTax
                  )}
                />
              ),
              limit: (
                <Price
                  price={cheapest_option.low_order_fee_limit.getPrice(withTax)}
                />
              )
            }}
          />
          {')'}
        </div>
      ) : (
        <div className="ProductAvailabilityShipping__cheapest-option-low-fee">
          {'('}
          <FormattedMessage
            {...globalTranslations.lowOrderFeeSentence}
            values={{
              amount: (
                <Price
                  price={cheapest_option.includes_low_order_fee.getPrice(true)}
                />
              ),
              amountWithoutTax: (
                <Price
                  price={cheapest_option.includes_low_order_fee.getPrice(false)}
                />
              ),
              limit: (
                <Price
                  price={cheapest_option.low_order_fee_limit.getPrice(true)}
                />
              ),
              limitwithoutTax: (
                <Price
                  price={cheapest_option.low_order_fee_limit.getPrice(false)}
                />
              )
            }}
          />
          {')'}
        </div>
      ));

    return (
      <div className="ProductAvailabilityShipping__cheapest-option">
        <div
          className={classNames(
            'ProductAvailabilityShipping__cheapest-option-delivery',
            {
              'ProductAvailabilityShipping__cheapest-option-delivery-no-flex': !lowOrderFeeSentence
            }
          )}
        >
          <FormattedMessage
            {...globalTranslations.deliveryStartingSentence}
            values={{
              price: <Price price={cheapest_option.price.getPrice(withTax)} />
            }}
          />
          {this.renderActiveCountry()}
        </div>
        {lowOrderFeeSentence}
        <div className="ProductAvailabilityShipping__icon">
          <Icon name="caret-down" />
        </div>
      </div>
    );
  };

  renderPreferredPickup = (details) => {
    const { withTax } = this.props;
    const preferred_pickup_option = details.preferred_pickup_option;

    if (!preferred_pickup_option) {
      return null;
    }

    return (
      <div className="ProductAvailabilityShipping__preferred-pickup-option">
        {preferred_pickup_option.name}
        <div className="ProductAvailabilityShipping__preferred-pickup-option-price">
          <Price price={preferred_pickup_option.price.getPrice(withTax)} />
        </div>
      </div>
    );
  };

  renderCheckoutNotification = (details) => {
    const preferred = details.preferred_pickup_option;
    const hasPreferredCargoCalculation =
      preferred && preferred.has_starting_price === true;

    const cheapest = details.cheapest_option;
    const hasCheapestCargoCalculation =
      cheapest && cheapest.has_starting_price === true;

    if (!hasPreferredCargoCalculation && !hasCheapestCargoCalculation) {
      return null;
    }

    return (
      <div className="ProductAvailabilityShipping__has-starting-price">
        {'('}
        <FormattedMessage {...globalTranslations.hasStartingPriceSentence} />
        {')'}
      </div>
    );
  };

  renderPlaceHolder = () => {
    return (
      <div className="ProductAvailabilityShipping__placeholder">
        <FormattedMessage {...globalTranslations.loading} />
      </div>
    );
  };

  render() {
    const { activeProductId, product, scrollToShipping } = this.props;

    if (product.shippingStates.get(activeProductId) !== RequestState.LOADED) {
      return this.renderPlaceHolder();
    }

    const details = product.shippingDetails.get(activeProductId);

    if (!details.cheapest_option && !details.preferred_pickup_option) {
      return null;
    }

    return (
      <li className="ProductAvailabilityShipping">
        <ButtonLink onClick={scrollToShipping}>
          <div className="ProductAvailabilityShipping__options">
            {this.renderCampaignPromotion(details.options)}
            {this.renderCheapestOption(details)}
            {this.renderPreferredPickup(details)}
            {this.renderCheckoutNotification(details)}
          </div>
        </ButtonLink>
      </li>
    );
  }
}

ProductAvailabilityShipping.propTypes = {
  activeProductId: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  scrollToShipping: PropTypes.func.isRequired,
  withTax: PropTypes.bool.isRequired,
  countryStore: modelOf(CountryStore).isRequired
};

export default inject((stores, props) => ({
  countryStore: stores.countryStore,
  withTax: isNil(props.withTax)
    ? stores.accountStore.showPricesWithTax
    : props.withTax
}))(ProductAvailabilityShipping);
