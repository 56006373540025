import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { uniqueId, camelCase } from 'lodash';
import ReactResizeDetector from 'react-resize-detector';
import LazyLoad from 'react-lazyload';

import Banner from '../Banner';
import Slider from '../../common/Slider';
import AdLoader from '../AdLoader';
import { modelOf } from '../../../prop-types';
import UIStore from '../../../store/UIStore';
import ConfigStore from '../../../store/ConfigStore';
import Analytics from '../../../analytics/Analytics';
import AdStore from '../../../store/AdStore';

/*
When slider first time initializes, it's properties (e.g. css classes) cannot be changed from outside
without refreshing the slider by using slick-specific methods.
https://github.com/kenwheeler/slick#methods
*/

const SLIDER_SETTINGS = {
  arrows: true,
  autoplay: true,
  autoplaySpeed: 5000,
  centerMode: true,
  draggable: true,
  infinite: true,
  swipeToSlide: true,
  variableWidth: true,
};

// Mobile Aspect ratio 1:1. e.g. 250x250px or as close as possible.
const MOBILE_ASPECT_RATIO = 1;

@observer
export class BannerSlider extends Component {
  constructor(props) {
    super(props);

    this.id = uniqueId('BannerSlider--');
    this.bannerSliderRef = null;
    this.sentPromotionEvents = [];

    this.state = {
      element: null,
      elementWidth: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.elementWidth !== this.state.elementWidth) {
      this.bannerSliderRef && this.bannerSliderRef.slickRefresh();
    }
  }

  getAds = () => (ads) => {
    if (!ads || ads.length === 0) {
      return null;
    }

    const { className } = this.props;
    const slides = this.getSlides(ads);
    const cssClasses = classNames('BannerSlider', className);
    const sliderEvents = this.getSliderEvents(ads);

    return (
      <div className={cssClasses} id={this.id} ref={this.updateElement}>
        <Slider
          {...this.getSettings(ads.length)}
          sliderRef={(ref) => (this.bannerSliderRef = ref)}
          events={sliderEvents}
        >
          {slides}
        </Slider>
        <ReactResizeDetector
          handleWidth
          onResize={this.handleResize}
          refreshMode="throttle"
          refreshRate={1000}
        />
        <style>{this.getCSS(ads)}</style>
      </div>
    );
  };

  getSlides = (ads) =>
    ads
      .filter((banner) => {
        return (
          (this.props.uiStore.isSmallest && !banner.hide_from_mobile_users) ||
          (!this.props.uiStore.isSmallest &&
            !banner.hide_from_desktop_tablet_users)
        );
      })
      .map((banner) => {
        const { searchParams } = this.props;

        return (
          <div key={banner.id} className="BannerSlider__slide">
            <Banner
              banner={banner}
              bannerZone={searchParams.bannerZone}
              isMobileBanner={this.props.uiStore.isSmallest}
            />
          </div>
        );
      });

  getSliderEvents = (ads) => {
    const { configStore } = this.props;
    const { analytics } = configStore;

    const events = {};

    if (analytics.ga4.enabled) {
      events.afterChange = (event, slick, currentSlide) =>
        this.onBannerAfterChange(event, slick, currentSlide, ads);
      events.init = (event, slick) => this.onSliderInit(event, slick, ads);
      events.reInit = (event, slick) => this.onSliderInit(event, slick, ads);
    }

    return events;
  };

  onBannerAfterChange = (event, slick, currentSlide, ads) => {
    ads?.length > 0 && this.sendPromotionView(currentSlide, ads);
  };

  onSliderInit = (event, slick, ads) => {
    const currentSlide = slick.slickCurrentSlide();
    ads?.length > 0 && this.sendPromotionView(currentSlide, ads);
  };

  sendPromotionView = (currentSlide, ads) => {
    const { adStore, analytics, searchParams } = this.props;
    const banners = adStore.getAdsBySearchParameters(searchParams);
    const banner = ads[currentSlide];

    if (
      banner &&
      this.sentPromotionEvents.indexOf(currentSlide) === -1 &&
      currentSlide <= banners.length - 1
    ) {
      const bannerZone = searchParams.bannerZone;
      analytics.promoView([
        {
          bannerZone,
          banner,
        },
      ]);
      this.sentPromotionEvents.push(currentSlide);
    }
  };

  updateElement = (element) => {
    // Ref is stored in state because we want to force a rerender (which will
    // trigger a new call to getCss()) after the element is first created.
    if (this.state.element !== element) {
      this.setState(() => ({
        element,
      }));
    }
  };

  getSettings = (slideCount) => {
    const { settingOverrides } = this.props;
    const effectiveSettings = {
      ...SLIDER_SETTINGS,
      ...settingOverrides,
    };

    if (effectiveSettings.vertical) {
      effectiveSettings.slidesToShow = Math.min(
        slideCount,
        effectiveSettings.slidesToShow
      );
    }

    return effectiveSettings;
  };

  handleResize = () => {
    const { element } = this.state;
    if (!element) {
      return null;
    }

    const elementWidth = element.clientWidth;

    if (this.state.elementWidth !== elementWidth) {
      this.setState({
        elementWidth,
      });
    }
  };

  /**
   * Generates a CSS style for the slider that restricts the maximum size
   * it may take at the current window size.
   *
   * The banner slider areas each have a preferred aspect ratio and a maximum
   * height/width (depending on the orientation). The general behavior is that
   * the slider area scales in the preferred aspect ratio until it hits the
   * maximum height or width, then only scales in the unrestricted direction
   * if it has more room. E.g. a horizontal banner slider with 2:1 aspect
   * ratio and 200 px maximum cross axis (height) size will behave as follows:
   *
   * Usable width -> Enforced size
   *    100 px    -> 100 px wide, 50 px tall (restricted by aspect ratio)
   *    400 px    -> 400 px wide, 200 px tall (restricted by aspect ratio)
   *    401 px    -> 401 px wide, 200 px tall (restricted by height)
   *    800 px    -> 800 px wide, 200 px tall (restricted by height)
   */
  getCSS = (ads) => {
    const { elementWidth } = this.state;

    if (!this.state.element || !elementWidth) {
      return '';
    }

    const aspectRatio = this.getAspectRatio(ads);
    const maximumCrossAxisSize = this.getMaximumHeight(ads);
    const mainAxis = this.getMainAxis();
    const crossAxis = this.getCrossAxis();

    if (mainAxis === 'width') {
      const mainAxisSize = this.countMainAxisSize(elementWidth, aspectRatio);
      const crossAxisSize = this.countMinCrossAxisSize(
        maximumCrossAxisSize,
        mainAxisSize
      );
      const mainAxisDefaultSize = this.countMainAxisDefaultSize(
        crossAxisSize,
        aspectRatio
      );

      const { bannerWrapper, bannerBackgroundImage } =
        this.getBannerMobileCssClasses(ads);
      return `
        #${this.id} .BannerSlider__slide ${bannerWrapper},
        #${this.id} .BannerSlider__slide ${bannerBackgroundImage} {
          ${crossAxis}: ${crossAxisSize}px
        }
        #${this.id} .BannerSlider__slide {
          max-${mainAxis}: ${elementWidth}px;
        }
        #${this.id} .BannerSlider__slide .Banner--default-width {
          ${mainAxis}: ${mainAxisDefaultSize}px;
        }
        `;
    } else {
      const crossAxisSize = this.countMinCrossAxisSize(
        maximumCrossAxisSize,
        this.getCrossAxisSize()
      );
      const mainAxisSize = this.countMainAxisSize(crossAxisSize, aspectRatio);

      return `
        #${this.id} .BannerSlider__slide img {
          max-${crossAxis}: ${crossAxisSize}px;
        }
        #${this.id} .BannerSlider__slide {
          ${mainAxis}: ${mainAxisSize}px;
        }
        `;
    }
  };

  getBannerMobileCssClasses = (ads) => {
    const ifMoreThanOneMobileImages =
      this.filterVisibleMobileBanners(ads).length >= 1;

    const bannerWrapper = ifMoreThanOneMobileImages
      ? '.Banner__wrapper-mobile'
      : '.Banner__wrapper';

    const bannerBackgroundImage = ifMoreThanOneMobileImages
      ? '.Banner__background-mobile-image'
      : '.Banner__background-image';

    return { bannerWrapper, bannerBackgroundImage };
  };

  getAspectRatio = (ads) =>
    this.filterVisibleMobileBanners(ads).length >= 1
      ? MOBILE_ASPECT_RATIO
      : this.props.aspectRatio;

  getMaximumHeight = (ads) =>
    this.filterVisibleMobileBanners(ads).length >= 1
      ? this.state.elementWidth
      : this.props.maximumCrossAxisSize;

  getMainAxis = () => (this.getSettings().vertical ? 'height' : 'width');

  getCrossAxis = () => (this.getSettings().vertical ? 'width' : 'height');

  getCrossAxisSize = () =>
    this.state.element[camelCase('client ' + this.getCrossAxis())];

  countMainAxisSize = (elementWidth, aspectRatio) => elementWidth / aspectRatio;

  countMinCrossAxisSize = (maximumCrossAxisSize, axisSize) =>
    Math.min(maximumCrossAxisSize, axisSize);

  countMainAxisDefaultSize = (crossAxisSize, aspectRatio) =>
    crossAxisSize * aspectRatio;

  filterVisibleMobileBanners = (ads) => {
    const banners = ads && ads.slice();
    return banners.filter(
      (banner) => this.props.uiStore.isSmallest && banner.mobile_image
    );
  };

  render() {
    const { searchParams, maximumCrossAxisSize } = this.props;
    return (
      <LazyLoad once offset={50} height={maximumCrossAxisSize}>
        <AdLoader searchParams={searchParams}>{this.getAds()}</AdLoader>
      </LazyLoad>
    );
  }
}

BannerSlider.propTypes = {
  adStore: modelOf(AdStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  aspectRatio: PropTypes.number.isRequired,
  className: PropTypes.string,
  settingOverrides: PropTypes.object,
  // Cross axis size here refers to either maximum height (if the slider is
  // horizontal) or maximum width (if the slider is vertical).
  maximumCrossAxisSize: PropTypes.number,
  onElementOffsetUpdate: PropTypes.func,
};

BannerSlider.defaultProps = {
  settingOverrides: {},
  maximumCrossAxisSize: 400,
};

export default inject(
  'adStore',
  'configStore',
  'uiStore',
  'analytics'
)(BannerSlider);
