import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import LayoutBoxStore from '../../../store/LayoutBoxStore';
import UIStore from '../../../store/UIStore';
import SidebarNavigation from '../../common/SidebarNavigation';
import ManufacturerBox from '../../layout-box/ManufacturerBox';
import SidebarBanner from '../../ad/SidebarBanner';
import CampaignCodeBox from '../../layout-box/CampaignCodeBox';

const LayoutBoxComponents = {
  categories_new: SidebarNavigation,
  categories_minimized: SidebarNavigation,
  manufacturers: ManufacturerBox,
  campaign_code: CampaignCodeBox
};

@observer
export class DefaultSidebar extends Component {
  getLayoutBox = (layoutBox) => {
    if (LayoutBoxComponents[layoutBox.key]) {
      const Component = LayoutBoxComponents[layoutBox.key];
      return <Component title={layoutBox.title} />;
    }
  };

  render() {
    const { layoutBoxStore, bannerSearchParams, uiStore } = this.props;

    if (uiStore.isMobile) {
      return null;
    }

    return (
      <div className="DefaultSidebar">
        {layoutBoxStore.layoutBoxes.map((layoutBox) => (
          <div className="DefaultSidebar__box" key={layoutBox.key}>
            {this.getLayoutBox(layoutBox)}
          </div>
        ))}
        {bannerSearchParams && (
          <SidebarBanner searchParams={bannerSearchParams} />
        )}
      </div>
    );
  }
}

DefaultSidebar.propTypes = {
  layoutBoxStore: modelOf(LayoutBoxStore).isRequired,
  bannerSearchParams: PropTypes.object,
  uiStore: modelOf(UIStore).isRequired
};

export default inject('layoutBoxStore', 'uiStore')(DefaultSidebar);
