import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Icon from '../../common/Icon';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import ProductEnquiryForm from '../ProductEnquiryForm';
import globalTranslations from '../../../i18n/globalTranslations';
import ProductEnquiryType from '../../../types/ProductEnquiryType';

export const ProductEnquiryModal = ({
  configStore,
  enquiry,
  id,
  toggle,
  isOpen,
  price,
}) => {
  return (
    <Modal
      className="ProductEnquiryModal"
      size="md"
      toggle={toggle}
      isOpen={isOpen}
    >
      <ModalHeader className="ProductEnquiryModal__header" toggle={toggle}>
        <FormattedMessage {...enquiry.title} />
      </ModalHeader>
      <ModalBody>
        <ProductEnquiryForm id={id} type={enquiry.type} price={price} />
        <div className="ProductEnquiryModal__customer-service-open-hours">
          <FormattedMessage
            {...globalTranslations.isOpen}
            values={{
              timespan: configStore.customerService.open,
            }}
          />
        </div>
        <div className="ProductEnquiryModal__customer-service-phone-number">
          <Icon name="phone" />{' '}
          <FormattedMessage
            {...globalTranslations.infoCallPhone}
            values={{
              phone: configStore.customerService.phone,
            }}
          />
        </div>
        {configStore.customerService.callCost && (
          <div
            className="ProductEnquiryModal__customer-service-call-cost"
            dangerouslySetInnerHTML={{
              __html: configStore.customerService.callCost,
            }}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

ProductEnquiryModal.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  enquiry: PropTypes.shape({
    title: PropTypes.object,
    type: PropTypes.oneOf(Object.values(ProductEnquiryType)),
  }).isRequired,
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  price: PropTypes.number,
};

ProductEnquiryModal.defaultProps = {
  enquiry: {
    title: globalTranslations.productEnquiryTitle,
    type: ProductEnquiryType.MESSAGE,
  },
};

export default inject('configStore')(observer(ProductEnquiryModal));
