import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';

import RequestState from '../../../types/RequestState';
import ContentForState from '../../loader/ContentForState';
import Price from '../Price';
import globalTranslations from '../../../i18n/globalTranslations';
import ConfigStore from '../../../store/ConfigStore';
import { modelOf } from '../../../prop-types';
import ShippingCampaignType from '../../../types/ShippingCampaignType';

const MAX_IMAGE_WIDTH = 70;
const IMAGE_ASPECT_RATIO = 20 / 11;

@observer
export class ProductShippingDetails extends Component {
  componentDidMount() {
    this.loadShippingDetails();
  }

  componentDidUpdate(prevProps) {
    const { product, activeProductId } = this.props;

    if (
      prevProps.activeProductId !== activeProductId ||
      product.shippingStates.get(activeProductId) === undefined
    ) {
      this.loadShippingDetails();
    }
  }

  loadShippingDetails = () => {
    const { activeProductId, product } = this.props;

    if (product.shippingStates.get(activeProductId) !== RequestState.LOADED) {
      product.loadShippingDetails(activeProductId).catch((e) => {
        console.error(e);
      });
    }
  };

  getUniqueKeys = (options) => {
    const keys = [];
    options
      .sort(
        (a, b) => a.campaign_info.id - b.campaign_info.id
      )
      .map((option) => keys.push(option.campaign_info.id));

    const uniqueKeys = new Set(keys);
    return [...uniqueKeys];
  };

  renderAdditionalServices = (detail) => {
    const services = detail.additional_services.map((service) => service.name);
    return (
      <div className="ProductShippingDetails__detail-additional-services">
        <FormattedMessage
          id="shipping.availableServicesSentence"
          defaultMessage="Optional services"
        />
        {' ' + services.join(', ')}
      </div>
    );
  };

  renderDetail = (detail, index) => {
    const { withTax } = this.props;

    const imgWidth = MAX_IMAGE_WIDTH;
    const imgHeight = Math.round(MAX_IMAGE_WIDTH / IMAGE_ASPECT_RATIO);

    return (
      <div
        key={`${detail.name}--${index}`}
        className="ProductShippingDetails__detail"
      >
        {detail.image && (
          <div className="ProductShippingDetails__detail-image">
            <img
              src={detail.image}
              alt={detail.name}
              width={imgWidth}
              height={imgHeight}
              loading="lazy"
            />
          </div>
        )}
        <div className="ProductShippingDetails__detail-description">
          <div className="ProductShippingDetails__detail-name">
            {detail.name}
          </div>
          <div className="ProductShippingDetails__detail-price">
            <Price price={detail.price.getPrice(withTax)} />
          </div>
          {detail.has_starting_price && (
            <div className="ProductShippingDetails__detail-has-starting-price">
              <FormattedMessage
                {...globalTranslations.hasStartingPriceSentence}
              />
            </div>
          )}
          {detail.low_order_fee_limit && (
            <div className="ProductShippingDetails__detail-low-order-fee">
              {withTax ? (
                <FormattedMessage
                  {...globalTranslations.lowOrderFeeNoTaxSentence}
                  values={{
                    amount: (
                      <Price
                        price={detail.includes_low_order_fee.getPrice(withTax)}
                      />
                    ),
                    limit: (
                      <Price
                        price={detail.low_order_fee_limit.getPrice(withTax)}
                      />
                    )
                  }}
                />
              ) : (
                <FormattedMessage
                  {...globalTranslations.lowOrderFeeSentence}
                  values={{
                    amount: (
                      <Price
                        price={detail.includes_low_order_fee.getPrice(true)}
                      />
                    ),
                    amountWithoutTax: (
                      <Price
                        price={detail.includes_low_order_fee.getPrice(false)}
                      />
                    ),
                    limit: (
                      <Price
                        price={detail.low_order_fee_limit.getPrice(true)}
                      />
                    ),
                    limitwithoutTax: (
                      <Price
                        price={detail.low_order_fee_limit.getPrice(false)}
                      />
                    )
                  }}
                />
              )}
            </div>
          )}
          {detail.additional_services.length > 0 &&
            this.renderAdditionalServices(detail)}
        </div>
      </div>
    );
  };

  renderGroupDetails = (options, title = null, info = null, index = null) => {
    const { product } = this.props;

    return (
      <div key={product.id + index} className="ProductShippingDetails__group">
        {title && (
          <div className="ProductShippingDetails__group-title">{title}</div>
        )}
        {info && (
          <div className="ProductShippingDetails__group-info">{info}</div>
        )}
        {options.map((detail, index) => this.renderDetail(detail, index))}
      </div>
    );
  };

  getTitleContentByType = (campaign) => {
    const { withTax } = this.props;

    const endAt =
      campaign.end_at === null ? null : (
        <Fragment>
          {' - '}
          <FormattedMessage
            {...globalTranslations.couponValidUntilSentence}
            values={{
              offerExpiry: DateTime.fromISO(campaign.end_at).toLocaleString()
            }}
          />
        </Fragment>
      );

    switch (campaign.type) {
      case ShippingCampaignType.FREE_SHIPPING:
        return (
          <FormattedMessage
            id="shipping.freeShippingSentence"
            defaultMessage="Free shipping{endAt}"
            values={{
              endAt: endAt
            }}
          />
        );
      case ShippingCampaignType.TOTAL_SUM_TABLE:
        return (
          <FormattedMessage
            id="shipping.totalSumSentence"
            defaultMessage="Free shipping for orders over {amount}{endAt}"
            values={{
              amount: (
                <Price
                  price={campaign.free_shipping_price_limit.getPrice(withTax)}
                />
              ),
              endAt: endAt
            }}
          />
        );
      case ShippingCampaignType.TOTAL_WEIGHT_TABLE:
        return (
          <FormattedMessage
            id="shipping.totalWeightSentence"
            defaultMessage="Free shipping for orders total weight over {amount}{endAt}"
            values={{
              amount: campaign.free_shipping_weight_limit,
              endAt: endAt
            }}
          />
        );
      case ShippingCampaignType.PRODUCT_QUANTITY_TABLE:
        return (
          <FormattedMessage
            id="shipping.totalQuantitySentence"
            defaultMessage="Free shipping for orders with at least {amount} products{endAt}"
            values={{
              amount: campaign.free_shipping_quantity_limit,
              endAt: endAt
            }}
          />
        );
      case ShippingCampaignType.TOTAL_VOLUME_TABLE:
        return (
          <FormattedMessage
            id="shipping.totalVolumeSentence"
            defaultMessage="Free shipping for orders volume over {amount} m3{endAt}"
            values={{
              amount: campaign.free_shipping_volume_limit,
              endAt: endAt
            }}
          />
        );
      case ShippingCampaignType.FIXED_PRICE:
      default:
        return (
          <FormattedMessage
            id="shipping.fixedPriceSentence"
            defaultMessage="Lowered price"
          />
        );
    }
  };

  campaignHasValidLimit = (campaign) => {
    switch (campaign.type) {
      case ShippingCampaignType.TOTAL_SUM_TABLE:
        return !!campaign.free_shipping_price_limit;
      case ShippingCampaignType.TOTAL_WEIGHT_TABLE:
        return !!campaign.free_shipping_weight_limit;
      case ShippingCampaignType.PRODUCT_QUANTITY_TABLE:
        return !!campaign.free_shipping_quantity_limit;
      case ShippingCampaignType.TOTAL_VOLUME_TABLE:
        return !!campaign.free_shipping_volume_limit;
      case ShippingCampaignType.FREE_SHIPPING:
      case ShippingCampaignType.FIXED_PRICE:
      default:
        return true;
    }
  };

  renderShippingDetails = () => {
    const { activeProductId, product } = this.props;
    const details = product.shippingDetails.get(activeProductId);

    if (details.options.length === 0) {
      return (
        <div className="ProductShippingDetails">
          <FormattedMessage
            id="shipping.noMethodsAvailable"
            defaultMessage="This product doesn't have any available shipping methods for the chosen delivery country"
          />
        </div>
      );
    }

    const basicOptions = details.options.filter(
      (option) =>
        option.campaign_info === null ||
        !this.campaignHasValidLimit(option.campaign_info)
    );

    let basicTitle = null;

    const sortedCampaignKeys = this.getUniqueKeys(
      details.options.filter(
        (option) =>
          option.campaign_info !== null &&
          this.campaignHasValidLimit(option.campaign_info)
      )
    );

    const campaignOptions = sortedCampaignKeys.map((campaignKey, index) => {
      const filtered = details.options.filter(
        (option) =>
          option.campaign_info &&
          this.campaignHasValidLimit(option.campaign_info) &&
          option.campaign_info.id === campaignKey
      );

      const campaign = filtered[0].campaign_info;
      const title = (
        <FormattedMessage
          id="shipping.campaignHeader"
          defaultMessage="{name}"
          values={{
            name: !!campaign.name
              ? campaign.name
              : this.getTitleContentByType(campaign)
          }}
        />
      );

      return this.renderGroupDetails(filtered, title, null, index);
    });

    if (campaignOptions.length > 0) {
      basicTitle = (
        <FormattedMessage
          id="shipping.otherMethodsSentence"
          defaultMessage="Normal prices"
        />
      );
    }

    return (
      <div key={activeProductId} className="ProductShippingDetailsContent">
        {campaignOptions}
        {basicOptions.length > 0 &&
          this.renderGroupDetails(basicOptions, basicTitle)}
      </div>
    );
  };

  render() {
    const { activeProductId, product } = this.props;

    return (
      <div className="ProductShippingDetails">
        <ContentForState
          state={product.shippingStates.get(activeProductId)}
          forLoaded={this.renderShippingDetails}
        />
      </div>
    );
  }
}

ProductShippingDetails.propTypes = {
  activeProductId: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  withTax: PropTypes.bool.isRequired,
  configStore: modelOf(ConfigStore).isRequired
};

export default inject((stores, props) => ({
  configStore: stores.configStore,
  withTax: isNil(props.withTax)
    ? stores.accountStore.showPricesWithTax
    : props.withTax
}))(ProductShippingDetails);
