import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import InfoPageModal from '../../info-page/InfoPageModal';
import ButtonLink from '../../common/ButtonLink';

@observer
export class TermsOfUseStatementLink extends Component {
  state = {
    show: false
  };

  toggle = () => {
    this.setState({ show: !this.state.show });
  };

  render() {
    const { configStore, children } = this.props;

    return (
      <Fragment>
        {children || (
          <FormattedMessage
            id="termsOfUseStatement.termsOfUse"
            defaultMessage="{termsOfUse}"
            values={{
              termsOfUse: (
                <ButtonLink
                  className="TermsOfUseStatementLink"
                  onClick={this.toggle}
                >
                  <FormattedMessage
                    id="termsOfUseStatement.termsOfUseLink"
                    defaultMessage="terms of use"
                  />
                </ButtonLink>
              )
            }}
          />
        )}
        {this.state.show && (
          <InfoPageModal
            isOpen={this.state.show}
            toggle={this.toggle}
            pageId={configStore.termsOfUseStatement.infoPageId}
          />
        )}
      </Fragment>
    );
  }
}

TermsOfUseStatementLink.propTypes = {
  configStore: modelOf(ConfigStore).isRequired
};

export default inject('configStore')(TermsOfUseStatementLink);
