import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import classNames from 'classnames';

import NavigationEntity from '../../../types/NavigationEntity';
import MaybeExternalLink from '../../common/MaybeExternalLink';
class NavigationEntityLink extends Component {
  render() {
    const { className, entity, ...rest } = this.props;
    const { active, path, label, image, type } = entity;

    if (!entity) {
      return null;
    }

    return (
      <NavLink
        tag={MaybeExternalLink}
        className={className}
        to={path}
        active={active}
        type={type}
        aria-label={entity.accessible_text}
        {...rest}
      >
        <div
          className={classNames('NavigationEntityLink', {
            'NavigationEntityLink__has-image': image
          })}
        >
          {image && <img src={image} alt={image} loading="lazy" />}
          {label}
        </div>
      </NavLink>
    );
  }
}

NavigationEntityLink.propTypes = {
  entity: NavigationEntity.isRequired,
  className: PropTypes.string
};

export default NavigationEntityLink;
