import { types } from 'mobx-state-tree';

import AccountPersonalInfo from './AccountPersonalInfo';
import Client from './Client';

const Token = types.model('Token', {
  algorithm: types.string,
  type: types.string,
  value: types.string,
});

const User = types.model('User', {
  clients: types.optional(types.array(Client), []),
  country_id: types.number,
  currency_code: types.string,
  customer_group_id: types.number,
  customer_id: types.number,
  group_name: types.maybeNull(types.string),
  is_customer_user: types.boolean,
  is_logged: types.boolean,
  is_logged_as_client: types.boolean,
  is_retailer: types.boolean,
  is_client_login_enabled: types.boolean,
  logo: types.maybeNull(types.string),
  max_product_quantity: types.number,
  multi_product_matrix: types.boolean,
  personal_info: types.maybeNull(AccountPersonalInfo),
  post_login_redirect_path: types.maybeNull(types.string),
  redirect_to_customers_prices: types.boolean,
  show_prices_with_tax: types.boolean,
  show_cart_matrix: types.boolean,
  show_product_prices: types.boolean,
  styles: types.maybeNull(types.string),
  tokens: types.array(Token),
  user_id: types.maybeNull(types.number),
  view_only: types.boolean,
  allow_address_modifications: types.maybeNull(types.boolean),
});

export default User;
