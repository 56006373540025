import { types } from 'mobx-state-tree';

const Modal = types
  .model('Modal', {
    isOpen: types.optional(types.boolean, false)
  })
  .actions((self) => {
    return {
      toggle() {
        self.isOpen = !self.isOpen;
      }
    };
  });

export default Modal;
