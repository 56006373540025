import React, { Component } from 'react';
import { Switch, withRouter, Redirect, Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';
import loadable from '@loadable/component';

import './styles/main.css';

import CommonNavigation from './components/header/CommonNavigation';
import ProductPage from './pages/ProductPage';
import CategoryPage from './pages/CategoryPage';
import CommonFooter from './components/footer/CommonFooter';
import { modelOf } from './prop-types';
import RootStore from './store';
import Paths from './types/Paths';
import RequestState from './types/RequestState';
import ApiWrapper from './services/ApiWrapper';
import HomePage from './pages/HomePage';
import AdvancedSearchPage from './pages/AdvancedSearchPage';
import setResizeListener from './util/setResizeListener';
import GoogleTagManager from './analytics/GoogleTagManager';
import CriteoAnalytics from './analytics/CriteoAnalytics';
import ManufacturerPage from './pages/ManufacturerPage';
import SectionPage from './pages/SectionPage';
import ManufacturerListPage from './pages/ManufacturerListPage';
import IntlWrapper from './i18n/IntlWrapper';
import { parseLanguageCode } from './util/url';
import SectionAwareRoute from './components/route/SectionAwareRoute';
import RouteHistoryKeeper from './components/route/RouteHistoryKeeper';
import CookieNotice from './components/cookie-notice/CookieNotice';
import TimerCoupons from './components/coupon/TimerCoupons';
import ActivatedCoupons from './components/coupon/ActivatedCoupons';
import * as queryString from './util/queryString';
import UninitializedAppSpinner from './components/loader/UninitializedAppSpinner';
import FatalError from './components/error/FatalError';
import RobotsMeta from './components/head/RobotsMeta';
import ScrollTracker from './components/common/ScrollTracker';
import SetNewPasswordPage from './pages/SetNewPasswordPage';
import PasswordResetRedirect from './components/route/PasswordResetRedirect';
import NotFoundPage from './pages/NotFoundPage';
import GclidTracker from './components/common/GclidTracker';
import RouteService from './services/RouteService';
import FacebookPixel from './analytics/FacebookPixel';
import StyleOverrides from './components/head/StyleOverrides';
import Giosg from './analytics/Giosg';
import {
  globalUserMessageDataKey,
  storageKeys,
  urlParams,
} from './util/constants';
import { getStorageAndParse, stringifyAndSetStorage } from './util/storage';
import GenericMeta from './components/head/GenericMeta';
import SpotmoreWidget from './components/marketing/SpotmoreWidget';
import YotpoReviewWrapper from './components/review/YotpoReviewWrapper';
import LocalStorageKey from './types/LocalStorageKey';
import { browserLanguage } from './util/browser';
import PostAffiliateWidget from './integrations/postaffiliate/PostAffiliateWidget';
import LoginPageContent from './components/account/LoginPageContent';
import LogoutParamRedirect from './components/route/LogoutParamRedirect';
import UniversalJavascriptTool from './components/common/UniversalJavascriptTool';
import SEOSchema from './components/head/SEOSchema';
import StoreGarbageCollector from './components/common/StoreGarbageCollector';
import Analytics from './analytics/Analytics';
import MyAccountThankYouPage from './pages/MyAccountThankYouPage';
import ProposalModal from './components/proposal/ProposalModal';
import CheckoutNavigation from './components/checkout/CheckoutNavigation';
import CheckoutFooter from './components/checkout/CheckoutFooter';
import AccountModal from './components/account/AccountModal';
import CartModal from './components/cart/CartModal';
import CartErrorModal from './components/cart/CartErrorModal';
import RedirectModal from './components/localization/RedirectModal';
import SurveyModal from './components/surveys/SurveyModal';
import MobileRegionalSettingsModal from './components/common/MobileRegionalSettingsModal';
import InfoPage from './pages/InfoPage';
import VehiclePartSearchPage from './pages/VehiclePartSearchPage';
import OnSalePage from './pages/OnSalePage';
import NewProductsPage from './pages/NewProductsPage';
import SoldProductsPage from './pages/SoldProductsPage';
import PopularProductsPage from './pages/PopularProductsPage';
import RecommendedProductsPage from './pages/RecommendedProductsPage';
import FallbackSpinner from './components/loader/FallbackSpinner';
import ServiceFormWidget from './integrations/serviceForm/ServiceFormWidget';
import TimerMessage from './components/common/TimerMessage';

const loadableOptions = {
  fallback: <FallbackSpinner />,
};

const MyAccountPage = loadable(
  () => import(/* webpackChunkName: "myAccount" */ './pages/MyAccountPage'),
  loadableOptions
);

const CheckoutPage = loadable(
  () => import(/* webpackChunkName: "checkout" */ './pages/CheckoutPage'),
  loadableOptions
);
const ThankYouPage = loadable(
  () => import(/* webpackChunkName: "checkout" */ './pages/ThankYouPage'),
  loadableOptions
);
const ProposalThankYouPage = loadable(
  () =>
    import(/* webpackChunkName: "checkout" */ './pages/ProposalThankYouPage'),
  loadableOptions
);
const KlarnaInstantShoppingThankYouPage = loadable(
  () =>
    import(
      /* webpackChunkName: "checkout" */ './pages/KlarnaInstantShoppingThankYouPage'
    ),
  loadableOptions
);

const WishListPage = loadable(
  () => import(/* webpackChunkName: "wishlist" */ './pages/WishListPage'),
  loadableOptions
);

const CustomerProductsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "sortingLists" */ './pages/CustomerProductsPage'
    ),
  loadableOptions
);

const SectionRoutes = [
  createSectionRoute(Paths.Product, ProductPage),
  createSectionRoute(Paths.MainProductGroup, CategoryPage),
  createSectionRoute(Paths.ProductGroup, CategoryPage),
  createSectionRoute(Paths.SubProductGroup, CategoryPage),
  createSectionRoute(Paths.OnSale, OnSalePage),
  createSectionRoute(Paths.NewProducts, NewProductsPage),
  createSectionRoute(Paths.SoldProducts, SoldProductsPage),
  createSectionRoute(Paths.PopularProducts, PopularProductsPage),
  createSectionRoute(Paths.RecommendedProducts, RecommendedProductsPage),
  createSectionRoute(Paths.CustomerProducts, CustomerProductsPage),
  createSectionRoute(Paths.WishList, WishListPage),
];

function createSectionRoute(path, component) {
  return {
    path,
    component,
  };
}

@observer
class App extends Component {
  constructor(props) {
    super(props);
    const { store, location } = this.props;
    const { uiStore } = store;

    // Set up the language for the first time. We only have the URL to go with at this point.
    // If the URL doesn't specify a language, we'll set it again after the bootstrap.
    this.setLanguageCodeFromUrl();

    const queryParams = queryString.parse(location.search);

    this.setLocalStorageFromUrl(queryParams);

    if (store.state !== RequestState.LOADED) {
      store
        .bootstrap(
          queryParams.sellerID,
          queryParams.campaign_code,
          queryParams.allowTestModeScripts,
          queryParams.processLogout
        )
        .catch((e) => {
          // 503 is sent from backend for maintenance mode therefore error is not needed to print.
          if (e.response && e.response.status !== 503) {
            console.error(e.response);
          }
          // Catch errors in bootstrapping.
          if (!e.response) {
            console.error(e);
          }
        });
    }

    setResizeListener(uiStore.setViewActiveBreakpoint);
    this.maybeSetProductListAccessTokenParamFromUrl();

    window.addEventListener(
      globalUserMessageDataKey.globalUserMessage,
      (event) => {
        this.handleGlobalMessages(event.detail);
      }
    );

    this.processEventTimeout = null;
  }

  setLanguageCodeFromUrl = () => {
    const { store, location, apiWrapper } = this.props;
    const { languageStore } = store;

    let languageCode = parseLanguageCode(location.pathname);
    if (languageCode) {
      languageStore.setupLocale(languageCode);
      apiWrapper.setLanguageCode(languageCode);
    }
  };

  setLocalStorageFromUrl = (params) => {
    // Check user choice for chosen site.
    if (params.siteChosen && params.siteChosen === '1') {
      stringifyAndSetStorage(LocalStorageKey.SITE_CHOSEN, true);
    }
  };

  setProductListFeaturesIfEnabled = () => {
    if (!this.props.store.configStore.wishlist.enabled) {
      return;
    }

    this.setProductListGuestStorage();
  };

  setProductListGuestStorage = () => {
    const storage = getStorageAndParse(storageKeys.wishlist);
    if (!storage) {
      stringifyAndSetStorage(storageKeys.wishlist, []);
    }
  };

  maybeSetProductListAccessTokenParamFromUrl = () => {
    const { location, history } = this.props;
    const search = location.search;
    if (search.includes(urlParams.accessToken)) {
      const params = search.replace(`?${urlParams.accessToken}=`, '');
      history.push(Paths.WishList + params);
    }
  };

  handleGlobalMessages = (data) => {
    const { uiStore } = this.props.store;

    uiStore.addEvent({ data });
    uiStore.processEvents();
    this.processEventTimeout = setTimeout(uiStore.resetEvent, 5000);
  };

  componentWillUnmount() {
    document.removeEventListener(
      globalUserMessageDataKey.globalUserMessage,
      (event) => {
        this.handleGlobalMessages(event.detail);
      }
    );

    this.processEventTimeout = null;
  }

  /**
   * Some of our routes change, if sections are enabled.
   *
   * @param prefix Prefix for all routes (e.g. "/dogs/")
   * @param component Optional component that replaces all Route targets (e.g. for redirecting).
   * @param section Section to which this route belongs to.
   * @returns {Component[]}
   */
  getSectionDependantRoutes = (prefix, component, section) => {
    const routes = SectionRoutes.concat(this.getRoutesBehindConfig());
    return routes.map((sectionRoute) => (
      <SectionAwareRoute
        key={sectionRoute.path}
        section={section}
        exact
        path={prefix + sectionRoute.path}
        component={component || sectionRoute.component}
      />
    ));
  };

  getRoutesBehindConfig = () => {
    const { configStore } = this.props.store;
    const routes = [];

    if (configStore.vehiclePartSearch.enabled) {
      routes.push(
        createSectionRoute(Paths.VehiclePartSearch, VehiclePartSearchPage)
      );
    }

    if (configStore.siteConfig.isShoppingCenter) {
      routes.push(
        createSectionRoute(
          Paths.KlarnaInstantShoppingThankYouPage,
          KlarnaInstantShoppingThankYouPage
        )
      );
    }

    return routes;
  };

  /**
   * We first match if the route matches to any section and then render all
   * routes under that section.
   *
   * @param prefix Route prefix (e.g. "/en")
   * @param component Optional component that replaces all Route targets (e.g. for redirecting).
   * @returns {Component[]}
   */
  getSectionRoutes = (prefix, component) => {
    const { sectionStore } = this.props.store;

    return sectionStore.sections.map((section) => (
      <SectionAwareRoute
        key={section.id}
        path={`${prefix}/${section.slug}/`}
        section={section}
        render={({ match }) => {
          return (
            <Switch>
              <SectionAwareRoute
                exact
                section={section}
                path={match.url}
                component={component || SectionPage}
              />
              {this.getSectionDependantRoutes(match.url, component, section)}
              <Redirect to="/" />
            </Switch>
          );
        }}
      />
    ));
  };

  /**
   * Some of our routes need to be rendered for each language.
   *
   * @param prefix Language prefix (e.g. "/en")
   * @param component Optional component for overriding normal
   * @returns {Component[]}
   */
  getLanguageDependantRoutes = (prefix, component) => {
    const { configStore } = this.props.store;
    const sectionDependantRoutes = configStore.activateSections
      ? this.getSectionRoutes(prefix, component)
      : [];
    // We need to return an array, because <Switch /> does not support nesting or Fragments.
    const languageDependantRoutes = [];

    if (
      configStore.account.enableMyAccount &&
      !configStore.siteConfig.isShoppingCenter
    ) {
      languageDependantRoutes.push(
        <SectionAwareRoute
          key={Paths.MyAccount}
          path={prefix + Paths.MyAccount}
          component={component || MyAccountPage}
        />
      );
    }

    languageDependantRoutes.push([
      <SectionAwareRoute
        key={Paths.AdvancedSearch}
        exact
        path={prefix + Paths.AdvancedSearch}
        component={component || AdvancedSearchPage}
      />,
      <SectionAwareRoute
        key={Paths.InfoPage}
        exact
        path={prefix + Paths.InfoPage}
        component={component || InfoPage}
      />,
      <SectionAwareRoute
        key={Paths.Manufacturer}
        exact
        path={prefix + Paths.Manufacturer}
        component={component || ManufacturerPage}
      />,
      <SectionAwareRoute
        key={Paths.OnSale}
        exact
        path={prefix + Paths.OnSale}
        component={component || OnSalePage}
      />,
      <SectionAwareRoute
        key={Paths.NewProducts}
        exact
        path={prefix + Paths.NewProducts}
        component={component || NewProductsPage}
      />,
      <SectionAwareRoute
        key={Paths.PopularProducts}
        exact
        path={prefix + Paths.PopularProducts}
        component={component || PopularProductsPage}
      />,
      <SectionAwareRoute
        key={Paths.RecommendedProducts}
        exact
        path={prefix + Paths.RecommendedProducts}
        component={component || RecommendedProductsPage}
      />,
      <SectionAwareRoute
        key={Paths.ManufacturerList}
        exact
        path={prefix + Paths.ManufacturerList}
        component={component || ManufacturerListPage}
      />,
      <SectionAwareRoute
        key={Paths.SetNewPassword}
        exact
        path={prefix + Paths.SetNewPassword}
        component={component || SetNewPasswordPage}
      />,
      <SectionAwareRoute
        key={Paths.ThankYouPage}
        exact
        path={prefix + Paths.ThankYouPage}
        component={component || ThankYouPage}
      />,
      <SectionAwareRoute
        key={Paths.MyAccountThankYouPage}
        exact
        path={prefix + Paths.MyAccountThankYouPage}
        component={component || MyAccountThankYouPage}
      />,
      <SectionAwareRoute
        key={Paths.CustomerProducts}
        exact
        path={prefix + Paths.CustomerProducts}
        component={component || CustomerProductsPage}
      />,
      <SectionAwareRoute
        key={Paths.WishList}
        exact
        path={prefix + Paths.WishList}
        component={component || WishListPage}
      />,
      <SectionAwareRoute
        key={Paths.WishListProductList}
        exact
        path={prefix + Paths.WishListProductList}
        component={component || WishListPage}
      />,
      <SectionAwareRoute
        key={Paths.ProposalCheckout}
        exact
        path={prefix + Paths.ProposalCheckout}
        component={component || CheckoutPage}
      />,
      <SectionAwareRoute
        key={Paths.ProposalThankYouPage}
        exact
        path={prefix + Paths.ProposalThankYouPage}
        component={component || ProposalThankYouPage}
      />,
      ...sectionDependantRoutes,
      // Always render routes without section
      ...this.getSectionDependantRoutes(prefix, component),
    ]);

    return languageDependantRoutes;
  };

  /**
   * If we have more than one language, we need to redirect routes without a
   * language prefix to the active language routes.
   *
   * @returns {Component[]}
   */
  getDefaultLanguageRedirects = () => {
    const { languageStore } = this.props.store;
    const RedirectComponent = (props) => (
      <Redirect
        to={`/${languageStore.activeLocale}${props.location.pathname}${props.location.search}${props.location.hash}`}
      />
    );
    return this.getLanguageDependantRoutes('', RedirectComponent);
  };

  /**
   * Returns a switch with all the routes for the main content ("pages").
   * The routes have two optional prefixes: Language (code) and Section (slug).
   * Language prefixes are used, if there are more than 1 language.
   * Section prefixes are used, if sections are enabled.
   * @returns {Switch}
   */
  getRoutes = () => {
    const { routeService, store } = this.props;
    const { languageStore } = store;

    const routes =
      languageStore.languages.length > 1
        ? [
            ...languageStore.languages.map((language) => (
              <SectionAwareRoute
                key={language.code}
                path={`/${language.code}/`}
                render={({ match }) => (
                  <Switch>
                    <SectionAwareRoute
                      exact
                      path={match.url}
                      component={HomePage}
                    />
                    {this.getLanguageDependantRoutes(match.url)}
                    <SectionAwareRoute component={NotFoundPage} />
                  </Switch>
                )}
              />
            )),
            <SectionAwareRoute
              exact
              path="/"
              key="/"
              render={() => (
                <Redirect to={routeService.getPath(Paths.FrontPage)} />
              )}
            />,
            ...this.getDefaultLanguageRedirects(),
          ]
        : [
            ...this.getLanguageDependantRoutes(''),
            <SectionAwareRoute exact path="/" key="/" component={HomePage} />,
          ];
    return (
      <Switch>
        <Route
          exact
          strict
          path="/:url*"
          render={(props) => (
            <Redirect
              to={`${props.location.pathname}/${props.location.search}${props.location.hash}`}
            />
          )}
        />
        {routes}
        <SectionAwareRoute path={Paths.NotFoundPage} component={NotFoundPage} />
        <SectionAwareRoute component={NotFoundPage} />
      </Switch>
    );
  };

  allowIndexing = () => {
    const { location, store } = this.props;
    const queryParams = queryString.parse(location.search);
    const filterParams = store.configStore.seo.indexedParameters;

    filterParams.forEach((parameter) => {
      delete queryParams[parameter];
    });

    return Object.keys(queryParams).length === 0;
  };

  getSchemaData = () => ({
    '@context': 'https://schema.org/',
    '@type': 'WebPage',
    name: this.props.store.configStore.store.name,
  });

  getHeader = () => {
    const { location } = this.props;

    return location.pathname.endsWith(Paths.ProposalCheckout) ? (
      <CheckoutNavigation />
    ) : (
      <CommonNavigation />
    );
  };

  getFooter = (loaded) => {
    const { location } = this.props;

    if (!loaded) {
      return null;
    }

    return location.pathname.endsWith(Paths.ProposalCheckout) ? (
      <CheckoutFooter />
    ) : (
      <CommonFooter />
    );
  };

  render() {
    const { analytics, store, location, baseUrl } = this.props;
    const {
      accountStore,
      categoryStore,
      cartStore,
      configStore,
      countryStore,
      currencyStore,
      languageStore,
      sectionStore,
      translationStore,
      uiStore,
    } = store;

    if (store.lastError) {
      const navigatorLanguage = browserLanguage();
      const overrideLocale = navigatorLanguage && navigatorLanguage.language;

      return (
        <IntlWrapper
          languageStore={languageStore}
          translationStore={translationStore}
          overrideLocale={overrideLocale}
        >
          <FatalError />
        </IntlWrapper>
      );
    }

    const loaded =
      configStore.state === RequestState.LOADED &&
      sectionStore.state === RequestState.LOADED &&
      languageStore.state === RequestState.LOADED &&
      languageStore.activeLanguage &&
      languageStore.hasBeenSetup &&
      currencyStore.activeCurrency &&
      (categoryStore.state === RequestState.LOADED ||
        categoryStore.state === RequestState.ERROR);

    // URLs with parameters should not be indexed.
    let shouldIndex = true;

    if (loaded) {
      shouldIndex = this.allowIndexing();
    }

    if (
      loaded &&
      !shouldIndex &&
      categoryStore.activeCategory &&
      configStore.suitableProducts.enabledOnCategories.indexOf(
        categoryStore.activeCategory.id
      ) !== -1
    ) {
      shouldIndex = true;
    }

    const isCrawler = window.isSSR;

    let loginRequired = false;
    if (loaded) {
      this.setProductListFeaturesIfEnabled();
      loginRequired = configStore.store.loginRequired && !accountStore.loggedIn;
      configStore.analytics.ga4.enabled && analytics.reInitializeWithGA4();
    }

    const openGraphPrefix = 'og: https://ogp.me/ns#';
    const doLoadCart = !accountStore.isViewOnly;
    const doLoadProposalCart =
      !accountStore.isViewOnly &&
      loaded &&
      configStore.siteConfig.proposalEnabled;
    const canLoadScripts = uiStore.currentPageIsSet;
    const ifCartError =
      cartStore.state === RequestState.LOADED &&
      cartStore.cart.ifRenderErrorModal;
    const baseHref = !!window.isSSR ? baseUrl : window.location.origin;
    const { postAffiliate } = loaded && configStore.integrations;

    return (
      <div className="App">
        {loaded && !isCrawler && <ServiceFormWidget />}
        <Route path="/" component={RouteHistoryKeeper} />
        {loaded ? (
          <IntlWrapper
            languageStore={languageStore}
            translationStore={translationStore}
          >
            <>
              {configStore.gclid.enabled && (
                <GclidTracker location={location} />
              )}
              <SEOSchema data={this.getSchemaData()} />
              <Helmet>
                <html
                  lang={languageStore.activeLocale}
                  prefix={openGraphPrefix}
                />
                <body
                  className={classNames(
                    `category-${
                      categoryStore.activeCategory &&
                      categoryStore.activeCategory.id
                    }`,
                    `country-${
                      countryStore.activeCountry &&
                      countryStore.activeCountry.iso_code_2
                    }`,
                    `currency-${currencyStore.currencyCode}`,
                    `customer-group-${
                      accountStore.account &&
                      accountStore.account.customer_group_id
                    }`,
                    `language-${languageStore.activeLocale}`,
                    `path-${location.pathname}`,
                    `section-${
                      sectionStore.activeSection &&
                      sectionStore.activeSection.id
                    }`
                  ).toLowerCase()}
                />
              </Helmet>
              <Helmet>
                <base href={baseHref} />
              </Helmet>
              {!shouldIndex && <RobotsMeta noindex />}
              {configStore.denyRobots && <RobotsMeta noindex nofollow />}
              {configStore.facebookVerification.length > 0 && (
                <GenericMeta
                  name="facebook-domain-verification"
                  content={configStore.facebookVerification}
                />
              )}

              {!isCrawler && configStore.gtm.enabled && <GoogleTagManager />}
              {!isCrawler && <CriteoAnalytics />}
              {!isCrawler && <FacebookPixel />}
              {!isCrawler && <Giosg />}
              {!isCrawler && <YotpoReviewWrapper />}
              {!isCrawler && <SpotmoreWidget />}

              {!isCrawler && postAffiliate.enabled && <PostAffiliateWidget />}

              {!loginRequired && (
                <div>
                  {this.getHeader()}
                  {this.getRoutes()}
                  {this.getFooter(!isCrawler ? uiStore.contentLoaded : true)}
                </div>
              )}
              {loginRequired && <LoginPageContent />}

              {!isCrawler && <CookieNotice />}
              {!isCrawler && <TimerCoupons />}
              {!isCrawler && <ActivatedCoupons />}
              {!isCrawler && <TimerMessage />}

              {!loginRequired && <AccountModal />}
              {doLoadCart && <CartModal />}
              {doLoadProposalCart && <ProposalModal />}
              {ifCartError && <CartErrorModal />}
              {!isCrawler && <MobileRegionalSettingsModal />}
              {!isCrawler && <RedirectModal />}
              {!isCrawler && <SurveyModal />}

              <ScrollTracker />
              <PasswordResetRedirect />
              <StoreGarbageCollector />
              <LogoutParamRedirect />
              {!isCrawler && canLoadScripts && <UniversalJavascriptTool />}

              <StyleOverrides />
            </>
          </IntlWrapper>
        ) : (
          <UninitializedAppSpinner />
        )}
      </div>
    );
  }
}

App.propTypes = {
  store: modelOf(RootStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  apiWrapper: PropTypes.instanceOf(ApiWrapper).isRequired,
  location: RouterPropTypes.location.isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  baseUrl: PropTypes.string,
};

// withRouter is needed for the observer to update the component on route changes.
export default inject(
  'analytics',
  'apiWrapper',
  'routeService'
)(withRouter(App));
