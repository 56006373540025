import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import NavigationEntity from '../../../types/NavigationEntity';

const NavigationEntityTextItem = (props) => {
  const { className, entity, ...rest } = props;
  const { active, label } = entity;

  if (!entity) {
    return null;
  }

  // nav-item class is used for now to match Bootstrap class names.
  // Should be changed in the future.
  return (
    <span
      className={classNames('nav-text', className, {
        active,
      })}
      aria-label={entity.accessible_text}
      {...rest}
    >
      {label}
    </span>
  );
};

NavigationEntityTextItem.propTypes = {
  entity: NavigationEntity.isRequired,
  className: PropTypes.string,
};

export default NavigationEntityTextItem;
