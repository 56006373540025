import React, { Component } from 'react';
import classNames from 'classnames';

/* TODO: make toggleable with a configuration setting? */
class PageTitle extends Component {
  render() {
    const { children, className, ...rest } = this.props;

    return (
      <h1 className={classNames('PageTitle', className)} {...rest}>
        {children}
      </h1>
    );
  }
}

export default PageTitle;
