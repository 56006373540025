import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ContentSlot from '../ContentSlot';
import AdLoader from '../AdLoader';
import SSRAwareLazyLoad from '../../common/SSRAwareLazyLoad';

@observer
export class ContentSlots extends Component {
  render() {
    const { className, searchParams } = this.props;

    return (
      <SSRAwareLazyLoad once offset={50} height={25}>
        <AdLoader searchParams={searchParams}>
          {(ads) => {
            if (!ads || ads.length === 0) {
              return null;
            }

            return (
              <div className={classNames('ContentSlots', className)}>
                {ads.map((ad) => (
                  <ContentSlot
                    ad={ad}
                    bannerZone={searchParams.bannerZone}
                    key={ad.id}
                  />
                ))}
              </div>
            );
          }}
        </AdLoader>
      </SSRAwareLazyLoad>
    );
  }
}

ContentSlots.propTypes = {
  searchParams: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default inject('adStore')(ContentSlots);
