import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Alert, Button, Card, CardBody, CardHeader, Form } from 'reactstrap';
import PropTypes from 'prop-types';

import UnexpectedError from '../../loader/UnexpectedError';
import globalTranslations from '../../../i18n/globalTranslations';
import { createFormField } from '../../../models/FormField';
import { createForm } from '../../../models/Form';
import FormGroupField from '../../form/FormGroupField';
import { modelOf } from '../../../prop-types';
import Icon from '../../common/Icon';
import ButtonLink from '../../common/ButtonLink';
import ContentForState from '../../loader/ContentForState';
import CampaignCodeStore from '../../../store/CampaignCodeStore';
import CampaignCodeDiscount from '../../campaign-code/CampaignCodeDiscount';
import RequestState from '../../../types/RequestState';
import AccountStore from '../../../store/AccountStore';

const ActivationStatus = {
  NONE: 'NONE',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  ACTIVATING: 'ACTIVATING',
  REMOVING: 'REMOVING',
  UNEXPECTED_ERROR: 'UNEXPECTED_ERROR'
};

@observer
export class CampaignCodeBox extends Component {
  form = null;
  state = {
    activationStatus: ActivationStatus.NONE
  };

  constructor(props) {
    super(props);

    const fields = {
      campaignCode: createFormField({})
    };
    this.form = createForm(fields);
  }

  componentDidMount() {
    this.loadActiveCodes();
  }

  loadActiveCodes = () => {
    const { campaignCodeStore } = this.props;

    if (campaignCodeStore.state !== RequestState.LOADED) {
      campaignCodeStore.loadActiveCodes().catch((e) => {
        console.error(e);
      });
    }
  };

  handleSet = (e) => {
    e.preventDefault();

    const { campaignCodeStore } = this.props;
    const campaignCode = this.form.fields.get('campaignCode').value;

    if (campaignCode) {
      this.setState({
        activationStatus: ActivationStatus.ACTIVATING
      });

      campaignCodeStore
        .setCode(campaignCode)
        .then(() => {
          this.setState({
            activationStatus: ActivationStatus.SUCCESS
          });
        })
        .catch((e) => {
          const status = e.response ? e.response.status : 500;
          const errorMap = {
            404: ActivationStatus.FAILED,
            500: ActivationStatus.UNEXPECTED_ERROR
          };

          this.setState({
            activationStatus: errorMap[status]
          });
        });

      this.form.fields.get('campaignCode').setValue('');
    }
  };

  handleUnset = () => {
    const { campaignCodeStore } = this.props;

    this.setState({
      activationStatus: ActivationStatus.REMOVING
    });

    campaignCodeStore
      .unsetCode()
      .then(() => {
        this.setState({
          activationStatus: ActivationStatus.NONE
        });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  renderForm = () => {
    return (
      <Form noValidate onSubmit={this.handleSet}>
        <div className="CampaignCodeBox__form-fields">
          <div className="CampaignCodeBox__field CampaignCodeBox__field--campaignCode">
            <FormGroupField
              field={this.form.fields.get('campaignCode')}
              fieldName={'campaignCode'}
              formName="CampaignCodeBox"
              label=""
              hideLabel
              placeholder={this.props.intl.formatMessage(
                globalTranslations.campaignBoxTitle
              )}
            />
          </div>
          <Button className="CampaignCodeBox__submit" color="primary">
            <FormattedMessage
              id="campaign.activateCode"
              defaultMessage="Activate"
            />
          </Button>
        </div>
      </Form>
    );
  };

  renderActivatedView = () => {
    return (
      <div className="CampaignCodeBox__activated-view">
        <Icon name="check" className="CampaignCodeBox__icon" />
        <FormattedMessage
          id="campaign.isActivated"
          defaultMessage="The campaign code is activated!"
        />
        <CampaignCodeDiscount />
        <div className="CampaignCodeBox__remove-code">
          <ButtonLink
            className="RemoveCampaignCodeLink"
            onClick={this.handleUnset}
          >
            <FormattedMessage
              id="campaign.removeCouponCode"
              defaultMessage="Remove code"
            />
          </ButtonLink>
        </div>
      </div>
    );
  };

  renderFormView = () => {
    return (
      <div className="CampaignCodeBox__form">
        <FormattedMessage
          id="campaign.haveYouReceived"
          defaultMessage="Have you received a campaign or discount code?"
        />
        {this.renderForm()}
      </div>
    );
  };

  renderMessage = () => {
    const { activationStatus } = this.state;
    let message;

    switch (activationStatus) {
      case ActivationStatus.SUCCESS:
        message = (
          <Alert color="success">
            <FormattedMessage
              id="campaign.setSuccess"
              defaultMessage="The campaign code was activated successfully!"
            />
          </Alert>
        );
        break;
      case ActivationStatus.FAILED:
        message = (
          <Alert color="danger">
            <FormattedMessage
              id="campaign.setError"
              defaultMessage="The entered campaign code was not found."
            />
          </Alert>
        );
        break;
      case ActivationStatus.ACTIVATING:
        message = (
          <Alert color="info">
            <FormattedMessage
              id="campaign.activationInProgress"
              defaultMessage="Activating code..."
            />
          </Alert>
        );
        break;
      case ActivationStatus.REMOVING:
        message = (
          <Alert color="info">
            <FormattedMessage
              id="campaign.removalInProgress"
              defaultMessage="Removing code..."
            />
          </Alert>
        );
        break;
      case ActivationStatus.UNEXPECTED_ERROR:
        message = <UnexpectedError />;
        break;
      case ActivationStatus.NONE:
      default:
        break;
    }
    return message;
  };

  render() {
    const { accountStore, title, campaignCodeStore } = this.props;

    const ifProductCatalog = accountStore.isViewOnly;

    if (ifProductCatalog) {
      return null;
    }

    return (
      <Card>
        <CardHeader>{title}</CardHeader>
        <ContentForState
          state={campaignCodeStore.state}
          forLoaded={() => {
            return (
              <div className="CampaignCodeBox">
                <CardBody>
                  {this.renderMessage()}
                  {campaignCodeStore.activeCodes.length === 0
                    ? this.renderFormView()
                    : this.renderActivatedView()}
                </CardBody>
              </div>
            );
          }}
        />
      </Card>
    );
  }
}

CampaignCodeBox.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  campaignCodeStore: modelOf(CampaignCodeStore).isRequired,
  intl: intlShape.isRequired,
  title: PropTypes.string
};

export default inject('accountStore', 'campaignCodeStore')(
  injectIntl(CampaignCodeBox)
);
