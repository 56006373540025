import { types } from 'mobx-state-tree';
import { forEach } from 'lodash';

import FormField from './FormField';
import FieldType from '../types/FieldType';

/*
 * A Form is used to keep the data for a form in the UI that has multiple fields.
 * Each field should have its own FormField.
 */
const Form = types
  .model('Form', {
    fields: types.optional(types.map(FormField), {}),
  })
  .views((self) => {
    return {
      get fieldsArray() {
        return Array.from(self.fields, ([key, value]) => value);
      },
      get valid() {
        let valid = true;
        // We use lodash forEach instead of for ... of for IE 11 compatibility
        forEach(self.fieldsArray, (field) => {
          if (field.active && field.valid === null) {
            valid = null;
            return false; // break
          }
          if (field.active && field.valid === false) {
            valid = false;
            return false; // break
          }
        });
        return valid;
      },
    };
  })
  .actions((self) => {
    return {
      getFieldValues: () => {
        const values = {};
        self.fields.forEach((field, key) => {
          // File fields only use the value for validation.
          if (field.active && field.type === FieldType.NORMAL) {
            values[key] = field.value;
          }
        });
        return values;
      },
      setField(fieldName, field) {
        self.fields.set(fieldName, field);
      },
      validateField(fieldName) {
        return self.fields.get(fieldName).setShouldValidate(true);
      },
      validate() {
        return self.fields.forEach((field) => field.setShouldValidate(true));
      },
    };
  });

export default Form;

export function createForm(fields) {
  const formModel = Form.create({});
  Object.keys(fields).forEach((fieldName) => {
    formModel.setField(fieldName, fields[fieldName]);
  });

  return formModel;
}
