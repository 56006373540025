import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ReCAPTCHA from 'react-google-recaptcha';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import { FormGroup } from 'reactstrap';

@observer
export class RecaptchaField extends Component {
  render() {
    const { configStore, ...rest } = this.props;

    if (!configStore.recaptcha.sitekey) {
      return null;
    }

    return (
      <FormGroup className="RecaptchaField">
        <ReCAPTCHA sitekey={configStore.recaptcha.sitekey} {...rest} />
      </FormGroup>
    );
  }
}

RecaptchaField.propTypes = {
  configStore: modelOf(ConfigStore).isRequired
};

export default inject('configStore')(RecaptchaField);
