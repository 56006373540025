import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { Container } from 'reactstrap';

import Page from '../../components/layout/Page';
import WysiwygContent from '../../components/common/WysiwygContent';
import ProductTabs from '../../components/product/ProductTabs';
import ConfigStore from '../../store/ConfigStore';
import { modelOf } from '../../prop-types';
import GenericLayout from '../../components/layout/GenericLayout';
import ContentPopup from '../../components/ad/ContentPopup';
import MainBanner from '../../components/ad/MainBanner';
import ScrollReset from '../../components/common/ScrollReset';
import AdPages from '../../types/AdPages';
import PropTypes from 'prop-types';
import RouteService from '../../services/RouteService';
import SEOHomePage from '../../components/head/SEOHomePage';
import CommonPage from '../../types/CommonPage';

@observer
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.yotpoTimeout = null;
  }

  componentDidMount() {
    const { configStore } = this.props;

    if (configStore.integrations.yotpo.enabled && window && window.yotpo) {
      this.yotpoTimeout = setTimeout(() => window.yotpo.refreshWidgets(), 800);
    }
  }

  componentWillUnmount() {
    this.yotpoTimeout && clearTimeout(this.yotpoTimeout);
  }

  getSearchParams = () => {
    return {
      bannerPage: AdPages.FRONT_PAGE,
    };
  };

  render() {
    const { configStore } = this.props;

    const mainBanner = <MainBanner searchParams={this.getSearchParams()} />;
    const mainBannerWithinContent =
      configStore.mainBanner.withinContent && !configStore.mainBanner.fullWidth;

    return (
      <Page name={CommonPage.HOME} className="HomePage">
        <ScrollReset />
        <SEOHomePage />
        {!mainBannerWithinContent && mainBanner}
        <Container className="HomePage__container">
          <GenericLayout
            content={
              <Fragment>
                {mainBannerWithinContent && mainBanner}
                <div className="SectionPage__section-content">
                  <WysiwygContent html={configStore.frontpageText} />
                </div>
                <ProductTabs disableQuickSearch requireCanonicalLink={true} />
                <ContentPopup searchParams={this.getSearchParams()} />
              </Fragment>
            }
          />
        </Container>
      </Page>
    );
  }
}

HomePage.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
};

export default inject('configStore', 'routeService')(HomePage);
