import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GoBackLink from '../GoBackLink';
import Breadcrumbs from '../Breadcrumbs';
import BreadcrumbPath from '../../../types/BreadcrumbPath';

class HelperNavigation extends Component {
  render() {
    const { breadcrumbsPath, withGoBackLink, rightSide } = this.props;
    return (
      <div className="HelperNavigation">
        {rightSide}
        {withGoBackLink && (
          <span>
            <GoBackLink />
          </span>
        )}
        <Breadcrumbs path={breadcrumbsPath} />
      </div>
    );
  }
}

HelperNavigation.propTypes = {
  breadcrumbsPath: BreadcrumbPath.isRequired,
  withGoBackLink: PropTypes.bool,
  rightSide: PropTypes.node
};

export default HelperNavigation;
