import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Alert, Container } from 'reactstrap';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import Page from '../../components/layout/Page';
import RouteService from '../../services/RouteService';
import MainProductList from '../../components/product-list/MainProductList';
import HelperNavigation from '../../components/common/HelperNavigation';
import GenericLayout from '../../components/layout/GenericLayout';
import globalTranslations from '../../i18n/globalTranslations';
import { parse } from '../../util/queryString';
import SEOTitle from '../../components/head/SEOTitle';
import CanonicalLink from '../../components/head/CanonicalLink';
import PageTitle from '../../components/common/PageTitle';
import Paths from '../../types/Paths';
import ProductSortOrderBy from '../../types/ProductSortOrderBy';
import SortOder from '../../types/SortOrder';
import ContentPopup from '../../components/ad/ContentPopup';
import ScrollReset from '../../components/common/ScrollReset';
import RobotsMeta from '../../components/head/RobotsMeta';
import CommonPage from '../../types/CommonPage';

@observer
export class AdvancedSearchPage extends Component {
  getBreadcrumbsPath = () => {
    const { intl, routeService } = this.props;
    return routeService.transformBreadCrumbs([
      {
        text: intl.formatMessage(globalTranslations.searchResultsTitle),
        url: Paths.AdvancedSearch,
      },
    ]);
  };

  renderSearchContent = (searchText) => {
    const listId = 'advanced_search_list';
    const listName = 'Advanced Search List';
    const fixedParams = {
      text: searchText,
      orderBy: ProductSortOrderBy.RELEVANCE,
      order: SortOder.DESC,
    };

    return (
      <MainProductList
        fixedParams={fixedParams}
        listId={listId}
        name={listName}
        showSort={false}
        allSections={true}
        redirectOnSingleMatch
        disableQuickSearch
        advancedSearchPage
      />
    );
  };

  render() {
    const { location, routeService } = this.props;
    const queryParams = parse(location.search);
    const searchText = queryParams.keywords;

    return (
      <Page name={CommonPage.ADVANCED_SEARCH} className="AdvancedSearchPage">
        <ScrollReset key={searchText} />
        <SEOTitle
          title={{
            values: { term: searchText },
            ...globalTranslations.searchResultsForTitle,
          }}
        />
        <CanonicalLink path={routeService.getPath(Paths.AdvancedSearch)} />
        <RobotsMeta noindex />
        <Container className="AdvancedSearchPage__container">
          <GenericLayout
            content={
              <Fragment>
                <HelperNavigation breadcrumbsPath={this.getBreadcrumbsPath()} />
                <PageTitle>
                  <FormattedMessage
                    {...globalTranslations.searchResultsForTermTitle}
                    values={{ term: searchText }}
                  />
                </PageTitle>
                {searchText ? (
                  this.renderSearchContent(searchText)
                ) : (
                  <Alert color="danger">
                    <FormattedMessage
                      id="search.termTooShort"
                      defaultMessage="Search term is too short."
                    />
                  </Alert>
                )}
              </Fragment>
            }
          />
        </Container>
        <ContentPopup />
      </Page>
    );
  }
}

AdvancedSearchPage.propTypes = {
  intl: intlShape.isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
};

export default injectIntl(
  withRouter(inject('routeService')(AdvancedSearchPage))
);
