import { types } from 'mobx-state-tree';

const OrderAddress = types.model('OrderAddress', {
  care_of: types.maybeNull(types.string),
  city: types.optional(types.string, ''),
  company: types.maybeNull(types.string),
  country_id: types.maybeNull(types.number),
  email: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  postalcode: types.optional(types.string, ''),
  street_address: types.optional(types.string, ''),
  street_address2: types.maybeNull(types.string),
  telephone: types.maybeNull(types.string),
});

export default OrderAddress;
