import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AdZones from '../../../types/AdZones';
import BannerSlider from '../BannerSlider';

@observer
export class SidebarBanner extends Component {
  render() {
    const { className, searchParams } = this.props;

    return (
      <BannerSlider
        searchParams={{
          bannerZone: AdZones.SIDEBAR_BANNER,
          ...searchParams
        }}
        settingOverrides={{
          slidesToShow: 3,
          slidesToScroll: 1,
          vertical: true,
          variableWidth: false
        }}
        className={classNames('SidebarBanner', className)}
        aspectRatio={1}
      />
    );
  }
}

SidebarBanner.propTypes = {
  className: PropTypes.string,
  searchParams: PropTypes.object
};

export default SidebarBanner;
