import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { uniqueId, find } from 'lodash';

import ProductWarranty from '../ProductWarranty';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import ProductCode from '../ProductCode';
import globalTranslations from '../../../i18n/globalTranslations';
import ProductFeatureImages from '../ProductFeatureImages';
import RequestState from '../../../types/RequestState';
import ProductClass from '../../../types/ProductClass';

@observer
export class ProductExtraInfo extends Component {
  constructor(props) {
    super(props);
    this.maybeLoadFeatureImages();
  }

  componentDidUpdate() {
    this.maybeLoadFeatureImages();
  }

  maybeLoadFeatureImages = () => {
    const { product } = this.props;

    if (product.propertyImagesState === RequestState.NONE) {
      product.loadPropertyImages();
    }
  };

  renderProductId = () => {
    const { configStore, product } = this.props;
    const showProductCode = configStore.productPage.showProductCode;
    const showAdditionalProductCode =
      configStore.productPage.showAdditionalProductCode;
    const ifHideProductCode = !product.id || !showProductCode;
    const ifHideAdditionalProductCode =
      showAdditionalProductCode && product.class === ProductClass.MULTI_CHILD;

    if (ifHideProductCode || ifHideAdditionalProductCode) {
      return null;
    }

    return (
      <li key="id" className="ProductExtraInfo--product-id">
        <ProductCode code={product.productCode} />
      </li>
    );
  };

  render() {
    const { product, singleColumn, configStore } = this.props;
    const warranty = configStore.productPage.showWarranty &&
      product.warranty && (
        <li key="warranty" className="ProductExtraInfo--warranty">
          <ProductWarranty warranty={product.warranty} />
        </li>
      );
    const weight = configStore.productPage.showWeight &&
      product.display_weight && (
        <li key="weight" className="ProductExtraInfo--weight">
          <FormattedMessage
            id="product.weightLabel"
            defaultMessage="Weight: {weight}"
            values={{
              weight: <span>{product.display_weight}</span>,
            }}
          />
        </li>
      );
    const propertyImages = product.labels.filter(
      (image) =>
        image.placement === 'product_properties' && image.show_on_product_card
    );
    const extra_properties = product.extra_properties
      .filter(
        (property) =>
          // Filter out properties with a defined property image
          !find(
            propertyImages,
            (image) => image.property_value_id === property.value_id
          )
      )
      .map((property) => (
        <li
          key={uniqueId('property-' + property.value_id)}
          className={`ProductExtraInfo--property-${property.id}`}
        >
          {property.name}: {property.value_name}
        </li>
      ));
    const features = product.features.map((feature) => (
      <li
        key={'feature-' + feature.id}
        className={`ProductExtraInfo--feature-${feature.id}`}
      >
        {feature.name}
      </li>
    ));
    const free_text_fields = product.free_text_fields.map((free_text_field) => (
      <li
        key={'free-text-' + free_text_field.name + free_text_field.value}
        className="ProductExtraInfo--free-text"
      >
        {free_text_field.name}: {free_text_field.value}
      </li>
    ));
    const id = this.renderProductId();
    const ean = configStore.productPage.showEanCode && product.ean && (
      <li key="ean" className="ProductExtraInfo--ean">
        <FormattedMessage
          {...globalTranslations.eanLabel}
          values={{ ean: product.ean }}
        />
      </li>
    );
    const extraId = configStore.productPage.showExtraId && product.extra_id && (
      <li key="extra_id" className="ProductExtraInfo--extra-id">
        <FormattedMessage
          {...globalTranslations.extraIdLabel}
          values={{ extraId: product.extra_id }}
        />
      </li>
    );
    const manufacturersCode = configStore.productPage.showManufacturersCode &&
      product.manufacturer_product_id && (
        <li
          key="manufacturer_product_id"
          className="ProductExtraInfo--manufacturer"
        >
          <FormattedMessage
            {...globalTranslations.manufacturerCodeLabel}
            values={{ manufacturerCode: product.manufacturer_product_id }}
          />
        </li>
      );
    const extraInfos = [
      id,
      ean,
      extraId,
      manufacturersCode,
      warranty,
      weight,
      ...extra_properties,
      ...features,
      ...free_text_fields,
    ].filter((info) => !!info);

    return (
      <div>
        <ProductFeatureImages
          product={product}
          position={'product_properties'}
        />
        <div
          className={classNames('ProductExtraInfo', {
            'ProductExtraInfo--short-list': extraInfos.length < 4,
            'ProductExtraInfo--single-column': singleColumn,
          })}
        >
          <ul className="inline-dot">{extraInfos}</ul>
        </div>
      </div>
    );
  }
}

ProductExtraInfo.propTypes = {
  // Both Products and ChildProducts are allowed.
  product: PropTypes.object.isRequired,
  singleColumn: PropTypes.bool,
  configStore: modelOf(ConfigStore).isRequired,
};

export default inject('configStore')(ProductExtraInfo);
