import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { getSnapshot } from 'mobx-state-tree';
import classNames from 'classnames';
import { Button, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import ProductStore from '../../../store/ProductStore';
import CartStore from '../../../store/CartStore';
import AccountStore from '../../../store/AccountStore';
import RequestState from '../../../types/RequestState';
import globalTranslations from '../../../i18n/globalTranslations';
import Slider from '../../common/Slider';
import ProductPrice from '../ProductPrice';
import { ViewBreakpointMinWidth } from '../../../types/ViewBreakpoint';
import ProductPriceInfo from '../../../models/ProductPriceInfo';
import Icon from '../../common/Icon';
import Price from '../Price';
import CustomCol from '../../bootstrap/CustomCol';
import CurrencyStore from '../../../store/CurrencyStore';
import ProductCard from '../ProductCard';
import Analytics from '../../../analytics/Analytics';
import MissingProductLoader from '../MissingProductLoader';
import ProductImpressions from '../../product/ProductImpressions';
import {
  calculateNearestProductQuantityWithQuantityStep,
  calculateTotal,
} from '../../../util/number';

const SLIDER_SETTINGS = {
  arrows: true,
  infinite: false,
  slidesToShow: 6,
  slidesToScroll: 6,
  adaptiveHeight: true,
  dots: true,
  responsive: [
    {
      breakpoint: ViewBreakpointMinWidth.XXL,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: ViewBreakpointMinWidth.MD,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: ViewBreakpointMinWidth.SM,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

@observer
export class ProductBundles extends Component {
  constructor(props) {
    super(props);

    this.listId = 'product_bundle_list';
    this.listName = 'Product Bundle List';

    this.state = {
      selectedProductIds: [],
      cartLoading: false,
    };

    this.nearestProductQuantity =
      calculateNearestProductQuantityWithQuantityStep;
  }

  renderSelectedProduct = (product, price, onClick) => {
    const { accountStore } = this.props;
    const withTax = accountStore.showPricesWithTax;
    const showTaxExcludedInfo = !withTax;
    return (
      <div className="ProductBundles__selected-product" onClick={onClick}>
        <div className="ProductBundles__selected-product-icon">
          {this.renderIconCheckbox(true)}
        </div>
        <div className="ProductBundles__selected-product-info">
          <div className="ProductBundles__selected-product-title">
            {product.name}{' '}
            {price && (
              <Price
                price={price.getPrice(withTax)}
                showTaxExcludedInfo={showTaxExcludedInfo}
              />
            )}
          </div>
          <div className="ProductBundles__selected-product-model">
            {product.model}
          </div>
        </div>
      </div>
    );
  };

  renderSelectedProducts = () => {
    const { productStore, product } = this.props;

    const checkboxes = this.getSelectedBundleProductInfos().map(
      ({ price, product_id }) => {
        const product = productStore.products.get(product_id);
        return (
          <CustomCol xxl="4" lg="12" key={product.id}>
            {this.renderSelectedProduct(product, price, () =>
              this.toggleProduct(product_id)
            )}
          </CustomCol>
        );
      }
    );

    return (
      <div className="ProductBundles__selected-products">
        {checkboxes.length > 0 && (
          <div className="ProductBundles__selected-product-main-product">
            {this.renderSelectedProduct(
              product,
              product.price_info ? product.price_info.price : null,
              null
            )}
          </div>
        )}
        <Row>{checkboxes}</Row>
      </div>
    );
  };

  renderAddToCartButton = () => {
    const { selectedProductIds, cartLoading } = this.state;

    return (
      <div className="ProductBundles__add-to-cart">
        <Button
          size="lg"
          color="primary"
          className="ProductBundles__add-to-cart-button"
          disabled={selectedProductIds.length === 0}
          onClick={this.handleAddToCartClick}
        >
          <Icon
            name={cartLoading ? 'circle-o-notch' : 'shopping-cart'}
            spin={cartLoading}
          />
          <FormattedMessage
            id="productBundle.addToCartButton"
            defaultMessage="Add bundle to cart"
          />
        </Button>
      </div>
    );
  };

  handleAddToCartClick = () => {
    const {
      accountStore,
      currencyStore,
      activeProductId,
      analytics,
      cartStore,
      product,
    } = this.props;
    const { selectedProductIds } = this.state;
    const quantity = 1;

    // If cartStore is already cartLoading, don't do anything
    if (cartStore.state === RequestState.LOADING) {
      return;
    }

    if (activeProductId) {
      const withTax = accountStore.showPricesWithTax;
      const quantityStep = product.getQuantityStep(product);
      const mainProductPrice = product.getPrice(withTax, activeProductId);
      const precision = currencyStore.activeCurrency.precision;

      this.setState({ cartLoading: true });
      cartStore
        .addToCart(activeProductId, quantity, selectedProductIds)
        .then(() => {
          const { selectedBundleProducts, bundleProducts } =
            this.getAnalyticsBundleProducts(quantity);

          const totalValue = this.calculateAnalyticsTotalValue(
            mainProductPrice * quantity * quantityStep,
            selectedBundleProducts
          );

          analytics.addToCart(
            [
              {
                product,
                quantity,
                activeProductId,
                itemListId: this.listId,
                itemListName: this.listName,
              },
              ...bundleProducts,
            ],
            Number(totalValue.toFixed(precision))
          );

          analytics.cartStatus({
            cart: cartStore.cart,
          });

          this.setState({ cartLoading: false });
        })
        .catch(() => {
          this.setState({ cartLoading: false });
        });
    }
  };

  getAnalyticsBundleProducts = (quantity) => {
    const { accountStore, currencyStore, productStore } = this.props;
    const withTax = accountStore.showPricesWithTax;
    const precision = currencyStore.activeCurrency.precision;

    const selectedBundleProducts = this.getSelectedBundleProductInfos();
    const bundleProducts = selectedBundleProducts.map(
      ({ price, product_id }) => {
        const product = productStore.products.get(product_id);
        const productPrice = product.getPrice(withTax);
        const bundleProductPrice = price.getPrice(withTax);

        return {
          product,
          quantity,
          price: bundleProductPrice,
          discount: Number(
            (productPrice - bundleProductPrice).toFixed(precision)
          ),
          itemListId: this.listId,
          itemListName: this.listName,
        };
      }
    );

    return { selectedBundleProducts, bundleProducts };
  };

  calculateAnalyticsTotalValue = (mainProductPrice, selectedBundleProducts) => {
    const { accountStore, currencyStore, productStore } = this.props;
    const withTax = accountStore.showPricesWithTax;
    const precision = currencyStore.activeCurrency.precision;
    const prices = selectedBundleProducts
      .filter(({ price, product_id }) => {
        const filterBundleProduct = productStore.products.get(product_id);
        return !!(
          price &&
          filterBundleProduct &&
          filterBundleProduct.price_info
        );
      })
      .map(({ price }) => price.getPrice(withTax));

    return calculateTotal(
      [mainProductPrice, ...prices.map((price) => price)],
      precision
    );
  };

  getSelectedBundleProductInfos = () => {
    const { productStore, product } = this.props;
    const { selectedProductIds } = this.state;

    return product.bundleProductInfos.filter(
      ({ product_id }) =>
        selectedProductIds.indexOf(product_id) !== -1 &&
        productStore.products.has(product_id)
    );
  };

  renderBundleTotal = () => {
    const {
      accountStore,
      productStore,
      currencyStore,
      product,
      activeProductId,
    } = this.props;
    const withTax = accountStore.showPricesWithTax;
    const showTaxExcludedInfo = !withTax;
    const prices = this.getSelectedBundleProductInfos()
      .filter(({ price, product_id }) => {
        const product = productStore.products.get(product_id);
        return !!(price && product && product.price_info);
      })
      .map(({ price, product_id }) => {
        const product = productStore.products.get(product_id);
        return {
          inBundle: price.getPrice(withTax),
          withoutBundle: product.price_info.getNormalPrice(withTax),
        };
      });
    const mainProductPrice = product.getPrice(withTax, activeProductId);
    const precision = currencyStore.activeCurrency.precision;
    const bundleTotal = calculateTotal(
      [mainProductPrice, ...prices.map(({ inBundle }) => inBundle)],
      precision
    );
    const withoutBundleTotal = calculateTotal(
      [mainProductPrice, ...prices.map(({ withoutBundle }) => withoutBundle)],
      precision
    );
    const totalSavings = withoutBundleTotal - bundleTotal;
    return (
      <div className="ProductBundles__total">
        <div className="ProductBundles__total-price">
          <FormattedMessage
            id="productBundle.price"
            defaultMessage="Bundle price"
          />{' '}
          <Price price={bundleTotal} discount />{' '}
          <Price price={withoutBundleTotal} muted />
        </div>
        <div className="ProductBundles__total-savings">
          <FormattedMessage
            id="productBundle.youSave"
            defaultMessage="You save"
          />{' '}
          <Price
            price={totalSavings}
            discount
            showTaxExcludedInfo={showTaxExcludedInfo}
          />
        </div>
      </div>
    );
  };

  renderAdditionalProductCount = () => {
    const { selectedProductIds } = this.state;

    return (
      <div className="ProductBundles__product-count">
        {selectedProductIds.length > 0 ? (
          <FormattedMessage
            id="productBundle.selectedProducts"
            defaultMessage="You have selected {count, plural, one {# additional product} other {# additional products}}"
            values={{ count: selectedProductIds.length }}
          />
        ) : (
          <FormattedMessage
            id="productBundle.noSelectedProducts"
            defaultMessage="No additional products selected"
          />
        )}
      </div>
    );
  };

  renderProductImpressions = () => {
    const { productStore, product } = this.props;
    const products = product.bundleProductInfos
      .filter(({ product_id }) => productStore.products.has(product_id))
      .map(({ product_id }) => productStore.products.get(product_id));
    return (
      <ProductImpressions
        listId={this.listId}
        listName={this.listName}
        products={products}
      />
    );
  };

  renderIconCheckbox = (checked) => {
    return checked ? <Icon name="check-square" /> : <Icon name="square-o" />;
  };

  toggleProduct(productId) {
    const { selectedProductIds, cartLoading } = this.state;

    if (cartLoading) {
      return;
    }

    const productIdIndex = selectedProductIds.indexOf(productId);
    const productIdExists = productIdIndex !== -1;
    const newIds = selectedProductIds.slice();
    if (productIdExists) {
      newIds.splice(productIdIndex, 1);
    } else {
      newIds.push(productId);
    }
    this.setState({
      selectedProductIds: newIds,
    });
  }

  renderProductCard = (product, priceInfo, position) => {
    const { activeProductId } = this.props;
    const renderPrice = priceInfo || product.price_info;

    return (
      <div className="ProductBundles__card">
        <ProductCard
          product={product}
          position={position}
          listId={this.listId}
          listName={this.listName}
          hideDescription
          hidePrice
          hideProperties
          hideQuantity={true}
          target="_blank"
        />
        {renderPrice && (
          <ProductPrice
            activeProductId={activeProductId}
            productBundle
            product={product}
            priceInfo={priceInfo || product.price_info}
          />
        )}
      </div>
    );
  };

  isProductSelected = (productId) => {
    return this.state.selectedProductIds.indexOf(productId) !== -1;
  };

  renderBundleProductSlider = () => {
    const { product, productStore } = this.props;

    const slides = product.bundleProductInfos
      .filter(({ product_id }) => productStore.products.has(product_id))
      .map(({ price, product_id }, index) => {
        const position = index + 1;
        const product = productStore.products.get(product_id);
        const priceInfo = product.price_info
          ? ProductPriceInfo.create({
              ...getSnapshot(product.price_info),
              price: getSnapshot(price),
              is_discount: true,
            })
          : null;

        const checked = this.isProductSelected(product_id);

        return (
          <article className="ProductBundles__slide" key={product.id}>
            {this.renderProductCard(product, priceInfo, position)}
            <Button
              className={classNames('ProductBundles__slide-button', {
                'ProductBundles__slide-button--active': checked,
              })}
              color="primary"
              outline
              block
              onClick={() => this.toggleProduct(product_id)}
            >
              {this.renderIconCheckbox(checked)}
              {checked ? (
                <FormattedMessage {...globalTranslations.selectedTitle} />
              ) : (
                <FormattedMessage {...globalTranslations.selectTitle} />
              )}
            </Button>
          </article>
        );
      });

    return (
      <Slider className="ProductBundles__slider" {...SLIDER_SETTINGS}>
        {slides}
      </Slider>
    );
  };

  renderContent = () => {
    const { selectedProductIds } = this.state;

    return (
      <div className="ProductBundles__wrapper">
        <div className="ProductBundles__content">
          {this.renderBundleProductSlider()}
          {this.renderProductImpressions()}
          <div className="ProductBundles__overview">
            <div className="ProductBundles__selected-products">
              {this.renderAdditionalProductCount()}
              {this.renderSelectedProducts()}
            </div>
            <div className="ProductBundles__buy">
              {selectedProductIds.length > 0 && this.renderBundleTotal()}
              {this.renderAddToCartButton()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  getProductIds = () => {
    const { product } = this.props;
    return product.bundleProductInfos
      .slice() // Slice is used to convert Mobx array to normal array.
      .map(({ product_id }) => product_id);
  };

  render() {
    const { product } = this.props;
    return (
      <div className="ProductBundles">
        <h3 className="ProductBundles__title">
          <FormattedMessage
            id="productBundle.createYourOwn"
            defaultMessage={
              'Bundle "{productName}" with additional products and save'
            }
            values={{ productName: product.name }}
          />
        </h3>
        <MissingProductLoader ids={this.getProductIds()}>
          {() => this.renderContent()}
        </MissingProductLoader>
      </div>
    );
  }
}

ProductBundles.propTypes = {
  activeProductId: PropTypes.string.isRequired,
  product: PropTypes.oneOfType([modelOf(Product), PropTypes.object]).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  productStore: modelOf(ProductStore).isRequired,
  accountStore: modelOf(AccountStore).isRequired,
  cartStore: modelOf(CartStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
};

export default inject(
  'accountStore',
  'analytics',
  'cartStore',
  'currencyStore',
  'productStore'
)(ProductBundles);
