import { types } from 'mobx-state-tree';
import TranslatedImage from '../models/TranslatedImage';

const TranslationStore = types
  .model('TranslationStore', {
    messages: types.optional(types.frozen(), {}),
    translatedImages: types.array(TranslatedImage)
  })
  .actions((self) => ({
    setup: (messages, translatedImages) => {
      self.messages = messages;
      self.translatedImages = translatedImages;
    }
  }));

export default TranslationStore;
