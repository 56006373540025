import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../../common/Icon';

@observer
export class NavigationHiddenContentHelper extends Component {
  render() {
    return (
      <div
        className={classNames('NavigationHiddenContentHelper', {
          'NavigationHiddenContentHelper--hidden': !this.props.visible
        })}
      >
        <Container className="NavigationHiddenContent__helper-container">
          <Row>
            <Col xs="12">
              <Icon
                name="angle-down"
                className="NavigationHiddenContentHelper__icon"
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

NavigationHiddenContentHelper.propTypes = {
  visible: PropTypes.bool.isRequired
};

export default NavigationHiddenContentHelper;
