import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { filter, isEqual } from 'lodash';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';

import FilterContainer from '../FilterContainer';
import Filter from '../../../models/Filter';
import { modelOf } from '../../../prop-types';
import globalTranslations from '../../../i18n/globalTranslations';
import FilterAcceptButton from '../FilterAcceptButton';
import Icon from '../../common/Icon';

@observer
export class TermFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValues: props.selectedValues.slice(0),
      filterOptions: props.contentFilter.options,
      filterTerm: '',
    };

    this.setAddressFilterString = this.setAddressFilterString.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      selectedValues: props.selectedValues.slice(0),
      filterOptions: props.contentFilter.options,
    });
  }

  renderFilterOptions = () => {
    const { contentFilter } = this.props;
    const { selectedValues, filterOptions } = this.state;

    if (!filterOptions) {
      return null;
    }

    return filterOptions.map((filterOption) => {
      const checked =
        selectedValues && selectedValues.constructor === Array
          ? selectedValues.some((sv) => sv === filterOption.value)
          : selectedValues === filterOption.value;
      return (
        <label
          key={contentFilter.key + filterOption.value}
          className="TermFilter__option"
        >
          <input
            type="checkbox"
            onChange={() => {
              if (!checked) {
                this.addSelection(filterOption.value);
              } else {
                this.removeSelection(filterOption.value);
              }
            }}
            value={filterOption.value}
            checked={checked}
            className="TermFilter__option-checkbox"
          />
          <span className="TermFilter__option-name">{filterOption.name}</span>
          <span className="TermFilter__option-items-count">
            {filterOption.count}
          </span>
        </label>
      );
    });
  };

  addSelection = (value) => {
    let { selectedValues } = this.state;
    selectedValues.push(value);
    this.setState({ selectedValues });
  };

  removeSelection = (value) => {
    let { selectedValues } = this.state;
    selectedValues = filter(selectedValues, (val) => val !== value);
    this.setState({ selectedValues });
  };

  isValueChanged = () =>
    !isEqual(this.props.selectedValues, this.state.selectedValues);

  acceptValues = () => {
    const { contentFilter, onAccept } = this.props;
    const { selectedValues } = this.state;
    if (this.isValueChanged()) onAccept(contentFilter, selectedValues);
    if (this.filterContainer) this.filterContainer.toggleOpen();
  };

  onFilterClosedAutomatically = () => this.acceptValues();

  renderTextFilterInput = (filterTerm) => {
    const { contentFilter, defaultOptionsAmount, intl } = this.props;
    const filterOptions = contentFilter.options;
    return (
      filterOptions &&
      filterOptions.length >= defaultOptionsAmount && (
        <InputGroup className="TermFilter__text-filter btn-group">
          <Input
            value={filterTerm}
            onChange={this.setAddressFilterString}
            placeholder={intl.formatMessage(
              globalTranslations.termFilterPlaceholder
            )}
          />
          {filterTerm && (
            <InputGroupAddon addonType="append">
              <Button color="primary" onClick={this.clearSearch}>
                <Icon name="close" />
              </Button>
            </InputGroupAddon>
          )}
        </InputGroup>
      )
    );
  };

  setAddressFilterString = (event) => {
    const { contentFilter } = this.props;
    const filterTerm = event.target.value.toLowerCase();
    const filterOptions = contentFilter.options.filter((filterOption) =>
      filterOption.name.toLowerCase().includes(filterTerm)
    );
    this.setState({
      filterOptions,
      filterTerm,
    });
  };

  clearSearch = () =>
    this.setState({
      filterTerm: '',
      filterOptions: this.props.contentFilter.options,
    });

  render() {
    const { contentFilter } = this.props;
    const { selectedValues, filterTerm } = this.state;

    return (
      <div className="TermFilter">
        <FilterContainer
          filterInUse={selectedValues.length > 0}
          headerText={contentFilter.name}
          onFilterClosedAutomatically={this.onFilterClosedAutomatically}
          ref={(ref) => (this.filterContainer = ref)}
        >
          {this.renderTextFilterInput(filterTerm)}
          <div className="TermFilter__options-container">
            {this.renderFilterOptions()}
          </div>
          <FilterAcceptButton
            className="TermFilter__accept-button"
            onClick={this.acceptValues}
          >
            <FormattedMessage {...globalTranslations.saveTitle} />
          </FilterAcceptButton>
        </FilterContainer>
      </div>
    );
  }
}

TermFilter.propTypes = {
  contentFilter: modelOf(Filter).isRequired,
  onAccept: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
};

TermFilter.defaultProps = {
  selectedValues: [],
  defaultOptionsAmount: 20,
};

export default injectIntl(TermFilter);
