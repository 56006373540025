import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import { modelOf } from '../../../prop-types';
import ProductStore from '../../../store/ProductStore';
import ConfigStore from '../../../store/ConfigStore';
import ProductList from '../ProductList';
import ContentForState from '../../loader/ContentForState';
import { paramsToQueryIdentifier } from '../../../util/query';

@observer
export class SimpleProductList extends Component {
  constructor(props) {
    super(props);
    if (!this.getCurrentSearchResult()) {
      this.loadProducts();
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.searchParams, this.props.searchParams)) {
      this.loadProducts();
    }
  }

  getQueryIdentifier() {
    return paramsToQueryIdentifier(this.props.searchParams);
  }

  getCurrentSearchResult() {
    const { productStore } = this.props;
    return productStore.productQueryResults.get(this.getQueryIdentifier());
  }

  getCurrentSearchState() {
    const { productStore } = this.props;
    return productStore.productQueryStates.get(this.getQueryIdentifier());
  }

  loadProducts = () => {
    const { productStore, allSections, searchParams } = this.props;
    productStore.loadProducts(searchParams, allSections).catch((e) => {
      console.error(e);
    });
  };

  render() {
    const {
      productStore,
      searchParams,
      listId,
      name,
      listComponent: ListComponent,
      completeListingLink,
      preloadOnly,
      singleRowMode,
      requireCanonicalLink,
      ...rest
    } = this.props;

    if (!productStore || !searchParams || preloadOnly) {
      return null;
    }

    return (
      <div className="SimpleProductList" style={{ minHeight: 600 }}>
        <ContentForState
          state={this.getCurrentSearchState()}
          error={productStore.lastError}
          forLoaded={() => (
            <ListComponent
              products={this.getCurrentSearchResult().data}
              listId={listId}
              name={name}
              completeListingLink={!singleRowMode ? completeListingLink : null}
              cardSettings={{
                requireCanonicalLink: requireCanonicalLink,
              }}
              {...rest}
            />
          )}
        />
      </div>
    );
  }
}

SimpleProductList.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  productStore: modelOf(ProductStore).isRequired,
  searchParams: PropTypes.object.isRequired,
  listId: PropTypes.string,
  name: PropTypes.string,
  listComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  completeListingLink: PropTypes.node,
  preloadOnly: PropTypes.bool,
  allSections: PropTypes.bool,
  singleRowMode: PropTypes.bool,
  requireCanonicalLink: PropTypes.bool,
};

SimpleProductList.defaultProps = {
  listComponent: ProductList,
  preloadOnly: false,
  allSections: false,
  singleRowMode: false,
  requireCanonicalLink: false,
};

export default inject('productStore', 'configStore')(SimpleProductList);
