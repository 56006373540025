import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import { modelOf } from '../../../prop-types';
import AdStore from '../../../store/AdStore';
import { paramsToQueryIdentifier } from '../../../util/query';

@observer
export class AdLoader extends Component {
  componentDidMount() {
    this.loadAds();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.searchParams, this.props.searchParams)) {
      this.loadAds();
    }
  }

  loadAds = () => {
    const { adStore, searchParams } = this.props;
    adStore.loadAds(searchParams);
  };

  render() {
    const { adStore, searchParams, children } = this.props;

    if (!adStore) {
      return null;
    }

    const ads = adStore.adQueries.get(paramsToQueryIdentifier(searchParams));
    return children(ads);
  }
}

AdLoader.propTypes = {
  searchParams: PropTypes.object.isRequired,
  adStore: modelOf(AdStore).isRequired
};

export default inject('adStore')(AdLoader);
