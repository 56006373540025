import qs from 'qs';
import { endsWith } from 'lodash';

export const stringify = (params, options) => {
  const stringifyParams = {
    arrayFormat: 'brackets'
  };
  return qs.stringify(params, { ...stringifyParams, ...options });
};

export const parse = (params, options) => {
  const parseParams = {
    ignoreQueryPrefix: true,
    arrayLimit: 0
  };
  return qs.parse(params, { ...parseParams, ...options });
};

export const addParametersToPath = (path, params) => {
  let pathToReturn = path;
  if (params && Object.keys(params).length > 0) {
    if (!endsWith(path, '?')) {
      pathToReturn += '?';
    }
    pathToReturn += stringify(params);
  }
  return pathToReturn;
};
