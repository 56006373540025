import React, { Component, Fragment } from 'react';
import { Row, Col, Container } from 'reactstrap';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';

import ProductImage from '../../../components/product/ProductImage';
import UIStore from '../../../store/UIStore';
import CurrencyStore from '../../../store/CurrencyStore';
import ConfigStore from '../../../store/ConfigStore';
import AccountStore from '../../../store/AccountStore';
import { modelOf } from '../../../prop-types';
import CartStore from '../../../store/CartStore';
import Price from '../../product/Price';
import { roundWithPrecision } from '../../../util/number';
import globalTranslations from '../../../i18n/globalTranslations';
import CartNewProductType from '../../../types/CartNewProductType';
import ProductImageModel from '../../../models/ProductImage';

const POPOVER_DURATION = 3 * 1000;

@observer
export class NavigationCartPopover extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.reactionDisposer = null;
    this.state = {
      isOpen: false
    };
  }

  componentDidMount() {
    this.reactionDisposer = reaction(
      () => this.props.cartStore.popoverModal.isOpen,
      this.handleNewNotification
    );
  }

  componentWillUnmount() {
    this.reactionDisposer();
  }

  handleNewNotification = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.setState({
      isOpen: true
    });

    this.props.uiStore.togglePopover(true);

    this.timer = setTimeout(() => {
      this.setState({ isOpen: false });
      this.props.uiStore.togglePopover(false);
    }, POPOVER_DURATION);
  };

  getProductPrice = () => {
    const { accountStore, currencyStore } = this.props;
    const { new_product_info } = this.props.cartStore.cart;

    if (
      new_product_info.type === CartNewProductType.ARRAY ||
      !new_product_info.price
    ) {
      return null;
    }

    const withTax = accountStore.showPricesWithTax;
    const showTaxExcludedInfo = !withTax;
    const showUnit = !!new_product_info.stock_unit;

    const totalPrice =
      new_product_info.quantity *
      roundWithPrecision(
        new_product_info.price.getPrice(withTax),
        currencyStore.activeCurrency
          ? currencyStore.activeCurrency.precision
          : 2
      );
    return (
      <Fragment>
        {totalPrice && (
          <Price price={totalPrice} showTaxExcludedInfo={showTaxExcludedInfo} />
        )}
        {totalPrice && showUnit && (
          <span className="CartModal__stock-unit">
            {' '}
            / {new_product_info.stock_unit}
          </span>
        )}
      </Fragment>
    );
  };

  renderCartTotal = () => {
    const { cartStore, accountStore } = this.props;
    const withTax = accountStore.showPricesWithTax;

    const price = withTax
      ? cartStore.cart.total.with_tax
      : cartStore.cart.total.without_tax;

    return (
      <Row>
        <Col className="NavigationCartPopover__cart-details mt-2">
          <div className="d-block NavigationCartPopover__cart-items">
            <FormattedMessage
              {...globalTranslations.itemsInCart}
              values={{
                itemCount: (
                  <FormattedMessage
                    id="global.itemCount"
                    values={{
                      count: cartStore.cart.number_of_products
                    }}
                  />
                )
              }}
            />
          </div>
          <div className="d-block NavigationCartPopover__cart-total">
            <FormattedMessage {...globalTranslations.totalPriceSentence} />:{' '}
            <Price price={price} />
          </div>
        </Col>
      </Row>
    );
  };

  createProductImage = (url) => {
    return url
      ? ProductImageModel.create({
        id: 0,
        product_id: '',
        sizes: {
          small: url
        }
      })
      : null;
  };

  renderContent = () => {
    const { cartStore, uiStore } = this.props;
    let newProductInfo;

    if (cartStore.cart) {
      newProductInfo = cartStore.cart.new_product_info;
    }

    if (!newProductInfo) {
      return null;
    }

    const isBundle = newProductInfo.type === CartNewProductType.BUNDLE;
    const priceText = this.getProductPrice();

    return (
      <Fragment>
        <h3>
          {isBundle ? (
            <FormattedMessage
              {...globalTranslations.productBundleAddedToCartSentence}
            />
          ) : (
            <FormattedMessage
              {...globalTranslations.productAddedToCartSentence}
              values={{
                quantity: newProductInfo.quantity
              }}
            />
          )}
        </h3>
        <hr />
        {newProductInfo &&
          newProductInfo.type !== CartNewProductType.ARRAY && (
            <Row className="no-gutters">
              <Col xs={3} className="d-flex align-items-center">
                <ProductImage
                  forceAspectRatio={false}
                  product={newProductInfo}
                  productImage={this.createProductImage(newProductInfo.image)}
                  size="small"
                  lazyLoading={false}
                />
              </Col>
              <Col xs={9} className="NavigationCartPopover__productInfo">
                {!isBundle && (
                  <span className="NavigationCartPopver__quantity">
                    {newProductInfo.quantity}x
                  </span>
                )}
                <span
                  className="NavigationCartPopver__productName"
                  dangerouslySetInnerHTML={{ __html: newProductInfo.name }}
                />
                <span className="NavigationCartPopver__price">{priceText}</span>
              </Col>
            </Row>
          )}
        {uiStore.isMobile && this.renderCartTotal()}
      </Fragment>
    );
  };

  render() {
    return (
      <Container
        className={classNames('NavigationCartPopover', {
          popoverVisible: this.state.isOpen
        })}
      >
        {this.renderContent()}
      </Container>
    );
  }
}

NavigationCartPopover.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  cartStore: modelOf(CartStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  uiStore: modelOf(UIStore).isRequired
};

export default inject((stores) => ({
  cartStore: stores.cartStore,
  uiStore: stores.uiStore,
  currencyStore: stores.currencyStore,
  accountStore: stores.accountStore,
  configStore: stores.configStore
}))(NavigationCartPopover);
