import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { Container } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';

import Page from '../../components/layout/Page';
import WysiwygContent from '../../components/common/WysiwygContent';
import MainProductList from '../../components/product-list/MainProductList';
import HelperNavigation from '../../components/common/HelperNavigation';
import GenericLayout from '../../components/layout/GenericLayout';
import PageTitle from '../../components/common/PageTitle';
import ContentForState from '../../components/loader/ContentForState';
import SEOTitle from '../../components/head/SEOTitle';
import SEODescription from '../../components/head/SEODescription';
import CanonicalLink from '../../components/head/CanonicalLink';
import ContentPopup from '../../components/ad/ContentPopup';
import ScrollReset from '../../components/common/ScrollReset';
import MainBanner from '../../components/ad/MainBanner';
import { pathFragmentMatchesLocation } from '../../util/url';
import RouteService from '../../services/RouteService';
import { modelOf } from '../../prop-types';
import ManufacturerStore from '../../store/ManufacturerStore';
import ConfigStore from '../../store/ConfigStore';
import SectionStore from '../../store/SectionStore';
import CommonPage from '../../types/CommonPage';
import globalTranslations from '../../i18n/globalTranslations';
import Paths from '../../types/Paths';
import { injectIntl, intlShape } from 'react-intl';

@observer
class ManufacturerPage extends Component {
  constructor(props) {
    super(props);
    this.yotpoTimeout = null;
    this.maybeLoadManufacturer(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    const { configStore } = this.props;

    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.maybeLoadManufacturer(this.props.match.params.id);
    }

    if (prevProps.match.path !== this.props.match.path) {
      if (configStore.integrations.yotpo.enabled && window && window.yotpo) {
        this.yotpoTimeout = setTimeout(
          () => window.yotpo.refreshWidgets(),
          800
        );
      }
    }
  }

  componentWillUnmount() {
    this.yotpoTimeout && clearTimeout(this.yotpoTimeout);
  }

  maybeLoadManufacturer = (id) => {
    const { manufacturerStore } = this.props;
    const manufacturer = manufacturerStore.manufacturers.get(id);
    if (
      !manufacturerStore.manufacturerContentStates.get(id) ||
      (manufacturer && manufacturer.description_html === null)
    ) {
      manufacturerStore.loadManufacturer(id).catch((e) => {
        console.error(e);
      });
    }
  };

  getActiveManufacturer = () => {
    const { match, manufacturerStore } = this.props;
    return manufacturerStore.manufacturers.get(match.params.id);
  };

  getBreadcrumbsPath = () => {
    const { intl, routeService } = this.props;
    const manufacturer = this.getActiveManufacturer();

    const breadcrumbs = [
      {
        text: intl.formatMessage(globalTranslations.ourBrandsTitle),
        url: Paths.ManufacturerList,
      },
      manufacturer.breadcrumbs,
    ];
    return routeService.transformBreadCrumbs(breadcrumbs);
  };

  render() {
    const {
      configStore,
      sectionStore,
      manufacturerStore,
      routeService,
      match,
      location,
    } = this.props;
    const manufacturer = this.getActiveManufacturer();

    if (manufacturer) {
      if (!pathFragmentMatchesLocation(manufacturer.path, location.pathname)) {
        const url = `${routeService.getPath(manufacturer.path)}${
          location.search
        }${location.hash}`;
        return <Redirect to={url} />;
      }
    }

    const adSearchParams = {
      manufacturer: manufacturer && manufacturer.id,
    };

    const mainBanner = <MainBanner searchParams={adSearchParams} />;
    const mainBannerWithinContent =
      configStore.mainBanner.withinContent && !configStore.mainBanner.fullWidth;

    return (
      <Page name={CommonPage.MANUFACTURER} className="ManufacturerPage">
        <ScrollReset key={match.params.id} />
        {!mainBannerWithinContent && mainBanner}

        <Container className="ManufacturerPage__container">
          <GenericLayout
            content={
              <ContentForState
                state={manufacturerStore.manufacturerContentStates.get(
                  match.params.id
                )}
                error={manufacturerStore.lastError}
                forLoaded={() => {
                  if (!manufacturer) {
                    return null;
                  }

                  const listId = 'manufacturer_list';
                  const listName = 'Manufacturer List';
                  const fixedParams = { manufacturers: [manufacturer.id] };
                  if (sectionStore.activeSection) {
                    fixedParams.sections = [sectionStore.activeSection.id];
                  }

                  return (
                    <Fragment>
                      <SEOTitle
                        title={manufacturer.SEO_title || manufacturer.name}
                        noSuffix={!!manufacturer.SEO_title}
                      />
                      {manufacturer.SEO_description && (
                        <SEODescription
                          content={manufacturer.SEO_description}
                        />
                      )}
                      <CanonicalLink
                        path={routeService.getPath(manufacturer.path)}
                      />
                      {mainBannerWithinContent && mainBanner}
                      <HelperNavigation
                        breadcrumbsPath={this.getBreadcrumbsPath()}
                      />
                      <PageTitle>{manufacturer.name}</PageTitle>
                      <div className="ManufacturerPage__description">
                        {manufacturer.description_html && (
                          <WysiwygContent
                            html={manufacturer.description_html}
                          />
                        )}
                      </div>
                      <MainProductList
                        fixedParams={fixedParams}
                        listId={listId}
                        name={listName}
                        allSections={true}
                      />
                      {manufacturer.footer_description_html && (
                        <div className="ManufacturerPage__footer-content">
                          <WysiwygContent
                            html={manufacturer.footer_description_html}
                          />
                        </div>
                      )}
                      <ContentPopup searchParams={adSearchParams} />
                    </Fragment>
                  );
                }}
              />
            }
          />
        </Container>
      </Page>
    );
  }
}

ManufacturerPage.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  manufacturerStore: modelOf(ManufacturerStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  match: RouterPropTypes.match.isRequired,
  location: RouterPropTypes.location.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(
  inject(
    'configStore',
    'manufacturerStore',
    'routeService',
    'sectionStore'
  )(ManufacturerPage)
);
