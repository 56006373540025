import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { DateTime } from 'luxon';

@observer
export class ProductOfferSchema extends Component {
  render() {
    const { product } = this.props;

    return (
      <span>
        {product.price_info &&
          product.price_info.valid_until && (
            <time
              dateTime={DateTime.fromISO(
                product.price_info.valid_until
              ).toISODate()}
            />
          )}
      </span>
    );
  }
}

ProductOfferSchema.propTypes = {
  product: PropTypes.object.isRequired
};

export default ProductOfferSchema;
