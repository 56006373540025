import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import RouterPropTypes from 'react-router-prop-types';

import Icon from '../../common/Icon';
import { createFormField } from '../../../models/FormField';
import { createForm } from '../../../models/Form';
import Field from '../../form/Field';
import { parse } from '../../../util/queryString';
import globalTranslations from '../../../i18n/globalTranslations';

class LicensePlateInput extends Component {
  form = null;

  constructor(props) {
    super(props);

    this.state = { licensePlateNumber: '' };

    this.form = createForm({
      licensePlateNumber: createFormField({})
    });
  }

  componentDidUpdate(prevProps) {
    const prevParams =
      prevProps && prevProps.location && parse(prevProps.location.search);
    const params =
      this.props && this.props.location && parse(this.props.location.search);
    if (prevParams && this.props && prevParams.id && !params.id) {
      this.setState({ licensePlateNumber: '' });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.form.validate();
    if (this.form.valid && this.props.handleOnSubmit) {
      this.props.handleOnSubmit(this.state.licensePlateNumber);
    }
    if (this.props.clearAfterSubmit) {
      this.setState({ licensePlateNumber: '' });
    }
  };

  handleInput = (event) =>
    this.setState({ licensePlateNumber: event.target.value });

  render() {
    const { disabled, overrideText, hideSearchButtonText } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <InputGroup>
          <Field
            component={Input}
            field={this.form.fields.get('licensePlateNumber')}
            type="text"
            name="licensePlateNumber"
            id="LicensePlateInput__licensePlateNumber"
            disabled={disabled}
            value={overrideText ? overrideText : this.state.licensePlateNumber}
            onChange={this.handleInput}
            className={classNames(['LicensePlateInput'], {
              'LicensePlateInput--disabled': !disabled
            })}
            placeholder="ABC-123"
          />
          {!disabled && (
            <InputGroupAddon addonType="append">
              <Button color="primary">
                <Icon name="search" />
                {!hideSearchButtonText && (
                  <span className="LicensePlateInput__button-text">
                    <FormattedMessage {...globalTranslations.search} />
                  </span>
                )}
              </Button>
            </InputGroupAddon>
          )}
        </InputGroup>
      </Form>
    );
  }
}

LicensePlateInput.propTypes = {
  location: RouterPropTypes.location,
  disabled: PropTypes.bool,
  handleOnSubmit: PropTypes.func,
  loading: PropTypes.bool,
  overrideText: PropTypes.string,
  clearAfterSubmit: PropTypes.bool,
  hideSearchButtonText: PropTypes.bool
};
export default LicensePlateInput;
