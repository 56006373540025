import React, { Component, Fragment } from 'react';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link, matchPath, withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';

import { modelOf } from '../../../prop-types';
import Icon from '../../common/Icon';
import globalTranslations from '../../../i18n/globalTranslations';
import UIStore from '../../../store/UIStore';
import Paths from '../../../types/Paths';
import BlockSpinner from '../../loader/BlockSpinner';
import RequestState from '../../../types/RequestState';
import Analytics from '../../../analytics/Analytics';
import RouteService from '../../../services/RouteService';
import SectionStore from '../../../store/SectionStore';
import ProductStore from '../../../store/ProductStore';
import ConfigStore from '../../../store/ConfigStore';
import ProposalStore from '../../../store/ProposalStore';
import Price from '../../product/Price';
import CartNewProductType from '../../../types/CartNewProductType';
import ProductImage from '../../product/ProductImage';
import ProductImageModel from '../../../models/ProductImage';

@observer
export class ProposalModal extends Component {
  constructor(props) {
    super(props);

    this.rightColumn = React.createRef();

    this.state = {
      rightColumnHeight: null,
    };
  }

  componentDidMount() {
    this.maybeLoadProposalCart();
  }

  updateRightColumnHeight = () => {
    const rightColumnHeight = this.rightColumn
      ? this.rightColumn.clientHeight
      : 300;

    this.setState({
      rightColumnHeight: rightColumnHeight,
    });
  };

  maybeLoadProposalCart = () => {
    const { proposalStore, location, routeService, sectionStore } = this.props;

    if (proposalStore.state === RequestState.NONE && !!!window.isSSR) {
      proposalStore.loadCart().then(() => {
        const thankYouPath = matchPath(location.pathname, {
          path: routeService.getPath(
            Paths.ProposalThankYouPage,
            sectionStore.activeSection
          ),
          strict: false,
          exact: true,
        });
      });
    }
  };

  sendAnalyticsViewProposalEvent = () => {
    const { proposalStore, analytics, withTax } = this.props;

    const cartProducts = proposalStore.cart.products;
    const value = proposalStore.cart.getCartTotalValue(withTax);
    analytics.viewProposal(value, cartProducts);
  };

  handleOpen = () => {
    const { configStore, proposalStore } = this.props;

    if (typeof document === 'undefined') {
      return null;
    }
    document.body.classList.add('modal-open');

    this.updateRightColumnHeight();
    proposalStore.proposalModal.isOpen &&
      configStore.analytics.ga4.enabled &&
      this.sendAnalyticsViewProposalEvent();
  };

  toggleProposalModal = () => this.props.proposalStore.proposalModal.toggle();

  getContinueShoppingButton = () => {
    const { proposalStore } = this.props;

    return (
      <div className="ProposalModal__continue">
        <Button
          color="secondary"
          onClick={proposalStore.proposalModal.toggle}
          size="lg"
          block
        >
          <Icon
            className="ProposalModal__continue-icon"
            name="angle-double-left"
          />
          <FormattedMessage {...globalTranslations.continueShopping} />
        </Button>
      </div>
    );
  };

  getCheckoutButtonContent = () => {
    const { proposalStore } = this.props;

    if (proposalStore.state === RequestState.LOADING) {
      return <FormattedMessage {...globalTranslations.loading} />;
    }

    return (
      <FormattedMessage {...globalTranslations.proceedToProposalSentence} />
    );
  };

  navigateTo = () => {
    const { history, routeService } = this.props;
    history.push(routeService.getPath(Paths.MyAccountUserList));
  };

  onClickHandler = () => {
    const { proposalStore } = this.props;
    proposalStore.toggleModal();
  };

  goToProposalButton = () => {
    const { proposalStore, routeService, intl } = this.props;

    return (
      <div className="ProposalModal__checkout">
        <Button
          block
          color="primary"
          tag={Link}
          to={routeService.getPath(Paths.ProposalCheckout)}
          size="lg"
          onClick={this.onClickHandler}
          aria-label={intl.formatMessage(
            globalTranslations.proceedToCheckoutSentence
          )}
          disabled={proposalStore.state === RequestState.LOADING}
        >
          <Icon className="ProposalModal__checkout-icon" name="list" />
          {this.getCheckoutButtonContent()}
        </Button>
      </div>
    );
  };

  getNewProductTitle = () => {
    const { new_product_info } = this.props.proposalStore.cart;
    let message;
    if (!new_product_info) {
      return null;
    }

    switch (new_product_info.type) {
      case CartNewProductType.BUNDLE:
        message = (
          <FormattedMessage
            {...globalTranslations.productBundleAddedToProposalSentence}
          />
        );
        break;
      case CartNewProductType.NORMAL:
      case CartNewProductType.ARRAY:
      default:
        message = (
          <FormattedMessage
            {...globalTranslations.productAddedToProposalSentence}
            values={{ quantity: new_product_info.quantity }}
          />
        );
        break;
    }
    return message;
  };

  isNewProduct = () => {
    return !!this.props.proposalStore.cart.new_product_info;
  };

  createProductImage = (url) => {
    return url
      ? ProductImageModel.create({
          id: 0,
          product_id: '',
          sizes: {
            small: url,
          },
        })
      : null;
  };

  getNewProductContent = () => {
    const { withTax } = this.props;
    const { new_product_info } = this.props.proposalStore.cart;

    if (
      !new_product_info ||
      new_product_info.type === CartNewProductType.ARRAY
    ) {
      return null;
    }
    const showUnit = !!new_product_info.stock_unit;

    return (
      <Row>
        <Col className="ProposalModal__left-column-left" xs={4}>
          <div className="ProposalModal__column-image">
            <ProductImage
              product={new_product_info}
              productImage={this.createProductImage(new_product_info.image)}
              size="small"
              forceAspectRatio={false}
              lazyLoading={false}
            />
          </div>
        </Col>
        <Col xs={8}>
          <div className="ProposalModal__column-info">
            <div className="ProposalModal__column-manufacturer">
              {new_product_info.manufacturer}
            </div>
            <div
              className="ProposalModal__column-name"
              dangerouslySetInnerHTML={{ __html: new_product_info.name }}
            />
            {new_product_info.variations.length > 0 && (
              <div className="ProposalModal__column-variations">
                {new_product_info.variations.map((variation) => (
                  <span
                    key={variation}
                    className="ProposalModal__column-variation"
                  >
                    {variation}
                  </span>
                ))}
              </div>
            )}
            {new_product_info.price && (
              <div className="ProposalModal__column-price">
                <Price price={new_product_info.price.getPrice(withTax)} />
                {showUnit && (
                  <span className="ProposalModal__stock-unit">
                    {' '}
                    / {new_product_info.stock_unit}
                  </span>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
    );
  };

  getTotalPrice = () => {
    const { proposalStore, withTax } = this.props;
    const { cart } = proposalStore;
    return cart.total ? cart.total.getPrice(withTax) : null;
  };

  getTotalPriceInfo = () => {
    const price = this.getTotalPrice();

    if (!price) {
      return null;
    }

    return (
      <div className="ProposalModal__price">
        <div className="ProposalModal__price-title">
          <FormattedMessage
            id="cart.productsTotalWithExtraLabel"
            defaultMessage="Total amount"
          />{' '}
          <FormattedMessage {...globalTranslations.sumWithVat} />
        </div>
        <div className="ProposalModal__price-sum">
          <Price price={price} />
        </div>
      </div>
    );
  };

  getProposalContent = () => {
    const { proposalStore } = this.props;
    const { cart } = proposalStore;

    const productCount = cart.number_of_products;
    const showLeftColumn = !proposalStore.proposalModal.openedManually;

    return (
      <Fragment>
        <Row>
          {showLeftColumn && (
            <Col
              xs="12"
              md="6"
              className="ProposalModal__column ProposalModal__left-column"
            >
              <h4 className="ProposalModal__title">
                {this.getNewProductTitle()}
              </h4>
              <div className="ProposalModal__column-inner">
                {this.getNewProductContent()}
              </div>
            </Col>
          )}
          <Col
            xs={12}
            md={showLeftColumn ? 6 : 12}
            className="ProposalModal__right-column ProposalModal__column"
          >
            <h4 className="ProposalModal__title">
              <FormattedMessage {...globalTranslations.proposalContents} />
            </h4>
            <div
              className="ProposalModal__column-inner"
              ref={(rightColumn) => (this.rightColumn = rightColumn)}
            >
              <div>
                <span className="ProposalModal__cart-product-count">
                  {productCount === 0 && (
                    <FormattedMessage
                      {...globalTranslations.cartEmptySentence}
                    />
                  )}
                  {productCount > 0 && (
                    <FormattedMessage
                      {...globalTranslations.itemsInProposal}
                      values={{
                        itemCount: (
                          <FormattedMessage
                            {...globalTranslations.itemCount}
                            values={{
                              count: productCount,
                            }}
                          />
                        ),
                      }}
                    />
                  )}
                </span>
                <div className="ProposalModal__price-information">
                  {this.getTotalPriceInfo()}
                </div>
              </div>
              <div>
                {this.goToProposalButton()}
                {this.getContinueShoppingButton()}
              </div>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  };

  render() {
    const { proposalStore } = this.props;
    if (!proposalStore.cart) {
      return null;
    }

    return (
      <Modal
        className={classNames('ProposalModal', {
          'ProposalModal--after-add-to-proposal':
            !proposalStore.proposalModal.openedManually,
        })}
        size="lg"
        onOpened={this.handleOpen}
        isOpen={proposalStore.proposalModal.isOpen}
        toggle={proposalStore.proposalModal.toggle}
      >
        <div className="ProposalModal__hanging-buttons">
          <Button onClick={this.toggleProposalModal.bind(this)} color="primary">
            <span className="ProposalModal__close-button">
              <Icon className="ProposalModal__close-icon" name="times" />
              <FormattedMessage {...globalTranslations.closeTitle} />
            </span>
          </Button>
        </div>
        <ModalBody>
          {proposalStore.state === RequestState.LOADING ? (
            <BlockSpinner />
          ) : (
            this.getProposalContent()
          )}
        </ModalBody>
      </Modal>
    );
  }
}

ProposalModal.propTypes = {
  proposalStore: modelOf(ProposalStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  productStore: modelOf(ProductStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  history: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  location: RouterPropTypes.location.isRequired,
  withTax: PropTypes.bool.isRequired,
};

export default injectIntl(
  withRouter(
    inject((stores) => ({
      proposalStore: stores.proposalStore,
      configStore: stores.configStore,
      sectionStore: stores.sectionStore,
      productStore: stores.productStore,
      uiStore: stores.uiStore,
      analytics: stores.analytics,
      routeService: stores.routeService,
      withTax: stores.accountStore.showPricesWithTax,
    }))(ProposalModal)
  )
);
