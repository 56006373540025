import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';

import Page from '../../components/layout/Page';
import MainProductList from '../../components/product-list/MainProductList';
import HelperNavigation from '../../components/common/HelperNavigation';
import GenericLayout from '../../components/layout/GenericLayout';
import ProductSortOrderBy from '../../types/ProductSortOrderBy';
import SortOrder from '../../types/SortOrder';
import globalTranslations from '../../i18n/globalTranslations';
import PageTitle from '../../components/common/PageTitle';
import Paths from '../../types/Paths';
import SEOTitle from '../../components/head/SEOTitle';
import CanonicalLink from '../../components/head/CanonicalLink';
import ContentPopup from '../../components/ad/ContentPopup';
import ProductRecommendedPosition from '../../types/ProductRecommendedPosition';
import MainBanner from '../../components/ad/MainBanner';
import CategoryStore from '../../store/CategoryStore';
import ConfigStore from '../../store/ConfigStore';
import SectionStore from '../../store/SectionStore';
import RouteService from '../../services/RouteService';
import { modelOf } from '../../prop-types';
import ScrollReset from '../../components/common/ScrollReset';
import AdPages from '../../types/AdPages';
import CommonPage from '../../types/CommonPage';

@observer
class RecommendedProductsPage extends Component {
  getBreadcrumbsPath = () => {
    const { intl, routeService } = this.props;
    return routeService.transformBreadCrumbs([
      {
        text: intl.formatMessage(globalTranslations.weRecommendTitle),
        url: Paths.RecommendedProducts,
      },
    ]);
  };

  getSearchParams = () => ({
    bannerPage: AdPages.RECOMMENDED_PRODUCTS,
  });

  render() {
    const listId = 'recommended_products_list';
    const listName = 'Recommended Products List';
    const { categoryStore, configStore, sectionStore, routeService } =
      this.props;

    const fixedParams = {
      orderBy: ProductSortOrderBy.RECOMMENDED_SORT_ORDER,
      order: SortOrder.ASC,
      recommendedPositions: [ProductRecommendedPosition.EVERYWHERE],
      includeChildProducts: 1,
      includeFilters: 0,
    };

    if (categoryStore.activeCategory) {
      fixedParams.categories = [categoryStore.activeCategory.id];
      fixedParams.recommendedPositions.push(
        ProductRecommendedPosition.CATEGORY
      );
    } else {
      fixedParams.recommendedPositions.push(
        ProductRecommendedPosition.FRONTPAGE
      );

      if (sectionStore.activeSection) {
        fixedParams.sections = [sectionStore.activeSection.id];
      }
    }

    const mainBanner = <MainBanner searchParams={this.getSearchParams()} />;
    const mainBannerWithinContent =
      configStore.mainBanner.withinContent && !configStore.mainBanner.fullWidth;

    return (
      <Page
        name={CommonPage.RECOMMENDED_PRODUCTS}
        className="RecommendedProductsPage"
      >
        <ScrollReset />
        <SEOTitle
          title={{
            ...globalTranslations.weRecommendTitle,
          }}
        />
        <CanonicalLink path={routeService.getPath(Paths.RecommendedProducts)} />
        {!mainBannerWithinContent && mainBanner}
        <Container className="RecommendedProductsPage__container">
          <GenericLayout
            content={
              <Fragment>
                {mainBannerWithinContent && mainBanner}
                <HelperNavigation breadcrumbsPath={this.getBreadcrumbsPath()} />
                <PageTitle>
                  <FormattedMessage {...globalTranslations.weRecommendTitle} />
                </PageTitle>
                <MainProductList
                  fixedParams={fixedParams}
                  listId={listId}
                  name={listName}
                  showSort={false}
                />
                <ContentPopup />
              </Fragment>
            }
          />
        </Container>
      </Page>
    );
  }
}

RecommendedProductsPage.propTypes = {
  categoryStore: modelOf(CategoryStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  intl: intlShape.isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
};

export default injectIntl(
  inject(
    'categoryStore',
    'configStore',
    'sectionStore',
    'routeService'
  )(RecommendedProductsPage)
);
