import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

export class SEOSchema extends Component {
  render() {
    const { data } = this.props;
    const schema = JSON.stringify(data);

    return (
      <Helmet>
        <script type="application/ld+json">{schema}</script>
      </Helmet>
    );
  }
}

SEOSchema.propTypes = {
  data: PropTypes.object.isRequired
};

export default SEOSchema;
