import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';

export class ProductSizeGuideModal extends Component {
  render() {
    const { guide } = this.props;

    return (
      <Modal
        className="ProductSizeGuideModal"
        size="lg"
        toggle={this.props.toggle}
        isOpen={this.props.isOpen}
      >
        <ModalHeader toggle={this.props.toggle}>
          <div className="ProductSizeGuideModal__header">{guide.name}</div>
        </ModalHeader>
        <ModalBody>
          <div
            key={guide.name}
            className="ProductSizeGuideModal__content"
            dangerouslySetInnerHTML={{
              __html: guide.contents
            }}
          />
        </ModalBody>
      </Modal>
    );
  }
}

ProductSizeGuideModal.propTypes = {
  guide: PropTypes.object.isRequired,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool
};

export default inject('configStore')(ProductSizeGuideModal);
