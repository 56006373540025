import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Col, Collapse, Row, Container } from 'reactstrap';
import classNames from 'classnames';

import MenuItemList from '../MenuItemList';
import Icon from '../../common/Icon';
import NavigationEntity from '../../../types/NavigationEntity';
import NavigationEntityItem from '../../navigation/NavigationEntityItem';

/* NOTE: this component shows only one level menus under the menu title */
@observer
class CollapsibleMenu extends Component {
  state = {
    collapse: false
  };

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const { collapse } = this.state;
    const { item } = this.props;

    return (
      <div
        className={classNames('CollapsibleMenu', {
          'CollapsibleMenu--has-children': !!item.children
        })}
      >
        <Container className="CollapsibleMenu__container">
          <Row>
            <Col
              className="col-12 CollapsibleMenu__menu-col"
              onClick={this.toggle}
            >
              <NavigationEntityItem entity={item} />
              {item.children &&
                item.children.length > 0 && (
                  <Icon name={this.state.collapse ? 'minus' : 'plus'} />
                )}
            </Col>
          </Row>
          <Row>
            <Col className="col-12 CollapsibleMenu__menu-col-children">
              <Collapse isOpen={collapse}>
                {item.children && (
                  <MenuItemList items={item.children} key={item.id} />
                )}
              </Collapse>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

CollapsibleMenu.propTypes = {
  item: NavigationEntity.isRequired
};

export default CollapsibleMenu;
