import React from 'react';
import { Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { modelOf } from '../../../prop-types';
import Price from '../../product/Price';
import Icon from '../../common/Icon';
import MarkerBadge from '../../common/MarkerBadge';
import globalTranslations from '../../../i18n/globalTranslations';
import RequestState from '../../../types/RequestState';
import AccountStore from '../../../store/AccountStore';
import ConfigStore from '../../../store/ConfigStore';
import UIStore from '../../../store/UIStore';
import Analytics from '../../../analytics/Analytics';
import ProductAddedToCardModalType from '../../../types/ProductAddedToCartModalStyle';
import ProposalStore from '../../../store/ProposalStore';
import Paths from '../../../types/Paths';
import RouteService from '../../../services/RouteService';

export const NavigationProposal = ({
  accountStore,
  proposalStore,
  configStore,
  uiStore,
  routeService,
  analytics,
  intl,
  withTax,
  className,
  withCheckoutButton,
  wrapperClassName,
}) => {
  const getTotalPrice = () => {
    const { cart } = proposalStore;

    if (!cart) {
      return null;
    }
    const priceTotal = cart?.total && cart.total.getPrice(withTax);

    return priceTotal || null;
  };

  const getHidePriceOnMobile = () => {
    const price = getTotalPrice();
    return price > 1000 || price === null;
  };

  const renderPrice = () => {
    const price = getTotalPrice();
    return price !== null ? <Price price={price} /> : null;
  };

  const renderProposalText = () => {
    const productCount =
      (proposalStore.cart && proposalStore.cart.number_of_products) || 0;
    let text;

    switch (proposalStore.state) {
      case RequestState.LOADED:
        if (productCount === 0) {
          text = (
            <FormattedMessage {...globalTranslations.proposalEmptySentence} />
          );
        } else {
          text = (
            <span>
              <FormattedMessage
                {...globalTranslations.itemCount}
                values={{
                  count: productCount,
                }}
              />{' '}
              {renderPrice()}
            </span>
          );
        }
        break;
      case RequestState.LOADING:
      default:
        text = <FormattedMessage {...globalTranslations.loading} />;
        break;
    }

    return text;
  };

  const sendAnalyticsProposalEvent = () => {
    if (!configStore.gtm.enabled) {
      return;
    }

    const proposalProducts = proposalStore.cart.products;
    const actionField = { step: 1, option: 'Move To Checkout' };
    analytics.checkout(proposalProducts, actionField);
  };

  const cartIsPopover = () => {
    if (!accountStore.account) {
      return null;
    }

    const isRetailer = accountStore.account.is_retailer;
    const modalType = configStore.cart.type;

    return isRetailer || modalType === ProductAddedToCardModalType.POPOVER;
  };

  const onClickHandler = () => {
    proposalStore.toggleModal();
    sendAnalyticsProposalEvent();
  };

  const getButtonContent = () => {
    if (proposalStore.state === RequestState.LOADING) {
      return <FormattedMessage {...globalTranslations.loading} />;
    }

    return (
      <FormattedMessage {...globalTranslations.proceedToProposalSentence} />
    );
  };

  const ifProductCatalog = accountStore.isViewOnly;
  if (ifProductCatalog) {
    return null;
  }

  const proposalCount =
    proposalStore.cart && proposalStore.cart.number_of_products;

  return (
    <div className={classNames('NavigationProposal', className)}>
      <div
        className={classNames(
          'NavigationProposal__cart-button-wrapper',
          wrapperClassName
        )}
      >
        <Button
          block
          color="primary"
          className="NavigationProposal__proposal-button"
          onClick={() => proposalStore.proposalModal.toggle(true)}
          disabled={proposalStore.state !== RequestState.LOADED}
        >
          <Icon name="list" />
          <span
            className={classNames('NavigationProposal__proposal-text', {
              'NavigationProposal__proposal-text--hide-on-mobile':
                getHidePriceOnMobile(),
            })}
          >
            {!uiStore.isSmallest && (
              <span className="NavigationProposal__proposal-text-desktop">
                {renderProposalText()}
              </span>
            )}
            <span className="NavigationProposal__proposal-text-mobile">
              {renderPrice()}
            </span>
          </span>
        </Button>
        {proposalCount > 0 && (
          <MarkerBadge className="d-xs-inline d-sm-none">
            {proposalCount}
          </MarkerBadge>
        )}
      </div>
      {uiStore.isDesktop && withCheckoutButton && (
        <div
          className={classNames(
            'NavigationProposal__checkout-button-wrapper',
            wrapperClassName
          )}
        >
          <Button
            block
            color="primary"
            tag={Link}
            to={routeService.getPath(Paths.ProposalCheckout)}
            className="NavigationProposal__proposal-button"
            aria-label={intl.formatMessage(
              globalTranslations.proceedToProposalSentence
            )}
            disabled={proposalStore.state === RequestState.LOADING}
          >
            {getButtonContent()}
          </Button>
        </div>
      )}
    </div>
  );
};

NavigationProposal.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  proposalStore: modelOf(ProposalStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  intl: intlShape.isRequired,
  withTax: PropTypes.bool.isRequired,
  className: PropTypes.string,
  withCheckoutButton: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

NavigationProposal.defaultProps = {
  withCheckoutButton: true,
  className: '',
};

export default injectIntl(
  inject((stores) => ({
    accountStore: stores.accountStore,
    proposalStore: stores.proposalStore,
    configStore: stores.configStore,
    uiStore: stores.uiStore,
    routeService: stores.routeService,
    analytics: stores.analytics,
    withTax: stores.accountStore.showPricesWithTax,
  }))(observer(NavigationProposal))
);
