import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { filter, head } from 'lodash';

import ConfigStore from '../../../store/ConfigStore';
import UIStore from '../../../store/UIStore';
import ProductAdditionalServicesModal from '../../product/ProductAdditionalServicesModal';
import { modelOf } from '../../../prop-types';
import { FormattedMessage } from 'react-intl';
import ButtonLink from '../../common/ButtonLink';
import Icon from '../../common/Icon';
import { ADDITIONAL_SERVICES_ID } from '../../product/ProductPageContent';
import AddToCartPostalCodeValidation from '../AddToCartPostalCodeValidation';
import { createFormField } from '../../../models/FormField';
import Product from '../../../models/Product';
import { createForm } from '../../../models/Form';
import ProductAdditionalService from '../../product/ProductAdditionalService';
import AccountStore from '../../../store/AccountStore';
import CategoryStore from '../../../store/CategoryStore';

export const createService = (
  areaRequired,
  id,
  isSelected,
  options,
  productId,
  text,
  textMaxLength,
  textRequired,
  validPostalCode
) => {
  return {
    areaRequired,
    id,
    isSelected,
    options,
    productId,
    text,
    textMaxLength,
    textRequired,
    validPostalCode,
  };
};

const form = createForm({});

const AddToCartServices = ({
  accountStore,
  categoryStore,
  configStore,
  uiStore,
  activeProductId,
  modalDisabled,
  onAddToCart,
  selectedServices,
  onSelectedServices,
  onPostalCodeValidation,
  scrollToServices,
  product,
}) => {
  const ifAdditionalServicesModalEnabled =
    configStore.productPage.enableAdditionalServicesModal;

  useEffect(() => {
    product.ifServiceRequiresPostalCodeValidation() && createPostalCodeField();
  }, []);

  const createPostalCodeField = () => {
    const postalCodeFieldName = `postalCode_${product.id}`;
    const formField = createFormField({});
    form.setField(postalCodeFieldName, formField);
  };

  const getPostalCodeField = () => {
    return form.fields.get(`postalCode_${product.id}`);
  };

  const renderServiceModal = () => {
    const isOpen = uiStore.additionalServicesModal.isOpen;

    if (!isOpen) {
      return null;
    }

    return (
      <ProductAdditionalServicesModal
        onAddToCart={onAddToCart}
        modalDisabled={modalDisabled}
      >
        {renderPostalCodeValidationInput()}
        {getServices()}
      </ProductAdditionalServicesModal>
    );
  };

  const handleAdditionalServiceClick = (selectedService) => {
    let mutableSelectedServices = [...selectedServices];

    if (
      mutableSelectedServices.length === 0 ||
      filter(mutableSelectedServices, (val) => val.id === selectedService.id)
        .length === 0
    ) {
      mutableSelectedServices.push(selectedService);
    } else {
      mutableSelectedServices = mutableSelectedServices.map((service) => {
        if (service.id === selectedService.id) {
          return {
            ...service,
            isSelected: selectedService.isSelected,
            text: selectedService.text,
            options: selectedService.options,
          };
        } else {
          return service;
        }
      });
    }

    onSelectedServices && onSelectedServices(mutableSelectedServices);
  };

  const getSelectedServiceInfo = (id) => {
    const match = selectedServices
      .map((service) => service)
      .filter((service) => service.id === id);

    return match.length !== 0 ? match : null;
  };

  const renderProductAdditionalServices = (service, index) => {
    const withTax = accountStore.showPricesWithTax;
    const selectedService = head(getSelectedServiceInfo(service.id));
    const postalCodeField = getPostalCodeField();

    return (
      <ProductAdditionalService
        key={`${service.id}-${index}`}
        service={service}
        withTax={withTax}
        form={form}
        isSelected={
          selectedService ? selectedService.isSelected : service.isSelected
        }
        text={selectedService ? selectedService.text : ''}
        postalCodeField={postalCodeField}
        selectedOptions={selectedService ? selectedService.options : null}
        handleSelectedServices={handleAdditionalServiceClick}
        product={product}
        activeProductId={activeProductId}
      />
    );
  };

  const getServices = () => {
    return product.additionalServices.map(renderProductAdditionalServices);
  };

  const renderServices = () => {
    return ifAdditionalServicesModalEnabled
      ? renderServiceModal()
      : getServices();
  };

  const getPostalCodeValidationState = () => {
    const postalCodeField = getPostalCodeField();

    if (!postalCodeField.value) {
      return true;
    }

    return product.ifValidatedServicesExists({
      postalCode: postalCodeField.value,
    });
  };

  const handleValidatedServices = () => {
    const postalCode = getPostalCodeField().value;
    let mutableSelectedServices = [...selectedServices];

    if (selectedServices && selectedServices.length > 0) {
      mutableSelectedServices = mutableSelectedServices.map(
        (selectedService) => {
          const validatedService = product.getValidatedService({
            postalCode,
            serviceId: selectedService.id,
          });

          if (
            selectedService.id === validatedService.id &&
            selectedService.areaRequired
          ) {
            return {
              ...selectedService,
              isSelected: validatedService.isSelected,
            };
          } else {
            return {
              ...selectedService,
              isSelected: selectedService.isSelected,
            };
          }
        }
      );

      onSelectedServices && onSelectedServices(mutableSelectedServices);
    }

    onPostalCodeValidation &&
      onPostalCodeValidation(getPostalCodeValidationState());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const postalCode = getPostalCodeField().value;
    form.validate();
    if (form.valid) {
      product
        .validateAdditionalServices({
          category: categoryStore.activeCategory.id,
          postalCode,
        })
        .then(handleValidatedServices);
    }
  };

  const handleChange = () => {
    const postalCodeField = getPostalCodeField();

    const ifValidatedServicesExists = product.ifValidatedServicesExists({
      postalCode: postalCodeField.value,
    });

    if (ifValidatedServicesExists) {
      handleValidatedServices();
    } else {
      let mutableSelectedServices = [...selectedServices];
      mutableSelectedServices = mutableSelectedServices.map((service) => {
        if (service.areaRequired) {
          return {
            ...service,
            isSelected: false,
          };
        } else {
          return service;
        }
      });
      onSelectedServices && onSelectedServices(mutableSelectedServices);
      onPostalCodeValidation &&
        onPostalCodeValidation(getPostalCodeValidationState());
    }
  };

  const renderPostalCodeValidationInput = () => {
    const postalCodeField = getPostalCodeField();
    if (!postalCodeField) {
      return null;
    }

    return (
      <AddToCartPostalCodeValidation
        product={product}
        postalCodeField={postalCodeField}
        ifValidForm={form.valid}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
      />
    );
  };

  const scrollToTab = () => {
    scrollToServices && scrollToServices(ADDITIONAL_SERVICES_ID, true);
  };

  const renderAdditionalServicesTabButton = () => {
    if (!ifAdditionalServicesModalEnabled) {
      return null;
    }

    return (
      <span className="AddToCart__additional-services-tab-button">
        <ButtonLink
          className="AddToCart__additional-services-tab-button-text"
          onClick={scrollToTab}
        >
          <FormattedMessage
            id="additionalService.availableServices"
            defaultMessage="Check available services here"
          />
        </ButtonLink>
        <span className="AddToCart__additional-services-tab-button-icon">
          <Icon name="caret-down" />
        </span>
      </span>
    );
  };

  return (
    <div className="AddToCart__additional-services-wrapper">
      {renderAdditionalServicesTabButton()}
      {!ifAdditionalServicesModalEnabled && renderPostalCodeValidationInput()}
      {selectedServices.length > 0 && renderServices()}
    </div>
  );
};

AddToCartServices.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  categoryStore: modelOf(CategoryStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  product: modelOf(Product).isRequired,
  onAddToCart: PropTypes.func.isRequired,
  onSelectedServices: PropTypes.func.isRequired,
  modalDisabled: PropTypes.bool.isRequired,
  onPostalCodeValidation: PropTypes.func,
  scrollToServices: PropTypes.func,
  selectedServices: PropTypes.arrayOf(
    PropTypes.shape({
      areaRequired: PropTypes.bool,
      id: PropTypes.number,
      isSelected: PropTypes.bool,
      options: PropTypes.object,
      productId: PropTypes.string,
      text: PropTypes.string,
      textMaxLength: PropTypes.number,
      textRequired: PropTypes.bool,
      validPostalCode: PropTypes.bool,
    })
  ),
};

export default inject(
  'accountStore',
  'categoryStore',
  'configStore',
  'uiStore'
)(observer(AddToCartServices));
