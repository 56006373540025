import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import ManufacturerLink from '../../manufacturer/ManufacturerLink';
import ManufacturerStore from '../../../store/ManufacturerStore';
import Paths from '../../../types/Paths';
import RouteService from '../../../services/RouteService';
import RequestState from '../../../types/RequestState';

@observer
export class FooterManufacturers extends Component {
  constructor(props) {
    super(props);
    const { manufacturerStore } = this.props;
    if (
      !manufacturerStore.loadedWithContent &&
      manufacturerStore.state === RequestState.NONE
    ) {
      manufacturerStore.loadManufacturers(false);
    }
  }

  render() {
    const { manufacturerStore, routeService } = this.props;

    if (!manufacturerStore.hasManufacturers) {
      return null;
    }

    return (
      <div className="FooterManufacturers">
        <h3>
          <Link to={routeService.getPath(Paths.ManufacturerList)}>
            <FormattedMessage {...globalTranslations.ourBrandsTitle} />
          </Link>
        </h3>
        <div className="FooterManufacturers__list">
          {manufacturerStore.manufacturersWithProducts.map((manufacturer) => (
            <div key={manufacturer.id}>
              <ManufacturerLink manufacturer={manufacturer} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

FooterManufacturers.propTypes = {
  manufacturerStore: modelOf(ManufacturerStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired
};

export default inject('manufacturerStore', 'routeService')(FooterManufacturers);
