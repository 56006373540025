import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import RouterPropTypes from 'react-router-prop-types';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import Page from '../../components/layout/Page';
import HelperNavigation from '../../components/common/HelperNavigation';
import { modelOf } from '../../prop-types';
import InfoPageNavigation from '../../components/info-page/InfoPageNavigation';
import InfoPageStore from '../../store/InfoPageStore';
import RouteService from '../../services/RouteService';
import SidebarLayout from '../../components/layout/SidebarLayout';
import PageTitle from '../../components/common/PageTitle';
import ContentForState from '../../components/loader/ContentForState';
import SEOTitle from '../../components/head/SEOTitle';
import SEODescription from '../../components/head/SEODescription';
import CanonicalLink from '../../components/head/CanonicalLink';
import RequestState from '../../types/RequestState';
import SidebarBanner from '../../components/ad/SidebarBanner';
import BannerSlider from '../../components/ad/BannerSlider';
import AdZones from '../../types/AdZones';
import ContentPopup from '../../components/ad/ContentPopup';
import ScrollReset from '../../components/common/ScrollReset';
import WysiwygOrBlockContent from '../../components/common/WysiwygOrBlockContent';
import UnexpectedError from '../../components/loader/UnexpectedError';
import { pathFragmentMatchesLocation } from '../../util/url';
import ConfigStore from '../../store/ConfigStore';
import CommonPage from '../../types/CommonPage';

@observer
class InfoPage extends Component {
  constructor(props) {
    super(props);

    const { infoPageStore } = this.props;

    this.state = {
      contentState: RequestState.LOADED,
    };

    this.yotpoTimeout = null;
    // We load all pages without content first for hierarchy
    if (infoPageStore.state !== RequestState.LOADED) {
      infoPageStore.loadPages().then(() => {
        this.maybeLoadPage();
      });
    } else {
      this.maybeLoadPage();
    }
  }

  componentDidMount() {
    const { configStore } = this.props;

    if (configStore.integrations.yotpo.enabled && window && window.yotpo) {
      this.yotpoTimeout = setTimeout(() => window.yotpo.refreshWidgets(), 800);
    }
  }

  componentDidUpdate(prevProps) {
    const { match, history, routeService } = this.props;

    if (prevProps.match.params.id !== match.params.id) {
      this.maybeLoadPage();
    }

    // If we have a link, where the slug is '-', update it.
    // Used when linking based only on page ID.
    if (match.params.slug === '-') {
      const infoPage = this.getActiveInfoPage();
      if (infoPage) {
        history.replace(
          `${routeService.getPath(infoPage.path)}${history.location.search}${
            history.location.hash
          }`
        );
      }
    }
  }

  componentWillUnmount() {
    this.yotpoTimeout && clearTimeout(this.yotpoTimeout);
  }

  maybeLoadPage = () => {
    const { infoPageStore, configStore, match } = this.props;
    const pageState = infoPageStore.pageStates.get(match.params.id);

    if (!pageState || pageState !== RequestState.LOADED) {
      this.setState({
        contentState: RequestState.LOADING,
      });
      infoPageStore
        .loadPage(match.params.id)
        .then(() => {
          if (
            configStore.integrations.yotpo.enabled &&
            window &&
            window.yotpo
          ) {
            this.yotpoTimeout = setTimeout(
              () => window.yotpo.refreshWidgets(),
              800
            );
          }

          this.setState({
            contentState: RequestState.LOADED,
          });
        })
        .catch((e) => {
          this.setState({
            contentState: RequestState.ERROR,
          });
          console.error(e);
        });
    }
  };

  getActiveInfoPage = () => {
    const { match, infoPageStore } = this.props;
    const activePageId = match.params.id;
    return infoPageStore.pages.get(activePageId);
  };

  render() {
    const { match, location, infoPageStore, routeService } = this.props;

    const infoPage = this.getActiveInfoPage();

    if (infoPage) {
      if (!pathFragmentMatchesLocation(infoPage.path, location.pathname)) {
        const url = `${routeService.getPath(infoPage.path)}${location.search}${
          location.hash
        }`;
        return <Redirect to={url} />;
      }
    }

    return (
      <Page name={CommonPage.INFO} className="InfoPage">
        <ScrollReset key={match.params.id} />
        <ContentForState
          state={this.state.contentState}
          error={infoPageStore.lastError}
          forLoaded={() => (
            <Fragment>
              {infoPage && (
                <CanonicalLink path={routeService.getPath(infoPage.path)} />
              )}
              <Container className="InfoPage__container">
                <SidebarLayout
                  sidebar={
                    <Fragment>
                      <InfoPageNavigation
                        currentInfoPage={infoPage}
                        key={infoPage ? infoPage.id : -1}
                      />
                      <SidebarBanner />
                    </Fragment>
                  }
                  content={
                    <ContentForState
                      state={infoPageStore.pageStates.get(match.params.id)}
                      error={infoPageStore.lastSinglePageError}
                      forLoaded={() => {
                        if (!infoPage) {
                          // The page should always exist if no error occurred, but...
                          return <UnexpectedError />;
                        }

                        return (
                          <Fragment>
                            <HelperNavigation
                              breadcrumbsPath={routeService.transformBreadCrumbs(
                                infoPage.breadcrumbs
                              )}
                            />
                            <PageTitle>{infoPage.name}</PageTitle>
                            {infoPage.content !== null && (
                              <Fragment>
                                <SEOTitle
                                  title={infoPage.SEO_title || infoPage.name}
                                  noSuffix={!!infoPage.SEO_title}
                                />
                                {infoPage.SEO_description && (
                                  <SEODescription
                                    content={infoPage.SEO_description}
                                  />
                                )}
                                <div className="InfoPage__contents">
                                  <WysiwygOrBlockContent
                                    content={infoPage.content}
                                  />
                                </div>
                                <BannerSlider
                                  searchParams={{
                                    bannerZone: AdZones.INFO_PAGE_BANNER,
                                  }}
                                  aspectRatio={2.85}
                                />
                                <ContentPopup />
                              </Fragment>
                            )}
                          </Fragment>
                        );
                      }}
                    />
                  }
                />
              </Container>
            </Fragment>
          )}
        />
      </Page>
    );
  }
}

InfoPage.propTypes = {
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  configStore: modelOf(ConfigStore),
  infoPageStore: modelOf(InfoPageStore),
  match: RouterPropTypes.match.isRequired,
  location: RouterPropTypes.location.isRequired,
};

export default inject('configStore', 'infoPageStore', 'routeService')(InfoPage);
