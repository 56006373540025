import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import AdZones from '../../../types/AdZones';
import { paramsToQueryIdentifier } from '../../../util/query';
import AdStore from '../../../store/AdStore';
import { modelOf } from '../../../prop-types';
import Slider from '../../common/Slider';
import MaybeExternalLink from '../../common/MaybeExternalLink';

const SLIDER_SETTINGS = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000
};

export const ITEM_PARAMS = {
  bannerZone: AdZones.MAIN_TEXT_CAROUSEL
};

@observer
export class NavigationLinkCarousel extends Component {
  componentDidMount() {
    this.loadBanners();
  }

  loadBanners = () => {
    const { adStore } = this.props;
    adStore.loadAds(ITEM_PARAMS);
  };

  adToLink = (ad) => {
    const Wrapper = ({ children }) => (
      <div className="NavigationLinkCarousel__link">
        {ad.link_url ? (
          <MaybeExternalLink to={ad.link_url}>{children}</MaybeExternalLink>
        ) : (
          children
        )}
      </div>
    );
    return (
      <Wrapper key={ad.id}>
        <div dangerouslySetInnerHTML={{ __html: ad.content }} />
      </Wrapper>
    );
  };

  render() {
    const { adStore } = this.props;

    const ads = adStore.adQueries.get(paramsToQueryIdentifier(ITEM_PARAMS));

    if (
      !ads ||
      ads.length === 0 ||
      ads.filter((ad) => !!ad.content).length === 0
    ) {
      return null;
    }

    const links = ads.filter((ad) => !!ad.content).map(this.adToLink);

    return (
      <div className="NavigationLinkCarousel">
        <Slider key={ads.map((ad) => ad.id).join(',')} {...SLIDER_SETTINGS}>
          {links}
        </Slider>
      </div>
    );
  }
}

NavigationLinkCarousel.propTypes = {
  adStore: modelOf(AdStore).isRequired
};

export default inject('adStore')(NavigationLinkCarousel);
