import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { observer, inject } from 'mobx-react';
import { matchPath, withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';

import Paths from '../../../types/Paths';
import UIStore from '../../../store/UIStore';
import { modelOf } from '../../../prop-types';
import Icon from '../Icon';

@observer
export class GoBackLink extends Component {
  render() {
    const { uiStore, history } = this.props;
    const prefix = '/:locale?/:section?';
    const paths = [
      prefix + Paths.NewProducts,
      prefix + Paths.OnSale,
      prefix + Paths.Category,
      prefix + Paths.Manufacturer,
      '/:locale?' + Paths.Section
    ];
    const lastPageIsListing = paths.some((path) => {
      return matchPath(uiStore.lastPathname, { path, exact: true });
    });
    if (!lastPageIsListing) {
      return null;
    }
    return (
      <Button
        className="GoBackLink"
        color="link"
        onClick={history.goBack}
        role="link"
      >
        <Icon name="chevron-left" />
        <FormattedMessage
          id="navigation.goBackLink"
          defaultMessage="Back to search results"
        />
      </Button>
    );
  }
}

GoBackLink.propTypes = {
  uiStore: modelOf(UIStore).isRequired,
  history: RouterPropTypes.history.isRequired
};

export default inject('uiStore')(withRouter(GoBackLink));
