import { types } from 'mobx-state-tree';

const FilterType = {
  TERM: 'TERM',
  RANGE: 'RANGE',
  BOOLEAN: 'BOOLEAN'
};

export default FilterType;

export const FilterTypeType = types.enumeration(
  'FilterTypeType',
  Object.values(FilterType)
);
