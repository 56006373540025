import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Section from '../../../models/Section';
import { modelOf } from '../../../prop-types';
import SectionStore from '../../../store/SectionStore';
import { Route } from 'react-router-dom';

/**
 * Used to easily set the active section when section specific routes
 * are rendered.
 */
@observer
export class SectionAwareRoute extends Component {
  constructor(props) {
    super(props);
    const { section, sectionStore } = this.props;
    sectionStore.setActiveSection(section || null);
  }

  render() {
    const { section, sectionStore, ...props } = this.props;
    return <Route {...props} />;
  }
}

SectionAwareRoute.propTypes = {
  section: modelOf(Section),
  sectionStore: modelOf(SectionStore).isRequired
};

export default inject('sectionStore')(SectionAwareRoute);
