import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ProductSign from '../ProductSign';
import Discount from '../Discount';

class ProductSignDiscount extends Component {
  render() {
    const { percent, withUpTo, singlePriceLabel } = this.props;

    if (!percent) {
      return null;
    }

    return (
      <div className="ProductDiscountPercentageSign">
        <ProductSign>
          <Discount
            percentage={percent}
            withLabel={!withUpTo && singlePriceLabel}
            withUpTo={withUpTo}
          />
        </ProductSign>
      </div>
    );
  }
}

ProductSignDiscount.propTypes = {
  percent: PropTypes.number,
  withUpTo: PropTypes.bool,
  singlePriceLabel: PropTypes.bool
};

ProductSignDiscount.defaultProps = {
  singlePriceLabel: true
};

export default ProductSignDiscount;
