import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label, UncontrolledTooltip } from 'reactstrap';
import { uniqueId } from 'lodash';

import { modelOf } from '../../../prop-types';
import FormField from '../../../models/FormField';
import Field from '../Field';
import Icon from '../../common/Icon';

import FieldError from '../FieldError';

@observer
class FormGroupField extends Component {
  constructor(props) {
    super(props);

    // In the normal use cases, the form and field names don't change, and
    // this only really affects the input/label binding, so not updating the id
    // afterwards if props change should still be fine.
    this.id = uniqueId(`${props.formName}__${props.fieldName}--`);
  }

  getTooltip = (text) => {
    return (
      <Fragment>
        <Icon name="info" className={`tooltip-${this.id}`} />
        <UncontrolledTooltip target={`.tooltip-${this.id}`}>
          {text}
        </UncontrolledTooltip>
      </Fragment>
    );
  };

  render() {
    const {
      label,
      fieldName,
      field,
      type,
      hideLabel,
      required,
      tooltip,
      description,
      // unused, but no reason to pass to Field with rest
      formName,
      ...rest
    } = this.props;
    return (
      <FormGroup className="FormGroupField">
        <Label for={this.id} hidden={hideLabel}>
          {label}
          {required && ' * '}
          {tooltip && this.getTooltip(tooltip)}
        </Label>
        <Field
          component={Input}
          field={field}
          type={type}
          name={fieldName}
          id={this.id}
          required={required}
          {...rest}
        />
        {description}
        <FieldError field={field} />
      </FormGroup>
    );
  }
}

FormGroupField.propTypes = {
  field: modelOf(FormField).isRequired,
  label: PropTypes.node.isRequired,
  formName: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  type: PropTypes.string,
  hideLabel: PropTypes.bool,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
  description: PropTypes.node,
};

FormGroupField.defaultProps = {
  type: 'text',
};

export default FormGroupField;
