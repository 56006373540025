import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import InfoPage from '../../../models/InfoPage';
import InfoPageStore from '../../../store/InfoPageStore';
import InfoPageList from '../InfoPageList';
import UIStore from '../../../store/UIStore';
import TopLevelNavigationDropdown from '../../common/TopLevelNavigationDropdown';
import ConfigStore from '../../../store/ConfigStore';

export const InfoPageNavigation = ({
  uiStore,
  infoPageStore,
  configStore,
  currentInfoPage,
}) => {
  const getActiveInfoPages = () => [
    ...currentInfoPage.ancestors,
    currentInfoPage,
  ];

  const getInfoPages = (id) => {
    return infoPageStore.pagesArray.filter(
      (page) => !page.parent_id && page.show_in_listing && page.id === id
    );
  };

  if (!uiStore.isDesktop) {
    return null;
  }

  const activeInfoPages = currentInfoPage ? getActiveInfoPages() : [];

  return (
    <Fragment>
      {configStore.navigation.sidebar.enabled && <TopLevelNavigationDropdown />}
      <div className="InfoPageNavigation">
        {activeInfoPages.length > 0 && (
          <InfoPageList
            infoPages={getInfoPages(activeInfoPages[0].id)}
            activeInfoPages={activeInfoPages}
          />
        )}
      </div>
    </Fragment>
  );
};

InfoPageNavigation.propTypes = {
  uiStore: modelOf(UIStore).isRequired,
  infoPageStore: modelOf(InfoPageStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  currentInfoPage: modelOf(InfoPage),
};

export default inject(
  'infoPageStore',
  'uiStore',
  'configStore'
)(observer(InfoPageNavigation));
