import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';

import Page from '../../components/layout/Page';
import MainProductList from '../../components/product-list/MainProductList';
import HelperNavigation from '../../components/common/HelperNavigation';
import GenericLayout from '../../components/layout/GenericLayout';
import ProductSortOrderBy from '../../types/ProductSortOrderBy';
import SortOrder from '../../types/SortOrder';
import globalTranslations from '../../i18n/globalTranslations';
import PageTitle from '../../components/common/PageTitle';
import Paths from '../../types/Paths';
import SEOTitle from '../../components/head/SEOTitle';
import CanonicalLink from '../../components/head/CanonicalLink';
import ContentPopup from '../../components/ad/ContentPopup';
import MainBanner from '../../components/ad/MainBanner';
import ConfigStore from '../../store/ConfigStore';
import SectionStore from '../../store/SectionStore';
import RouteService from '../../services/RouteService';
import { modelOf } from '../../prop-types';
import ScrollReset from '../../components/common/ScrollReset';
import AdPages from '../../types/AdPages';
import CommonPage from '../../types/CommonPage';

@observer
class PopularProductsPage extends Component {
  getBreadcrumbsPath = () => {
    const { intl, routeService } = this.props;
    return routeService.transformBreadCrumbs([
      {
        text: intl.formatMessage(globalTranslations.mostPopularTitle),
        url: Paths.PopularProducts,
      },
    ]);
  };

  getSearchParams = () => ({
    bannerPage: AdPages.POPULAR_PRODUCTS,
  });

  render() {
    const listId = 'popular_products_list';
    const listName = 'Popular Products List';
    const { configStore, sectionStore, routeService } = this.props;
    const fixedParams = {
      orderBy: ProductSortOrderBy.SALES,
      order: SortOrder.DESC,
    };
    if (sectionStore.activeSection) {
      fixedParams.sections = [sectionStore.activeSection.id];
    }

    const mainBanner = <MainBanner searchParams={this.getSearchParams()} />;
    const mainBannerWithinContent =
      configStore.mainBanner.withinContent && !configStore.mainBanner.fullWidth;

    return (
      <Page name={CommonPage.POPULAR_PRODUCTS} className="PopularProductsPage">
        <ScrollReset />
        <SEOTitle
          title={{
            ...globalTranslations.mostPopularTitle,
          }}
        />
        <CanonicalLink path={routeService.getPath(Paths.PopularProducts)} />
        {!mainBannerWithinContent && mainBanner}
        <Container className="PopularProductsPage__container">
          <GenericLayout
            content={
              <Fragment>
                {mainBannerWithinContent && mainBanner}
                <HelperNavigation breadcrumbsPath={this.getBreadcrumbsPath()} />
                <PageTitle>
                  <FormattedMessage {...globalTranslations.mostPopularTitle} />
                </PageTitle>
                <MainProductList
                  fixedParams={fixedParams}
                  listId={listId}
                  name={listName}
                  showSort={false}
                />
                <ContentPopup />
              </Fragment>
            }
          />
        </Container>
      </Page>
    );
  }
}

PopularProductsPage.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  intl: intlShape.isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
};

export default injectIntl(
  inject('configStore', 'sectionStore', 'routeService')(PopularProductsPage)
);
