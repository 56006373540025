import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import classNames from 'classnames';
import { inject } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import CustomCol from '../../bootstrap/CustomCol';
import ConfigStore from '../../../store/ConfigStore';

export class SidebarLayout extends Component {
  renderCustomCol = (node, sizes) => {
    return <CustomCol {...sizes}>{node}</CustomCol>;
  };

  render() {
    const { sidebar, content, className, configStore, forceSidebar } =
      this.props;
    const fullWidthPage =
      configStore.navigation.sidebar.showFullWidthPageContent && !forceSidebar;
    return (
      <Row className={classNames('SidebarLayout', className)}>
        {!fullWidthPage &&
          this.renderCustomCol(sidebar, {
            md: 12,
            lg: 3,
            xxl: 2,
            className: 'SidebarLayout__left',
          })}
        {fullWidthPage
          ? this.renderCustomCol(content, { md: 12 })
          : this.renderCustomCol(content, {
              md: 12,
              lg: 9,
              xxl: 10,
              className: 'SidebarLayout__right',
            })}
      </Row>
    );
  }
}

SidebarLayout.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  sidebar: PropTypes.node,
  content: PropTypes.node,
  className: PropTypes.string,
  // Ignores full width configuration and forces sidebar to be used (ex. MyAccountPage)
  forceSidebar: PropTypes.bool,
};

export default inject('configStore')(SidebarLayout);
