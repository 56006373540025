import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class Icon extends Component {
  render() {
    return (
      <span
        className={classNames(
          'Icon',
          'fa',
          `fa-${this.props.name}`,
          'icon',
          `icon-${this.props.name}`,
          this.props.className,
          { 'fa-spin': this.props.spin }
        )}
      />
    );
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  spin: PropTypes.bool
};

Icon.defaultProps = {
  className: '',
  spin: false
};

export default Icon;
