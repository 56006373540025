import React, { Component } from 'react';
import ProductTabs from '../../product/ProductTabs';

class SingleRowProductTabs extends Component {
  render() {
    const { children, ...props } = this.props;
    return (
      <ProductTabs singleRowMode={true} {...props}>
        {children}
      </ProductTabs>
    );
  }
}

export default SingleRowProductTabs;
