import React, { Component } from 'react';
import { Alert, Button, Form } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import { createForm } from '../../../models/Form';
import globalTranslations from '../../../i18n/globalTranslations';
import FormGroupField from '../../form/FormGroupField';
import { isEmail, minLength, notBlank } from '../../../util/formValidators';
import ConfigStore from '../../../store/ConfigStore';
import { modelOf } from '../../../prop-types';
import { createFormField } from '../../../models/FormField';
import CheckboxField from '../../form/CheckboxField';
import RequestState from '../../../types/RequestState';
import ErrorHandler from '../../loader/ErrorHandler';
import { createErrorModel } from '../../../util/error';
import PrivacyStatementLink from '../../privacy-statement/PrivacyStatementLink';
import ApiWrapper from '../../../services/ApiWrapper';
import RecaptchaField from '../../form/RecaptchaField';
import LanguageStore from '../../../store/LanguageStore';

const labels = {
  fullName: <FormattedMessage {...globalTranslations.fullNameTitle} />,
  lastName: <FormattedMessage {...globalTranslations.lastNameTitle} />,
  email: <FormattedMessage {...globalTranslations.emailTitle} />,
  telephone: <FormattedMessage {...globalTranslations.telephoneTitle} />,
  streetAddress: (
    <FormattedMessage {...globalTranslations.streetAddressTitle} />
  ),
  postalCode: <FormattedMessage {...globalTranslations.zipCodeTitle} />,
  city: <FormattedMessage {...globalTranslations.cityTitle} />,
  message: <FormattedMessage {...globalTranslations.messageTitle} />,
  privacyStatement: (
    <FormattedMessage {...globalTranslations.privacyStatementAcceptSentence} />
  ),
};

function createOption(value, label) {
  return {
    value,
    label,
  };
}

@observer
export class ContactUsForm extends Component {
  form = null;
  formName = 'ContactUsForm';
  state = {
    recaptcha: null,
    error: null,
    requestState: RequestState.NONE,
    // This is used to know, if we've made a new request so we can re-animate errors
    requestId: 0,
  };

  constructor(props) {
    super(props);
    const { configStore } = this.props;

    this.form = createForm({
      fullName: createFormField({}, [
        minLength(
          labels.fullName,
          configStore.formValidation.firstName.minLength
        ),
      ]),
      email: createFormField({}, [
        minLength(labels.email, configStore.formValidation.email.minLength),
        isEmail(labels.email),
      ]),
      telephone: createFormField(
        {},
        [
          minLength(
            labels.telephone,
            configStore.formValidation.telephone.minLength
          ),
        ],
        () => configStore.contactUs.requirePhone
      ),
      streetAddress: createFormField(
        {},
        configStore.contactUs.requireAddress
          ? [
              minLength(
                labels.streetAddress,
                configStore.formValidation.streetAddress.minLength
              ),
            ]
          : [],
        () => configStore.contactUs.enableAddress
      ),
      postalCode: createFormField(
        {},
        configStore.contactUs.requireAddress
          ? [
              minLength(
                labels.postalCode,
                configStore.formValidation.postalCode.minLength
              ),
            ]
          : [],
        () => configStore.contactUs.enableAddress
      ),
      city: createFormField(
        {},
        configStore.contactUs.requireAddress
          ? [minLength(labels.city, configStore.formValidation.city.minLength)]
          : [],
        () => configStore.contactUs.enableAddress
      ),
      message: createFormField({}, [notBlank(labels.message)]),
      privacyStatement: createFormField({}, [
        (value) => {
          return !value ? (
            <FormattedMessage
              key="mustApprove"
              {...globalTranslations.privacyStatementMustApproveSentence}
            />
          ) : null;
        },
      ]),
    });
  }

  onRecaptchaChange = (value) => {
    this.setState({ recaptcha: value });
  };

  isRecaptchaValid = () => {
    const { recaptcha } = this.state;
    const { configStore } = this.props;
    return !!recaptcha || !configStore.contactUs.enableCaptcha;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { recaptcha } = this.state;
    const { apiWrapper, languageStore } = this.props;

    this.form.validate();
    if (this.form.valid && this.isRecaptchaValid()) {
      this.setState({ requestState: RequestState.LOADING });
      apiWrapper
        .apiAxios()
        .post(`forms/contact-us?lang=${languageStore.activeLocale}`, {
          recaptcha,
          ...this.form.getFieldValues(),
        })
        .then(() => {
          this.setState({ requestState: RequestState.LOADED });
        })
        .catch((error) => {
          this.setState({
            requestState: RequestState.ERROR,
            error: error,
            requestId: this.state.requestId + 1,
          });
        });
    }
  };

  field = (fieldName, props) => {
    const field = this.form.fields.get(fieldName);
    return (
      field.active && (
        <FormGroupField
          required={field.hasValidators()}
          field={field}
          formName={this.formName}
          fieldName={fieldName}
          label={labels[fieldName]}
          {...props}
        />
      )
    );
  };

  checkbox = (fieldName, props) => {
    const field = this.form.fields.get(fieldName);
    return (
      field.active && (
        <CheckboxField
          required={field.hasValidators()}
          field={field}
          formName={this.formName}
          fieldName={fieldName}
          {...props}
        />
      )
    );
  };

  getErrorMessages = () => {
    const { error } = this.state;
    return get(error, 'response.data.messages');
  };

  getForm = () => {
    const { requestState, requestId, error } = this.state;
    const { configStore } = this.props;

    // If address fields are shown, make the message field a bit longer.
    const messageRows = configStore.contactUs.enableAddress ? 10 : 7;
    return (
      <Form
        className="ContactUsForm__form"
        noValidate
        onSubmit={this.handleSubmit}
      >
        {error && get(error, 'response.status') !== 422 && (
          <ErrorHandler error={createErrorModel(error)} />
        )}
        {this.getErrorMessages() && (
          <Alert color="danger" key={requestId}>
            {this.getErrorMessages().map((message, index) => (
              <div key={index}>{message}</div>
            ))}
          </Alert>
        )}
        <Row>
          <Col md="6">
            {this.field('fullName', {
              autoComplete: 'name',
            })}
            {this.field('streetAddress', { autoComplete: 'street-address' })}
            <Row>
              <Col sm="4">
                {this.field('postalCode', { autoComplete: 'postal-code' })}
              </Col>
              <Col sm="8">
                {this.field('city', { autoComplete: 'address-level2' })}
              </Col>
            </Row>
            {this.field('email', { type: 'email', autoComplete: 'email' })}
            {this.field('telephone', {
              type: 'tel',
              autoComplete: 'tel-national',
            })}
          </Col>
          <Col md="6">
            {this.field('message', { type: 'textarea', rows: messageRows })}
            {this.checkbox('privacyStatement', {
              options: createOption('1', labels.privacyStatement),
            })}
            <div className="ContactUsForm__privacy-statement-link-wrapper">
              (<PrivacyStatementLink />)
            </div>
            {configStore.contactUs.enableCaptcha && (
              <RecaptchaField
                key={requestId}
                ref={(recaptcha) => (this.recaptcha = recaptcha)}
                onChange={this.onRecaptchaChange}
              />
            )}
            <Button
              color="primary"
              block
              disabled={
                this.form.valid === false ||
                this.isRecaptchaValid() === false ||
                requestState === RequestState.LOADING
              }
            >
              <FormattedMessage {...globalTranslations.sendTitle} />
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  render() {
    const { requestState } = this.state;

    return (
      <div className="ContactUsForm">
        {requestState === RequestState.LOADED ? (
          <Alert color="success">
            <FormattedMessage
              id="contactUs.success"
              defaultMessage="Your message was sent to our customer service successfully. We aim to handle the message as soon as possible."
            />
          </Alert>
        ) : (
          this.getForm()
        )}
      </div>
    );
  }
}

ContactUsForm.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  languageStore: modelOf(LanguageStore).isRequired,
  apiWrapper: PropTypes.instanceOf(ApiWrapper).isRequired,
};

export default inject(
  'configStore',
  'languageStore',
  'apiWrapper'
)(ContactUsForm);
