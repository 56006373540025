import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import RouterPropTypes from 'react-router-prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import {
  Alert,
  Col,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
} from 'reactstrap';
import PropTypes from 'prop-types';

import Page from '../../components/layout/Page';
import MainProductList, {
  ANCHOR_ID,
} from '../../components/product-list/MainProductList';
import LicensePlateInput from '../../components/vehicle/LicensePlateInput';
import VehicleInfo from '../../components/vehicle/VehicleInfo';
import RequestState from '../../types/RequestState';
import { createErrorModel } from '../../util/error';
import ContentForState from '../../components/loader/ContentForState';
import HelperNavigation from '../../components/common/HelperNavigation';
import Paths from '../../types/Paths';
import globalTranslations from '../../i18n/globalTranslations';
import GenericLayout from '../../components/layout/GenericLayout';
import PageTitle from '../../components/common/PageTitle';
import ApiWrapper from '../../services/ApiWrapper';
import RouteService from '../../services/RouteService';
import { parse, addParametersToPath } from '../../util/queryString';
import { modelOf } from '../../prop-types';
import UIStore from '../../store/UIStore';
import { scrollToElementById } from '../../util/dom';
import CommonPage from '../../types/CommonPage';

const VEHICLE_PRODUCTS_API = 'vehicles/products';
const LIST_ID = 'vehicle_part_search_list';
const LIST_NAME = 'VehiclePartSearchList';
const ErrorType = {
  NOT_FOUND: 'NOT_FOUND',
};

@observer
export class VehiclePartSearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestState: RequestState.NONE,
      vehicleInfo: null,
      categories: [],
      error: null,
      categoryId: null,
      subModelDropdownOpen: false,
      subModelDropdownText: (
        <FormattedMessage
          id="vehiclePartSearch.selectModel"
          defaultMessage="Select more precise model"
        />
      ),
      selectedSubModel: null,
    };
  }

  componentDidMount() {
    const params = parse(this.props.location.search);
    if (params.id || (params.id && params.categoryId)) {
      this.loadData(params.id, params.categoryId);
    } else {
      this.getActiveVehicle();
    }
    params.categoryId &&
      this.setState({ categoryId: Number(params.categoryId) });
  }

  componentDidUpdate(prevProps) {
    const params = parse(this.props.location.search);
    const prevParams = parse(prevProps.location.search);
    if (params.id && !params.vehicleSearch && params.id !== prevParams.id) {
      this.setState({ categoryId: null }, this.loadData(params.id));
    }
  }

  loadData = (licensePlateNumber, categoryId) => {
    const { apiWrapper, routeService, history } = this.props;

    if (!licensePlateNumber) {
      return null;
    }

    this.setState({
      requestState: RequestState.LOADING,
      selectedSubModel: null,
      subModelDropdownText: (
        <FormattedMessage
          id="vehiclePartSearch.selectModel"
          defaultMessage="Select more precise model"
        />
      ),
    });

    if (licensePlateNumber) {
      const licensePlateUrl = addParametersToPath(Paths.VehiclePartSearch, {
        id: licensePlateNumber,
        categoryId,
        vehicleSearch: true,
      });
      const url = routeService.getPath(licensePlateUrl);
      history.push(url);
    }

    licensePlateNumber && categoryId && this.scrollToList(ANCHOR_ID);

    apiWrapper
      .request(`vehicles?licensePlateNumber=${licensePlateNumber} `)
      .then((data) => {
        let error = null;
        if (!data) {
          data = null;
          error = ErrorType.NOT_FOUND;
        }

        this.setState({
          requestState: RequestState.LOADED,
          vehicleInfo: data,
          error,
        });
      })
      .then(this.findSubModelIfActiveSubModelParamId)
      .catch((err) => {
        const error = createErrorModel(err);
        if (error.httpStatusCode === 404) {
          this.setState({
            requestState: RequestState.LOADED,
            error: ErrorType.NOT_FOUND,
          });
          this.clearActiveVehicle();
        } else {
          this.setState({
            requestState: RequestState.ERROR,
            error,
          });
        }
      });
  };

  getActiveVehicle = () => {
    const { apiWrapper } = this.props;

    this.setState({ requestState: RequestState.LOADING });
    apiWrapper
      .request(`vehicles/active`)
      .then((data) => {
        this.setState({
          requestState: RequestState.LOADED,
          vehicleInfo: data,
        });
      })
      .then(this.findSubModelIfActiveSubModelParamId)
      .catch((err) => {
        const error = createErrorModel(err);
        error.httpStatusCode !== 404
          ? this.setState({
              requestState: RequestState.ERROR,
              error,
            })
          : this.setState({ requestState: RequestState.LOADED });
      });
  };

  findSubModelIfActiveSubModelParamId = () => {
    const params = parse(this.props.location.search);
    const subModelId = params.subModelId;
    const activeSubModel = this.state.vehicleInfo.sub_models.find(
      (sub_model) => sub_model.id === subModelId
    );
    activeSubModel
      ? this.setState(
          {
            selectedSubModel: activeSubModel,
            subModelDropdownText: activeSubModel.name,
          },
          this.loadCategoryData
        )
      : this.loadCategoryData();
  };

  loadCategoryData = () => {
    const { apiWrapper, location, uiStore } = this.props;
    const { vehicleInfo, selectedSubModel } = this.state;

    if (!vehicleInfo) {
      return null;
    }

    if (vehicleInfo.sub_models.length !== 0 && !selectedSubModel) {
      this.setState({
        categories: [],
      });

      return null;
    }

    const categoryApi =
      vehicleInfo.sub_models.length > 0 && selectedSubModel
        ? `vehicles/categories?subModelId=${selectedSubModel.id}`
        : `vehicles/categories`;

    this.setState({
      requestState: RequestState.LOADING,
      categories: [],
    });

    apiWrapper
      .request(`${categoryApi}`)
      .then((data) => {
        const params = parse(location.search);
        this.setState({
          requestState: RequestState.LOADED,
          categories: data,
        });

        uiStore.isMobile && params.categoryId && this.scrollToList();
      })
      .catch((error) => {
        this.setState({
          requestState: RequestState.ERROR,
          error: createErrorModel(error),
        });
      });
  };

  scrollToList = () => {
    const { vehicleInfo, categoryId } = this.state;
    this.setHash(ANCHOR_ID);
    vehicleInfo &&
      categoryId &&
      scrollToElementById(ANCHOR_ID, { behavior: 'instant' });
  };

  setHash = (hash) => {
    const { history } = this.props;
    const hashString = hash ? '#' + hash : '';
    history.replace(
      history.location.pathname + history.location.search + hashString
    );
  };

  getBreadcrumbsPath = () => {
    const { intl, routeService } = this.props;
    return routeService.transformBreadCrumbs([
      {
        text: intl.formatMessage(globalTranslations.vehiclePartSearchTitle),
        url: Paths.VehiclePartSearch,
      },
    ]);
  };

  renderInfoText = () => (
    <p className="VehicleSearchPage__help-text">
      <FormattedMessage
        id="vehiclePartSearch.helpText"
        defaultMessage="Insert your license plate number to find compatible parts for the vehicle"
      />
    </p>
  );

  renderInfoSection = () => {
    const { vehicleInfo, error } = this.state;
    const notFound = error === ErrorType.NOT_FOUND;

    const content = (
      <Fragment>
        {notFound && (
          <Alert color="warning">
            <FormattedMessage
              id="vehiclePartSearch.notFound"
              defaultMessage="No vehicle found with given license plate number"
            />
          </Alert>
        )}
        <VehicleInfo vehicleInfo={vehicleInfo} />
        {vehicleInfo && (
          <span
            onClick={this.clearActiveVehicle.bind(this)}
            className="VehilePartSearchPage__clear-selection"
          >
            <FormattedMessage
              id="vehiclePartSearch.clearVehicle"
              defaultMessage="Clear vehicle information"
            />
          </span>
        )}
      </Fragment>
    );
    return (
      <Col>
        <ContentForState
          state={this.state.requestState}
          forLoaded={() => content}
        />
      </Col>
    );
  };

  clearActiveVehicle = () => {
    const { apiWrapper, history } = this.props;
    this.setState({ requestState: RequestState.LOADING });
    apiWrapper
      .apiAxios()
      .delete(`vehicles/clear`)
      .then(() => {
        this.setState({
          requestState: RequestState.LOADED,
          categories: [],
          vehicleInfo: null,
          categoryId: null,
          selectedSubModel: null,
          subModelDropdownText: (
            <FormattedMessage
              id="vehiclePartSearch.selectModel"
              defaultMessage="Select more precise model"
            />
          ),
        });
        history.replace();
      })
      .catch((error) => {
        this.setState({
          requestState: RequestState.ERROR,
          error: createErrorModel(error),
        });
      });
  };

  renderCategorySection = () => {
    const {
      categories,
      categoryId,
      subModelDropdownOpen,
      subModelDropdownText,
      vehicleInfo,
      selectedSubModel,
    } = this.state;

    const content = (
      <Row className="mb-3">
        {vehicleInfo.sub_models.length > 0 && (
          <Col sm={12}>
            <div className="VehicleSearchPage__vehicle-models-info">
              <FormattedMessage
                id="vehiclePartSearch.severalCarsFound"
                defaultMessage="Several models were found with the license plate"
              />
            </div>
            <Dropdown
              isOpen={subModelDropdownOpen}
              toggle={this.toggleSubModelDropdown.bind(this)}
            >
              <DropdownToggle
                caret
                color="primary"
                className="VehicleSearchPage__select-sub-model"
              >
                {subModelDropdownText}
              </DropdownToggle>
              <DropdownMenu className="VehicleSearchPage__sub-model-menu">
                {vehicleInfo.sub_models.map((sub_model, index) => (
                  <DropdownItem
                    key={index}
                    onClick={this.selectSubModel.bind(this, sub_model)}
                  >
                    {sub_model.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Col>
        )}
        {categories.map((parentCategory, parentCategoryIndex) => (
          <Col className="mt-2" key={parentCategoryIndex} md={4}>
            <h2 className="VehicleSearchPage__parent-category-title">
              {parentCategory.name}
            </h2>
            {parentCategory.children.map(
              (childCategory, childCategoryIndex) => (
                <li
                  key={childCategoryIndex}
                  className={classNames(['category-item'], {
                    'selected-item': categoryId === childCategory.id,
                  })}
                  onClick={this.setActiveCategory.bind(this, childCategory)}
                >
                  {childCategory.name}
                </li>
              )
            )}
          </Col>
        ))}
        {selectedSubModel && categories.length === 0 && (
          <Col>
            <p className="VehicleSearchPage__no-search-results">
              <FormattedMessage
                id="vehiclePartSearch.noSearchResults"
                defaultMessage="No search results"
              />
            </p>
          </Col>
        )}
      </Row>
    );
    return (
      <ContentForState
        state={this.state.requestState}
        forLoaded={() => content}
      />
    );
  };

  toggleSubModelDropdown = () =>
    this.setState({ subModelDropdownOpen: !this.state.subModelDropdownOpen });

  selectSubModel = (sub_model) => {
    this.setSubModelIdParam(sub_model);
    this.setState(
      {
        selectedSubModel: sub_model,
        subModelDropdownText: sub_model.name,
        categoryId: null,
      },
      this.loadCategoryData
    );
  };

  setSubModelIdParam = (sub_model) => {
    const { routeService, history } = this.props;
    const subModelId = sub_model.id;
    const subModelParameter = addParametersToPath(Paths.VehiclePartSearch, {
      subModelId,
    });
    const url = routeService.getPath(subModelParameter);
    history.push(url);
  };

  setActiveCategory = (category) => {
    this.setState({ categoryId: category.id }, () => {
      const { history, routeService, location, uiStore } = this.props;
      const params = this.setUrlParams(location);
      const categoryUrl = addParametersToPath(Paths.VehiclePartSearch, params);
      const url = routeService.getPath(categoryUrl);
      history.push(url);
      uiStore.isMobile && this.scrollToList();
    });
  };
  setUrlParams = (location) => {
    const params = parse(location.search);
    return params.id
      ? {
          id: params.id,
          ...params,
          categoryId: Number(this.state.categoryId),
        }
      : {
          ...params,
          categoryId: Number(this.state.categoryId),
        };
  };

  render() {
    const { vehicleInfo, requestState, categoryId } = this.state;
    const loading = requestState === RequestState.LOADING;
    const disabled = vehicleInfo !== null || loading;
    return (
      <Page name={CommonPage.VEHICLE_PART_SEARCH}>
        <Container className="VehiclePartSearchPage">
          <GenericLayout
            content={
              <Fragment>
                <HelperNavigation breadcrumbsPath={this.getBreadcrumbsPath()} />
                <PageTitle>
                  <FormattedMessage
                    {...globalTranslations.vehiclePartSearchTitle}
                  />
                </PageTitle>
                {this.renderInfoText()}
                <Row className="mb-3">
                  <Col md={4} className="mb-2">
                    <LicensePlateInput
                      handleOnSubmit={this.loadData}
                      disabled={disabled}
                      loading={loading}
                      overrideText={
                        vehicleInfo ? vehicleInfo.license_plate_number : null
                      }
                      location={this.props.location}
                    />
                  </Col>
                  {this.renderInfoSection()}
                </Row>
                <hr />
                {vehicleInfo && this.renderCategorySection()}
                {vehicleInfo && categoryId && (
                  <Fragment>
                    <hr />
                    <MainProductList
                      listId={LIST_ID}
                      name={LIST_NAME}
                      fixedParams={{
                        categoryId,
                      }}
                      urlOverride={VEHICLE_PRODUCTS_API}
                    />
                  </Fragment>
                )}
              </Fragment>
            }
          />
        </Container>
      </Page>
    );
  }
}

VehiclePartSearchPage.propTypes = {
  uiStore: modelOf(UIStore).isRequired,
  intl: intlShape.isRequired,
  apiWrapper: PropTypes.instanceOf(ApiWrapper).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  match: RouterPropTypes.match.isRequired,
  location: RouterPropTypes.location.isRequired,
};

export default injectIntl(
  inject('apiWrapper', 'routeService', 'uiStore')(VehiclePartSearchPage)
);
