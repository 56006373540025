import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import UIStore from '../../../store/UIStore';
import globalTranslations from '../../../i18n/globalTranslations';
import { allowScroll, blockScroll } from '../../../util/dom';

const labels = {
  header: (
    <FormattedMessage
      id="additionalService.selectService"
      defaultMessage="Select service"
    />
  ),
  continue: <FormattedMessage {...globalTranslations.continueTitle} />,
  cancel: <FormattedMessage {...globalTranslations.cancelTitle} />,
};

@observer
export class ProductAdditionalServicesModal extends Component {
  componentDidMount() {
    blockScroll();
  }

  componentWillUnmount() {
    allowScroll();
  }

  render() {
    const { children, uiStore, modalDisabled, onAddToCart } = this.props;

    const isOpen = uiStore.additionalServicesModal.isOpen;
    const toggle = uiStore.additionalServicesModal.toggle;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="ProductAdditionalServicesModal"
      >
        <ModalHeader
          toggle={toggle}
          className="ProductAdditionalServicesModal__title"
        >
          {labels.header}
        </ModalHeader>
        <ModalBody
          className={classNames(
            'ProductAdditionalServicesModal__additional-services'
          )}
        >
          <div className="form-inline">{children}</div>
        </ModalBody>
        <ModalFooter className="ProductAdditionalServicesModal__footer">
          <Row className="ProductAdditionalServicesModal__buttons">
            <Col>
              <Button
                className="ProductAdditionalServicesModal__cancel-button"
                onClick={toggle}
              >
                {labels.cancel}
              </Button>
            </Col>
            <Col>
              <Button
                color="primary"
                onClick={onAddToCart}
                disabled={modalDisabled}
                className={classNames(
                  'ProductAdditionalServicesModal__continue-button',
                  {
                    'ProductAdditionalServicesModal__continue-button--not-allowed':
                      modalDisabled,
                  }
                )}
              >
                {labels.continue}
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

ProductAdditionalServicesModal.propTypes = {
  uiStore: modelOf(UIStore).isRequired,
  onAddToCart: PropTypes.func.isRequired,
  modalDisabled: PropTypes.bool.isRequired,
};

export default inject('uiStore')(ProductAdditionalServicesModal);
