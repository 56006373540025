import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AdZones from '../../../types/AdZones';
import BannerSlider from '../BannerSlider';

@observer
export class MainBanner extends Component {
  render() {
    const { className, searchParams, fullWidth } = this.props;

    return (
      <BannerSlider
        searchParams={{
          bannerZone: AdZones.MAIN_BANNER,
          ...searchParams
        }}
        className={classNames(
          'MainBanner',
          { 'MainBanner--container-width': !fullWidth },
          className
        )}
        aspectRatio={2.85}
        maximumCrossAxisSize={400}
      />
    );
  }
}

MainBanner.propTypes = {
  className: PropTypes.string,
  searchParams: PropTypes.object
};

export default inject((stores) => ({
  fullWidth: stores.configStore.mainBanner.fullWidth
}))(MainBanner);
