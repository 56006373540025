import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Helmet } from 'react-helmet-async';

import ConfigStore from '../../store/ConfigStore';
import AccountStore from '../../store/AccountStore';
import { modelOf } from '../../prop-types';
import FacebookEventHandler from './FacebookEventHandler';

@observer
class FacebookPixel extends Component {
  componentDidMount() {
    const { configStore } = this.props;
    if (configStore.integrations.facebookPixel.enabled) {
      new FacebookEventHandler();
    }
  }

  render() {
    const { configStore } = this.props;
    if (!configStore.integrations.facebookPixel.enabled) {
      return null;
    }

    return (
      <div>
        <Helmet>
          <script>
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[1];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${configStore.integrations.facebookPixel.id}');
            fbq('track', 'PageView');`}
          </script>
        </Helmet>
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            alt=""
            src={`https://www.facebook.com/tr?id=${configStore.integrations.facebookPixel.id}&ev=PageView&noscript=1`}
          />
        </noscript>
      </div>
    );
  }
}

FacebookPixel.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  accountStore: modelOf(AccountStore).isRequired,
};

export default inject('configStore', 'accountStore')(FacebookPixel);
