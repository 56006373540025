import { types } from 'mobx-state-tree';

import ProductPropertyElement from './ProductPropertyElement';
import { ProductPropertyTypeType } from '../types/ProductPropertyType';

const ProductProperty = types
  .model('ProductProperty', {
    id: types.number,
    name: types.maybeNull(types.string),
    type: ProductPropertyTypeType,
    placeholder: types.maybeNull(types.string),
    elements: types.array(ProductPropertyElement)
  })
  .views((self) => {
    return {
      getElementWithId(id) {
        return self.elements.find((element) => {
          return element.id === id;
        });
      },
      isEmpty() {
        return self.id === 0;
      }
    };
  });

export default ProductProperty;
