import React from 'react';

import NavigationEntity from '../../../types/NavigationEntity';
import NavigationEntityItemButton from '../NavigationEntityItemButton';
import NavigationEntityLink from '../NavigationEntityLink';
import NavigationEntityTextItem from '../NavigationEntityTextItem';

const NavigationEntityItem = ({ entity, ...rest }) => {
  if (!entity) {
    return null;
  }

  const { path, page_id } = entity;

  const getEntityComponent = () => {
    if (page_id) {
      return NavigationEntityItemButton;
    }

    if (path) {
      return NavigationEntityLink;
    }

    return NavigationEntityTextItem;
  };

  const Component = getEntityComponent();

  return <Component entity={entity} {...rest} />;
};

NavigationEntityItem.propTypes = {
  entity: NavigationEntity.isRequired,
};

export default NavigationEntityItem;
