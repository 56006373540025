import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { modelOf } from '../../../prop-types';
import CartStore from '../../../store/CartStore';
import Price from '../../product/Price';
import Icon from '../../common/Icon';
import MarkerBadge from '../../common/MarkerBadge';
import globalTranslations from '../../../i18n/globalTranslations';
import NavigationCartPopover from '../NavigationCartPopover';
import RequestState from '../../../types/RequestState';
import AccountStore from '../../../store/AccountStore';
import ConfigStore from '../../../store/ConfigStore';
import UIStore from '../../../store/UIStore';
import Analytics from '../../../analytics/Analytics';
import ProductAddedToCardModalType from '../../../types/ProductAddedToCartModalStyle';
import CampaignCodeStore from '../../../store/CampaignCodeStore';
import NavigationProposal from '../../proposal/NavigationProposal';
import SiteType from '../../../types/Site';

@observer
export class NavigationCart extends Component {
  constructor(props) {
    super(props);
    this.cartIsPopover = this.cartIsPopover();
    this.timer = null;
  }

  componentWillUnmount() {
    this.resetTimer();
  }

  resetTimer = () => {
    this.timer && clearTimeout(this.timer);
  };

  getPrice = () => {
    const { cartStore, withTax } = this.props;
    return cartStore.cart && cartStore.cart.total.getPrice(withTax);
  };

  getHidePriceOnMobile = () => this.getPrice() > 1000;

  renderPrice = () => {
    const price = this.getPrice();
    return price !== null ? <Price price={price} /> : <Price price={0} />;
  };

  renderCartText = () => {
    const { cartStore } = this.props;
    let text;

    switch (cartStore.state) {
      case RequestState.LOADED:
        if (!cartStore.cart || cartStore.cart.number_of_products === 0) {
          text = <FormattedMessage {...globalTranslations.cartEmptySentence} />;
        } else {
          text = (
            <span>
              <FormattedMessage
                {...globalTranslations.itemCount}
                values={{
                  count: cartStore.cart.number_of_products,
                }}
              />{' '}
              {this.renderPrice()}
            </span>
          );
        }
        break;
      case RequestState.LOADING:
      default:
        text = <FormattedMessage {...globalTranslations.loading} />;
        break;
    }

    return text;
  };

  sendAnalyticsCheckoutEvent = () => {
    const {
      accountStore,
      campaignCodeStore,
      cartStore,
      configStore,
      analytics,
    } = this.props;

    if (!configStore.gtm.enabled) {
      return;
    }

    let coupon;
    const activeCoupon = campaignCodeStore.activeCoupon;
    if (activeCoupon && !activeCoupon.hasProductLimitations()) {
      coupon = activeCoupon.code;
    }

    const withTax = accountStore.showPricesWithTax;
    const cartProducts = cartStore.cart.products;
    const actionField = { step: 1, option: 'Move To Checkout' };
    const value = cartStore.cart.getCartTotalValue(withTax);
    analytics.checkout(cartProducts, actionField, value, coupon);
  };

  cartIsPopover = () => {
    const { accountStore, configStore } = this.props;

    if (!accountStore.account) {
      return null;
    }

    const isRetailer = accountStore.account.is_retailer;
    const modalType = configStore.cart.type;

    return isRetailer || modalType === ProductAddedToCardModalType.POPOVER;
  };

  onClickHandler = () => {
    const { cartStore } = this.props;

    cartStore.toggleCartButton();

    this.timer = setTimeout(() => {
      cartStore.closeModal();
      cartStore.toggleCartButton();
    }, 1700);

    this.sendAnalyticsCheckoutEvent();
  };

  getButtonContent = () => {
    const { cartStore } = this.props;

    return cartStore.cartButtonDisabled ? (
      <FormattedMessage {...globalTranslations.loading} />
    ) : (
      <FormattedMessage {...globalTranslations.proceedToCheckoutSentence} />
    );
  };

  render() {
    const {
      accountStore,
      configStore,
      cartStore,
      className,
      intl,
      withCheckoutButton,
      wrapperClassName,
    } = this.props;

    const { checkout } = configStore;

    if (accountStore.isViewOnly) {
      return null;
    }

    return (
      <div className="NavigationCart__wrapper">
        <div className={classNames('NavigationCart', className)}>
          {configStore.siteConfig.siteType !== SiteType.OFFER_REQUEST_SITE && (
            <div
              className={classNames({
                'NavigationCart__cart-button-wrapper':
                  configStore.siteConfig.siteType !==
                  SiteType.WEB_STORE_WITH_OFFER_REQUESTS,
                'NavigationCart__combination-button-wrapper':
                  configStore.siteConfig.siteType ===
                  SiteType.WEB_STORE_WITH_OFFER_REQUESTS,
                wrapperClassName,
              })}
            >
              <Button
                block
                color="primary"
                onClick={() => cartStore.cartModal.toggle(true)}
                disabled={cartStore.state !== RequestState.LOADED}
              >
                <Icon name="shopping-cart" />
                <span
                  className={classNames('NavigationCart__cart-text', {
                    'NavigationCart__cart-text--hide-on-mobile':
                      this.getHidePriceOnMobile(),
                  })}
                >
                  <span className="NavigationCart__cart-text-desktop">
                    {this.renderCartText()}
                  </span>
                  <span className="NavigationCart__cart-text-mobile">
                    {this.renderPrice()}
                  </span>
                </span>
              </Button>
              {cartStore.cart && cartStore.cart.number_of_products > 0 && (
                <MarkerBadge className="d-xs-inline d-md-none">
                  {cartStore.cart.number_of_products}
                </MarkerBadge>
              )}
            </div>
          )}
          {withCheckoutButton && !configStore.siteConfig.proposalEnabled && (
            <div className="NavigationCart__checkout-button-wrapper">
              <Button
                block
                color="primary"
                tag="a"
                href={checkout.checkoutPath}
                rel="noopener"
                className="NavigationCart__checkout-button"
                onClick={this.onClickHandler}
                aria-label={intl.formatMessage(
                  globalTranslations.proceedToCheckoutSentence
                )}
                disabled={cartStore.cartButtonDisabled}
              >
                {this.getButtonContent()}
              </Button>
            </div>
          )}
          {configStore.siteConfig.proposalEnabled && (
            <NavigationProposal
              withCheckoutButton={
                configStore.siteConfig.siteType === SiteType.OFFER_REQUEST_SITE
              }
            />
          )}
          {this.cartIsPopover && <NavigationCartPopover />}
        </div>
      </div>
    );
  }
}

NavigationCart.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  campaignCodeStore: modelOf(CampaignCodeStore).isRequired,
  cartStore: modelOf(CartStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  intl: intlShape.isRequired,
  withTax: PropTypes.bool.isRequired,
  className: PropTypes.string,
  withCheckoutButton: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

NavigationCart.defaultProps = {
  withCheckoutButton: false,
  className: '',
};

export default injectIntl(
  inject((stores) => ({
    accountStore: stores.accountStore,
    cartStore: stores.cartStore,
    configStore: stores.configStore,
    campaignCodeStore: stores.campaignCodeStore,
    uiStore: stores.uiStore,
    analytics: stores.analytics,
    withTax: stores.accountStore.showPricesWithTax,
  }))(NavigationCart)
);
