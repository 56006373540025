import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { inject } from 'mobx-react';
import LinkType from '../../../types/LinkType';
import {
  transformInternalLink,
  transformExternalLink
} from '../../../util/url';

import {
  isNotHandledByReactRouter,
  stripHostIfMatches
} from '../../../util/url';

export class MaybeExternalLink extends Component {
  isExternal = () => {
    const { type } = this.props;
    return type && type === LinkType.EXTERNAL_LINK;
  };

  isInternal = () => {
    const { type } = this.props;
    return (
      type && (type === LinkType.PAGE_LINK || type === LinkType.INTERNAL_LINK)
    );
  };

  render() {
    const { host, to, className, active, children, type, ...rest } = this.props;

    const classNameString = classNames('MaybeExternalLink', className, {
      active
    });

    if (this.isExternal()) {
      return (
        <a
          target="_blank"
          rel="noopener"
          href={transformExternalLink(to)}
          className={classNameString}
          {...rest}
        >
          {this.props.children}
        </a>
      );
    }

    if (this.isInternal()) {
      return (
        <NavLink
          to={
            type === LinkType.INTERNAL_LINK
              ? transformInternalLink(to)
              : stripHostIfMatches(to, host)
          }
          exact={true}
          className={classNameString}
          isActive={(match) => (typeof active === 'boolean' ? active : match)}
          {...rest}
        >
          {children}
        </NavLink>
      );
    }

    const url = stripHostIfMatches(to, host);
    const isNormalLink = isNotHandledByReactRouter(url);

    return isNormalLink ? (
      <a href={url} className={classNameString} {...rest}>
        {this.props.children}
      </a>
    ) : (
      <NavLink
        to={url}
        exact={true}
        className={classNameString}
        isActive={(match) => (typeof active === 'boolean' ? active : match)}
        {...rest}
      >
        {children}
      </NavLink>
    );
  }
}

MaybeExternalLink.propTypes = {
  to: PropTypes.string.isRequired,
  active: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  host: PropTypes.string.isRequired
};

export default inject((stores) => ({
  host: stores.locationContext.host
}))(MaybeExternalLink);
