import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { Button } from 'reactstrap';

import MobileNavigationList from '../../navigation/MobileNavigationList';
import globalTranslations from '../../../i18n/globalTranslations';
import Icon from '../../common/Icon';
import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import RouteService from '../../../services/RouteService';
import ConfigStore from '../../../store/ConfigStore';
import Paths from '../../../types/Paths';
import MenuStore from '../../../store/MenuStore';
import SectionStore from '../../../store/SectionStore';
import CategoryStore from '../../../store/CategoryStore';
import CategoryBadges from '../../common/CategoryBadges';
import UIStore from '../../../store/UIStore';
import MobileNavigationTab from '../../../types/MobileNavigationTab';
import AccountModalTab from '../../../types/AccountModalTab';
import Analytics from '../../../analytics/Analytics';
import LinkType from '../../../types/LinkType';
import CustomNavigationLinkStore from '../../../store/CustomNavigationLinkStore';
import CustomNavigationLinkParentType from '../../../types/CustomNavigationLinkParentType';
import ClientLoginLink from '../../account/ClientLoginLink';
import Site from '../../../types/Site';

@observer
export class MobileNavigation extends Component {
  constructor(props) {
    super(props);

    if (props.configStore.account.isMoneyTransfer) {
      this.toggleTab(MobileNavigationTab.ACCOUNT);
    }
  }

  getProductTabItems = () => {
    const {
      configStore,
      routeService,
      sectionStore,
      categoryStore,
      accountStore,
    } = this.props;

    const staticItems = [];

    if (accountStore.isRetailer) {
      staticItems.push({
        id: 'CustomerProducts',
        label: (
          <FormattedMessage {...globalTranslations.customerProductsTitle} />
        ),
        path: routeService.getPath(Paths.CustomerProducts),
      });
    }

    if (configStore.navigation.sidebar.showOnSale) {
      staticItems.push({
        id: 'OnSale',
        label: <FormattedMessage {...globalTranslations.onSaleTitle} />,
        path: routeService.getPath(Paths.OnSale),
      });
    }

    if (configStore.navigation.sidebar.showNewProducts) {
      staticItems.push({
        id: 'NewProducts',
        label: <FormattedMessage {...globalTranslations.newProductsTitle} />,
        path: routeService.getPath(Paths.NewProducts),
      });
    }

    if (configStore.navigation.sidebar.showSoldProducts) {
      staticItems.push({
        id: 'SoldProducts',
        label: <FormattedMessage {...globalTranslations.soldProductsTitle} />,
        path: routeService.getPath(Paths.SoldProducts),
      });
    }

    if (configStore.navigation.sidebar.showMostSold) {
      staticItems.push({
        id: 'MostSold',
        label: <FormattedMessage {...globalTranslations.mostPopularTitle} />,
        path: routeService.getPath(Paths.PopularProducts),
      });
    }

    if (configStore.navigation.sidebar.showRecommended) {
      staticItems.push({
        id: 'Recommended',
        label: <FormattedMessage {...globalTranslations.weRecommendTitle} />,
        path: routeService.getPath(Paths.RecommendedProducts),
      });
    }

    staticItems.push(this.getSpacerEntity());

    staticItems.push({
      id: 'info',
      path: '#',
      label: (
        <span
          className="MobileNavigationList__sublabel"
          onClick={() => {
            this.toggleTab(MobileNavigationTab.INFO);
          }}
        >
          <FormattedMessage {...globalTranslations.infoTitle} />
          <Icon name="info-circle" />
        </span>
      ),
    });
    if (!configStore.siteConfig.isShoppingCenter) {
      staticItems.push({
        id: 'my-account',
        path: '#',
        label: (
          <span
            className="MobileNavigationList__sublabel"
            onClick={() => {
              this.toggleTab(MobileNavigationTab.ACCOUNT);
            }}
          >
            <FormattedMessage {...globalTranslations.myAccountTitle} />
            <Icon name="user" />
          </span>
        ),
      });
    }
    staticItems.push(this.getSpacerEntity());

    let sectionsOrTopLevelCategories = [];

    if (configStore.activateSections) {
      sectionsOrTopLevelCategories = sectionStore.activeSections.map(
        this.convertSectionToEntity
      );
    } else {
      sectionsOrTopLevelCategories = categoryStore.categories.map((category) =>
        this.convertCategoryToEntity(category, null)
      );
    }

    return sectionsOrTopLevelCategories.concat(staticItems);
  };

  convertSectionToEntity = (section) => {
    const { routeService, sectionStore } = this.props;

    return {
      id: section.id,
      label: section.navigationName,
      path: routeService.getPath(section.path),
      active: sectionStore.activeSection === section,
      children: section.mainCategories.map((category) =>
        this.convertCategoryToEntity(category, section)
      ),
      customNavLinks: this.getCustomNavigationLinks(
        section,
        CustomNavigationLinkParentType.SECTION
      ),
    };
  };

  convertCategoryToEntity = (category, section) => {
    const { routeService, categoryStore } = this.props;

    const children = section
      ? category.getNonBlockedChildren(section.blockedCategoriesDict)
      : category.children;

    return {
      id: category.id,
      label: (
        <span>
          {category.navigationName} <CategoryBadges category={category} />
        </span>
      ),
      path: routeService.getPath(category.path, section),
      active: categoryStore.isInActiveCategoryHierarchy(category),
      children: children.map((subcategory) =>
        this.convertCategoryToEntity(subcategory, section)
      ),
      customNavLinks: this.getCustomNavigationLinks(
        category,
        CustomNavigationLinkParentType.CATEGORY
      ),
    };
  };

  getCustomNavigationLinks = (categoryOrSection, type) => {
    const { customNavigationLinkStore } = this.props;

    if (type === CustomNavigationLinkParentType.CATEGORY) {
      return this.convertCustomNavigationLinksToEntityItems(
        customNavigationLinkStore.filterByCategoryId(categoryOrSection.id)
      );
    }

    if (type === CustomNavigationLinkParentType.SECTION) {
      return this.convertCustomNavigationLinksToEntityItems(
        customNavigationLinkStore.filterBySectionId(categoryOrSection.id)
      );
    }
  };

  convertCustomNavigationLinksToEntityItems = (
    topLevelCustomNavigationLinks
  ) => {
    const { customNavigationLinkStore } = this.props;
    return topLevelCustomNavigationLinks.map((customNavigationLink) => {
      return {
        id: customNavigationLink.id,
        label: customNavigationLink.name,
        path: this.getCategoryListPath(customNavigationLink),
        location: customNavigationLink.location,
        parent_sibling_id: customNavigationLink,
        customNavLinks: this.convertCustomNavigationLinksToEntityItems(
          customNavigationLinkStore.findChildLinks(customNavigationLink.id)
        ),
      };
    });
  };

  getCategoryListPath = (customNavLink) => {
    const { routeService, sectionStore } = this.props;

    if (customNavLink.link_type === LinkType.EXTERNAL_LINK) {
      return customNavLink.link;
    }

    if (customNavLink.link_type === LinkType.INTERNAL_LINK) {
      return routeService.getPath(customNavLink.link);
    }

    return routeService.getPath(customNavLink.link, sectionStore.activeSection);
  };

  getInfoTabItems = () => {
    const { configStore, menuStore } = this.props;

    const staticItems = [this.getSpacerEntity()];

    configStore.customerService.email.length > 0 &&
      staticItems.push({
        id: 'email',
        path: '#',
        label: (
          <span
            className="MobileNavigationList__sublabel"
            onClick={() => {
              document.location = `mailto:${configStore.customerService.email}`;
            }}
          >
            <FormattedMessage {...globalTranslations.infoSendEmail} />
            <Icon name="envelope-square" />
          </span>
        ),
      });

    configStore.customerService.phone.length > 0 &&
      staticItems.push({
        id: 'phone',
        label: (
          <span
            className="MobileNavigationList__sublabel"
            onClick={(e) => {
              e.stopPropagation();
              document.location = `tel:${configStore.customerService.phone}`;
            }}
          >
            <div className="MobileNavigationList__text-container d-flex flex-column">
              <FormattedMessage
                {...globalTranslations.infoCallPhone}
                values={{ phone: configStore.customerService.phone }}
              />
              <FormattedMessage
                {...globalTranslations.isOpen}
                values={{
                  timespan: configStore.customerService.open,
                }}
              />
            </div>
            <span className="MobileNavigationList__sublabel-placer">
              <span className="fa-stack fa-stack-small">
                <Icon name="square" className="fa-stack-1x" />
                <Icon name="phone" className="fa-stack-3qx fa-inverse" />
              </span>
            </span>
          </span>
        ),
      });

    return menuStore.menus
      .map(this.convertMenuToNavigationEntity)
      .concat(staticItems);
  };

  getSpacerEntity = () => {
    return {
      id: 'Spacer' + uniqueId(),
      label: '',
    };
  };

  convertMenuToNavigationEntity = (menu) => {
    return {
      id: `info${menu.id}`,
      label:
        typeof menu.title === 'string' ? (
          <div dangerouslySetInnerHTML={{ __html: menu.title }} />
        ) : menu.title !== null ? (
          menu.title
        ) : (
          ''
        ),
      children: menu.children.map(this.convertMenuToNavigationEntity),
      path: menu.url,
      image: menu.image,
      type: menu.type,
    };
  };

  getAccountTabItems = () => {
    const { accountStore, configStore, routeService } = this.props;
    const { accountModal } = accountStore;
    const tabItems = [];
    if (accountStore.loggedIn) {
      return this.getLoggedInTabItems();
    } else {
      if (
        configStore.siteConfig.siteType === Site.WEB_STORE ||
        configStore.account.isMoneyTransfer
      ) {
        tabItems.push({
          id: 'login',
          path: '#',
          label: (
            <span
              className="MobileNavigationList__sublabel"
              onClick={() => {
                accountModal.setTab(AccountModalTab.LOGIN);
                accountModal.toggle();
              }}
            >
              <FormattedMessage {...globalTranslations.logInSentence} />
              <Icon name="sign-in" />
            </span>
          ),
        });
      }
      if (
        configStore.registration.enabled &&
        (configStore.siteConfig.siteType === Site.WEB_STORE ||
          configStore.account.isMoneyTransfer)
      ) {
        tabItems.push({
          id: 'register',
          path: '#',
          label: (
            <span
              className="MobileNavigationList__sublabel"
              onClick={() => {
                accountModal.setTab(AccountModalTab.REGISTER);
                accountModal.toggle();
              }}
            >
              <FormattedMessage {...globalTranslations.registerTitle} />
            </span>
          ),
        });
      }
      if (
        configStore.wishlist.enabled &&
        !configStore.account.isMoneyTransfer
      ) {
        tabItems.push({
          id: 'wishlist',
          path: routeService.getPath(Paths.WishList),
          label: <FormattedMessage {...globalTranslations.wishListTitle} />,
        });
      }

      return tabItems;
    }
  };

  getLoggedInTabItems = () => {
    const { routeService, configStore, analytics, accountStore } = this.props;
    let accountPath = accountStore.isBalanceBuyer
      ? Paths.MyAccountBalanceUser
      : Paths.MyAccount;
    if (accountStore.isBalanceUser) {
      accountPath = Paths.MyAccountBalanceSubUser;
    }

    const tabItems = [];

    if (configStore.account.enableMyAccount) {
      tabItems.push({
        id: 'customerAccount',
        path: routeService.getPath(accountPath),
        label: (
          <FormattedMessage {...globalTranslations.myCustomerAccountTitle} />
        ),
      });
    }

    if (!accountStore.isBalanceUser) {
      if (configStore.account.enableMyAccount) {
        const accountType = accountStore.isBalanceBuyer
          ? this.getBalanceUserAccount()
          : this.getCustomerUserOrderHistory();
        tabItems.push(accountType);
      }

      if (configStore.wishlist.enabled) {
        tabItems.push({
          id: 'wishlist',
          path: routeService.getPath(Paths.WishList),
          label: <FormattedMessage {...globalTranslations.wishListTitle} />,
        });
      }
    }

    if (accountStore.isClientLoginEnabled) {
      const label = (
        <Fragment>
          <ClientLoginLink />
          {accountStore.isClient && (
            <span className="MobileNavigation--client-login">
              ({accountStore.name})
            </span>
          )}
        </Fragment>
      );
      tabItems.push({
        id: 'clientLogin',
        path: '#',
        label: label,
      });
    }

    tabItems.push({
      id: 'logout',
      path: '#',
      label: (
        <span
          className="MobileNavigationList__sublabel"
          onClick={() => {
            analytics.clearCart();
            accountStore.logout();
          }}
        >
          <FormattedMessage {...globalTranslations.accountLogOutSentence} />
          <Icon name="sign-out" />
        </span>
      ),
    });

    return tabItems;
  };

  getBalanceUserAccount = () => {
    const { routeService } = this.props;
    return {
      id: 'balanceUsers',
      path: routeService.getPath(Paths.MyAccountBalanceUserPaymentHistory),
      label: (
        <FormattedMessage
          {...globalTranslations.balanceUserAccountBalanceInfo}
        />
      ),
    };
  };

  getCustomerUserOrderHistory = () => {
    const { routeService } = this.props;
    return {
      id: 'orderHistory',
      path: routeService.getPath(Paths.MyAccountOrderList),
      label: <FormattedMessage {...globalTranslations.myOrderHistoryTitle} />,
    };
  };

  getEntityTree = () => {
    const { shouldHideProductContent, configStore } = this.props;
    const tree = [];
    const accountTabItems = this.getAccountTabItems();

    if (!shouldHideProductContent) {
      tree.push({
        id: MobileNavigationTab.PRODUCTS,
        label: <FormattedMessage {...globalTranslations.productsTitle} />,
        children: this.getProductTabItems(),
      });
    }

    tree.push({
      id: MobileNavigationTab.INFO,
      label: <FormattedMessage {...globalTranslations.infoTitle} />,
      children: this.getInfoTabItems(),
    });
    if (
      accountTabItems.length > 0 &&
      !configStore.siteConfig.isShoppingCenter
    ) {
      tree.push({
        id: MobileNavigationTab.ACCOUNT,
        label: <FormattedMessage {...globalTranslations.myAccountTitle} />,
        children: accountTabItems,
      });
    }

    return tree;
  };

  toggleTab = (tabId) => {
    const { mobileNavigation } = this.props.uiStore;
    mobileNavigation.selectTab(tabId);
  };

  render() {
    const { configStore } = this.props;
    const { mobileNavigation } = this.props.uiStore;
    const tree = this.getEntityTree();
    return (
      <div className="MobileNavigation">
        <div className="MobileNavigation__tabs">
          {tree.map((tab) => (
            <Button
              key={tab.id}
              className={classNames('MobileNavigation__tab', {
                'MobileNavigation__tab--active':
                  tab.id === mobileNavigation.activeTab,
              })}
              color="plain"
              onClick={() => this.toggleTab(tab.id)}
            >
              {tab.label}
            </Button>
          ))}
        </div>
        <MobileNavigationList
          activeTabId={mobileNavigation.activeTab}
          entities={tree}
          close={mobileNavigation.close}
          hasSections={!!configStore.activateSections}
        />
      </div>
    );
  }
}

MobileNavigation.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  menuStore: modelOf(MenuStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  categoryStore: modelOf(CategoryStore).isRequired,
  customNavigationLinkStore: modelOf(CustomNavigationLinkStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  shouldHideProductContent: PropTypes.bool,
};

export default inject(
  'accountStore',
  'configStore',
  'menuStore',
  'sectionStore',
  'categoryStore',
  'routeService',
  'uiStore',
  'customNavigationLinkStore',
  'analytics'
)(MobileNavigation);
