import { types } from 'mobx-state-tree';

const CustomerUser = types.model('CustomerUser', {
  id: types.number,
  firstname: types.string,
  lastname: types.string,
  email: types.string,
  telephone: types.maybeNull(types.string),
  login_name: types.string,
  is_active: types.boolean,
  seller_id: types.number,
  allow_address_modifications: types.boolean
});

export default CustomerUser;
