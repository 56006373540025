import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button } from 'reactstrap';

import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import CartStore from '../../../store/CartStore';
import Analytics from '../../../analytics/Analytics';
import AccountStore from '../../../store/AccountStore';
import ProductCollectionMatrix from '../ProductCollectionMatrix';
import ProductClass from '../../../types/ProductClass';
import ProductMultiMatrix from '../ProductMultiMatrix';
import RequestState from '../../../types/RequestState';
import Icon from '../../common/Icon';
import globalTranslations from '../../../i18n/globalTranslations';
import { PRODUCT_INFORMATION_BANNER_ID } from '../ProductPageContent';
import ScrollableAnchor from '../../anchor/ScrollableAnchor';
import CurrencyStore from '../../../store/CurrencyStore';
import { calculateTotal, roundWithPrecision } from '../../../util/number';

@observer
export class ProductMatrix extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProducts: [],
      analyticsProducts: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.product.id !== this.props.product.id) {
      this.setState({
        selectedProducts: [],
        analyticsProducts: [],
      });
    }
  }

  handleProductQuantity = (newProduct) => {
    const { accountStore, product, activeProductId } = this.props;
    const { selectedProducts } = this.state;
    const newSelectedProducts = selectedProducts;
    const withTax = accountStore.showPricesWithTax;
    const currentProductIndex = selectedProducts.findIndex(
      (selectedProduct) => selectedProduct.id === newProduct.id
    );

    if (currentProductIndex >= 0) {
      if (Number(newProduct.qty) === 0) {
        newSelectedProducts.splice(currentProductIndex, 1);
      } else {
        newSelectedProducts[currentProductIndex].qty = newProduct.qty;
      }
    } else {
      newSelectedProducts.push(newProduct);
    }

    const analyticsProducts = newSelectedProducts.map((selectedProduct) => {
      const analyticsProductId = activeProductId || selectedProduct.id;

      return {
        product,
        quantity: selectedProduct.qty,
        activeProductId: analyticsProductId,
        price: product.getPrice(withTax, analyticsProductId),
      };
    });

    this.setState({
      selectedProducts: newSelectedProducts,
      analyticsProducts,
    });
  };

  populateCart = () => {
    const { analytics, cartStore, accountStore } = this.props;
    const { selectedProducts, analyticsProducts } = this.state;

    const productsValid = selectedProducts && selectedProducts.length > 0;
    const analyticsValid = analyticsProducts && analyticsProducts.length > 0;

    productsValid &&
      cartStore
        .populate(selectedProducts, accountStore.showMultiProductMatrix)
        .then(() => {
          if (analyticsValid) {
            const totalValue = this.calculateTotalAnalyticsValue();
            analytics.addToCart(analyticsProducts, totalValue);
            analytics.cartStatus({
              cart: cartStore.cart,
            });
          }
        });
  };

  calculateTotalAnalyticsValue = () => {
    const { currencyStore } = this.props;
    const { analyticsProducts } = this.state;
    const precision = currencyStore.activeCurrency.precision;
    return roundWithPrecision(
      calculateTotal(
        analyticsProducts.map((product) => product.price * product.quantity),
        precision
      ),
      precision
    );
  };

  renderAddToCartButton = (classname) => {
    const { cartStore } = this.props;
    const loading = cartStore.state === RequestState.LOADING;

    return (
      <Button
        className={classname}
        onClick={this.populateCart}
        color="primary"
        disabled={this.state.formError || loading}
        block
      >
        {loading ? (
          <Icon name="circle-o-notch" spin={loading} />
        ) : (
          <FormattedMessage {...globalTranslations.addToCartButton} />
        )}
      </Button>
    );
  };

  renderAnchor = () => (
    <ScrollableAnchor
      id={PRODUCT_INFORMATION_BANNER_ID}
      scrollIfInHash={false}
    />
  );

  renderCollectionMatrix = () => {
    const { product, activeProductId } = this.props;
    const collection = product.collection;

    if (
      !collection ||
      (!collection.column && !collection.row) ||
      (!collection.column.elements && !collection.row.elements)
    ) {
      return null;
    }

    return (
      <Fragment>
        {this.renderAnchor()}
        <ProductCollectionMatrix
          product={product}
          productMatrix={product.collection}
          activeProductId={activeProductId}
        />
      </Fragment>
    );
  };

  renderMultiMatrix = () => {
    const { product } = this.props;

    return (
      <Fragment>
        {this.renderAnchor()}
        <div className="ProductMatrix__info">
          <FormattedMessage {...globalTranslations.productMatrixInfo} />
          <div className="ProductMatrix__info-help-no">
            <Icon name="ban" />
            {' = '}
            <FormattedMessage
              id="matrix.noProductHelp"
              defaultMessage="Variation does not exist."
            />
          </div>
        </div>
        {this.renderAddToCartButton('ProductMatrix__add-to-cart-top')}
        <ProductMultiMatrix
          handleProductQuantity={this.handleProductQuantity}
          multi={product.multi}
        />
        {this.renderAddToCartButton('ProductMatrix__add-to-cart-bottom')}
      </Fragment>
    );
  };

  render() {
    const { product } = this.props;

    if (product.class === ProductClass.COLLECTION) {
      return this.renderCollectionMatrix();
    } else if (product.class === ProductClass.MULTI) {
      return this.renderMultiMatrix();
    }
    return null;
  }
}

ProductMatrix.propTypes = {
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  accountStore: modelOf(AccountStore).isRequired,
  cartStore: modelOf(CartStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  product: modelOf(Product).isRequired,
  activeProductId: PropTypes.string,
};

export default injectIntl(
  inject(
    'analytics',
    'accountStore',
    'cartStore',
    'currencyStore'
  )(ProductMatrix)
);
