import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import Field from '../../form/Field';
import Icon from '../../common/Icon';
import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import FormField from '../../../models/FormField';
import RequestState from '../../../types/RequestState';
import CategoryStore from '../../../store/CategoryStore';

const labels = defineMessages({
  postalCode: {
    id: 'addToCartPostalCodeValidation.checkPostalCodeInfo',
    defaultMessage:
      'Search the availabilities of the services with your postal code',
  },
  postalCodePlaceholder: {
    id: 'addToCartPostalCodeValidation.postalCodePlaceholder',
    defaultMessage: 'Enter your postal code',
  },
});

@observer
export class AddToCartPostalCodeValidation extends Component {
  handleChange = (event) => {
    const { postalCodeField, handleChange } = this.props;
    const postalCode = event.target.value;
    postalCodeField.setValue(postalCode);
    handleChange();
  };

  getPostalCodeValidationState = () => {
    const { product, postalCodeField } = this.props;

    if (!postalCodeField || !postalCodeField.value) {
      return { loading: false, ifValidatedService: false };
    }

    const serviceIsLoading =
      product.validatedServicesState.get(
        `${product.id}--${postalCodeField.value}`
      ) === RequestState.LOADING;

    const ifValidatedService = product.ifValidatedServicesExists({
      postalCode: postalCodeField.value,
    });

    const validServices = product.ifValidServices({
      productId: product.id,
      postalCode: postalCodeField.value,
    });

    return { serviceIsLoading, ifValidatedService, validServices };
  };

  renderValidateButton = () => {
    const { ifValidForm, postalCodeField } = this.props;

    const buttonStates = {
      initial: 'primary',
      valid: 'success',
      invalid: 'danger',
    };

    let buttonState = 'initial';

    const { serviceIsLoading, ifValidatedService, validServices } =
      this.getPostalCodeValidationState();

    const buttonDisabled =
      serviceIsLoading ||
      ifValidForm === false ||
      ifValidatedService ||
      !postalCodeField.value;

    if (validServices === false) {
      buttonState = 'invalid';
    }

    if (validServices) {
      buttonState = 'valid';
    }

    return (
      <div className="AddToCartPostalCodeValidation__additional-services-postal-code-form-validate-button">
        <Button color={buttonStates[buttonState]} disabled={buttonDisabled}>
          {this.getCheckButtonContent()}
        </Button>
      </div>
    );
  };

  getCheckButtonContent = () => {
    const { serviceIsLoading, validServices } =
      this.getPostalCodeValidationState();

    if (serviceIsLoading) {
      return <Icon name="circle-o-notch" spin={serviceIsLoading} />;
    }

    if (validServices === false) {
      return (
        <Fragment>
          <Icon name="times" />
          <FormattedMessage {...globalTranslations.notFoundTitle} />
        </Fragment>
      );
    }

    if (validServices) {
      return (
        <Fragment>
          <Icon name="check" />
          <FormattedMessage {...globalTranslations.checkedTitle} />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Icon name="search" />
        <FormattedMessage {...globalTranslations.checkTitle} />
      </Fragment>
    );
  };

  render() {
    const { product, intl, postalCodeField, handleSubmit } = this.props;

    if (!product.ifServiceRequiresPostalCodeValidation()) {
      return null;
    }

    return (
      <Form
        className="AddToCartPostalCodeValidation"
        noValidate
        onSubmit={handleSubmit}
      >
        <FormGroup className="AddToCartPostalCodeValidation__additional-services-postal-code-form">
          <div className="AddToCartPostalCodeValidation__additional-services-postal-code-form-label">
            <Label for="AddToCartForm__postalCode">
              {intl.formatMessage(labels.postalCode)}
            </Label>
          </div>
          <div className="AddToCartPostalCodeValidation__additional-services-postal-code-form-input-row">
            <div className="AddToCartPostalCodeValidation__additional-services-postal-code-form-input">
              <Field
                handleChange={this.handleChange}
                component={Input}
                field={postalCodeField}
                type="text"
                name="postalCode"
                id="AddToCartForm__postalCode"
                placeholder={intl.formatMessage(labels.postalCodePlaceholder)}
              />
            </div>
            {this.renderValidateButton()}
          </div>
        </FormGroup>
      </Form>
    );
  }
}

AddToCartPostalCodeValidation.propTypes = {
  categoryStore: modelOf(CategoryStore).isRequired,
  product: modelOf(Product).isRequired,
  postalCodeField: modelOf(FormField).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  ifValidForm: PropTypes.bool,
};

export default inject('categoryStore')(
  injectIntl(AddToCartPostalCodeValidation)
);
