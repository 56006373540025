import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import cookies from 'js-cookie';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { isEqual } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import Analytics from '../../../analytics/Analytics';
import { paramsToQueryIdentifier } from '../../../util/query';
import AdStore from '../../../store/AdStore';
import globalTranslations from '../../../i18n/globalTranslations';
import AdZones from '../../../types/AdZones';

const POPUP_COOKIE_NAME = 'nhcontentpop';

@observer
export class ContentPopup extends Component {
  state = {
    seenAds: [],
  };

  componentDidMount() {
    this.checkSeenAds();
    this.loadAds();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.searchParams, this.props.searchParams)) {
      this.loadAds();
    }
  }

  checkSeenAds = () => {
    const seenAds = cookies.getJSON(POPUP_COOKIE_NAME) || [];

    this.setState({
      seenAds,
    });
  };

  loadAds = () => {
    const { adStore } = this.props;
    adStore.loadAds(this.getSearchParams());
  };

  getSearchParams = () => {
    const { searchParams } = this.props;

    return {
      ...(searchParams || {}),
      bannerZone: AdZones.POPUP_CONTENT,
    };
  };

  sendView = (ad) => {
    const { analytics } = this.props;

    if (!ad) {
      return;
    }

    analytics.promoView([
      {
        bannerZone: AdZones.POPUP_CONTENT,
        banner: ad,
      },
    ]);
  };

  getUnseenAds = () => {
    const { adStore } = this.props;

    const ads = adStore.adQueries.get(
      paramsToQueryIdentifier(this.getSearchParams())
    );

    if (!ads) {
      return [];
    }

    return ads.filter((ad) => this.state.seenAds.indexOf(ad.id) === -1);
  };

  setAdSeen = (ad) => {
    const seenAds = this.state.seenAds.concat([ad.id]);

    cookies.set(POPUP_COOKIE_NAME, seenAds);
    this.setState({
      seenAds,
    });

    this.sendView();
  };

  render() {
    const { className } = this.props;

    const unseenAds = this.getUnseenAds();
    const firstAd = unseenAds[0];

    if (!firstAd || window.isSSR) {
      return null;
    }

    const toggle = () => {
      this.setAdSeen(firstAd);
    };

    return (
      <Modal
        className={classNames(
          className,
          'ContentPopup',
          `ContentPopup_${firstAd.id}`
        )}
        size="lg"
        toggle={toggle}
        isOpen={true}
        backdrop="static"
      >
        <ModalHeader toggle={toggle} />
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: firstAd.content }} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            <FormattedMessage {...globalTranslations.closeTitle} />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

ContentPopup.propTypes = {
  adStore: modelOf(AdStore).isRequired,
  searchParams: PropTypes.object,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  className: PropTypes.string,
};

ContentPopup.defaultProps = {
  searchParams: {},
};

export default inject('analytics', 'adStore')(ContentPopup);
