import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Button, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Page from '../../components/layout/Page';
import GenericLayout from '../../components/layout/GenericLayout';
import SEOTitle from '../../components/head/SEOTitle';
import ScrollReset from '../../components/common/ScrollReset';
import Icon from '../../components/common/Icon';
import Paths from '../../types/Paths';
import RouteService from '../../services/RouteService';
import RobotsMeta from '../../components/head/RobotsMeta';
import AdZones from '../../types/AdZones';
import SectionStore from '../../store/SectionStore';
import { modelOf } from '../../prop-types';
import AdStore from '../../store/AdStore';
import ContentForState from '../../components/loader/ContentForState';
import RequestState from '../../types/RequestState';
import { paramsToQueryIdentifier } from '../../util/query';
import WysiwygContent from '../../components/common/WysiwygContent';
import Analytics from '../../analytics/Analytics';
import CommonPage from '../../types/CommonPage';

const messages = defineMessages({
  notFoundTitle: {
    id: 'error.notFoundTitle',
    defaultMessage: 'Page not found'
  }
});

@observer
export class NotFoundPage extends Component {
  constructor(props) {
    super(props);

    const { staticContext } = props;
    this.searchParams = {
      bannerZone: AdZones.NOT_FOUND_CONTENT
    };

    if (staticContext) {
      staticContext.is404 = true;
    }
  }

  componentDidMount() {
    this.loadAds();
    this.sendAnalyticsEvent();
  }

  loadAds = () => {
    const { adStore } = this.props;
    adStore.loadAds(this.searchParams);
  };

  sendAnalyticsEvent = () => {
    this.props.analytics.pageNotFound({ location: window.location.href, referrer: document.referrer });
  };

  renderDefaultPageContent = () => {
    const { adStore } = this.props;
    const adQueryState = adStore.getAdQueryStateBySearchParameters(
      this.searchParams
    );
    const ifLoading = adQueryState === RequestState.LOADING;

    const bannerContent = adStore.getAdsBySearchParameters(this.searchParams);

    if ((bannerContent && bannerContent.length > 0) || ifLoading) {
      return null;
    }

    return (
      <p className="NotFoundPage__help-text">
        <FormattedMessage
          id="error.notFoundHelpText"
          defaultMessage="If it still exists, its address might have changed. You can continue browsing the web store or attempt to find what you were looking by using the search function."
        />
      </p>
    );
  };

  getContent = () => {
    const { adStore } = this.props;
    const ads = adStore.getAdsBySearchParameters(this.searchParams);

    if (!ads || ads.length === 0) {
      return null;
    }

    return ads.map((ad) => <WysiwygContent key={ad.id} html={ad.content} />);
  };

  renderNavigationButtons = () => (
    <Fragment>
      <Button
        className="NotFoundPage__back-to-home-button"
        tag={Link}
        to={this.props.routeService.getPath(Paths.FrontPage)}
        color="primary"
      >
        <Icon name="home" />{' '}
        <FormattedMessage
          id="error.notFoundBackToHome"
          defaultMessage="Return to the front page"
        />
      </Button>
      <FormattedMessage
        id="error.notFoundGoToSearch"
        defaultMessage="or use the product search."
      />
    </Fragment>
  );

  render() {
    const { adStore } = this.props;
    const adQueryState = adStore.adQueryStates.get(
      paramsToQueryIdentifier(this.searchParams)
    );

    return (
      <Page name={CommonPage.NOT_FOUND} className="NotFoundPage">
        <ScrollReset />
        <SEOTitle
          title={{
            ...messages.notFoundTitle
          }}
        />
        <RobotsMeta noindex />
        <Container className="NotFoundPage__container">
          <GenericLayout
            content={
              <Row className="NotFoundPage__content">
                <Col
                  xs="12"
                  md={{ size: 10, offset: 1 }}
                  xl={{ size: 8, offset: 2 }}
                >
                  <h1>
                    <FormattedMessage
                      id="error.notFoundHeader"
                      defaultMessage="Unfortunately the page you requested could not be found anymore."
                    />
                  </h1>
                  <div className="NotFoundPage__text-container">
                    <ContentForState
                      state={adQueryState}
                      error={adStore.adQueryError}
                      forLoaded={this.getContent}
                    />
                    {this.renderDefaultPageContent()}
                  </div>
                  {this.renderNavigationButtons()}
                </Col>
              </Row>
            }
          />
        </Container>
      </Page>
    );
  }
}

NotFoundPage.propTypes = {
  adStore: modelOf(AdStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  staticContext: PropTypes.object
};

export default inject('adStore', 'analytics', 'sectionStore', 'routeService')(
  withRouter(NotFoundPage)
);
