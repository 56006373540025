import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

import Icon from '../../common/Icon';
import ProductSizeGuideModal from '../ProductSizeGuideModal';
import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';

@observer
export class ProductSizeGuides extends Component {
  state = {
    modal: false
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  render() {
    const { sizeGuides } = this.props.product;

    if (sizeGuides.length === 0) {
      return null;
    }

    return (
      <div className="ProductSizeGuides">
        {sizeGuides.map((guide, index) => {
          return (
            <Fragment key={guide.name + index}>
              <ProductSizeGuideModal
                isOpen={this.state.modal}
                toggle={this.toggleModal}
                guide={guide}
              />
              <Button
                color="secondary"
                className="ProductSizeGuides__modal-button"
                onClick={this.toggleModal}
              >
                <Icon name="info-circle" /> {guide.name}
              </Button>
            </Fragment>
          );
        })}
      </div>
    );
  }
}

ProductSizeGuides.propTypes = {
  product: PropTypes.oneOfType([modelOf(Product), PropTypes.object]).isRequired
};

export default ProductSizeGuides;
