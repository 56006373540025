import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ProductAvailabilityText extends Component {
  render() {
    let { availabilityHtml } = this.props;
    return (
      <span
        className="ProductAvailabilityText"
        dangerouslySetInnerHTML={{ __html: availabilityHtml }}
      />
    );
  }
}

ProductAvailabilityText.propTypes = {
  availabilityHtml: PropTypes.string.isRequired
};

export default ProductAvailabilityText;
