import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import ProductAvailabilityText from '../ProductAvailabilityText';
import { AVAILABILITY_TAB_ID, SHIPPING_TAB_ID } from '../ProductPageContent';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import Product from '../../../models/Product';
import RequestState from '../../../types/RequestState';
import ProductAvailabilityType from '../../../types/ProductAvailabilityType';
import ButtonLink from '../../common/ButtonLink';
import ProductClass from '../../../types/ProductClass';
import ProductAvailabilityShipping from '../ProductAvailabilityShipping';

@observer
export class ProductAvailabilityList extends Component {
  scrollToTab = (tabId) => {
    const { scrollToTab } = this.props;
    if (scrollToTab) {
      scrollToTab(tabId, true);
    }
  };

  scrollToAvailability = (e) => {
    e.stopPropagation();
    this.props.onBeforeScroll();
    this.scrollToTab(AVAILABILITY_TAB_ID);
  };

  scrollToShipping = (e) => {
    e.stopPropagation();
    this.props.onBeforeScroll();
    this.scrollToTab(SHIPPING_TAB_ID);
  };

  getStoreAvailability() {
    const { product, activeProduct, configStore } = this.props;

    if (!configStore.product.showStoreAvailability) {
      return null;
    }

    let availabilityText;
    switch (product.stockStates.get(activeProduct.id)) {
      case RequestState.LOADING:
        availabilityText = (
          <FormattedMessage
            id="stock.loadingStoreAvailability"
            defaultMessage="Loading store availability..."
          />
        );
        break;
      case RequestState.LOADED:
        const stocks = product.stocks.get(activeProduct.id);
        const hasMultipleStores = stocks.length > 1;
        const available = stocks.some((stock) => stock.free_quantity > 0);
        if (available) {
          const availabilityMessage = (
            <ButtonLink
              className="ProductAvailabilityList__store-availability-message"
              onClick={this.scrollToAvailability}
            >
              <FormattedMessage
                id="stock.availableInStores"
                defaultMessage="Check store availability here"
              />
            </ButtonLink>
          );
          availabilityText = (
            <span className="ProductAvailabilityList__store-availability-text">
              {availabilityMessage}
            </span>
          );
        } else {
          const availabilityMessage = hasMultipleStores ? (
            <FormattedMessage
              id="stock.storesOutOfStock"
              defaultMessage="Stores out of stock"
            />
          ) : (
            <FormattedMessage
              id="stock.storeOutOfStock"
              defaultMessage="Store out of stock"
            />
          );
          availabilityText = <span>{availabilityMessage}</span>;
        }
        break;
      case RequestState.ERROR:
        break;
      case RequestState.NONE:
      default:
        availabilityText = '';
        break;
    }

    return (
      <li className="ProductAvailabilityList__store-availability">
        {availabilityText}
      </li>
    );
  }

  render() {
    const {
      configStore,
      product,
      activeProduct,
      shipping,
      onlineAvailability,
      storeAvailability,
    } = this.props;

    const onlyInShop =
      activeProduct.availability_type === ProductAvailabilityType.ONLY_IN_SHOP;
    const shippingProduct =
      product.class === ProductClass.COLLECTION ? product : activeProduct;

    return (
      <ul className="ProductAvailabilityList">
        {!onlyInShop &&
          onlineAvailability &&
          configStore.visibility.availability &&
          activeProduct.availability_html && (
            <li className="ProductAvailabilityList__availability">
              <ProductAvailabilityText
                availabilityHtml={activeProduct.availability_html}
              />
            </li>
          )}
        {storeAvailability && this.getStoreAvailability()}
        {!onlyInShop && shipping && (
          <ProductAvailabilityShipping
            activeProductId={activeProduct.id}
            product={shippingProduct}
            scrollToShipping={this.scrollToShipping}
          />
        )}
      </ul>
    );
  }
}

ProductAvailabilityList.propTypes = {
  scrollToTab: PropTypes.func.isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  product: modelOf(Product).isRequired,
  activeProduct: PropTypes.shape({
    id: PropTypes.string.isRequired,
    availability_html: PropTypes.string,
  }).isRequired,
  shipping: PropTypes.bool,
  onlineAvailability: PropTypes.bool,
  storeAvailability: PropTypes.bool,
  onBeforeScroll: PropTypes.func,
};

ProductAvailabilityList.defaultProps = {
  onlineAvailability: true,
  storeAvailability: true,
  onBeforeScroll: () => {},
};

export default inject('configStore')(ProductAvailabilityList);
