import React, { Component } from 'react';
import {
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Input,
} from 'reactstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react';

import Field from '../../form/Field';
import { modelOf } from '../../../prop-types';
import FormField from '../../../models/FormField';
import globalTranslations from '../../../i18n/globalTranslations';
import Icon from '../Icon';
import { roundWithPrecision } from '../../../util/number';

const QUANTITY_FIELD_GROUP_UNIQUE_ID = 'QuantityFieldGroup--';

@observer
export class QuantityFieldGroup extends Component {
  uniqueId = uniqueId(QUANTITY_FIELD_GROUP_UNIQUE_ID);

  constructor(props) {
    super(props);
    this.initializeValue();
    this.mathFloor = Math.floor;
  }

  initializeValue = () => {
    const { field, defaultValue } = this.props;
    const step = this.getStep();
    let newValue;

    if (step) {
      newValue = step;
    }

    if (defaultValue || defaultValue === 0) {
      newValue = defaultValue;
    }

    field.setValue(String(newValue));
  };

  getStep = () => {
    const { quantityStep, incrementStep } = this.props;
    return incrementStep || quantityStep;
  };

  incrementQuantity = () => {
    const { maxQuantity, field } = this.props;
    const factor = 100;
    const step = this.getStep();
    const precicionValue = roundWithPrecision(
      roundWithPrecision(Number(field.value), 2) * factor,
      0
    );
    const precicionStep = roundWithPrecision(
      roundWithPrecision(step, 2) * factor,
      0
    );
    const newValue =
      (this.mathFloor((precicionValue + precicionStep) / precicionStep) *
        precicionStep) /
      factor;

    if (newValue <= maxQuantity) {
      field.setValue(String(newValue));
    }
    this.updateProductQuantity(Number(newValue));
  };

  decrementQuantity = () => {
    const { maxQuantity, field, balanceUserAccount } = this.props;
    const factor = 100;
    const step = this.getStep();
    const precicionValue = roundWithPrecision(
      roundWithPrecision(Number(field.value), 2) * factor,
      0
    );
    const precicionStep = roundWithPrecision(
      roundWithPrecision(step, 2) * factor,
      0
    );

    const precicionMax = roundWithPrecision(
      roundWithPrecision(maxQuantity, 2) * factor,
      0
    );

    let newValue;

    if (
      precicionValue < precicionStep ||
      precicionValue - precicionStep < precicionStep
    ) {
      newValue = precicionStep;
    } else if (precicionValue > precicionMax) {
      newValue = precicionMax;
    } else if (
      precicionValue > precicionStep &&
      precicionValue % precicionStep
    ) {
      const nextIncrementalValue =
        this.mathFloor((precicionValue + precicionStep) / precicionStep) *
        precicionStep;
      const nextIncrementalValueStep =
        nextIncrementalValue -
        this.mathFloor((precicionValue - precicionStep) / precicionStep) *
          precicionStep;
      newValue = nextIncrementalValue - nextIncrementalValueStep / 2;
    } else {
      newValue = precicionValue - precicionStep;
    }

    if (balanceUserAccount) {
      newValue = this.countBalanceUserBalance(
        precicionValue,
        precicionStep,
        newValue
      );
    }

    const roundedWithPrecisionValue = String(
      roundWithPrecision(newValue / factor, 2)
    );
    field.setValue(roundedWithPrecisionValue);
    this.updateProductQuantity(roundedWithPrecisionValue);
  };

  countBalanceUserBalance(precicionValue, precicionStep, newValue) {
    if (
      precicionValue < precicionStep ||
      precicionValue - precicionStep < precicionStep
    ) {
      newValue = precicionStep;
    }

    if (precicionValue === precicionStep) {
      newValue = newValue - precicionStep;
    }
    return newValue;
  }

  updateProductQuantity = (value) => {
    if (this.props.onUpdateProductQuantity) {
      this.props.onUpdateProductQuantity(Number(value));
    }
  };

  onChangeProductQuantity = (e) => {
    const { field } = this.props;
    const newValue = e.target.value;

    field.setValue(String(newValue));

    if (this.props.onUpdateProductQuantity) {
      this.props.onUpdateProductQuantity(Number(newValue));
    }
  };

  render() {
    const {
      className,
      decrementQuantity,
      incrementQuantity,
      intl,
      disabled,
      field,
      maxQuantity,
      unit,
      balanceUserAccount,
      children,
    } = this.props;

    const step = this.getStep();
    const disableDecrementButton =
      balanceUserAccount && field.value > 0 ? false : field.value <= step;

    return (
      <div className={classNames('QuantityFieldGroup', className)}>
        <Label for={this.uniqueId} hidden>
          <FormattedMessage {...globalTranslations.quantityTitle} />
        </Label>
        <InputGroup className="QuantityFieldGroup__input-group">
          <InputGroupAddon addonType="prepend">
            <Button
              size="sm"
              color="secondary"
              disabled={disabled || disableDecrementButton}
              onClick={decrementQuantity || this.decrementQuantity}
              aria-label={intl.formatMessage(
                globalTranslations.quantityDecrementTitle
              )}
            >
              <Icon name="minus" />
            </Button>
          </InputGroupAddon>
          <Field
            component={Input}
            field={field}
            className="QuantityFieldGroup__quantity"
            value={step}
            type="number"
            min="1"
            max={maxQuantity}
            disabled={disabled}
            pattern="[0-9]*"
            id={this.uniqueId}
            onChange={this.onChangeProductQuantity}
          />
          <InputGroupAddon addonType="append">
            <InputGroupText>{unit}</InputGroupText>
            <Button
              size="sm"
              color="secondary"
              disabled={disabled || field.value >= maxQuantity}
              onClick={incrementQuantity || this.incrementQuantity}
              aria-label={intl.formatMessage(
                globalTranslations.quantityIncrementTitle
              )}
            >
              <Icon name="plus" />
            </Button>
          </InputGroupAddon>
        </InputGroup>
        {children}
      </div>
    );
  }
}

QuantityFieldGroup.propTypes = {
  intl: intlShape.isRequired,
  field: modelOf(FormField).isRequired,
  className: PropTypes.string,
  unit: PropTypes.string,
  onUpdateProductQuantity: PropTypes.func,
  incrementQuantity: PropTypes.func,
  decrementQuantity: PropTypes.func,
  maxQuantity: PropTypes.number,
  quantityStep: PropTypes.number,
  incrementStep: PropTypes.number,
  defaultValue: PropTypes.number,
  disabled: PropTypes.bool,
  balanceUserAccount: PropTypes.bool,
};

QuantityFieldGroup.defaultProps = {
  maxQuantity: 1,
  quantityStep: 1,
  balanceUserAccount: false,
};

export default injectIntl(QuantityFieldGroup);
