import { types } from 'mobx-state-tree';

import ProductPriceInfo from '../ProductPriceInfo';

const Ad = types
  .model('Ad', {
    content: types.maybeNull(types.string),
    // This refers to the possible product, not the banner itself.
    date_added: types.maybeNull(types.string),
    discount_text: types.maybeNull(types.string),
    hide_from_desktop_tablet_users: types.maybeNull(types.boolean),
    hide_from_mobile_users: types.maybeNull(types.boolean),
    id: types.identifier,
    image: types.maybeNull(types.string),
    link_url: types.maybeNull(types.string),
    manufacturer: types.maybeNull(types.string),
    mobile_image: types.maybeNull(types.string),
    overlay_background_color: types.maybeNull(types.string),
    overlay_image: types.maybeNull(types.string),
    price_info: types.maybeNull(ProductPriceInfo),
    priority: types.number,
    product_id: types.maybeNull(types.string),
    product_info: types.maybeNull(
      types.model('BannerProductInfo', {
        actual_code: types.string,
        canonical_path: types.optional(types.array(types.string), []),
        main_category_id: types.maybeNull(types.number),
        main_section_id: types.maybeNull(types.number),
      })
    ),
    scent: types.maybeNull(types.string),
    text_color: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
  })
  .views((self) => ({
    get canonicalPath() {
      return self.product_id && self.product_info?.canonical_path;
    },
    get mainCategoryId() {
      return self.product_id && self.product_info?.main_category_id;
    },
    get mainSectionId() {
      return self.product_id && self.product_info?.main_section_id;
    },
    get priceInfo() {
      return self.product_id && !!self.price_info && self.price_info;
    },
    get productId() {
      return self.product_id;
    },
    get productCode() {
      return self.product_info?.actual_code;
    },
  }));

export default Ad;
