import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import QuickSearch from '../../search/QuickSearch';

const messages = defineMessages({
  filterList: {
    id: 'productListFilter.filterList',
    defaultMessage: 'Filter list',
  },
});

const ProductListFilter = ({
  intl,
  clearProductListFilter,
  onProductListFiltering,
  searchTerm,
  hideButtonText,
  overridePlaceholder,
}) => {
  const placeholder =
    overridePlaceholder || intl.formatMessage(messages.filterList);

  const productListFilterButtonText = (
    <FormattedMessage
      id="productListFilter.filter"
      defaultMessage="Filter by keyword"
    />
  );

  return (
    <div className="ProductListFilter">
      <QuickSearch
        handleFocus={() => null}
        handleBlur={() => null}
        toggleSearch={() => null}
        productListFilter
        onProductListFiltering={onProductListFiltering}
        clearProductListFilter={clearProductListFilter}
        searchTerm={searchTerm}
        placeholder={placeholder}
        buttonText={productListFilterButtonText}
        quickSearchId="ProductListFilter__Autosuggest"
        hideButtonText={hideButtonText}
      />
    </div>
  );
};

ProductListFilter.propTypes = {
  overridePlaceholder: PropTypes.string,
  searchTerm: PropTypes.string,
  hideButtonText: PropTypes.bool,
  clearProductListFilter: PropTypes.func,
  onProductListFiltering: PropTypes.func,
};

ProductListFilter.defaultProps = {
  hideButtonText: false,
};

export default injectIntl(observer(ProductListFilter));
