import { types } from 'mobx-state-tree';

const ExternalReviewType = {
  WITVIEW: 'WITVIEW'
};

export default ExternalReviewType;

export const ExternalReviewTypeType = types.enumeration(
  'ExternalReviewType',
  Object.values(ExternalReviewType)
);
