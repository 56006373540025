const useNavigationEntityConverter = (entities) => {
  const convertEntityToNavigationEntity = (entity) => {
    let label;

    if (typeof entity.title === 'string') {
      label = (
        <div
          className="CommonNavigation__html-content"
          dangerouslySetInnerHTML={{
            __html: entity.title,
          }}
        />
      );
    } else if (entity.title != null) {
      label = entity.title;
    } else {
      label = '';
    }

    return {
      id: entity.id,
      path: entity.url || entity.link,
      type: entity.type || entity.link_type,
      image: entity.image,
      label: label || entity.name,
      children: entity.children
        ? entity.children.map(convertEntityToNavigationEntity)
        : [],
      accessible_text: entity.accessible_text || entity.name,
      position: entity.position,
      page_id: entity.page_id,
    };
  };

  return entities.map(convertEntityToNavigationEntity);
};

export default useNavigationEntityConverter;
