import {
  types,
  getEnv,
  flow,
  resolveIdentifier,
  getRoot,
  applySnapshot
} from 'mobx-state-tree';
import { computed } from 'mobx';
import { flatMap } from 'lodash';

import RequestState, { RequestStateType } from '../types/RequestState';
import Category from '../models/Category';
import { createErrorModel } from '../util/error';
import StatefulStore from '../models/StatefulStore';

const flatMapWithChildren = (categories) => {
  return categories.length > 0
    ? flatMap(categories, (category) => [
        category,
        ...flatMapWithChildren(category.children)
      ])
    : [];
};

const CategoryStore = StatefulStore.named('CategoryStore')
  .props({
    categories: types.optional(types.array(Category), []),
    activeCategory: types.maybeNull(types.reference(Category)),
    latestActiveCategory: types.maybeNull(types.reference(Category)),
    singleState: types.optional(RequestStateType, RequestState.LOADED)
  })
  .views((self) => {
    // Either a dedicated observer or a keepAlive computed property
    // is required for the result to be actually properly cached
    // instead of getting recalculated every time it is called.
    // A MST view getter doesn't do this automatically.
    const memoizedFlattenedCategories = computed(
      () => {
        return flatMapWithChildren(self.categories);
      },
      { keepAlive: true }
    );

    return {
      get flattenedCategories() {
        return memoizedFlattenedCategories.get();
      },
      isInActiveCategoryHierarchy(category) {
        return self.activeCategory
          ? self.activeCategory.hierarchy.indexOf(category) !== -1
          : false;
      }
    };
  })
  .actions((self) => {
    const ifShoppingCenter = () =>
      getRoot(self).configStore.siteConfig.isShoppingCenter;
    const categoriesApi = () =>
      ifShoppingCenter() ? 'shopping-center/categories' : 'categories';

    return {
      setup(categories) {
        self.categories = categories;
        self.setLoading(false);
      },
      setSingleStateError: (error) => {
        self.lastError = createErrorModel(error);
        self.singleState = RequestState.ERROR;
      },
      loadCategory: flow(function* loadCategory(id) {
        const categories = categoriesApi();
        self.singleState = RequestState.LOADING;
        try {
          const category = yield getEnv(self).apiWrapper.request(
            `${categories}/${id}`
          );
          const categoryModel = self.findCategoryById(category.id);
          category.children = categoryModel.children;
          applySnapshot(categoryModel, category);
        } catch (e) {
          self.setSingleStateError(e);
          throw e;
        }
        self.singleState = RequestState.LOADED;
      }),
      findCategoryById(categoryId) {
        return resolveIdentifier(Category, self.categories, categoryId);
      },
      setActiveCategory(activeCategoryId) {
        if (activeCategoryId) {
          self.activeCategory = resolveIdentifier(
            Category,
            getRoot(self),
            activeCategoryId
          );
          if (self.activeCategory) {
            self.latestActiveCategory = self.activeCategory;
          }
        } else {
          self.activeCategory = null;
        }
      }
    };
  });

export default CategoryStore;
