import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Icon from '../Icon';

@observer
export class ImageLightboxHelper extends Component {
  render() {
    return (
      <div
        className={classNames('ImageLightboxHelper', {
          ImageLightboxHelper__visible: this.props.visible
        })}
      >
        <Container className="ImageLightBoxHelper__container">
          <Row>
            <Col xs="12" className="ImageLightboxHelper__content">
              <Icon name="expand" className="ImageLightboxHelper__icon" />
              <div className="ImageLightboxHelper__text">
                <FormattedMessage
                  id="image.lightboxHelper"
                  defaultMessage="Click image to enlarge"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

ImageLightboxHelper.propTypes = {
  visible: PropTypes.bool.isRequired
};

export default ImageLightboxHelper;
