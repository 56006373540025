import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ScrollableAnchor from '../../anchor/ScrollableAnchor';

class SimpleListTabs extends Component {
  render() {
    const { tabs } = this.props;

    if (!tabs || !tabs.length) {
      return null;
    }

    return (
      <div className="SimpleListTabs">
        {tabs.map((tab) => (
          <div key={tab.id} className="SimpleListTabs__item">
            <div className="SimpleListTabs__item-header">
              <ScrollableAnchor
                id={tab.id}
                offset={15}
                className="SimpleListTabs__item-anchor"
                scrollIfInHash={false}
              />
              {tab.title}
            </div>
            {tab.getContent()}
          </div>
        ))}
      </div>
    );
  }
}

SimpleListTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.node.isRequired,
      getContent: PropTypes.func.isRequired
    })
  ).isRequired
};

export default SimpleListTabs;
