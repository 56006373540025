import React from 'react';
import { inject, observer } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import PaymentModuleStore from '../../../store/PaymentModuleStore';
import KlarnaPromotionWidget from '../../../integrations/klarna/KlarnaPromotionWidget';

const MAX_IMAGE_HEIGHT = 35;
const IMAGE_ASPECT_RATIO = 20 / 11;

const FooterPaymentModules = ({ paymentModuleStore }) => {
  const getKlarnaScript = () => {
    const klarnaWidget = paymentModuleStore.getKlarnaWidgets;

    return klarnaWidget?.script;
  };

  const renderPaymentModules = () => {
    const imgHeight = MAX_IMAGE_HEIGHT;
    const imgWidth = Math.round(MAX_IMAGE_HEIGHT * IMAGE_ASPECT_RATIO);

    return (
      <div className="FooterPaymentModules__images">
        {paymentModuleStore.methods.map((module, index) =>
          module && module.logo ? (
            <img
              key={index}
              width={imgWidth}
              height={imgHeight}
              src={module.logo}
              alt={module.name}
              loading="lazy"
              title={module.name}
              className="FooterPaymentModules__image"
            />
          ) : null
        )}
      </div>
    );
  };

  return (
    <div className="FooterPaymentModules">
      {paymentModuleStore.isKlarnaEnabled ? (
        <KlarnaPromotionWidget
          widgetType={'footer-promotion-auto-size'}
          paymentModuleQueryResultKey={'footer'}
          externalKlarnaScript={getKlarnaScript()}
        />
      ) : (
        renderPaymentModules()
      )}
    </div>
  );
};

FooterPaymentModules.propTypes = {
  paymentModuleStore: modelOf(PaymentModuleStore).isRequired,
};

export default inject('paymentModuleStore')(observer(FooterPaymentModules));
