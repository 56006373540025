import { getRoot, getType, types } from 'mobx-state-tree';
import { observe } from 'mobx';

import Category from './Category';
import Section from './Section';

const MegaMenu = types
  .model('MegaMenu', {
    isOpen: types.boolean,
    openCategory: types.maybeNull(types.reference(Category)),
    rootCategory: types.maybeNull(types.reference(Category)),
    activeSection: types.maybeNull(types.reference(Section)),
    visibleCategories: types.optional(
      types.array(types.reference(Category)),
      []
    ),
    entityCount: types.optional(types.map(types.number), {})
  })
  .actions((self) => {
    const afterCreate = () => {
      observe(self.visibleCategories, () => {
        self.setOpenCategory(null);
      });
    };

    const open = (categoryOrSection) => {
      if (getType(categoryOrSection) === Category) {
        self.activeSection = null;
        self.rootCategory = categoryOrSection.id;
        self.visibleCategories = categoryOrSection.children.map(
          (category) => category.id
        );
      } else {
        self.rootCategory = null;
        self.activeSection = categoryOrSection.id;
        self.visibleCategories = categoryOrSection.mainCategories;
      }
      // Automatically open active category, if it is available.
      const { categoryStore } = getRoot(self);
      if (categoryStore.activeCategory) {
        const activeCategories = categoryStore.activeCategory
          ? categoryStore.activeCategory.hierarchy
          : [];
        self.openCategory =
          self.visibleCategories.find(
            (visibleCategory) =>
              activeCategories.indexOf(visibleCategory) !== -1
          ) || null;
      }
      self.isOpen = true;
    };

    const setOpenCategory = (category) => {
      self.openCategory = category;
    };

    const close = () => {
      self.rootCategory = null;
      self.activeSection = null;
      self.visibleCategories = [];
      self.isOpen = false;
    };

    const toggle = (categoryOrSection) => {
      if (
        self.isOpen &&
        (self.rootCategory === categoryOrSection ||
          self.activeSection === categoryOrSection)
      ) {
        close();
      } else {
        open(categoryOrSection);
      }
    };

    const countEntities = (entity) => {
      let childEntityCount = 0;
      childEntityCount += getChildListLength(entity);
      childEntityCount += entity.children.length;
      self.entityCount.set(entity.id, childEntityCount);
    };

    const getChildListLength = (entity) => {
      let childList = [];
      entity.children.forEach((childEntity) => {
        childList = getChildEntities(childEntity, childList);
      });

      return childList.length;
    };

    const getChildEntities = (childEntity, childList) => {
      const customNavLinks = childEntity.customNavLinks;
      for (const customNavLink of customNavLinks) {
        if (customNavLink.customNavLinks.length > 0) {
          childList.push(customNavLink);
          getChildEntities(customNavLink, childList);
        } else {
          childList.push(customNavLink);
        }
      }

      return childList;
    };

    const getChildEntityLength = (childEntity, count) => {
      let cumulativeCount = count || 0;
      const customNavLinks = childEntity.customNavLinks;
      if (customNavLinks.length > 0) {
        getChildEntityLength(count);
      } else {
        return cumulativeCount;
      }
    };

    return {
      afterCreate,
      open,
      setOpenCategory,
      close,
      toggle,
      countEntities
    };
  })
  .views((self) => ({
    getEntityCount(entityId) {
      return self.entityCount.get(entityId);
    }
  }));

export default MegaMenu;
