import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Alert, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import { head, some } from 'lodash';
import { withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';
import LazyLoad from 'react-lazyload';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';
import qs from 'qs';
import { DateTime } from 'luxon';

import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import ProductImages from '../ProductImages';
import ProductFiles from '../ProductFiles';
import ProductClass from '../../../types/ProductClass';
import ProductCollectionProductPicker from '../ProductCollectionProductPicker';
import ProductMultiProductPicker from '../ProductMultiProductPicker';
import ManufacturerLink from '../../manufacturer/ManufacturerLink';
import ProductReviewWidget from '../ProductReviewWidget';
import LastVisitedProductsSlider from '../../slider/LastVisitedProductsSlider';
import ProductImageSigns from '../ProductImageSigns';
import ConfigStore from '../../../store/ConfigStore';
import ProductExtraInfo from '../ProductExtraInfo';
import HashTabs, { createHashTab } from '../../common/HashTabs';
import ProductAvailabilityList from '../ProductAvailabilityList';
import ProductPagePrice from '../ProductPagePrice';
import UIStore from '../../../store/UIStore';
import SEOTitle from '../../head/SEOTitle';
import SEODescription from '../../head/SEODescription';
import CanonicalLink from '../../head/CanonicalLink';
import RouteService from '../../../services/RouteService';
import ProductOfferSchema from '../ProductOfferSchema';
import RecommendedProductsSlider from '../../slider/RecommendedProductsSlider';
import globalTranslations from '../../../i18n/globalTranslations';
import WysiwygContent from '../../common/WysiwygContent';
import ProductShippingDetails from '../ProductShippingDetails';
import ProductReviews from '../ProductReviews';
import { scrollToElementById } from '../../../util/dom';
import ButtonLink from '../../common/ButtonLink';
import ProductPackageSize from '../ProductPackageSize';
import ProductAddToCart from '../ProductAddToCart';
import ProductSeasonInfo from '../ProductSeasonInfo';
import BannerSlider from '../../ad/BannerSlider';
import AdZones from '../../../types/AdZones';
import ContentSlots from '../../ad/ContentSlots';
import RequestState from '../../../types/RequestState';
import ProductBundles from '../ProductBundles';
import ProductCode from '../ProductCode';
import CategoryLink from '../../category/CategoryLink';
import CategoryStore from '../../../store/CategoryStore';
import AccordionTabs from '../../common/AccordionTabs';
import PaymentModuleStore from '../../../store/PaymentModuleStore';
import KlarnaPromotionWidget from '../../../integrations/klarna/KlarnaPromotionWidget';
import AdLoader from '../../ad/AdLoader';
import { parseHash } from '../../../util/url';
import PageTitle from '../../common/PageTitle';
import CustomCol from '../../bootstrap/CustomCol';
import ContentSlot from '../../ad/ContentSlot';
import ProductAdditionalTabText from '../ProductAdditionalTabText';
import ProductStoreAvailability from '../ProductStoreAvailability';
import ProductEnquiry from '../../product-enquiry/ProductEnquiry';
import AlsoPurchasedProductsSlider from '../../slider/AlsoPurchasedProductsSlider';
import ProductFileUploads from '../ProductFileUploads';
import ProductQuantityDiscounts from '../ProductQuantityDiscounts';
import ProductSizeGuides from '../ProductSizeGuides';
import ProductOnlyInStore from '../ProductOnlyInStore';
import ProductAvailabilityType from '../../../types/ProductAvailabilityType';
import { LocationContextPropType } from '../../../services/LocationContext';
import ProductDateAvailable from '../ProductDateAvailable';
import LoadBeeProductContentWidget from '../../../integrations/loadBee/LoadBeeProductContentWidget';
import ManufacturerIntegrationType from '../../../types/ManufacturerIntegrationType';
import ProductReserveInStoreModal from '../ProductReserveInStoreModal';
import ProductReserveInStoreButton from '../ProductReserveInStoreButton';
import AccountStore from '../../../store/AccountStore';
import ImageLightboxHelper from '../../common/ImageLightboxHelper';
import ProductEnquiryType from '../../../types/ProductEnquiryType';
import ProductFeatureImages from '../ProductFeatureImages';
import PriceWrapper from '../PriceWrapper';
import ProductAddToWishList from '../ProductAddToWishList';
import ProductPdf from '../../product-pdf/ProductPdf';
import CountryStore from '../../../store/CountryStore';
import CurrencyStore from '../../../store/CurrencyStore';
import { convertLocaleToFinlandIfAland } from '../../../util/locale';
import CompatibleVehicleModels from '../../vehicle/CompatibleVehicleModels';
import VehiclePartSlider from '../../vehicle/VehiclePartSlider';
import GenericProductsSlider from '../../slider/GenericProductsSlider';
import PriceSecondary from '../PriceSecondary';
import LanguageStore from '../../../store/LanguageStore';
import ExternalReviewType from '../../../types/ExternalReviewType';
import KlarnaInstantShopping from '../../../integrations/klarna/KlarnaInstantShopping';
import ProductStore from '../../../store/ProductStore';
import InfoPageLink from '../../info-page/InfoPageLink';
import CartStore from '../../../store/CartStore';
import ApiWrapper from '../../../services/ApiWrapper';
import { stringify } from '../../../util/queryString';
import ProductDescription from '../ProductDescription';
import Swogo from '../../../integrations/swogo/SwogoBundleWidget';
import BlockSpinner from '../../loader/BlockSpinner';
import YotpoMainWidget from '../../../integrations/yotpo/YotpoMainWidget';
import SEOSchema from '../../head/SEOSchema';
import { roundWithPrecision } from '../../../util/number';
import ProductDeposit from '../ProductDeposit';
import ProductMatrix from '../ProductMatrix';
import Icon from '../../common/Icon';
import ProductTypeClass from '../../../types/ProductTypeClass';
import ProductPdfType from '../../../types/ProductPdfType';
import Analytics from '../../../analytics/Analytics';

const messages = defineMessages({
  'product.manufacturerLogoAlt': {
    id: 'product.manufacturerLogoAlt',
    defaultMessage: '{manufacturer} logo',
  },
  shoppingCenterAcceptableTerms: {
    id: 'product.shoppingCenterAcceptableTerms',
    defaultMessage:
      "Merchant's {merchantName} {deliveryAndPaymentTerms} and {merchantPrivacyStatement}",
  },
});

const BRAND_TAB_ID = 'brand';
const DESCRIPTION_TAB_ID = 'description';
const REVIEWS_TAB_ID = 'reviews';
const KLARNA_WIDGET_TYPE = 'credit-promotion-auto-size';
export const AVAILABILITY_TAB_ID = 'availability';
export const SHIPPING_TAB_ID = 'shipping';
export const PRODUCT_INFORMATION_BANNER_ID = 'product-information';
export const ADDITIONAL_SERVICES_ID = 'additional-services';

@observer
export class ProductPageContent extends Component {
  constructor(props) {
    super(props);
    const { configStore } = props;

    this.supportedPurchaseCountriesAndCurrencies = {};
    this.ifSupportedCountryWithCurrency = false;
    this.yotpoTimeout = null;

    this.state = {
      quantity: 1,
      hasValidShippingMethods:
        window.isSSR || !configStore.productPage.validateForCartQuery,
      shippingOptionLoaded: RequestState.NONE,
      productIsValidForCart: true,
      openedTabs: new Set(),
    };

    this.maybeLoadBundleProductInfos();
    this.maybeLoadAdditionalTabs();
    this.maybeLoadSizeGuides();
    this.maybeLoadRelatedProducts();
    this.maybeLoadPaymentWidget();
    this.getPurchaseCountriesAndCurrencies();
    this.supportedCountryWithCurrency();
  }

  componentDidMount() {
    this.maybeOpenDefaultTab();
    window.addEventListener('scroll', this.handleScroll, true);
    window.addEventListener('resize', this.handleResize, true);
  }

  componentDidUpdate(prevProps) {
    const { configStore, activeProductId, cartLoaded, product } = this.props;

    if (prevProps.activeProductId !== activeProductId) {
      this.maybeLoadBundleProductInfos();
      this.maybeLoadAdditionalTabs();
      this.maybeLoadSizeGuides();
      this.maybeLoadRelatedProducts();
      this.maybeOpenDefaultTab();
      this.maybeLoadPaymentWidget();
      this.getPurchaseCountriesAndCurrencies();
      this.supportedCountryWithCurrency();
      configStore.analytics.ga4.enabled &&
        activeProductId &&
        this.sendAnalyticsEvents();
    }

    if (
      prevProps.activeProductId !== activeProductId ||
      prevProps.product !== product
    ) {
      this.maybeReloadScripts();
    }

    if (
      prevProps.activeProductId !== activeProductId ||
      (cartLoaded === true &&
        this.state.hasValidShippingMethods === false &&
        this.state.shippingOptionLoaded === RequestState.NONE)
    ) {
      this.maybeDisableCart();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
    window.removeEventListener('resize', this.handleResize, true);
    this.props.uiStore.toggleAddToCartRow(false);
    this.yotpoTimeout && clearTimeout(this.yotpoTimeout);
  }

  setAddToCartRef = (elem) => {
    this.ref = elem;
  };

  setSelfRef = (elem) => {
    this.refSelf = elem;
  };

  handleScroll = () => {
    const { uiStore } = this.props;
    if (this.ref && this.refSelf) {
      const position = this.ref.getBoundingClientRect().bottom;
      const positionSelf = this.refSelf.getBoundingClientRect().bottom - 80;
      if (position < 0 && positionSelf > 0 && !uiStore.addToCartRowIsVisible) {
        uiStore.toggleAddToCartRow(true);
      }
      if ((position > 0 || positionSelf < 0) && uiStore.addToCartRowIsVisible) {
        uiStore.toggleAddToCartRow(false);
      }
    }
  };

  handleResize = () => {
    const tabId = this.getHash();
    const { openedTabs } = this.state;
    if (tabId && !openedTabs.has(tabId)) {
      this.toggleTab(tabId);
    }
  };

  scrollToTab = (tabId, IfAnchorLink) => {
    if (window.isSSR) {
      return;
    }

    this.toggleTab(tabId, IfAnchorLink);
    scrollToElementById(tabId, null);
  };

  toggleTab = (tabId, IfAnchorLink) => {
    const { uiStore } = this.props;

    if (!uiStore.isMobile) {
      this.handleDesktopTabs(tabId);
    }

    if (uiStore.isMobile) {
      this.handleMobileTabs(tabId, IfAnchorLink);
    }
  };

  handleDesktopTabs = (tabId) => {
    if (tabId && this.getHash() !== tabId) {
      this.openTab(tabId);
      this.setHash(tabId);
    }
  };

  handleMobileTabs = (tabId, IfAnchorLink) => {
    const { openedTabs } = this.state;

    if (tabId && !openedTabs.has(tabId)) {
      this.openTab(tabId);
      this.setHash(tabId);
    }

    if (!IfAnchorLink && openedTabs.has(tabId)) {
      this.setHash(this.closeTab(tabId));
    }
  };

  openTab = (tabId) => {
    this.setState(({ openedTabs }) => {
      // Add opened tab to start of the object so when closing the tab we can get the previously opened tab.
      return {
        openedTabs: new Set([tabId, ...openedTabs]),
      };
    });
  };

  closeTab = (tabId) => {
    let hash = '';

    this.setState(({ openedTabs }) => {
      const updatedTabs = new Set(openedTabs);
      updatedTabs.delete(tabId);
      hash = updatedTabs.values().next().value;

      return {
        openedTabs: updatedTabs,
      };
    });

    return hash;
  };

  setHash = (hash) => {
    const { history } = this.props;
    const hashString = hash ? '#' + hash : '';

    if (hash === REVIEWS_TAB_ID) {
      this.maybeUpdateYotpoTab();
    }

    history.replace(
      history.location.pathname + history.location.search + hashString
    );
  };

  getHash = () => {
    const { history } = this.props;
    if (history.location.hash) {
      // History hash includes the hashtag (#), strip it off.
      return parseHash(history.location.hash);
    }
    return '';
  };

  maybeLoadBundleProductInfos = () => {
    const { activeProductId, product } = this.props;
    if (activeProductId) {
      const actualProduct = product.getActualProduct(activeProductId);
      if (actualProduct.bundleProductInfosState === RequestState.NONE) {
        actualProduct.loadBundleProductInfos().catch((e) => {
          if (e.response && e.response.status !== 404) {
            console.error(e);
          }
        });
      }
    }
  };

  maybeLoadAdditionalTabs = () => {
    const { activeProductId, product, configStore } = this.props;
    if (configStore.product.additionalProductTabsActive) {
      const actualProduct =
        product.getActualProduct(activeProductId) || product;
      if (
        actualProduct &&
        actualProduct.additionalTabsState === RequestState.NONE
      ) {
        actualProduct.loadAdditionalTabs().catch((e) => {
          if (e.response && e.response.status !== 404) {
            console.error(e);
          }
        });
      }
    }
  };

  maybeLoadSizeGuides = () => {
    const { activeProductId, product, adSearchParams } = this.props;
    if (product.product_type === ProductTypeClass.GIFT_VOUCHER) {
      return;
    }

    const actualProduct = product.getActualProduct(
      activeProductId || product.id
    );
    this.getProductSizeGuides(product, activeProductId, adSearchParams);
    this.getActiveProductSizeGuides(
      activeProductId,
      actualProduct,
      adSearchParams
    );
  };

  getProductSizeGuides = (product, activeProductId, adSearchParams) => {
    if (
      product &&
      product.sizeGuidesState === RequestState.NONE &&
      !activeProductId
    ) {
      product.loadSizeGuides(adSearchParams).catch((e) => {
        if (e.response && e.response.status !== 404) {
          console.error(e);
        }
      });
    }
  };

  getActiveProductSizeGuides = (
    activeProductId,
    actualProduct,
    adSearchParams
  ) => {
    if (
      activeProductId &&
      actualProduct &&
      actualProduct.sizeGuidesState === RequestState.NONE
    ) {
      actualProduct.loadSizeGuides(adSearchParams).catch((e) => {
        if (e.response && e.response.status !== 404) {
          console.error(e);
        }
      });
    }
  };

  maybeLoadRelatedProducts = () => {
    const { configStore, product } = this.props;
    configStore.vehiclePartSearch.enabled &&
      product.loadRelatedProducts().catch((e) => {
        if (e.response && e.response.status !== 404) {
          console.error(e);
        }
      });
  };

  maybeOpenDefaultTab = () => {
    const { uiStore } = this.props;
    const hash = this.getHash();

    if (uiStore.isMobile && !hash) {
      if (this.hasBrandTab()) {
        this.toggleTab(BRAND_TAB_ID, true);
      } else {
        this.toggleTab(DESCRIPTION_TAB_ID, true);
      }
    }

    if (hash) {
      this.scrollToTab(hash);
    }
  };

  maybeLoadPaymentWidget = () => {
    const { paymentModuleStore } = this.props;

    paymentModuleStore
      .loadProductWidget(this.getWidgetQueryParameters())
      .catch((e) => {
        if (e.response && e.response.status !== 404) {
          console.error(e);
        }
      });
  };

  maybeReloadScripts = () => {
    const { configStore } = this.props;

    if (configStore.integrations.witView.enabled && window._witViewPluginApp) {
      window._witViewPluginApp.run();
    }

    if (configStore.integrations.yotpo.enabled && window && window.yotpo) {
      this.yotpoTimeout = setTimeout(() => window.yotpo.refreshWidgets(), 800);
    }
  };

  maybeUpdateYotpoTab = () => {
    const { configStore } = this.props;

    if (
      configStore.integrations.yotpo.enabled &&
      document &&
      window &&
      window.yotpo
    ) {
      const domElements = document.getElementsByClassName('main-widget');
      this.refreshYotpo(domElements);
    }
  };

  refreshYotpo = (elements) => {
    if (elements.length === 0) {
      this.yotpoTimeout = setTimeout(() => window.yotpo.refreshWidgets(), 0);
    }
  };

  setAddingToCart = (value) => {
    this.setState({
      hasValidShippingMethods: value,
      shippingOptionLoaded: !!value
        ? RequestState.LOADED
        : RequestState.LOADING,
    });
  };

  maybeDisableCart = () => {
    const { apiWrapper, cartStore, configStore, cartLoaded } = this.props;

    if (
      window.isSSR ||
      !configStore.productPage.validateForCartQuery ||
      !cartLoaded
    ) {
      return;
    }

    // When cart is loaded and it has products we check valid shipping methods for current products eligibility to cart.
    if (cartStore.cart.number_of_products === 0) {
      this.setAddingToCart(true);
      return;
    }

    if (cartStore.cart.number_of_products > 0) {
      this.setAddingToCart(false);

      apiWrapper
        .request(
          `shipping/options-for-products/?${qs.stringify(
            this.getOptionsQueryParameters()
          )}`
        )
        .then((data) => {
          if (data.length > 0) {
            this.setAddingToCart(true);
          } else {
            this.setState({
              productIsValidForCart: false,
            });
          }
        });
    }
  };

  getOptionsQueryParameters = () => {
    const { cartStore, countryStore } = this.props;

    const productPayload = this.getCartProducts();
    return {
      countryCode: countryStore.activeCountry.iso_code_2.toLowerCase(),
      paymentModuleId: cartStore.cart.payment_method_id,
      products: productPayload,
    };
  };

  getCartProducts = () => {
    const { cartStore, activeProductId } = this.props;

    const productPayload = cartStore.cart.products.map((product) => ({
      extendedId: product.id,
      quantity: product.quantity,
      reference: product.reference,
    }));

    // Add current product also for the payload.
    productPayload.push({
      extendedId: activeProductId,
      quantity: 1,
    });

    return productPayload;
  };

  getWidgetQueryParameters = () => {
    const { accountStore, countryStore, product } = this.props;
    return {
      countryCode: countryStore.activeCountry.id,
      groupId: accountStore.getCustomerGroupId,
      productLicenseType: product.required_license,
    };
  };

  getActiveCollectionElementIds = () => {
    const { activeProductId, product, queryParams } = this.props;
    if (product.class === ProductClass.COLLECTION) {
      if (activeProductId) {
        let activeItem =
          product.collection.getItemWithProductId(activeProductId);
        if (activeItem) {
          return {
            columnId: activeItem.column_id,
            rowId: activeItem.row_id,
          };
        }
      }
      return {
        columnId: queryParams.columnId ? Number(queryParams.columnId) : null,
        rowId: queryParams.rowId ? Number(queryParams.rowId) : null,
      };
    }
    return {
      columnId: null,
      rowId: null,
    };
  };

  goToReviews = () => {
    this.scrollToTab(REVIEWS_TAB_ID, true);
  };

  goToDescription = () => {
    this.scrollToTab(DESCRIPTION_TAB_ID, true);
  };

  hasBrandTab = () => {
    const { product, configStore } = this.props;

    return (
      configStore.integrations.zoovu.enabled &&
      product.manufacturer &&
      some(product.manufacturer.integrations, {
        type: ManufacturerIntegrationType.LOADBEE,
      })
    );
  };

  getTabs = (addToCartProduct) => {
    const {
      product,
      activeProductId,
      configStore,
      accountStore,
      routeService,
      currencyStore,
    } = this.props;

    const tabs = [];

    const isViewOnly = accountStore.isViewOnly;
    const isStore = configStore.siteConfig.isStore;
    const isGiftCard =
      addToCartProduct.product_type === ProductTypeClass.GIFT_VOUCHER;

    const brandTab = this.getBrandTab();
    brandTab && tabs.push(brandTab);

    const descriptionTab = this.getProductDescriptionTab(addToCartProduct);
    descriptionTab && tabs.push(descriptionTab);

    if (
      activeProductId &&
      addToCartProduct.bundleProductInfos.length > 0 &&
      !isViewOnly
    ) {
      const productBundleTab = this.buildProductBundleTab(addToCartProduct);
      productBundleTab && tabs.push(productBundleTab);
    }

    const recommendedProductSlider = this.buildRecommendedProductsTab();

    if (
      !configStore.integrations.swogo.enabled &&
      !configStore.vehiclePartSearch.enabled &&
      product.recommended_with_ids.length > 0 &&
      isStore
    ) {
      recommendedProductSlider && tabs.push(recommendedProductSlider);
    }

    if (
      !configStore.integrations.swogo.enabled &&
      configStore.vehiclePartSearch.enabled &&
      product.recommendedIds.length > 0 &&
      isStore
    ) {
      recommendedProductSlider && tabs.push(recommendedProductSlider);
    }

    if (
      configStore.vehiclePartSearch.enabled &&
      product.alternativeIds.length > 0
    ) {
      const relatedProductsTitle = (
        <FormattedMessage {...globalTranslations.relatedProductsTitle} />
      );
      tabs.push(
        createHashTab('related products', relatedProductsTitle, () => (
          <GenericProductsSlider
            mainComponentClassName={'RelatedProductsSlider'}
            allSections
            ids={product.alternativeIds.slice()}
            formattedMessage={relatedProductsTitle}
          />
        ))
      );
    }

    if (
      configStore.product.showStoreAvailability &&
      activeProductId &&
      !isViewOnly &&
      product.product_type !== ProductTypeClass.GIFT_VOUCHER
    ) {
      const availabilityTab = this.renderProductAvailabilityTab();
      availabilityTab && tabs.push(availabilityTab);
    }

    let reviewComponent;
    if (configStore.integrations.yotpo.enabled && !isGiftCard) {
      const productId = `${product.id}`;
      const path = `${window.location.host}${routeService.getProductPath(
        product
      )}`;
      const image = product.getMainImage(product.product_id);
      const price = `${product.getPriceWithPrecision(true, activeProductId)}`;
      const currency = `${currencyStore.activeCurrency.code}`;
      const name = product.name;
      reviewComponent = (
        <YotpoMainWidget
          className="ProductPageContent__reviews"
          productId={productId}
          productPrice={price}
          currency={currency}
          productName={name}
          productPath={path}
          productImage={image}
          refresher={this.maybeUpdateYotpoTab}
        />
      );
    } else {
      reviewComponent = <ProductReviews product={product} />;
    }

    if (configStore.reviews.enabled && !isViewOnly && !isGiftCard) {
      const reviewsTab = this.renderProductReviewsTab(reviewComponent);
      reviewsTab && tabs.push(reviewsTab);
    }

    if (
      (addToCartProduct || activeProductId) &&
      !isViewOnly &&
      (!isGiftCard ||
        (isGiftCard && !configStore.productPage.shippingOnlyProduct))
    ) {
      const shippingTab = this.renderShippingTab(addToCartProduct);
      shippingTab && tabs.push(shippingTab);
    }

    if (
      configStore.product.additionalProductTabsActive &&
      addToCartProduct.additionalTabs.length > 0
    ) {
      addToCartProduct.additionalTabs
        .slice()
        .forEach((tab, index) =>
          tabs.push(
            createHashTab(tab.title + '-' + index, tab.title, () => (
              <ProductAdditionalTabText additionalTabHtml={tab.text} />
            ))
          )
        );
    }

    if (product.startaxInfo) {
      if (
        product.startaxInfo.compatibleModels &&
        product.startaxInfo.compatibleModels.length > 0
      ) {
        tabs.push(
          createHashTab('compatibleModels', 'compatibleModels', () => (
            <CompatibleVehicleModels product={product} />
          ))
        );
      }

      if (
        product.startaxInfo.alternatives &&
        product.startaxInfo.alternatives.length > 0
      ) {
        tabs.push(
          createHashTab('alternatives', 'alternatives', () => (
            <VehiclePartSlider
              productIds={product.startaxInfo.alternatives}
              title={
                <FormattedMessage
                  id="product.vehiclePartAlternatives"
                  defaultMessage="Alternatives"
                />
              }
            />
          ))
        );
      }

      if (
        product.startaxInfo.compatibleProducts &&
        product.startaxInfo.compatibleProducts.length > 0
      ) {
        tabs.push(
          createHashTab('compatibleProducts', 'compatibleProducts', () => (
            <VehiclePartSlider
              productIds={product.startaxInfo.compatibleProducts}
              title={
                <FormattedMessage
                  id="product.compatibleVehicleParts"
                  defaultMessage="Compatible"
                />
              }
            />
          ))
        );
      }

      if (
        product.startaxInfo.spareParts &&
        product.startaxInfo.spareParts.length > 0
      ) {
        tabs.push(
          createHashTab('spareParts', 'spareParts', () => (
            <VehiclePartSlider
              productIds={product.startaxInfo.spareParts}
              title={
                <FormattedMessage
                  id="product.spareParts"
                  defaultMessage="Spares"
                />
              }
            />
          ))
        );
      }
    }

    const servicesTab = this.getAdditionalServicesTab();
    servicesTab && tabs.push(servicesTab);

    return tabs;
  };

  getBrandTab = () => {
    const { product } = this.props;

    if (this.hasBrandTab()) {
      return createHashTab(
        BRAND_TAB_ID,
        <FormattedMessage
          id="product.manufacturersContentTitle"
          defaultMessage="Manufacturers content"
        />,
        () => (
          <LoadBeeProductContentWidget
            manufacturer={product.manufacturer}
            gtin={product.ean}
          />
        )
      );
    }
  };

  getProductDescriptionTab(addToCartProduct) {
    const { product, adSearchParams } = this.props;

    const productHasWysiwygContent =
      addToCartProduct && addToCartProduct.description_long;
    const productHasFiles =
      addToCartProduct &&
      addToCartProduct.files &&
      addToCartProduct.files.length > 0;
    const showProductDescriptionTab =
      productHasWysiwygContent || productHasFiles;

    if (showProductDescriptionTab) {
      return createHashTab(
        DESCRIPTION_TAB_ID,
        <FormattedMessage
          id="product.descriptionTitle"
          defaultMessage="Description"
        />,
        () => (
          <Fragment>
            <Row>
              <Col xs="12" lg="10">
                <div className="ProductPageContent__description">
                  {productHasFiles && (
                    <ProductFiles key={product.id} product={addToCartProduct} />
                  )}
                  {productHasWysiwygContent && (
                    <WysiwygContent html={addToCartProduct.description_long} />
                  )}
                </div>
              </Col>
              <Col
                sm="12"
                lg="2"
                className="ProductPageContent__description-details"
              >
                {this.renderSideAd()}
                <div>
                  <h6>
                    <FormattedMessage
                      id="product.descriptionDetails"
                      defaultMessage="Details"
                    />
                  </h6>
                  <ProductExtraInfo product={addToCartProduct} singleColumn />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <BannerSlider
                  searchParams={{
                    bannerZone: AdZones.PRODUCT_PAGE_BANNER_2,
                    ...adSearchParams,
                  }}
                  className="ProductPageContent__banner-slider--2"
                  aspectRatio={2.85}
                  maximumCrossAxisSize={250}
                />
                <ContentSlots
                  searchParams={{
                    bannerZone: AdZones.PRODUCT_PAGE_CONTENT_2,
                    ...adSearchParams,
                  }}
                  className="ProductPageContent__content-slots--2"
                />
              </Col>
            </Row>
          </Fragment>
        )
      );
    }
  }

  buildProductBundleTab = (addToCartProduct) => {
    const { activeProductId, configStore, product } = this.props;

    const ifShoppingCenter = configStore.siteConfig.isShoppingCenter;
    const { swogo } = configStore.integrations;
    const ifBuyingAllowed =
      ifShoppingCenter &&
      product.merchantInfo &&
      product.merchantInfo.buyingAllowed;

    if ((ifShoppingCenter && !ifBuyingAllowed) || swogo.enabled) {
      return;
    }

    return createHashTab(
      'bundles',
      <FormattedMessage {...globalTranslations.productBundlesTitle} />,
      () => (
        <ProductBundles
          product={addToCartProduct}
          activeProductId={activeProductId}
        />
      )
    );
  };

  buildRecommendedProductsTab = () => {
    const { configStore, product } = this.props;
    const { swogo } = configStore.integrations;

    if (swogo.enabled) {
      return;
    }

    return createHashTab(
      'recommended',
      <FormattedMessage {...globalTranslations.recommendedProductsTitle} />,
      () => (
        <RecommendedProductsSlider
          ids={
            configStore.vehiclePartSearch.enabled
              ? product.recommendedIds.slice()
              : product.recommended_with_ids.slice()
          }
        />
      )
    );
  };

  renderProductAvailabilityTab = () => {
    const { activeProductId, product } = this.props;

    return createHashTab(
      AVAILABILITY_TAB_ID,
      <FormattedMessage
        id="stock.storeAvailability"
        defaultMessage="Store availability"
      />,
      () => (
        <div>
          {activeProductId && (
            <ProductStoreAvailability
              activeProductId={activeProductId}
              product={product}
              merchantSiteUrl={product.merchantSiteUrl}
            />
          )}
        </div>
      )
    );
  };

  renderProductReviewsTab = (reviewComponent) => {
    const { product, configStore } = this.props;

    const ifShoppingCenter = configStore.siteConfig.isShoppingCenter;
    const ifReviews = product.reviews_count;

    if (ifShoppingCenter && !ifReviews) {
      return;
    }

    return createHashTab(
      REVIEWS_TAB_ID,
      <FormattedMessage {...globalTranslations.reviewsTitle} />,
      () => reviewComponent
    );
  };

  renderShippingTab = (addToCartProduct) => {
    const { activeProductId, product, configStore } = this.props;

    const ifOutOfStock = this.getShowOutOfStockParam();

    if (addToCartProduct.isNotAvailable() || ifOutOfStock) {
      return;
    }

    const costsSentence = configStore.productPage.shippingOnlyProduct ? (
      <FormattedMessage
        id="shipping.allCostsTitle"
        defaultMessage="Products shipping costs"
      />
    ) : (
      <FormattedMessage {...globalTranslations.shippingCostsTitle} />
    );

    return createHashTab(
      SHIPPING_TAB_ID,
      costsSentence,
      () =>
        (addToCartProduct || activeProductId) && (
          <ProductShippingDetails
            activeProductId={activeProductId || product.id}
            product={addToCartProduct}
          />
        )
    );
  };

  getAdditionalServicesTab = () => {
    const { product, configStore } = this.props;

    const ifAdditionalServicesModalEnabled =
      configStore.productPage.enableAdditionalServicesModal;
    const servicesAmount = product.additionalServices.length;
    const content = this.getServiceTabContent();

    if (ifAdditionalServicesModalEnabled && servicesAmount > 0) {
      return createHashTab(
        ADDITIONAL_SERVICES_ID,
        <FormattedMessage {...globalTranslations.additionalServicesTitle} />,
        content
      );
    }
  };

  getServiceTabContent = () => {
    const { product } = this.props;
    const servicesAreLoading =
      product.additionalServicesState === RequestState.LOADING;

    return servicesAreLoading
      ? () => <BlockSpinner />
      : () => (
          <Fragment>
            <div className="ProductPageContent__additional-services-information">
              <Row>
                <Col sm={12}>
                  <FormattedMessage
                    id="additionalService.information"
                    defaultMessage="You can select additional services, when the product is being added to the cart."
                  />
                </Col>
              </Row>
            </div>
            <ul className="ProductPageContent__additional-services">
              <Row>
                {product.additionalServices.map((service) => (
                  <Col key={service.id} sm={12}>
                    <li>{service.selection_text}</li>
                  </Col>
                ))}
              </Row>
            </ul>
          </Fragment>
        );
  };

  getVisibleTabs = (tabs) => {
    const { activeProductId, product } = this.props;
    const ifLoaded =
      product.stockStates.get(activeProductId) === RequestState.LOADED;
    const stocks = ifLoaded && product.stocks.get(activeProductId);
    const ifStocks = ifLoaded && stocks && stocks.length > 0;
    return ifLoaded && !ifStocks
      ? tabs.filter((tab) => tab.id !== AVAILABILITY_TAB_ID)
      : tabs;
  };

  showShipping = () => {
    const { activeProductId, product, hideAddToCart } = this.props;
    return (
      activeProductId && product.canBeOrdered(activeProductId) && !hideAddToCart
    );
  };

  pdfCatalogEnabled = () => {
    const { accountStore, configStore } = this.props;
    const { showPdfButton } = configStore.productPage;

    if (showPdfButton === '0') {
      return false;
    }
    if (
      showPdfButton === '1' ||
      (showPdfButton === '2' && accountStore.loggedIn)
    ) {
      return true;
    }
    return showPdfButton === '3' && accountStore.isRetailer;
  };

  renderProductPdfButton = () => {
    const { activeProductId, product } = this.props;

    const activeId =
      product.class === ProductClass.COLLECTION
        ? product.id
        : activeProductId || product.id;

    return <ProductPdf activeId={activeId} pdfType={ProductPdfType.PRODUCT} />;
  };

  renderManufacturerImage = (product) => (
    <ManufacturerLink manufacturer={product.manufacturer}>
      <div className="ProductPageContent__manufacturer-image">
        <img
          src={product.manufacturer.image}
          alt={this.props.intl.formatMessage(
            messages['product.manufacturerLogoAlt'],
            {
              manufacturer: product.manufacturer.name,
            }
          )}
          loading="lazy"
        />
      </div>
    </ManufacturerLink>
  );

  renderManufacturer = () => {
    const { product } = this.props;
    return (
      <div className="ProductPageContent__manufacturer">
        <ManufacturerLink
          manufacturer={product.manufacturer}
          className="ProductPageContent__extra-link"
        >
          <FormattedMessage
            id="product.allProductsFromManufacturer"
            defaultMessage="All {manufacturerLink} products"
            values={{
              manufacturerLink: (
                <span className="ProductPageContent__extra-link-target">
                  {product.manufacturer.name}
                </span>
              ),
            }}
          />
        </ManufacturerLink>
      </div>
    );
  };

  renderMerchantDescription = () => {
    const { product } = this.props;

    if (!product.merchantInfo) {
      return null;
    }

    return (
      product.merchantInfo.site_description && (
        <div
          className="ProductPageContent__site-description"
          dangerouslySetInnerHTML={{
            __html: product.merchantInfo.site_description,
          }}
        />
      )
    );
  };

  renderMerchantAcceptableTerms = () => {
    const { product } = this.props;

    if (!product.merchantInfo) {
      return null;
    }

    const merchantName = product.merchantInfo.site_name;
    const deliveryAndPaymentTerms = product.merchantInfo.deliveryTerms.link;
    const merchantPrivacyStatement = product.merchantInfo.privacyStatement.link;

    return (
      <div className="ProductPageContent__acceptable-terms">
        <FormattedMessage
          {...messages.shoppingCenterAcceptableTerms}
          values={{
            merchantName: (
              <span className="ProductPageContent__merchant-name">
                {merchantName}
              </span>
            ),
            deliveryAndPaymentTerms: (
              <a href={deliveryAndPaymentTerms} target="_blank" rel="noopener">
                <FormattedMessage
                  {...globalTranslations.shoppingCenterDeliveryAndPaymentTerms}
                />
              </a>
            ),
            merchantPrivacyStatement: (
              <a href={merchantPrivacyStatement} target="_blank" rel="noopener">
                <FormattedMessage
                  {...globalTranslations.shoppingCenterPrivacyStatement}
                />
              </a>
            ),
          }}
        />
      </div>
    );
  };

  renderSideAd = () => {
    const { adSearchParams } = this.props;
    const bannerZone = AdZones.PRODUCT_PAGE_CONTENT_4;

    return (
      <LazyLoad once offset={50} height={25}>
        <AdLoader searchParams={{ ...adSearchParams, bannerZone }}>
          {(ads) => {
            if (!ads || ads.length === 0) {
              return null;
            }
            const ad = head(ads);
            return (
              <ContentSlot
                ad={ad}
                bannerZone={bannerZone}
                className="ProductPageContent__side-ad"
              />
            );
          }}
        </AdLoader>
      </LazyLoad>
    );
  };

  renderKlarnaPromotionWidget = (hidePrice, addToCartProduct, product) =>
    !hidePrice &&
    product.price_info && (
      <KlarnaPromotionWidget
        paymentModuleQueryResultKey={stringify(this.getWidgetQueryParameters())}
        priceInfo={addToCartProduct.price_info}
        widgetType={KLARNA_WIDGET_TYPE}
        ifSupportedCountryWithCurrency={this.ifSupportedCountryWithCurrency}
      />
    );

  getPurchaseCountriesAndCurrencies = () => {
    const { configStore } = this.props;
    if (!configStore.klarnaSupportedLocaleCurrencies) {
      return;
    }

    const supportedCountriesAndCurrencies =
      configStore.klarnaSupportedLocaleCurrencies;
    supportedCountriesAndCurrencies.split(';').forEach((currency) => {
      const supportedCountriesAndCurrenciesArr = currency.split(',');
      supportedCountriesAndCurrenciesArr[1] &&
        (this.supportedPurchaseCountriesAndCurrencies[
          supportedCountriesAndCurrenciesArr[0]
        ] = supportedCountriesAndCurrenciesArr[1]);
    });
  };

  supportedCountryWithCurrency = () => {
    const { countryStore, currencyStore } = this.props;
    if (
      !countryStore.activeCountry ||
      !countryStore.activeCountry.iso_code_2 ||
      !currencyStore.activeCurrency ||
      !currencyStore.activeCurrency.code
    ) {
      return;
    }

    const purchaseCountryCode = convertLocaleToFinlandIfAland(
      countryStore.activeCountry.iso_code_2
    );
    const purchaseCurrencyCode = currencyStore.activeCurrency.code;
    const purchaseCountry =
      this.supportedPurchaseCountriesAndCurrencies.hasOwnProperty(
        purchaseCountryCode
      );
    const purchaseCurrency =
      this.supportedPurchaseCountriesAndCurrencies[purchaseCountryCode];
    this.ifSupportedCountryWithCurrency =
      purchaseCountry && purchaseCurrencyCode === purchaseCurrency;
  };

  updateProductQuantity = (quantity) => {
    const { cartStore } = this.props;
    cartStore.updateProductQuantity(quantity);
    this.setState({ quantity });
  };

  renderProductInformationOnCartMatrix = () => (
    <div
      className="ProductPageContent__cart-matrix-product-information"
      onClick={() => this.scrollToTab(PRODUCT_INFORMATION_BANNER_ID, true)}
    >
      <Row className="ProductPageContent__cart-matrix-product-information-row">
        <Col
          md={12}
          className="ProductPageContent__cart-matrix-product-information-column"
        >
          <p className="ProductPageContent__cart-matrix-product-information-text">
            <FormattedMessage
              id="product.cartMatrixProductInformation"
              defaultMessage="Select the products to order from the table below."
            />
          </p>
        </Col>
      </Row>
    </div>
  );

  renderWitViewWidget = () => {
    const { configStore, languageStore } = this.props;
    const customerId = configStore.integrations.witView.customerId;
    const apiUrl = configStore.integrations.witView.apiUrl;

    if (!customerId.length > 0 || !apiUrl.length > 0) {
      return null;
    }

    return (
      <Fragment>
        <Helmet>
          <script>
            {`(function (w, i, t) {
            w._witViewPlugin = {
              ci: "${customerId}", //Client id
              locale: "${languageStore.activeLocale}",
              fields: {
                selectors: {
                  fullTitle: "h1.PageTitle", // selector for product name in the page
                  stars: '//div[@class="originalAggregateRating"]/div[@class="originalRatingValue"]/@content', //selector for parent site stars
                  reviewerCount: '//div[@class="originalAggregateRating"]/div[@class="originalReviewCount"]/@content', //selector for parent site
                },
                buttons: {
                  showShopReviewsLink: '.ProductPageContent__show-reviews-link',
                  submitReviewLink: '.ProductPageContent__write-review-link',
                },
                  hideMe: {
                  parentReviewTopStars: '.ProductReviewWidget',
                }
              }
            };
            a = i.getElementsByTagName('head')[0]; r = i.createElement('script'); r.async = 1; r.src = t + '/witview.js?ci=' +
            w._witViewPlugin.ci; a.appendChild(r);
            })(window, document, '${apiUrl}');`}
          </script>
        </Helmet>
      </Fragment>
    );
  };

  renderTitleBlock = () => {
    const { configStore, product } = this.props;
    const productTitle = product.multiproduct_title || product.name;

    return (
      <div>
        <PageTitle>{productTitle}</PageTitle>
        {this.ifShowModel() && product.model && (
          <div className="ProductPageContent__model">{product.model}</div>
        )}
        {configStore.product.shortDescriptionTop &&
          this.renderShortDescription()}
        {this.renderReviews()}
      </div>
    );
  };

  renderShortDescription = () => {
    const { configStore, product } = this.props;

    if (!configStore.product.showShortDescription) {
      return null;
    }

    return (
      <ProductDescription
        className="ProductPageContent__short-description"
        overflowHandler={this.goToDescription}
        product={product}
        isProductPage
      />
    );
  };

  renderReviews = () => {
    const { accountStore, configStore } = this.props;
    const hideReviews = accountStore.isViewOnly;
    const witViewEnabled = configStore.integrations.witView.enabled;
    let reviewsWidget = null;

    if (!configStore.reviews.enabled || hideReviews) {
      return null;
    }

    if (witViewEnabled) {
      reviewsWidget = this.renderWitviewReviews();
    } else {
      reviewsWidget = this.renderStoreReviews();
    }
    return reviewsWidget;
  };

  renderWitviewReviews = () => {
    const { intl, product } = this.props;
    const { noReviews } = this.getReviews();
    return (
      <div className="ProductPageContent__reviews">
        <ProductReviewWidget product={product} />
        <div className="wit-heading" data-wit-container="heading" />
        <ButtonLink
          className="ProductPageContent__show-reviews-link"
          onClick={this.goToReviews}
        />
        <ButtonLink
          className="ProductPageContent__write-review-link"
          onClick={this.goToReviews}
          aria-label={intl.formatMessage(globalTranslations.writeNewReview)}
        >
          {noReviews && (
            <FormattedMessage {...globalTranslations.writeNewReview} />
          )}
        </ButtonLink>
        {/* Store's own aggregate rating for WitView to read */}
        <div className="originalAggregateRating">
          <div
            className="originalRatingValue"
            content={product.reviews_average}
          />
          <div
            className="originalReviewCount"
            content={product.reviews_count}
          />
        </div>
      </div>
    );
  };

  renderStoreReviews = () => {
    const { configStore, product, intl } = this.props;
    const isWebStore = configStore.siteConfig.isWebStore;
    const isGiftCard = product.product_type === ProductTypeClass.GIFT_VOUCHER;

    if (isGiftCard) {
      return null;
    }

    return (
      <div className="ProductPageContent__reviews">
        {product.reviews_count > 0 ? (
          <ButtonLink
            className="ProductPageContent__show-reviews-link"
            onClick={this.goToReviews}
          >
            <ProductReviewWidget product={product} />
          </ButtonLink>
        ) : (
          isWebStore && (
            <ButtonLink
              className="ProductPageContent__write-review-link"
              onClick={this.goToReviews}
              aria-label={intl.formatMessage(globalTranslations.writeNewReview)}
            >
              <FormattedMessage {...globalTranslations.writeNewReview} />
            </ButtonLink>
          )
        )}
      </div>
    );
  };

  getReviews = () => {
    const { configStore, product } = this.props;
    const witViewEnabled = configStore.integrations.witView.enabled;

    let schemaAverage;
    let schemaCount;
    let witViewRating;

    if (witViewEnabled) {
      witViewRating = this.getWitViewRating();
    }

    if (witViewEnabled && witViewRating) {
      schemaAverage = witViewRating.reviews_average;
      schemaCount = witViewRating.reviews_count;
    } else {
      schemaAverage = product.reviews_average;
      schemaCount = product.reviews_count;
    }
    const noReviews = schemaCount === 0 && schemaAverage === 0;
    return { schemaAverage, schemaCount, noReviews };
  };

  getWitViewRating = () => {
    const { product } = this.props;

    if (!product.external_reviews) {
      return;
    }

    return product.external_reviews.find(
      ({ type }) => type === ExternalReviewType.WITVIEW
    );
  };

  renderKlarnaInstantShopping = (disabled) => {
    const { configStore, activeProductId, product } = this.props;
    const ifShoppingCenter = configStore.siteConfig.isShoppingCenter;
    const productIsUnavailable =
      activeProductId && product.isTemporarilyUnavailable(activeProductId);
    const merchantId = product.merchantId;

    return (
      ifShoppingCenter &&
      !productIsUnavailable && (
        <KlarnaInstantShopping
          activeProductId={activeProductId}
          product={product}
          disabled={disabled}
          quantity={this.state.quantity}
          merchantId={merchantId}
        />
      )
    );
  };

  renderMerchantInfo = () => {
    const { product, activeProductId } = this.props;

    if (!product.merchantInfo) {
      return null;
    }

    const activeProduct = product.getActualProduct(activeProductId) || product;
    const url = this.redirectToMerchantsPage(activeProduct.id);
    const ifBuyingAllowed = product.merchantInfo.buying_allowed;
    const merchantName = product.merchantInfo.site_name;

    return (
      <div className="ProductPageContent__merchant-info">
        <a href={url} target="_blank" rel="noopener">
          {product.merchantInfo.site_logo && (
            <div className="ProductPageContent__merchant-image">
              <img
                src={product.merchantInfo.site_logo}
                alt="Merchant Logo"
                loading="lazy"
              />
            </div>
          )}
          <div className="ProductPageContent__merchant-page-buy-products-info">
            {ifBuyingAllowed ? (
              <FormattedMessage
                id="productPageContent.shoppingCenterBuyProductsInfo"
                defaultMessage="If you buy several products at once, you can also go to the merchant's online store. There you can also find the merchant's whole selection"
              />
            ) : (
              <FormattedMessage
                id="productPageContent.shoppingCenterBuyingNotAllowed"
                defaultMessage="The Product is for sale in the {merchantName} online shop. Please proceed to merchant's store by clicking the link below."
                values={{
                  merchantName,
                }}
              />
            )}
            <Icon name="long-arrow-down" />
          </div>
          <div className="ProductPageContent__merchant-page-redirect-button">
            <FormattedMessage
              id="productPageContent.shoppingCenterProductMerchantLink"
              defaultMessage="Go to the merchant's online store"
            />
          </div>
        </a>
      </div>
    );
  };

  redirectToMerchantsPage = (productId) => {
    const { locationContext, languageStore } = this.props;
    const lang = languageStore.activeLanguage.code;
    return `${locationContext.protocol}//${locationContext.host}/backend/api/v1/shopping-center/redirect/${productId}?lang=${lang}`;
  };

  renderProductPurchaseGuideLink = () => {
    const { configStore } = this.props;
    const pageId = configStore.infoPages.productPurchaseGuide;

    const linkText = (
      <FormattedMessage
        id="productPageContent.productPurchaseGuideTitle"
        defaultMessage="See purchase guide"
      />
    );

    return <InfoPageLink pageId={Number(pageId)} linkText={linkText} />;
  };

  ifShowModel = () => {
    const { configStore, product } = this.props;

    if (product.class === ProductClass.MULTI) {
      return (
        configStore.productPage.showModelName && configStore.product.showModel
      );
    }

    return configStore.product.showModel;
  };

  getShowOutOfStockParam = () => {
    const params = this.props.queryParams;
    if (params) {
      return params.showOutOfStock;
    }

    return false;
  };

  renderNotValidForCartBanner = () => {
    if (this.state.productIsValidForCart) {
      return null;
    }

    return (
      <Alert color="warning" className="ProductPageContent__AddToCartMessage">
        <FormattedMessage
          id="productPageContent.productNotValidForCart"
          defaultMessage="This product does not have any common shipping methods with the products in the cart. For example pickup from different storage."
        />
      </Alert>
    );
  };

  getSchemaData = () => {
    const { accountStore, configStore, product } = this.props;
    const productTitle = product.multiproduct_title || product.name;
    const { schemaAverage, schemaCount } = this.getReviews();
    const hideReviews = accountStore.isViewOnly;
    const images = product.getImagesBySize('full');

    const baseData = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: productTitle,
      description: product.description_short || product.seoDescription,
      mpn: product.manufacturer_product_id,
      gtin: product.ean,
      image: images,
      sku: this.getProductSkuSchema(),
    };

    if (product.manufacturer) {
      baseData.brand = {
        '@type': 'Brand',
        name: product.manufacturer.name,
      };
    }

    if (this.ifShowModel()) {
      baseData.model = product.model;
    }

    if (product.mainCategory) {
      baseData.category = product.mainCategory.hierarchy
        .map((category) => category.name)
        .join('>');
    }

    if (
      configStore.reviews.enabled &&
      !hideReviews &&
      !configStore.integrations.yotpo.enabled &&
      product.reviews.length
    ) {
      baseData.aggregateRating = {
        '@type': 'AggregateRating',
        ratingValue: schemaAverage,
        reviewCount: schemaCount,
      };

      baseData.review = this.getProductReviewsSchema();
    }

    if (configStore.productPage.showWeight) {
      baseData.weight = product.display_weight;
    }

    baseData.offers = this.getProductOfferSchema();

    baseData.warranty = this.getProductWarrantySchema();

    return baseData;
  };

  getProductSkuSchema = () => {
    const { activeProductId, product } = this.props;

    return activeProductId || product.id;
  };

  getProductReviewsSchema = () => {
    const { intl, product } = this.props;

    return product.reviews.map((review) => ({
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: review.customers_name,
      },
      datePublished: DateTime.fromISO(review.data_added).toISODate(),
      reviewBody: review.description.reviews_text,
      reviewRating: [
        {
          '@type': 'Rating',
          ratingValue: review.reviews_rating,
          worstRating: 1,
          bestRating: 5,
          reviewAspect: intl.formatMessage(
            globalTranslations.reviewProductRatingTitle
          ),
        },
        {
          '@type': 'Rating',
          ratingValue: review.service_rating,
          worstRating: 1,
          bestRating: 5,
          reviewAspect: intl.formatMessage(
            globalTranslations.reviewServiceRatingTitle
          ),
        },
      ],
    }));
  };

  getProductOfferSchema = () => {
    const {
      accountStore,
      currencyStore,
      routeService,
      product,
      locationContext,
    } = this.props;

    const withTax = accountStore.showPricesWithTax;
    const baseUrlSchema = `${locationContext.protocol}//${locationContext.host}`;

    const baseOffer = {
      '@type': 'Offer',
      priceCurrency: currencyStore.currencyCode,
    };

    if (product.class === ProductClass.MULTI) {
      return product.multi.children.map((childProduct) => ({
        ...baseOffer,
        url: {
          '@type': 'URL',
          url: `${baseUrlSchema}${childProduct.pathWithActiveProductId(
            childProduct.id
          )}`,
        },
        price: childProduct.price_info
          ? roundWithPrecision(childProduct.price_info.getPrice(withTax), 2)
          : 0,
        priceValidUntil: this.getValidProductPriceDate(childProduct),
        availability: this.getAvailability(childProduct),
      }));
    }

    return {
      ...baseOffer,
      url: {
        '@type': 'URL',
        url: `${baseUrlSchema}${routeService.getProductPath(product)}`,
      },
      price: product.price_info
        ? roundWithPrecision(product.price_info.getPrice(withTax), 2)
        : 0,
      priceValidUntil: this.getValidProductPriceDate(product),
      availability: this.getAvailability(product),
    };
  };

  getValidProductPriceDate = (product) => {
    if (!product.price_info) {
      return null;
    }

    return DateTime.fromISO(product.price_info.valid_until).toISODate();
  };

  getAvailability = (product) => {
    if (!product.availability_html || product.availability_html.length === 0) {
      return null;
    }

    if (product.availability_type === ProductAvailabilityType.ONLY_IN_SHOP) {
      return 'https://schema.org/InStoreOnly';
    } else if (
      product.free_quantity === 0 &&
      product.availability_type === ProductAvailabilityType.ALLOW_BACKORDER
    ) {
      return 'https://schema.org/PreOrder';
    } else if (product.free_quantity > 0) {
      return 'https://schema.org/InStock';
    }
  };

  getProductWarrantySchema = () => {
    const { product } = this.props;

    return {
      '@type': 'WarrantyPromise',
      durationOfWarranty: {
        '@type': 'QuantitativeValue',
        value: product.warranty,
        unitCode: 'MON',
      },
    };
  };

  renderTabs = () => {
    const { uiStore, activeProductId, product } = this.props;

    const addToCartProduct =
      product.getActualProduct(activeProductId) || product;

    let tabs = this.getTabs(addToCartProduct);
    tabs = this.getVisibleTabs(tabs);

    const hash = this.getHash();
    const activeTabId = some(tabs, { id: hash }) ? hash : '';

    const tabProps = {
      activeTabId,
      openedTabs: this.state.openedTabs,
      toggle: this.toggleTab,
      tabs,
    };

    const tabComponent = uiStore.isMobile ? (
      <AccordionTabs {...tabProps} />
    ) : (
      <HashTabs {...tabProps} />
    );

    return (
      tabs &&
      tabs.length > 0 && (
        <div className="ProductPageContent__tabs">{tabComponent}</div>
      )
    );
  };

  handleProductChange = (productId, queryParams) => {
    const { setActiveProductId } = this.props;
    setActiveProductId(productId, queryParams);
  };

  sendAnalyticsEvents = () => {
    const { accountStore, analytics, activeProductId, product } = this.props;
    const analyticsProduct = {
      product,
      activeProductId,
    };

    analytics.productDetail({
      productList: [analyticsProduct],
      value: product.getPriceWithPrecision(
        accountStore.showPricesWithTax,
        activeProductId
      ),
    });
  };

  render() {
    const {
      shareButtons,
      product,
      activeProductId,
      categoryStore,
      configStore,
      uiStore,
      routeService,
      adSearchParams,
      locationContext,
      accountStore,
      paymentModuleStore,
    } = this.props;

    const { quantity } = this.state;

    const withTax = accountStore.showPricesWithTax;
    const isGiftCard = product.product_type === ProductTypeClass.GIFT_VOUCHER;
    const hideAddToCart = accountStore.isViewOnly;
    const hideStorageInfo = accountStore.isViewOnly || isGiftCard;
    let hidePrice = accountStore.isViewOnly;
    const addToCartProduct =
      product.getActualProduct(activeProductId) || product;

    const activeCollectionElementIds = this.getActiveCollectionElementIds();
    const activeCollectionElementIdsArray = Object.values(
      activeCollectionElementIds
    ).filter((item) => !!item);

    const price = <PriceWrapper product={product} />;

    const ifWebStore = configStore.siteConfig.isWebStore;
    const ifShoppingCenter = configStore.siteConfig.isShoppingCenter;
    const ifBuyingAllowed =
      product.merchantInfo && product.merchantInfo.buyingAllowed;

    const quantityDiscountDisplayStyle =
      configStore.product.productQuantityDiscountDisplayStyle;

    const { hasPriceRange } = product.getDiscountInfo(
      withTax,
      quantityDiscountDisplayStyle
    );

    if (price) {
      hidePrice = false;
    }

    const productForDiscountCalculations =
      product.isMulti() && product.getQuantityDiscounts().discount?.getLevels()
        ? product.multi.getChildWithBestDiscount()
        : product;

    const withRecurringOrder =
      configStore.recurringOrder.enabled &&
      addToCartProduct.hasRecurringOrder();

    let canonicalPath = routeService.getCanonicalProductPath(product);

    const hasImages = product.images.length > 0;
    const enquireForProposal = configStore.product.enquireForProposal;

    const soldOut =
      activeProductId &&
      addToCartProduct.availability_type === ProductAvailabilityType.CLOSEOUT &&
      !product.hasEnoughStockAvailable(activeProductId);

    const disabled = !activeProductId || soldOut;

    const widgets = paymentModuleStore.paymentWidgetQueryResults.get(
      stringify(this.getWidgetQueryParameters())
    );

    let hasWidget;
    if (widgets) {
      hasWidget = widgets.widgets.length > 0;
    }

    const ifOutOfStock = this.getShowOutOfStockParam();
    const isValidByShippingMethods =
      !configStore.productPage.validateForCartQuery ||
      this.state.hasValidShippingMethods;

    const showMatrix =
      !accountStore.isViewOnly &&
      ((product.class === ProductClass.COLLECTION &&
        accountStore.showCartMatrix) ||
        (product.class === ProductClass.MULTI &&
          accountStore.showMultiProductMatrix &&
          product.multi &&
          product.multi.canUseMatrixLayout()));

    const canBeReserved =
      configStore.reserveInStore.enabled &&
      !hideStorageInfo &&
      !showMatrix &&
      !product.isBlockedFromStoreReservation(
        configStore.reserveInStore.excludePropertyId
      );

    const useOnlyEnquiry =
      addToCartProduct.isProposalType(
        addToCartProduct.id,
        enquireForProposal
      ) && configStore.product.enableEnquiry;

    return (
      <div
        ref={this.setSelfRef}
        id="productPageContent"
        className="ProductPageContent"
      >
        <SEOSchema data={this.getSchemaData()} />
        <SEOTitle title={product.seoTitle} />
        {product.seoDescription && (
          <SEODescription content={product.seoDescription} />
        )}
        <CanonicalLink path={canonicalPath} />
        {uiStore.isMobile && (
          <div className="ProductPageContent__mobile-header">
            {this.renderTitleBlock()}
            {product.class !== ProductClass.MULTI && (
              <div className="ProductPageContent__id">
                <ProductCode code={addToCartProduct.productCode} />
              </div>
            )}
            <div className="ProductPageContent__mobile-header-links">
              {ifWebStore && product.manufacturer && (
                <div className="ProductPageContent__mobile-header-manufacturer">
                  {this.renderManufacturer()}
                </div>
              )}
              <div className="ProductPageContent__mobile-header-category">
                {!categoryStore.activeCategory && (
                  <FormattedMessage {...globalTranslations.loading} />
                )}
                {categoryStore.activeCategory && (
                  <CategoryLink
                    category={categoryStore.activeCategory}
                    className="ProductPageContent__extra-link"
                  >
                    <FormattedMessage
                      id="product.allProductsFromCategory"
                      defaultMessage="All {categoryLink}"
                      values={{
                        categoryLink: (
                          <span className="ProductPageContent__extra-link-target">
                            {categoryStore.activeCategory.navigationName}
                          </span>
                        ),
                      }}
                    />
                  </CategoryLink>
                )}
              </div>
            </div>
          </div>
        )}
        <Row>
          <CustomCol
            xs="12"
            md="6"
            className="ProductPageContent__column-images"
          >
            <ProductImages
              key={product.id}
              product={product}
              size="large"
              lazyLoading={false}
              activeElementIds={activeCollectionElementIdsArray}
              activeProductId={activeProductId}
              mainImageOverlay={
                <div className="ProductPageContent__product-images-overlay">
                  {hasImages &&
                    (!accountStore.showCartMatrix ||
                      product.class !== ProductClass.COLLECTION) && (
                      <ImageLightboxHelper visible={!uiStore.isDesktop} />
                    )}
                  <ProductFeatureImages
                    product={product}
                    position={'product_image'}
                  />
                  <ProductImageSigns product={product} />
                </div>
              }
            />
            <BannerSlider
              searchParams={{
                bannerZone: AdZones.PRODUCT_PAGE_BANNER_4,
                ...adSearchParams,
              }}
              aspectRatio={2.85}
              className="ProductPageContent__banner-slider--4"
            />
          </CustomCol>
          <CustomCol xs="12" md="6" className="ProductPageContent__column-info">
            {!uiStore.isMobile && (
              <div
                className={classNames('ProductPageContent__side-content', {
                  'col-xxl-5': ifShoppingCenter,
                })}
              >
                {product.manufacturer &&
                  product.manufacturer.image &&
                  ifWebStore &&
                  this.renderManufacturerImage(product)}
                {product.manufacturer &&
                  ifWebStore &&
                  this.renderManufacturer()}
                {ifShoppingCenter &&
                  uiStore.isLargest &&
                  this.renderMerchantDescription()}
                {ifShoppingCenter &&
                  uiStore.isLargest &&
                  ifBuyingAllowed &&
                  this.renderMerchantAcceptableTerms()}
                {this.renderSideAd()}
                {ifShoppingCenter &&
                  ifBuyingAllowed &&
                  uiStore.isLargest &&
                  this.renderProductPurchaseGuideLink()}
              </div>
            )}
            <div
              className={classNames('ProductPageContent__product-content', {
                'col-xxl-7': ifShoppingCenter,
              })}
            >
              {!uiStore.isMobile && this.renderTitleBlock()}
              {product.class !== ProductClass.MULTI && (
                <ProductOfferSchema product={product} />
              )}
              {price &&
                !addToCartProduct.isProposalType(
                  addToCartProduct.id,
                  enquireForProposal
                ) &&
                !hidePrice &&
                productForDiscountCalculations &&
                !ifOutOfStock && (
                  <div className="ProductPageContent__price">
                    <ProductPagePrice
                      price={price}
                      secondaryPrice={
                        (configStore.product.showSecondaryTaxPrice ||
                          accountStore.isRetailer) && (
                          <PriceSecondary product={product} />
                        )
                      }
                      isMulti={product.isMulti()}
                      product={productForDiscountCalculations}
                      quantityDiscounts={
                        addToCartProduct.getQuantityDiscounts().discounts
                      }
                      withDiscountUpTo={hasPriceRange}
                      withDiscountBalloon={!hasPriceRange}
                      withDiscountSavings={!hasPriceRange}
                      discountInverted={hasPriceRange}
                    />
                    <ProductDeposit
                      product={addToCartProduct || product}
                      showPrice={!configStore.productPage.depositInPrice}
                    />
                    {hasWidget &&
                      !isGiftCard &&
                      this.renderKlarnaPromotionWidget(
                        hidePrice,
                        addToCartProduct,
                        product
                      )}
                  </div>
                )}
              {product.class !== ProductClass.MULTI && (
                <div
                  className={classNames('ProductPageContent__extra-info', {
                    'ProductPageContent__extra-info-hide': hidePrice,
                  })}
                >
                  <ProductExtraInfo product={product} />
                </div>
              )}
              {!accountStore.isViewOnly && product.sizeGuides.length > 0 && (
                <ProductSizeGuides product={addToCartProduct} />
              )}
              {!showMatrix && product.class === ProductClass.COLLECTION && (
                <ProductCollectionProductPicker
                  product={product}
                  productImages={product.images}
                  activeProductId={activeProductId}
                  productCollection={product.collection}
                  onProductChange={(productId, queryParams) =>
                    this.handleProductChange(productId, queryParams)
                  }
                  columnId={activeCollectionElementIds.columnId}
                  rowId={activeCollectionElementIds.rowId}
                />
              )}
              {!showMatrix && product.class === ProductClass.MULTI && (
                <ProductMultiProductPicker
                  key={product.id}
                  scrollToTab={this.scrollToTab}
                  activeProductId={activeProductId}
                  product={product}
                  productImages={product.images}
                  onProductChange={(productId) =>
                    this.handleProductChange(productId)
                  }
                  path={`${locationContext.protocol}//${locationContext.host}`}
                />
              )}
              {ifShoppingCenter &&
                product.merchantInfo &&
                product.merchantInfo.site_name && (
                  <div className="ProductPageContent__merchant-info-site-name">
                    <span className="ProductPageContent__merchant-info-site-name-marker" />
                    <label>
                      <FormattedMessage
                        id="productPageContent.shoppingCenterSiteName"
                        defaultMessage="You're buying from {siteName}"
                        values={{
                          siteName: product.merchantInfo.site_name,
                        }}
                      />
                    </label>
                  </div>
                )}
              {!hideStorageInfo && (
                <Fragment>
                  <div className="ProductPageContent__availability-list">
                    {product.class === ProductClass.COLLECTION &&
                      activeProductId && (
                        <ProductAvailabilityList
                          scrollToTab={this.scrollToTab}
                          product={product}
                          activeProduct={
                            product.collection.getItemWithProductId(
                              activeProductId
                            ).product
                          }
                          shipping={this.showShipping()}
                        />
                      )}
                    {product.class === ProductClass.SINGLE && (
                      <ProductAvailabilityList
                        scrollToTab={this.scrollToTab}
                        product={product}
                        activeProduct={product}
                        shipping={this.showShipping()}
                      />
                    )}
                  </div>
                  <ProductDateAvailable product={addToCartProduct} />
                </Fragment>
              )}
              {addToCartProduct.sellInPackage && (
                <ProductPackageSize product={addToCartProduct} />
              )}
              {addToCartProduct.season && !hideAddToCart && (
                <ProductSeasonInfo season={addToCartProduct.season} />
              )}
              {configStore.product.allowFileUploads &&
                !hideAddToCart &&
                product.allow_file_upload && (
                  <ProductFileUploads product={addToCartProduct} />
                )}
              {addToCartProduct.getQuantityDiscounts().discounts?.getLevels() &&
                !hidePrice && (
                  <ProductQuantityDiscounts
                    quantityDiscounts={
                      addToCartProduct.getQuantityDiscounts().discounts
                    }
                    isMulti={addToCartProduct.isMulti()}
                    stockUnit={addToCartProduct.stock_unit}
                  />
                )}
              {useOnlyEnquiry ? (
                <ProductEnquiry
                  id={activeProductId}
                  type={ProductEnquiryType.PROPOSAL_REQUEST}
                />
              ) : (
                !hideAddToCart && (
                  <Fragment>
                    {!showMatrix && (
                      <>
                        <div
                          className="ProductPageContent__AddToCartPosition"
                          ref={this.setAddToCartRef}
                        />
                        {!ifShoppingCenter &&
                          this.renderNotValidForCartBanner()}
                        <ProductAddToCart
                          product={product}
                          price={price}
                          activeProductId={activeProductId}
                          addToCartProduct={addToCartProduct}
                          withRecurringOrder={withRecurringOrder}
                          onUpdateProductQuantity={this.updateProductQuantity}
                          history={this.props.history}
                          quantity={quantity}
                          ifWebStore={ifWebStore}
                          isValid={isValidByShippingMethods}
                          scrollToServices={this.scrollToTab}
                        />
                      </>
                    )}
                    {canBeReserved && product && (
                      <ProductReserveInStoreButton
                        product={product}
                        activeProductId={activeProductId}
                      />
                    )}
                    {ifBuyingAllowed &&
                      this.renderKlarnaInstantShopping(disabled)}
                    {ifShoppingCenter && this.renderMerchantInfo()}
                    {product.manufacturer &&
                      ifShoppingCenter &&
                      this.renderManufacturer()}
                    {ifShoppingCenter &&
                      !uiStore.isLargest &&
                      ifBuyingAllowed &&
                      this.renderMerchantAcceptableTerms()}
                    {ifShoppingCenter &&
                      ifBuyingAllowed &&
                      !uiStore.isLargest &&
                      this.renderProductPurchaseGuideLink()}
                    {showMatrix && this.renderProductInformationOnCartMatrix()}
                    {!soldOut &&
                      !showMatrix &&
                      !isGiftCard &&
                      configStore.wishlist.enabled && (
                        <ProductAddToWishList
                          disabled={disabled}
                          activeProductId={activeProductId}
                          product={addToCartProduct}
                          accountStore={accountStore}
                          quantity={quantity}
                        />
                      )}
                    {configStore.reserveInStore.enabled &&
                      !hideStorageInfo &&
                      (!accountStore.showCartMatrix ||
                        product.class !== ProductClass.COLLECTION) && (
                        <ProductReserveInStoreModal />
                      )}
                  </Fragment>
                )
              )}
              {addToCartProduct.availability_type ===
                ProductAvailabilityType.ONLY_IN_SHOP && <ProductOnlyInStore />}
              {configStore.product.shortDescriptionBottom &&
                this.renderShortDescription()}
              {activeProductId &&
                !addToCartProduct.isProposalType(
                  addToCartProduct.id,
                  enquireForProposal
                ) && (
                  <ProductEnquiry
                    id={activeProductId}
                    price={product.getPrice(withTax)}
                    type={ProductEnquiryType.MESSAGE}
                  />
                )}
              {this.pdfCatalogEnabled() && this.renderProductPdfButton()}
              <BannerSlider
                searchParams={{
                  bannerZone: AdZones.PRODUCT_PAGE_BANNER_1,
                  ...adSearchParams,
                }}
                aspectRatio={2.85}
                className="ProductPageContent__banner-slider--1"
              />
              <ContentSlots
                searchParams={{
                  bannerZone: AdZones.PRODUCT_PAGE_CONTENT_1,
                  ...adSearchParams,
                }}
                className="ProductPageContent__content-slots--1"
              />
            </div>
          </CustomCol>
        </Row>
        {showMatrix && (
          <ProductMatrix product={product} activeProductId={activeProductId} />
        )}
        {configStore.integrations.witView.enabled && !isGiftCard && (
          <Row>
            <Col sm="12">
              <div className="wit-body" data-wit-container="BodyLayout" />
              {this.renderWitViewWidget()}
            </Col>
          </Row>
        )}
        {configStore.integrations.swogo.enabled && <Swogo />}
        <Row>
          <Col sm="12">
            {uiStore.isMobile && shareButtons}
            {this.renderTabs()}
          </Col>
        </Row>
        <BannerSlider
          searchParams={{
            bannerZone: AdZones.PRODUCT_PAGE_BANNER_3,
            ...adSearchParams,
          }}
          aspectRatio={2.85}
          className="ProductPageContent__banner-slider--3"
        />
        <ContentSlots
          searchParams={{
            bannerZone: AdZones.PRODUCT_PAGE_CONTENT_3,
            ...adSearchParams,
          }}
          className="ProductPageContent__content-slots--3"
        />
        {ifShoppingCenter &&
          !uiStore.isLargest &&
          this.renderMerchantDescription()}
        {product && (
          <AlsoPurchasedProductsSlider
            key={activeProductId}
            product={product}
          />
        )}
        {product.id && (
          <LastVisitedProductsSlider currentProductId={product.id} />
        )}
      </div>
    );
  }
}

ProductPageContent.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  cartStore: modelOf(CartStore).isRequired,
  categoryStore: modelOf(CategoryStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  countryStore: modelOf(CountryStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  languageStore: modelOf(LanguageStore).isRequired,
  paymentModuleStore: modelOf(PaymentModuleStore).isRequired,
  productStore: modelOf(ProductStore).isRequired,
  product: modelOf(Product).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  apiWrapper: PropTypes.instanceOf(ApiWrapper).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  history: RouterPropTypes.history.isRequired,
  intl: intlShape.isRequired,
  locationContext: LocationContextPropType.isRequired,
  setActiveProductId: PropTypes.func.isRequired,
  activeProductId: PropTypes.string,
  queryParams: PropTypes.object,
  adSearchParams: PropTypes.object,
  shareButtons: PropTypes.node,
  cartLoaded: PropTypes.bool,
};

export default injectIntl(
  inject(
    'accountStore',
    'categoryStore',
    'cartStore',
    'configStore',
    'countryStore',
    'currencyStore',
    'languageStore',
    'paymentModuleStore',
    'productStore',
    'uiStore',
    'analytics',
    'apiWrapper',
    'routeService',
    'locationContext'
  )(withRouter(ProductPageContent))
);
