import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import ProductEnquiryModal from '../ProductEnquiryModal';
import Icon from '../../common/Icon';
import globalTranslations from '../../../i18n/globalTranslations';
import ProductEnquiryType from '../../../types/ProductEnquiryType';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';

@observer
export class ProductEnquiry extends Component {
  state = {
    modal: false,
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  getTitle = () => {
    const { type } = this.props;
    switch (type) {
      case ProductEnquiryType.PROPOSAL_REQUEST:
        return globalTranslations.productEnquiryProposalTitle;
      case ProductEnquiryType.MESSAGE:
      default:
        return globalTranslations.productEnquiryTitle;
    }
  };

  renderMessage = () => {
    return <FormattedMessage {...this.getTitle()} />;
  };

  render() {
    const { configStore, id, type, price } = this.props;

    if (!configStore.product.enableEnquiry) {
      return null;
    }

    return (
      <div className="ProductEnquiry">
        <ProductEnquiryModal
          id={id}
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          enquiry={{
            title: this.getTitle(),
            type: type,
          }}
          price={price}
        />
        <Button
          className="ProductEnquiry__modal-button"
          onClick={this.toggleModal}
        >
          <Icon name="question-circle" /> {this.renderMessage()}
        </Button>
      </div>
    );
  }
}

ProductEnquiry.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(ProductEnquiryType)),
  price: PropTypes.number,
};

export default inject('configStore')(ProductEnquiry);
