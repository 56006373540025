import React, { Component } from 'react';
import { Alert, Button, Form } from 'reactstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import PrivacyStatementLink from '../../privacy-statement/PrivacyStatementLink';
import globalTranslations from '../../../i18n/globalTranslations';
import { createForm } from '../../../models/Form';
import { createFormField } from '../../../models/FormField';
import { isEmail, notBlank } from '../../../util/formValidators';
import ApiWrapper from '../../../services/ApiWrapper';
import UnexpectedError from '../../loader/UnexpectedError';
import ConfigStore from '../../../store/ConfigStore';
import { modelOf } from '../../../prop-types';
import FormGroupField from '../../form/FormGroupField';
import Analytics from '../../../analytics/Analytics';

const SubscriptionStatus = {
  NONE: 'NONE',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  SUCCESS: 'SUCCESS',
  EMAIL_EXISTS: 'EMAIL_EXISTS',
  UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
};

@observer
export class NewsletterSubscriptionForm extends Component {
  form = null;
  state = {
    status: SubscriptionStatus.NONE,
  };

  constructor(props) {
    super(props);

    const { configStore } = this.props;

    const fields = {
      email: createFormField({}, [
        notBlank(<FormattedMessage {...globalTranslations.emailTitle} />),
        isEmail(<FormattedMessage {...globalTranslations.emailTitle} />),
      ]),
    };

    if (configStore.newsletter.requireName) {
      fields.firstName = createFormField({}, [
        notBlank(<FormattedMessage {...globalTranslations.firstNameTitle} />),
      ]);
      fields.lastName = createFormField({}, [
        notBlank(<FormattedMessage {...globalTranslations.lastNameTitle} />),
      ]);
    }

    this.form = createForm(fields);
  }

  handleSubmit = (e) => {
    const { configStore, analytics, apiWrapper } = this.props;
    e.preventDefault();
    this.form.validate();
    if (this.form.valid) {
      apiWrapper
        .apiAxios()
        .post('newsletter-subscriptions', this.form.getFieldValues())
        .then(() => {
          this.setState({
            status: SubscriptionStatus.SUCCESS,
          });
          configStore.analytics.ga4.enabled &&
            analytics.joinGroup('Newsletter');
        })
        .catch((e) => {
          const status = e.response ? e.response.status : 500;
          const errorMap = {
            400: SubscriptionStatus.VALIDATION_ERROR,
            409: SubscriptionStatus.EMAIL_EXISTS,
            422: SubscriptionStatus.VALIDATION_ERROR,
            500: SubscriptionStatus.UNEXPECTED_ERROR,
          };
          this.setState({
            status: errorMap[status],
          });
        });
    }
  };

  getField = (name, message) => {
    const { compact } = this.props;
    return (
      <div
        className={`NewsletterSubscriptionForm__field NewsletterSubscriptionForm__field--${name}`}
      >
        <FormGroupField
          field={this.form.fields.get(name)}
          label={<FormattedMessage {...message} />}
          formName="NewsletterSubscriptionForm"
          fieldName={name}
          hideLabel={compact}
          placeholder={compact && this.props.intl.formatMessage(message)}
        />
      </div>
    );
  };

  getForm = () => {
    const { configStore, compact } = this.props;
    return (
      <Form noValidate onSubmit={this.handleSubmit}>
        {configStore.newsletter.requireName && (
          <div className="NewsletterSubscriptionForm__row">
            {this.getField('firstName', globalTranslations.firstNameTitle)}
            {this.getField('lastName', globalTranslations.lastNameTitle)}
          </div>
        )}
        <div className="NewsletterSubscriptionForm__row">
          {this.getField('email', globalTranslations.emailTitle)}
          <Button
            className="NewsletterSubscriptionForm__submit"
            color="primary"
            disabled={this.form.valid === false}
          >
            {compact ? (
              <FormattedMessage
                id="newsletter.subscribeCompact"
                defaultMessage="Subscribe"
              />
            ) : (
              <FormattedMessage
                id="newsletter.subscribe"
                defaultMessage="Subscribe"
              />
            )}
          </Button>
        </div>
      </Form>
    );
  };

  render() {
    const { compact } = this.props;
    const { status } = this.state;
    let message;
    switch (status) {
      case SubscriptionStatus.NONE:
        break;
      case SubscriptionStatus.SUCCESS:
        message = (
          <Alert color="success">
            <FormattedMessage
              id="newsletter.status.SUCCESS"
              defaultMessage="You have been added to our e-mail list. Thank you for subscribing!"
            />
          </Alert>
        );
        break;
      case SubscriptionStatus.VALIDATION_ERROR:
        // This is just in case, as it should actually never happen, because we validate the fields client side.
        message = (
          <Alert color="danger">
            <FormattedMessage
              id="newsletter.status.VALIDATION_ERROR"
              defaultMessage="Information you provided is not valid. Please recheck the form."
            />
          </Alert>
        );
        break;
      case SubscriptionStatus.EMAIL_EXISTS:
        message = (
          <Alert color="danger">
            <FormattedMessage
              id="newsletter.status.EMAIL_EXISTS"
              defaultMessage="The e-mail address you entered is already in our e-mail list."
            />
          </Alert>
        );
        break;
      default:
      case SubscriptionStatus.UNEXPECTED_ERROR:
        message = <UnexpectedError />;
    }

    return (
      <div
        className={classNames('NewsletterSubscriptionForm', {
          'NewsletterSubscriptionForm--compact': compact,
        })}
      >
        {message}
        {status !== SubscriptionStatus.SUCCESS && this.getForm()}
        <div className="NewsletterSubscriptionForm__privacy-statement">
          <FormattedMessage
            id="newsletter.subscriberAgreesTo"
            defaultMessage="By subscribing you agree to"
          />{' '}
          <PrivacyStatementLink>
            <FormattedMessage
              id="privacyStatement.subscriberAgreesLink"
              defaultMessage="the Privacy Statement"
            />
          </PrivacyStatementLink>
          .
        </div>
      </div>
    );
  }
}

NewsletterSubscriptionForm.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  apiWrapper: PropTypes.instanceOf(ApiWrapper).isRequired,
  compact: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default inject(
  'analytics',
  'apiWrapper',
  'configStore'
)(injectIntl(NewsletterSubscriptionForm));
