import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { matchPath, withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';

import Icon from '../../common/Icon';
import CollapsibleMenu from '../../menu/CollapsibleMenu';
import MenuItemList from '../../menu/MenuItemList';
import globalTranslations from '../../../i18n/globalTranslations';
import FooterBackToTopButton from '../FooterBackToTopButton';
import FooterCartExpirationBanner from '../FooterCartExpirationBar';
import FooterPaymentModules from '../FooterPaymentModules';
import { modelOf } from '../../../prop-types';
import MenuStore from '../../../store/MenuStore';
import ConfigStore from '../../../store/ConfigStore';
import CartStore from '../../../store/CartStore';
import AccountStore from '../../../store/AccountStore';
import NewsletterSubscriptionForm from '../../newsletter/NewsletterSubscriptionForm';
import RequestState from '../../../types/RequestState';
import FooterColumn from '../FooterColumn';
import Paths from '../../../types/Paths';
import RouteService from '../../../services/RouteService';
import CouponStore from '../../../store/CouponStore';
import CouponText from '../../coupon/CouponText';
import FooterManufacturers from '../FooterManufacturers';
import BannerSlider from '../../ad/BannerSlider';
import AdZones from '../../../types/AdZones';
import UIStore from '../../../store/UIStore';
import AccountModalTab from '../../../types/AccountModalTab';
import Price from '../../product/Price';
import AdPages from '../../../types/AdPages';
import SectionStore from '../../../store/SectionStore';
import ManufacturerStore from '../../../store/ManufacturerStore';
import PaymentModuleStore from '../../../store/PaymentModuleStore';
import NavigationAssistButton from '../../common/NavigationAssistButton';
import MobileNavigationTab from '../../../types/MobileNavigationTab';
import SectionIntegrationType from '../../../types/SectionIntegrationType';
import Analytics from '../../../analytics/Analytics';
import CampaignCodeStore from '../../../store/CampaignCodeStore';
import FooterCopyright from '../FooterCopyright';

@observer
export class CommonFooter extends Component {
  constructor(props) {
    super(props);
    const { manufacturerStore } = this.props;

    if (
      !manufacturerStore.loadedWithContent &&
      manufacturerStore.state === RequestState.NONE
    ) {
      manufacturerStore.loadManufacturers(false);
    }
  }

  convertMenuToEntity = (chevron) => (menu) => {
    return {
      id: menu.id,
      label: (
        <Fragment>
          {typeof menu.title === 'string' ? (
            <div
              dangerouslySetInnerHTML={{
                __html: menu.title,
              }}
            />
          ) : menu.title !== null ? (
            menu.title
          ) : (
            ''
          )}
          {chevron && menu.children.length === 0 && (
            <Icon name="chevron-right" />
          )}
        </Fragment>
      ),
      path: menu.url,
      children: menu.children.map(this.convertMenuToEntity(false)),
    };
  };

  shouldHideMixedContent = () => {
    const { configStore, sectionStore } = this.props;
    return (
      configStore.integrations.zoovu.enabled &&
      sectionStore.activeSection &&
      sectionStore.activeSection.integration_type ===
        SectionIntegrationType.ZOOVU
    );
  };

  getMenuEntities = (chevron) => {
    const { configStore, menuStore } = this.props;

    // @todo This will have a separate configuration for visibility.
    if (!configStore.siteConfig.isStore) {
      return null;
    }

    return menuStore.menus.map(this.convertMenuToEntity(chevron));
  };

  getStaticDesktopColumnEntities = () => {
    const { routeService, configStore, accountStore, manufacturerStore } =
      this.props;

    const children = [];

    if (accountStore.isRetailer) {
      children.push({
        id: 'shopCustomerProducts',
        label: (
          <FormattedMessage {...globalTranslations.customerProductsTitle} />
        ),
        path: routeService.getPath(Paths.CustomerProducts),
      });
    }

    children.push({
      id: 'shopOnSale',
      label: <FormattedMessage {...globalTranslations.onSaleTitle} />,
      path: routeService.getPath(Paths.OnSale),
    });
    children.push({
      id: 'shopNewProducts',
      label: <FormattedMessage {...globalTranslations.newProductsTitle} />,
      path: routeService.getPath(Paths.NewProducts),
    });

    if (manufacturerStore.hasManufacturers) {
      children.push({
        id: 'shopBrands',
        label: <FormattedMessage {...globalTranslations.brandsTitle} />,
        path: routeService.getPath(Paths.ManufacturerList),
      });
    }

    if (!accountStore.loggedIn && configStore.registration.enabled) {
      children.push({
        id: 'shopRegistration',
        label: (
          <span
            onClick={(e) => {
              e.preventDefault();
              accountStore.accountModal.setTab(AccountModalTab.REGISTER);
              accountStore.accountModal.toggle();
            }}
          >
            <FormattedMessage
              id="account.doYouWantToRegister"
              defaultMessage="Would you like to become a registered customer?"
            />
          </span>
        ),
        path: '#',
      });
    }

    return [
      {
        id: 'shop',
        label: configStore.store.name,
        children,
      },
    ];
  };

  getStaticMobileColumnEntities = () => {
    const { configStore, routeService, couponStore, accountStore } = this.props;

    const mobileEntities = {
      beforeMenuItems: [],
      afterMenuItems: [],
    };

    if (configStore.newsletter.enabled) {
      mobileEntities.beforeMenuItems.push({
        id: 'infoNewsletter',
        label: (
          <span>
            <FormattedMessage
              {...globalTranslations.newsletterSubscriptionSentence}
            />{' '}
            {couponStore.newsletterCoupon && (
              <span>
                – <CouponText coupon={couponStore.newsletterCoupon} short />
              </span>
            )}
          </span>
        ),
        children: [
          {
            id: 'newsletter',
            label: this.getNewsLetterForm(),
          },
        ],
      });
    }

    if (configStore.customerService.email.length > 0) {
      mobileEntities.afterMenuItems.push({
        id: 'infoSendEmail',
        path: `mailto:${configStore.customerService.email}`,
        label: (
          <Fragment>
            <FormattedMessage
              {...globalTranslations.infoSendEmail}
              values={{
                email: configStore.customerService.email,
              }}
            />
            <Icon name="envelope" />
          </Fragment>
        ),
      });
    }

    if (configStore.customerService.phone.length > 0) {
      mobileEntities.afterMenuItems.push({
        id: 'infoCallPhone',
        path: `tel:${configStore.customerService.phone}`,
        label: (
          <Fragment>
            <div className="CommonFooter__text-container d-flex flex-column">
              <FormattedMessage
                {...globalTranslations.infoCallPhone}
                values={{
                  phone: configStore.customerService.phone,
                }}
              />
              {configStore.customerService.open.length > 0 && (
                <FormattedMessage
                  {...globalTranslations.isOpen}
                  values={{
                    timespan: configStore.customerService.open,
                  }}
                />
              )}
            </div>
            <Icon name="phone" />
          </Fragment>
        ),
      });
    }

    if (configStore.siteConfig.isStore) {
      mobileEntities.afterMenuItems.push({
        id: 'brands',
        path: routeService.getPath(Paths.ManufacturerList),
        label: (
          <Fragment>
            <FormattedMessage {...globalTranslations.ourBrandsTitle} />
            <Icon name="chevron-right" />
          </Fragment>
        ),
      });
    }

    if (!configStore.siteConfig.isShoppingCenter) {
      mobileEntities.afterMenuItems.push(this.getAccountColumnEntities());
    }

    if (!accountStore.isViewOnly && configStore.siteConfig.isWebStore) {
      mobileEntities.afterMenuItems.push({
        id: 'cartContents',
        label: (
          <FormattedMessage {...globalTranslations.proceedToCartSentence} />
        ),
        children: [
          {
            id: 'cartContents',
            label: this.getCartContents() || (
              <FormattedMessage
                id="cart.loadingCartContents"
                defaultMessage="Loading cart contents"
              />
            ),
          },
        ],
      });
    }

    return mobileEntities;
  };

  getAccountColumnEntities = () => {
    const { accountStore, configStore, routeService, analytics } = this.props;

    if (accountStore.loggedIn) {
      let accountPath = accountStore.isBalanceBuyer
        ? Paths.MyAccountBalanceUser
        : Paths.MyAccount;
      if (accountStore.isBalanceUser) {
        accountPath = Paths.MyAccountBalanceSubUser;
      }

      const entities = {
        id: 'account',
        label: <FormattedMessage {...globalTranslations.myAccountTitle} />,
        children: [
          {
            id: 'accountLogout',
            path: '#',
            label: (
              <span
                onClick={(e) => {
                  e.preventDefault();
                  analytics.clearCart();
                  accountStore.logout();
                }}
              >
                <FormattedMessage
                  {...globalTranslations.accountLogOutSentence}
                />
              </span>
            ),
          },
        ],
      };

      if (configStore.account.enableMyAccount) {
        entities.children.push({
          id: 'accountDetails',
          path: routeService.getPath(accountPath),
          label: (
            <FormattedMessage {...globalTranslations.myCustomerAccountTitle} />
          ),
        });
      }

      if (
        configStore.account.enableMyAccount &&
        !configStore.account.isMoneyTransfer
      ) {
        entities.children.push({
          id: 'accountHistory',
          path: routeService.getPath(Paths.MyAccountOrderList),
          label: (
            <FormattedMessage {...globalTranslations.myOrderHistoryTitle} />
          ),
        });
      }

      return entities;
    } else {
      return {
        id: 'account',
        label: <FormattedMessage {...globalTranslations.myAccountTitle} />,
        children: [
          {
            id: 'accountLogin',
            path: '#',
            label: (
              <span
                onClick={(e) => {
                  e.preventDefault();
                  accountStore.accountModal.setTab(AccountModalTab.LOGIN);
                  accountStore.accountModal.toggle();
                }}
              >
                <FormattedMessage {...globalTranslations.logInSentence} />
              </span>
            ),
          },
          {
            id: 'accountForgotPassword',
            path: '#',
            label: (
              <span
                onClick={(e) => {
                  e.preventDefault();
                  accountStore.accountModal.setTab(AccountModalTab.LOGIN);
                  accountStore.accountModal.toggle();
                  accountStore.accountModal.toggleResetPasswordVisible();
                }}
              >
                <FormattedMessage
                  id="account.forgotYourPassword"
                  defaultMessage="Forgot your password?"
                />
              </span>
            ),
          },
        ],
      };
    }
  };

  getCartContents = () => {
    const { accountStore, cartStore, configStore, intl } = this.props;
    const { checkout } = configStore;

    if (!cartStore.cart) {
      return null;
    }

    const withTax = accountStore.showPricesWithTax;
    const showTaxExcludedInfo = !withTax;

    return (
      <div className="CommonFooter__cart-contents-container">
        <FormattedMessage
          {...globalTranslations.itemCount}
          values={{
            count: cartStore.cart.number_of_products,
          }}
        />
        <Price
          price={cartStore.cart.total.getPrice(withTax)}
          showTaxExcludedInfo={showTaxExcludedInfo}
        />
        <Button
          color="success"
          tag="a"
          href={checkout.checkoutPath}
          className="CommonFooter__cart-contents-button"
          onClick={this.onClickHandler}
          aria-label={intl.formatMessage(
            globalTranslations.proceedToCheckoutSentence
          )}
          disabled={cartStore.cartButtonDisabled}
        >
          <Icon name="shopping-cart" />
          {this.getCartButtonContent()}
        </Button>
      </div>
    );
  };

  onClickHandler = () => {
    const { cartStore } = this.props;
    cartStore.toggleCartButton();
    this.sendAnalyticsCheckoutEvent();
  };

  sendAnalyticsCheckoutEvent = () => {
    const { accountStore, campaignCodeStore, cartStore, configStore, analytics } = this.props;

    if (!configStore.gtm.enabled) {
      return;
    }

    let coupon;
    const activeCoupon = campaignCodeStore.activeCoupon;
    if (activeCoupon && !activeCoupon.hasProductLimitations()) {
      coupon = activeCoupon.code;
    }

    const withTax = accountStore.showPricesWithTax;
    const cartProducts = cartStore.cart.products;
    const actionField = { step: 1, option: 'Move To Checkout' };
    const value = cartStore.cart.getCartTotalValue(withTax);
    analytics.checkout(cartProducts, actionField, value, coupon);
  };

  getCartButtonContent = () => {
    const { cartStore } = this.props;

    if (cartStore.cartButtonDisabled) {
      return <FormattedMessage {...globalTranslations.loading} />;
    }

    return <FormattedMessage {...globalTranslations.proceedToCartSentence} />;
  };

  getNewsLetterForm = () => {
    const { couponStore } = this.props;
    return (
      <div className="CommonFooter__newsletter-container">
        {couponStore.newsletterCoupon && (
          <p className="CommonFooter__newsletter-discount-coupon-paragraph">
            <CouponText coupon={couponStore.newsletterCoupon} short />
          </p>
        )}
        <FormattedMessage
          id="newsletter.isFree"
          defaultMessage="Newsletter is free"
        />
        <NewsletterSubscriptionForm compact />
      </div>
    );
  };

  getNewsletterColumn = () => {
    const { configStore } = this.props;

    if (!configStore.newsletter.enabled) {
      return null;
    }

    return (
      <FooterColumn
        lg={12}
        xl={3}
        className="CommonFooter__newsletter-column"
        title={
          <FormattedMessage
            {...globalTranslations.newsletterSubscriptionSentence}
          />
        }
      >
        {this.getNewsLetterForm()}
      </FooterColumn>
    );
  };

  getColumnFromEntity = (menu) => (
    <FooterColumn className={menu.className} key={menu.id} title={menu.label}>
      {menu.children && <MenuItemList items={menu.children} />}
    </FooterColumn>
  );

  getDesktopFooterItems = () => {
    const { configStore } = this.props;
    const entities = this.getMenuEntities(false);
    let menuColumnSizes = {};
    let menus, multipleMenus;

    if (entities) {
      menus = entities.filter(
        (menu) => menu.children && menu.children.length > 0
      );
      multipleMenus = menus.length > 1;
      if (multipleMenus) {
        menuColumnSizes = {
          xl: 4,
          md: 6,
          xs: 12,
        };
      }
    }

    const columns = [].concat(this.getStaticDesktopColumnEntities());

    if (!configStore.siteConfig.isShoppingCenter) {
      columns.push(this.getAccountColumnEntities());
    }

    return (
      <Fragment>
        {!configStore.siteConfig.isHomePage && (
          <div className="CommonFooter__links">
            <Container className="CommonFooter__menu-container">
              <Row>
                <Col xl={'1-24'} />
                {menus && menus.length > 0 && (
                  <FooterColumn
                    {...menuColumnSizes}
                    className={classNames('CommonFooter__menu-column', {
                      'CommonFooter__menu-column--multiple-menus':
                        multipleMenus,
                    })}
                  >
                    {menus.map((menu) => (
                      <div
                        className="CommonFooter__menu-column-menu"
                        key={menu.id}
                      >
                        <h5>{menu.label}</h5>
                        <MenuItemList items={menu.children} />
                      </div>
                    ))}
                  </FooterColumn>
                )}
                {columns.map((menuItem, i) =>
                  this.getColumnFromEntity(menuItem)
                )}
                {this.getNewsletterColumn()}
              </Row>
            </Container>
          </div>
        )}
        {configStore.visibility.footer.showManufacturers && (
          <div className="CommonFooter__manufacturers">
            <Container className="CommonFooter__manufacturers-container">
              <Row>
                <Col xl={{ size: 11, offset: '1-24' }} lg="12">
                  <FooterManufacturers />
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </Fragment>
    );
  };

  getMobileFooterItems = () => {
    const staticItems = this.getStaticMobileColumnEntities();
    const menuEntities = this.getMenuEntities(true);
    let items;

    if (menuEntities) {
      items = [].concat(
        staticItems.beforeMenuItems,
        menuEntities,
        staticItems.afterMenuItems
      );
    } else {
      items = [].concat(
        staticItems.beforeMenuItems,
        staticItems.afterMenuItems
      );
    }

    return (
      <div className="CommonFooter__links-small">
        {items.map((item) => (
          <div className="CommonFooter__links-small-link" key={item.id}>
            <CollapsibleMenu item={item} />
          </div>
        ))}
      </div>
    );
  };

  getAdPageParameter = () => {
    const { location, routeService, sectionStore } = this.props;

    const candidates = [
      { path: Paths.FrontPage, parameter: AdPages.FRONT_PAGE },
      { path: Paths.NewProducts, parameter: AdPages.NEW_PRODUCTS },
      { path: Paths.OnSale, parameter: AdPages.CAMPAIGN_PRODUCTS },
    ];

    const matchingPath = candidates.find((candidate) => {
      return matchPath(location.pathname, {
        path: routeService.getPath(candidate.path, sectionStore.activeSection),
        strict: false,
        exact: true,
      });
    });

    if (matchingPath) {
      return matchingPath.parameter;
    }

    return undefined;
  };

  getDefaultMobileMenuTab = () => {
    const { location } = this.props;

    if (
      matchPath(location.pathname, {
        path: '/:locale?' + Paths.InfoPage,
      })
    ) {
      return MobileNavigationTab.INFO;
    }

    return MobileNavigationTab.PRODUCTS;
  };

  render() {
    const {
      menuStore,
      cartStore,
      uiStore,
      configStore,
      accountStore,
    } = this.props;

    const isCartExpirable =
      !accountStore.isViewOnly &&
      cartStore.cart &&
      cartStore.cart.expires_at &&
      cartStore.cart.number_of_products > 0;
    const hideMobileAssistMenu =
      configStore.mobileSettings.showAssistantMenu !== '2';
    return (
      <div className="CommonFooter">
        {!this.shouldHideMixedContent() && (
          <BannerSlider
            className="FooterBanner"
            searchParams={{
              bannerZone: AdZones.FOOTER_BANNER,
              bannerPage: this.getAdPageParameter(),
            }}
            aspectRatio={2.85}
          />
        )}
        {isCartExpirable && <FooterCartExpirationBanner />}
        <FooterBackToTopButton />
        {!this.shouldHideMixedContent() &&
          uiStore.isMobile &&
          hideMobileAssistMenu && (
            <div className="CommonFooter__mobile-assist">
              <NavigationAssistButton
                bottom
                tab={this.getDefaultMobileMenuTab()}
              />
            </div>
          )}
        {!this.shouldHideMixedContent() &&
          menuStore.state === RequestState.LOADED &&
          (uiStore.isMobile
            ? this.getMobileFooterItems()
            : this.getDesktopFooterItems())}
        {!this.shouldHideMixedContent() && !configStore.siteConfig.isHomePage && (
          <Container className="FooterPaymentModules__container">
            <Row>
              <Col xs="12">
                <FooterPaymentModules />
              </Col>
            </Row>
          </Container>
        )}
        <FooterCopyright />
      </div>
    );
  }
}

CommonFooter.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  campaignCodeStore: modelOf(CampaignCodeStore).isRequired,
  cartStore: modelOf(CartStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  couponStore: modelOf(CouponStore).isRequired,
  manufacturerStore: modelOf(ManufacturerStore).isRequired,
  menuStore: modelOf(MenuStore).isRequired,
  paymentModuleStore: modelOf(PaymentModuleStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  intl: intlShape.isRequired,
  location: RouterPropTypes.location.isRequired,
};

export default injectIntl(
  withRouter(
    inject(
      'accountStore',
      'campaignCodeStore',
      'cartStore',
      'configStore',
      'couponStore',
      'menuStore',
      'uiStore',
      'sectionStore',
      'manufacturerStore',
      'routeService',
      'analytics',
      'paymentModuleStore'
    )(CommonFooter)
  )
);
