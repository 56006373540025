import React, { Component } from 'react';
import classNames from 'classnames';

import LinkType from '../../../types/LinkType';

class ButtonLink extends Component {
  onClick = (e) => {
    e.preventDefault();
    this.props.onClick(e);
  };

  onKeyPress = (e) => {
    if (e.key === ' ' || e.key === 'Enter') {
      this.props.onClick(e);
    }
  };

  getTarget = () => {
    const { type } = this.props;

    if (!type) {
      return;
    }

    if (type === LinkType.EXTERNAL_LINK) {
      return '_blank';
    }

    return '_self';
  };

  render() {
    const { className, children, onClick, type, ...rest } = this.props;

    return (
      <a
        tabIndex="0"
        role="button"
        className={classNames('ButtonLink', className)}
        onClick={this.onClick}
        onKeyPress={this.onKeyPress}
        target={this.getTarget()}
        {...rest}
      >
        {children}
      </a>
    );
  }
}

export default ButtonLink;
