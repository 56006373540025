import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { withRouter, NavLink } from 'react-router-dom';
import { some, each } from 'lodash';
import RouterPropTypes from 'react-router-prop-types';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import ProductTab, {
  ProductTabName,
  ProductTabSearchParams,
} from '../../../types/ProductTab';
import ConfigStore from '../../../store/ConfigStore';
import { modelOf } from '../../../prop-types';
import TabProductList from '../../product-list/TabProductList';
import ProductRecommendedPosition from '../../../types/ProductRecommendedPosition';
import HashTabs, { createHashTab } from '../../common/HashTabs';
import { paramsToQueryIdentifier } from '../../../util/query';
import ProductStore from '../../../store/ProductStore';
import globalTranslations from '../../../i18n/globalTranslations';
import RouteService from '../../../services/RouteService';
import SectionStore from '../../../store/SectionStore';
import Paths from '../../../types/Paths';
import Icon from '../../common/Icon';
import { addParametersToPath } from '../../../util/queryString';
import { parseHash } from '../../../util/url';
import ProductSlider from '../../slider/ProductSlider';
import SimpleListTabs from '../../common/SimpleListTabs';
import ProductList from '../../product-list/ProductList';
import UIStore from '../../../store/UIStore';
import ProductSortOrderBy from '../../../types/ProductSortOrderBy';

const messages = defineMessages({
  'product.showAllOfType.RECOMMENDED_PRODUCTS': {
    id: 'product.showAllOfType.RECOMMENDED_PRODUCTS',
    defaultMessage: 'recommended products',
  },
  'product.showAllOfType.MOST_SOLD_PRODUCTS': {
    id: 'product.showAllOfType.MOST_SOLD_PRODUCTS',
    defaultMessage: 'popular products',
  },
  'product.showAllOfType.NEW_PRODUCTS': {
    id: 'product.showAllOfType.NEW_PRODUCTS',
    defaultMessage: 'new products',
  },
  'product.showAllOfType.CAMPAIGN_PRODUCTS': {
    id: 'product.showAllOfType.CAMPAIGN_PRODUCTS',
    defaultMessage: 'products on sale',
  },
  'product.showAllOfType.FALLBACK': {
    id: 'product.showAllOfType.FALLBACK',
    defaultMessage: 'products',
  },
});

@observer
export class ProductTabs extends Component {
  toggleTab = (tabId) => {
    if (tabId && this.getHash() !== tabId) {
      this.setHash(tabId);
    }
  };

  setHash = (hash) => {
    const { history } = this.props;
    const hashString = hash ? '#' + hash : '';
    history.replace(
      history.location.pathname + history.location.search + hashString
    );
  };

  getHash = () => {
    const { history } = this.props;
    if (history.location.hash) {
      // History hash includes the hashtag (#), strip it off.
      return parseHash(history.location.hash);
    }
    return '';
  };

  getTabSettings = (section, categoryIds) => {
    const { routeService, configStore, searchParams } = this.props;

    let recommendedPositions;
    if (searchParams.categories && searchParams.categories.length > 0) {
      recommendedPositions = [
        ProductRecommendedPosition.CATEGORY,
        ProductRecommendedPosition.EVERYWHERE,
      ];
    } else {
      recommendedPositions = [
        ProductRecommendedPosition.FRONTPAGE,
        ProductRecommendedPosition.EVERYWHERE,
      ];
    }

    const tabs = {
      [ProductTab.RECOMMENDED_PRODUCTS]: {
        labelMessage: globalTranslations.weRecommendTitle,
        completeListingPath: routeService.getPath(
          Paths.RecommendedProducts,
          section
        ),
        dynamicTabSearchParams: {
          recommendedPositions,
        },
      },
      [ProductTab.MOST_SOLD_PRODUCTS]: {
        labelMessage: globalTranslations.mostPopularTitle,
        completeListingPath: routeService.getPath(
          Paths.PopularProducts,
          section
        ),
      },
      [ProductTab.NEW_PRODUCTS]: {
        labelMessage: globalTranslations.newProductsTitle,
        completeListingPath: routeService.getPath(Paths.NewProducts, section),
        dynamicTabSearchParams: {
          dateAdded: {
            // We set second and millisecond to 0 in order for the query key not to
            // change every (milli)second
            min: DateTime.fromObject({
              second: 0,
              millisecond: 0,
            })
              .minus({ seconds: configStore.product.isNewForSeconds })
              .toISO(),
          },
        },
      },
      [ProductTab.CAMPAIGN_PRODUCTS]: {
        labelMessage: globalTranslations.onSaleTitle,
        completeListingPath: routeService.getPath(Paths.OnSale, section),
      },
    };

    if (categoryIds) {
      each(
        tabs,
        (tab) =>
          (tab.completeListingPath = addParametersToPath(
            tab.completeListingPath,
            {
              f: { categories: categoryIds },
            }
          ))
      );
    }

    return tabs;
  };

  createListIdFromName = (tabName) => tabName.toLowerCase().replace(/\s/, '_');

  render() {
    const {
      searchParams,
      configStore,
      productStore,
      sectionStore,
      uiStore,
      singleRowMode,
      requireCanonicalLink,
    } = this.props;

    const ListComponent = uiStore.isMobile ? ProductSlider : ProductList;
    const ContainerComponent = uiStore.isMobile ? SimpleListTabs : HashTabs;
    const isSingleRow = singleRowMode && !uiStore.isMobile;
    const componentProps = {};
    if (uiStore.isMobile) {
      componentProps.lazyLoadOptions = {
        height: 400,
      };
    }

    let section;
    if (searchParams.sections && searchParams.sections.length > 0) {
      section = sectionStore.findSectionById(searchParams.sections[0]);
    }

    let categoryIds;
    if (searchParams.categories && searchParams.categories.length > 0) {
      categoryIds = searchParams.categories;
    }

    const configurationParams = {
      inStock: configStore.inStockProductsOnly ? '1' : '0',
    };

    const tabSettings = this.getTabSettings(section, categoryIds);
    const tabSearchParams = {};
    configStore.frontpageTabOrder.forEach((tabId) => {
      const dynamicTabSearchParams =
        tabSettings[tabId].dynamicTabSearchParams || {};

      if (
        configStore.siteConfig.isShoppingCenter &&
        ProductTabSearchParams[tabId].orderBy ===
          ProductSortOrderBy.CAMPAIGN_DATE_ADDED
      ) {
        ProductTabSearchParams[tabId].orderBy = ProductSortOrderBy.DISCOUNT;
      }
      const optimizer = {
        excludeContent: 1,
        optimizeResponse: 1,
      };
      tabSearchParams[tabId] = {
        resultsPerPage: singleRowMode ? 5 : configStore.frontpageTabMaxProducts,
        ...ProductTabSearchParams[tabId],
        ...searchParams,
        ...dynamicTabSearchParams,
        ...configurationParams,
        ...optimizer,
      };
    });

    const visibleTabs = configStore.frontpageTabOrder
      .filter((tabId) => {
        const params = tabSearchParams[tabId];
        const queryResults = productStore.productQueryResults.get(
          paramsToQueryIdentifier(params)
        );
        const loadedAndResultEmpty =
          queryResults && queryResults.data.length === 0;
        return !loadedAndResultEmpty;
      })
      .map((tabId, index) => {
        const tabName = ProductTabName[tabId];
        const params = tabSearchParams[tabId];

        const listingLink = tabSettings[tabId].completeListingPath ? (
          <NavLink
            to={tabSettings[tabId].completeListingPath}
            className="ProductList__complete-listing-link"
          >
            <FormattedMessage
              id="product.showAllOfType"
              defaultMessage="Show all {type}"
              values={{
                type: (
                  <FormattedMessage
                    {...(messages[`product.showAllOfType.${tabName}`] ||
                      messages['product.showAllOfType.FALLBACK'])}
                  />
                ),
              }}
            />
            <Icon name="angle-right" />
          </NavLink>
        ) : null;

        return createHashTab(
          tabName,
          <FormattedMessage {...tabSettings[tabId].labelMessage} />,
          (contextProps) => (
            <TabProductList
              searchParams={params}
              name={`Tab "${tabName}"`}
              listId={this.createListIdFromName(tabName)}
              completeListingLink={listingLink}
              listComponent={ListComponent}
              singleRowMode={singleRowMode}
              requireCanonicalLink={requireCanonicalLink}
              isViewPortImages={index === 0}
              {...componentProps}
              {...contextProps}
            />
          )
        );
      });

    const hash = this.getHash();
    const activeTabId = some(visibleTabs, { id: hash }) ? hash : '';

    return (
      <div
        className={classNames('ProductTabs', {
          'ProductTabs__single-row': isSingleRow,
        })}
      >
        <ContainerComponent
          tabs={visibleTabs}
          toggle={this.toggleTab}
          activeTabId={activeTabId}
        />
      </div>
    );
  }
}

ProductTabs.propTypes = {
  searchParams: PropTypes.object,
  productStore: modelOf(ProductStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  history: RouterPropTypes.history,
  singleRowMode: PropTypes.bool,
  requireCanonicalLink: PropTypes.bool,
};

ProductTabs.defaultProps = {
  searchParams: {},
  singleRowMode: false,
  requireCanonicalLink: false,
};

export default inject(
  'configStore',
  'productStore',
  'sectionStore',
  'uiStore',
  'routeService'
)(withRouter(ProductTabs));
