import React from 'react';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import ProductCard from '../../product/ProductCard';
import { ViewBreakpointMinWidth } from '../../../types/ViewBreakpoint';
import Slider from '../../common/Slider';
import ProductImpressions from '../../product/ProductImpressions';

const SLIDER_SETTINGS = {
  draggable: true,
  arrows: true,
  infinite: true,
  slidesToShow: 6,
  slidesToScroll: 6,
  dots: true,
  lazyLoad: true,
  responsive: [
    {
      breakpoint: ViewBreakpointMinWidth.XXL,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: ViewBreakpointMinWidth.XL,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: ViewBreakpointMinWidth.MD,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: ViewBreakpointMinWidth.SM,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

export const ProductSlider = ({
  products,
  name,
  listId,
  settings,
  cardSettings,
  disableImpressions,
  lazyLoad,
  isViewPortImages,
  ...rest
}) => {
  const slides = products.map((product, index) => {
    const lazyLoading =
      !isViewPortImages || (isViewPortImages && index > 1 && lazyLoad);
    const productCardSettings = {
      product,
      productListView: true,
      position: index + 1,
      listId,
      listName: name,
      hideDescription: false,
      modelMargin: true,
      lazyLoading,
      ...cardSettings,
    };

    return (
      <div key={product.id}>
        <ProductCard {...productCardSettings} />
      </div>
    );
  });
  const sliderSettings = settings
    ? { ...SLIDER_SETTINGS, ...settings }
    : SLIDER_SETTINGS;

  return (
    <div className="ProductSlider">
      <Slider
        {...sliderSettings}
        {...rest}
        key={products.map((product) => product.id).join(',')}
      >
        {slides}
      </Slider>
      {!disableImpressions && (
        <ProductImpressions
          listId={listId}
          listName={name}
          products={products.slice()}
        />
      )}
    </div>
  );
};

ProductSlider.propTypes = {
  products: PropTypes.oneOfType([
    MobxPropTypes.observableArrayOf(modelOf(Product)),
    PropTypes.arrayOf(modelOf(Product)),
  ]).isRequired,
  listId: PropTypes.string,
  name: PropTypes.string,
  disableImpressions: PropTypes.bool,
  settings: PropTypes.object,
  cardSettings: PropTypes.object,
};

export default observer(ProductSlider);
