import { types } from 'mobx-state-tree';
import { TranslatedImageTypeType } from '../types/TranslatedImageType';

const TranslatedImage = types.model('TranslatedImage', {
  id: types.identifierNumber,
  image_key: TranslatedImageTypeType,
  image_path: types.string
});

export default TranslatedImage;
