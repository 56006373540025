import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { sortBy } from 'lodash';

import ProductImage from '../ProductImage';
import classNames from 'classnames';
import ViewBreakpoint from '../../../types/ViewBreakpoint';
import ProductClass from '../../../types/ProductClass';
import Product from '../../../models/Product';
import { modelOf } from '../../../prop-types';
import Slider from '../../common/Slider';
import UIStore from '../../../store/UIStore';
import ProductRotatingImage from '../ProductRotatingImage';
import ImageLightbox from '../../common/ImageLightbox';
import ConfigStore from '../../../store/ConfigStore';
import AccountStore from '../../../store/AccountStore';

@observer
export class ProductImages extends Component {
  thumbnailSlider = null;
  mainImageSlider = null;

  constructor(props) {
    super(props);

    const mainImageIndex = props.accountStore.showCartMatrix
      ? this.findMainImage()
      : 0;

    this.state = {
      mainImageIndex,
      currentThumbnailSliderIndex: 0,
      lightboxIsOpen: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { product } = this.props;
    const { mainImageIndex } = this.state;
    if (prevState.mainImageIndex !== mainImageIndex) {
      if (this.thumbnailSlider && !this.thumbnailIsVisible(mainImageIndex)) {
        const slidesPerPage = this.getThumbnailSliderSlidesToShow();
        const firstSlideOfPageIndex =
          Math.floor(mainImageIndex / slidesPerPage) * slidesPerPage;
        const lastNavigatableIndex = product.images.length - slidesPerPage;
        // slickGoTo does not work if the targetIndex + slidesPerPage is bigger
        // than number of available slides.
        const goToIndex = Math.min(lastNavigatableIndex, firstSlideOfPageIndex);

        this.thumbnailSlider.slickGoTo(goToIndex);
      }
      if (this.mainImageSlider) {
        this.mainImageSlider.slickGoTo(mainImageIndex, true);
      }
    }
  }

  findMainImage = () => {
    const images = this.props.product.images.slice();
    const sortedImages = sortBy(images, ['for_color_id']);
    const mainImageIndex = sortedImages.findIndex(
      (image) => image.for_color_id === null
    );
    return mainImageIndex !== -1 ? mainImageIndex : 0;
  };

  static getDerivedStateFromProps(nextProps) {
    let mainImageIndex = -1;

    const isMulti = nextProps.product.class === ProductClass.MULTI;
    if (isMulti && nextProps.activeProductId) {
      mainImageIndex = nextProps.product.images.findIndex(
        (image) => nextProps.activeProductId === image.product_id
      );
    }

    const isCollection = nextProps.product.class === ProductClass.COLLECTION;
    if (isCollection && nextProps.activeElementIds) {
      mainImageIndex = nextProps.product.images.findIndex(
        (image) => nextProps.activeElementIds.indexOf(image.for_color_id) !== -1
      );
    }

    if (mainImageIndex >= 0) {
      return {
        mainImageIndex,
      };
    }

    return null;
  }

  toggleLightbox = () => {
    this.setState({
      lightboxIsOpen: !this.state.lightboxIsOpen,
    });
  };

  thumbnailIsVisible = (thumbnailIndex) => {
    const { product } = this.props;
    const { currentThumbnailSliderIndex } = this.state;

    const pageSize = this.getThumbnailSliderSlidesToShow();
    const lastIndex = product.images.length - 1;
    const currentPageLastSlideIndex =
      currentThumbnailSliderIndex + pageSize - 1;

    let firstVisibleSlideIndex;
    let lastVisibleSlideIndex;
    if (currentPageLastSlideIndex > lastIndex) {
      firstVisibleSlideIndex = lastIndex - pageSize + 1;
      lastVisibleSlideIndex = firstVisibleSlideIndex + pageSize - 1;
    } else {
      firstVisibleSlideIndex = currentThumbnailSliderIndex;
      lastVisibleSlideIndex = currentPageLastSlideIndex;
    }

    return (
      thumbnailIndex >= firstVisibleSlideIndex &&
      thumbnailIndex <= lastVisibleSlideIndex
    );
  };

  getThumbnails = () => {
    const { product } = this.props;
    if (!product || this.getTotalSlidesCount() === 0) {
      return null;
    }

    const hasProductImages = product.images && product.images.length;

    const thumbnails = this.ifFilterDuplicateImages()
      ? this.getProductMultiThumbnails(hasProductImages)
      : this.getProductThumbnails(hasProductImages);

    const sliderSettings = this.getThumbnailSliderSettings();

    return (
      <div className="ProductImages__thumbnails">
        <Slider
          key={product.id}
          sliderRef={(ref) => (this.thumbnailSlider = ref)}
          arrows={this.getTotalSlidesCount() > sliderSettings.slidesToShow}
          {...sliderSettings}
        >
          {this.getRotatingImagesThumbnails()}
          {thumbnails}
        </Slider>
      </div>
    );
  };

  getTotalSlidesCount = () => {
    const { product } = this.props;
    const imagesCount = product && product.images ? product.images.length : 0;
    const rotatingImagesCount =
      product && product.rotating_images ? product.rotating_images.length : 0;

    return imagesCount + rotatingImagesCount;
  };

  ifFilterDuplicateImages = () => {
    const { product, configStore } = this.props;
    return (
      product.class === ProductClass.MULTI &&
      (configStore.product.multiproductAsDropdown ||
        !configStore.product.hideMultiProductSelectionImage)
    );
  };

  getProductThumbnails = (hasProductImages) => {
    const { product } = this.props;

    return (
      hasProductImages &&
      product.images.map((productImage, index) => {
        if (productImage.for_color_id) {
          return null;
        }

        return this.renderThumbnailSlide(index, productImage);
      })
    );
  };

  getProductMultiThumbnails = (hasProductImages) => {
    const { product } = this.props;

    return (
      hasProductImages &&
      product.images.map((productImage, index) => {
        const parentImage = this.getParentProductImages(
          productImage.product_id
        );

        if (!parentImage) {
          return null;
        }

        return this.renderThumbnailSlide(index, productImage);
      })
    );
  };

  renderThumbnailSlide = (index, productImage) => {
    const { product } = this.props;
    return (
      <div
        key={`${productImage.id}__${productImage.product_id}__${productImage.sizes.full}`}
        className="ProductImages__thumbnail-slide"
      >
        <div
          className={classNames('ProductImages__thumbnail', {
            'ProductImages__thumbnail--active':
              this.state.mainImageIndex === index,
          })}
          onMouseOver={() => this.setMainImageIndex(index)}
          onClick={() => this.setMainImageIndex(index)}
        >
          <ProductImage
            product={
              product.getActualProduct(productImage.product_id) || product
            }
            productImage={productImage}
            size="small"
            lazyLoading={false}
            schema
          />
        </div>
      </div>
    );
  };

  getParentProductImages = (productImageProductId) =>
    productImageProductId === this.props.product.id;

  getThumbnailSliderSettings = () => {
    const slidesToShow = this.getThumbnailSliderSlidesToShow();

    const commonSettings = {
      draggable: true,
      swipeToSlide: true,
      infinite: false,
      events: {
        afterChange: this.thumbnailSliderOnAfterChange,
      },
      slidesToShow,
      slidesToScroll: slidesToShow,
    };

    if (this.shouldShowThumbnailsBelow()) {
      return {
        ...commonSettings,
      };
    } else {
      return {
        ...commonSettings,
        vertical: true,
        verticalSwiping: true,
      };
    }
  };

  thumbnailSliderOnAfterChange = (event, slick, currentSlide) =>
    this.setState({ currentThumbnailSliderIndex: currentSlide });

  getRotatingImagesThumbnails = () => {
    const { product } = this.props;

    if (
      !product ||
      !product.rotating_images ||
      product.rotating_images.length === 0
    ) {
      return null;
    }

    return product.rotating_images.map((filePath) => (
      <div key={filePath} className="ProductImages__thumbnail-slide">
        <div className="ProductImages__thumbnail">
          <ProductRotatingImage product={product} filePath={filePath} />
        </div>
      </div>
    ));
  };

  setMainImageIndex = (mainImageIndex) => this.setState({ mainImageIndex });

  shouldShowThumbnailsBelow = () => {
    const { uiStore } = this.props;
    return ![ViewBreakpoint.XL, ViewBreakpoint.XXL].includes(
      uiStore.activeBreakpoint
    );
  };

  getThumbnailSliderSlidesToShow = () => {
    const { uiStore } = this.props;
    switch (uiStore.activeBreakpoint) {
      case ViewBreakpoint.XS:
        return 6;
      case ViewBreakpoint.SM:
        return 8;
      default:
        return 6;
    }
  };

  mainImageSliderOnAfterChange = (event, slick, currentSlide) => {
    this.setState({ mainImageIndex: currentSlide });
  };

  getMainImage() {
    const { product, size, mainImageOverlay, uiStore } = this.props;
    const { mainImageIndex } = this.state;

    let mainImage;
    if (product.images.length > 0) {
      if (uiStore.isMobile) {
        const sliderSettings = {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          events: {
            afterChange: this.mainImageSliderOnAfterChange,
          },
        };
        mainImage = (
          <Slider
            key={product.id}
            sliderRef={(ref) => (this.mainImageSlider = ref)}
            {...sliderSettings}
            className="ProductImages__main-image-slider"
          >
            {product.images.map((productImage) => (
              <div
                key={`${productImage.id}__${productImage.product_id}__${productImage.sizes.full}`}
              >
                <ProductImage
                  product={
                    product.getActualProduct(productImage.product_id) || product
                  }
                  productImage={productImage}
                  size={size}
                  forceAspectRatio={true}
                  lazyLoading={false}
                />
              </div>
            ))}
          </Slider>
        );
      } else {
        const productImage = product.images[mainImageIndex];
        mainImage = (
          <ProductImage
            product={
              product.getActualProduct(productImage.product_id) || product
            }
            productImage={productImage}
            size={size}
            lazyLoading={false}
            magnify
          />
        );
      }
    } else {
      mainImage = (
        <ProductImage product={product} size={size} lazyLoading={false} />
      );
    }

    return (
      <div className="ProductImages__main-image">
        {mainImageOverlay}
        <div
          className="ProductImages__main-image-lightbox"
          onClick={this.toggleLightbox}
        >
          {mainImage}
        </div>
      </div>
    );
  }

  getLightbox = () => {
    const { product, accountStore } = this.props;
    const { mainImageIndex } = this.state;
    const cartMatrixEnabled =
      product.class === ProductClass.COLLECTION && accountStore.showCartMatrix;
    if (
      product.images.length === 0 ||
      !this.state.lightboxIsOpen ||
      cartMatrixEnabled
    ) {
      return null;
    }

    return (
      <ImageLightbox
        images={product.images}
        mainImageIndex={mainImageIndex}
        onClick={this.toggleLightbox}
      />
    );
  };

  render() {
    return (
      <div
        className={classNames('ProductImages', {
          'ProductImages--thumbnails-below': this.shouldShowThumbnailsBelow(),
        })}
      >
        {this.getThumbnails()}
        {this.getLightbox()}
        {this.getMainImage()}
      </div>
    );
  }
}

ProductImages.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  size: PropTypes.string.isRequired,
  product: modelOf(Product),
  mainImageOverlay: PropTypes.node,
  activeProductId: PropTypes.string,
  activeElementIds: PropTypes.arrayOf(PropTypes.number),
};

export default inject('accountStore', 'uiStore', 'configStore')(ProductImages);
