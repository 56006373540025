import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { head } from 'lodash';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import ProductImage from '../ProductImage';
import ProductReviewWidget from '../ProductReviewWidget';
import PriceWrapper from '../PriceWrapper';
import ConfigStore from '../../../store/ConfigStore';
import ExternalReviewType from '../../../types/ExternalReviewType';
import ProductDescription from '../ProductDescription';

@observer
class ProductQuickSearchResult extends Component {
  getWitViewRating = () => {
    const { product } = this.props;

    if (!product.external_reviews) {
      return;
    }

    return product.external_reviews.find(
      ({ type }) => type === ExternalReviewType.WITVIEW
    );
  };

  shouldUseWitViewRating = () => {
    const { configStore } = this.props;

    if (configStore.integrations.witView.enabled) {
      const witViewRating = this.getWitViewRating();
      if (witViewRating && witViewRating.reviews_count === 0) {
        return false;
      }

      return true;
    }

    return false;
  };

  render() {
    const { product, configStore, hideDescription } = this.props;
    const productImage = head(product.images);

    const useWitViewRating = this.shouldUseWitViewRating();

    return (
      <article className="ProductQuickSearchResult">
        <div className="ProductQuickSearchResult__image">
          <ProductImage
            product={product}
            size="small"
            productImage={productImage}
            lazyLoading={false}
          />
        </div>
        <div>
          <h2>{product.multiproduct_title || product.name}</h2>
          {!hideDescription && configStore.productCard.showDescription && (
            <ProductDescription
              className="ProductQuickSearchDescription"
              product={product}
            />
          )}
          <PriceWrapper product={product} />
          <ProductReviewWidget
            product={product}
            useWitViewRating={useWitViewRating}
            compact
          />
        </div>
      </article>
    );
  }
}

ProductQuickSearchResult.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  product: modelOf(Product).isRequired,
  hideDescription: PropTypes.bool,
};

export default inject('configStore')(ProductQuickSearchResult);
