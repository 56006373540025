export function getElementRelativeTop(element) {
  return element.getBoundingClientRect().top;
}

export function getElementRelativeBottom(element) {
  return element.getBoundingClientRect().bottom;
}

export function getElementById(id) {
  if (typeof document === 'undefined') {
    return null;
  }

  return document.getElementById(id);
}

export function countElementYOffsetFromDocumentTop(element) {
  return window.pageYOffset + getElementRelativeTop(element);
}

export function scrollToElementById(id, options, elementYFromDocumentTop) {
  const element = getElementById(id);
  if (!element) {
    return null;
  }
  if (!elementYFromDocumentTop) {
    elementYFromDocumentTop = countElementYOffsetFromDocumentTop(element);
  }
  scrollToElement(element, options, elementYFromDocumentTop);
}

export function scrollToElement(element, options, elementYFromDocumentTop) {
  if (element) {
    const supportsBehavior = element.style.scrollBehavior !== undefined;

    if (supportsBehavior) {
      window.scroll({
        top: elementYFromDocumentTop,
        behavior: 'smooth',
        ...options
      });
    } else {
      window.scroll(0, elementYFromDocumentTop);
    }
  }
}

export function getDocumentScrollTop() {
  if (typeof document === 'undefined') {
    return null;
  }

  return (
    window.pageYOffset ||
    document.body.scrollTop ||
    document.documentElement.scrollTop
  );
}

export function blockScroll() {
  const { safeDocument, html } = _getSafeDocument();
  const { body } = safeDocument;

  if (!body || !body.style) {
    return;
  }

  const scrollBarWidth = window.innerWidth - html.clientWidth;
  const bodyPaddingRight =
    parseInt(
      window.getComputedStyle(body).getPropertyValue('padding-right'),
      10
    ) || 0;

  /**
   * 1. Fixes a bug in iOS and desktop Safari whereby setting
   *    `overflow: hidden` on the html/body does not prevent scrolling.
   * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
   *    scroll if an `overflow-x` style is also applied to the body.
   */
  html.style.position = 'relative'; /* [1] */
  html.style.overflow = 'hidden'; /* [2] */
  body.style.position = 'relative'; /* [1] */
  body.style.overflow = 'hidden'; /* [2] */
  body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;
}

export function allowScroll() {
  const { safeDocument, html } = _getSafeDocument();
  const { body } = safeDocument;

  if (!body || !body.style) {
    return;
  }

  html.style.position = '';
  html.style.overflow = '';
  body.style.position = '';
  body.style.overflow = '';
  body.style.paddingRight = '';
}

const _getSafeDocument = () => {
  const safeDocument = typeof document !== 'undefined' ? document : {};
  const html = safeDocument.documentElement;
  return { safeDocument, html };
};
