import { Component } from 'react';

/**
 * This is simple component that can be used to reset the scroll position.
 * Use the key to re-render the component. <ScrollReset key={resetScrollWhenThisChanges} />
 */
class ScrollReset extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

export default ScrollReset;
