import { types } from 'mobx-state-tree';

import Modal from './Modal';
import MobileNavigationTab, {
  MobileNavigationTabType
} from '../types/MobileNavigationTab';

const MobileNavigation = Modal.named('MobileNavigation')
  .props({
    activeTab: types.optional(
      MobileNavigationTabType,
      MobileNavigationTab.PRODUCTS
    )
  })
  .actions((self) => {
    return {
      selectTab(tab) {
        self.activeTab = tab;
      },
      close() {
        self.isOpen = false;
      },
      open() {
        self.isOpen = true;
      }
    };
  });

export default MobileNavigation;
