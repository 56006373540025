const AdPages = {
  FRONT_PAGE: 'FRONT_PAGE',
  NEW_PRODUCTS: 'NEW_PRODUCTS',
  CAMPAIGN_PRODUCTS: 'CAMPAIGN_PRODUCTS',
  CUSTOMER_PRODUCTS: 'CUSTOMER_PRODUCTS',
  RECOMMENDED_PRODUCTS: 'RECOMMENDED_PRODUCTS',
  POPULAR_PRODUCTS: 'POPULAR_PRODUCTS',
  SOLD_PRODUCTS: 'SOLD_PRODUCTS',
  NOT_FOUND_PAGE: 'NOT_FOUND_PAGE'
};

export default AdPages;
