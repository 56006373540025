import { types } from 'mobx-state-tree';

import Price from '../Price';
import { ServiceOptionTypeType } from '../../types/ServiceOptionType';

const AdditionalServiceOption = types.model('AdditionalServiceOption', {
  selection_text: types.string,
  product_id: types.string,
  price: types.maybeNull(Price),
  type: ServiceOptionTypeType,
  image: types.maybeNull(types.string),
});

export default AdditionalServiceOption;
