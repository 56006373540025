import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
} from 'reactstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
import { debounce, isEqual } from 'lodash';

import { modelOf } from '../../../../prop-types';
import RequestState from '../../../../types/RequestState';
import Icon from '../../../../components/common/Icon';
import globalTranslations from '../../../../i18n/globalTranslations';
import InstantShoppingCartStore from '../../../../store/InstantShoppingCartStore';
import CurrencyStore from '../../../../store/CurrencyStore';
import KlarnaInstantShoppingForm, {
  formFieldNames,
} from '../KlarnaInstantShoppingForm';
import UnexpectedError from '../../../../components/loader/UnexpectedError';
import PrivacyStatementLink from '../../../../components/privacy-statement/PrivacyStatementLink';
import TermsOfUseStatementLink from '../../../../components/terms-of-use-statement/TermsOfUseStatementLink';
import KlarnaInstantShoppingDropdownMenu from '../KlarnaInstantShoppingShippingSelectionFieldTypes/KlarnaInstantShoppingDropdownMenu';
import ProductShippingClass from '../../../../types/ProductShippingClass';
import ConfigStore from '../../../../store/ConfigStore';
import UIStore from '../../../../store/UIStore';
import ProductMerchantInfo from '../../../../models/ProductMerchantInfo';
import Price from '../../../../components/product/Price';
import { allowScroll, blockScroll } from '../../../../util/dom';
import ShippingSelectionFieldType from '../../../../types/ShippingSelectionFieldType';

const messages = defineMessages({
  creatingOrder: {
    id: 'klarnaInstantShoppingModal.creatingOrder',
    defaultMessage: 'Please wait...',
  },
  updatingOrder: {
    id: 'klarnaInstantShoppingModal.updatingOrder',
    defaultMessage: 'Updating order...',
  },
  chooseShippingMethod: {
    id: 'klarnaInstantShoppingModal.chooseShippingMethod',
    defaultMessage: 'Choose shipping method:',
  },
  choosePickupPoint: {
    id: 'klarnaInstantShoppingModal.choosePickupPoint',
    defaultMessage: 'Choose pickup point:',
  },
  fetchingShippingOptions: {
    id: 'klarnaInstantShoppingModal.fetchingShippingOptions',
    defaultMessage: 'Fetching shipping options...',
  },
  distance: {
    id: 'klarnaInstantShoppingModal.distance',
    defaultMessage: 'Distance: {distance} {distanceUnit}',
  },
  openingHours: {
    id: 'klarnaInstantShoppingModal.openingHours',
    defaultMessage: 'Opening hours: {openingHours}',
  },
  noPickupPoints: {
    id: 'klarnaInstantShoppingModal.noPickupPoints',
    defaultMessage:
      'Unfortunately, no pickup points were found. Check that the address is correct or choose another delivery method.',
  },
  shippingAndPickup: {
    id: 'klarnaInstantShoppingModal.shippingAndPickup',
    defaultMessage: '{storeName} - order',
  },
  orderProductsButton: {
    id: 'klarnaInstantShoppingModal.orderProductsButton',
    defaultMessage: 'Continue to order',
  },
});

@observer
export class KlarnaInstantShoppingModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: null,
    };
    this.handleInputThrottled = debounce(this.fetchPickupPointOptions, 350);
    this.fetchingInterval = null;
    this.tempFormFieldValues = null;
    this.ifUpdatedShippingMethod = false;
  }

  componentDidMount() {
    blockScroll();
  }

  componentDidUpdate() {
    const {
      instantShoppingCartStore,
      shippingDropdownOpen,
      pickupDropdownOpen,
    } = this.props;
    const instantShoppingInstanceId =
      instantShoppingCartStore.instanceShoppingInstanceId;
    const ifLoaded = instantShoppingCartStore.state === RequestState.LOADED;
    const formValues =
      instantShoppingCartStore && instantShoppingCartStore.formValues;
    const orderModalIsOpen = instantShoppingCartStore.isOpen.orderModal;

    if (!orderModalIsOpen) {
      allowScroll();
    }

    if (
      instantShoppingInstanceId &&
      orderModalIsOpen &&
      this.state.form &&
      ifLoaded &&
      !shippingDropdownOpen &&
      !pickupDropdownOpen &&
      !!formValues.postalCode &&
      !!formValues.streetAddress &&
      (!isEqual(formValues, this.tempFormFieldValues) ||
        this.ifUpdatedShippingMethod)
    ) {
      this.populateFormIfExistingValues();
      this.updatePickupPointOptions();
    }
  }

  componentWillUnmount() {
    allowScroll();
  }

  updatePickupPointOptions = () => {
    this.validateForm();

    if (this.state.form.valid) {
      this.fetchingInterval = setTimeout(this.handleInputThrottled, 500);
    }
  };

  validateForm = () => {
    const { form } = this.state;
    const { postalCode, streetAddress } = form.getFieldValues();

    if (postalCode && streetAddress) {
      form && form.validate();
    }
  };

  renderShippingAndPickupDropdowns = () => {
    const { instantShoppingCartStore } = this.props;

    const error = instantShoppingCartStore.state === RequestState.ERROR;
    const selectedShippingOption =
      instantShoppingCartStore.selectedShippingOption;
    const ifRequiresPickupPoint =
      selectedShippingOption && selectedShippingOption.requires_pickup_point;
    const ifSelectedFields =
      selectedShippingOption &&
      selectedShippingOption.selectionFields &&
      selectedShippingOption.selectionFields.length > 0;

    return (
      <Row className="KlarnaInstantShoppingModal__shipping-and-pickup-options">
        {error ? (
          <Col sm={12}>
            <UnexpectedError />
          </Col>
        ) : (
          <Fragment>
            {this.renderShippingDropdown()}
            {ifRequiresPickupPoint &&
              this.renderPickUpPointForm(selectedShippingOption)}
            {ifRequiresPickupPoint &&
              this.state.form &&
              this.renderPickupDropdown()}
            {ifSelectedFields && this.renderSelectionFields()}
          </Fragment>
        )}
      </Row>
    );
  };

  renderShippingDropdown = () => {
    const {
      shippingDropdownOpen,
      toggleShippingDropdown,
      instantShoppingCartStore,
      uiStore,
      merchantId,
    } = this.props;

    const loading =
      instantShoppingCartStore.shippingOptionState.get(merchantId) ===
      RequestState.LOADING;

    const selectedShippingOption =
      instantShoppingCartStore.selectedShippingOption;

    const shippingOptionName = (
      <Fragment>
        <span className="KlarnaInstantShoppingModal__shipping-method-dropdown-name">
          {instantShoppingCartStore.selectedShippingOptionName}
        </span>
        {selectedShippingOption && (
          <Price price={selectedShippingOption.getPrice()} />
        )}{' '}
      </Fragment>
    );

    return (
      <Fragment>
        {loading ? (
          <Col sm={12}>
            <Label className="KlarnaInstantShoppingModal__fetching-icon">
              <FormattedMessage {...messages.fetchingShippingOptions} />
              <span className="KlarnaInstantShoppingModal__fetching-shipping-options">
                <Icon name={'circle-o-notch'} spin={loading} />
              </span>
            </Label>
          </Col>
        ) : (
          selectedShippingOption && (
            <Fragment>
              <Col
                sm={12}
                className="KlarnaInstantShoppingModal__shipping-method"
              >
                <Label className="KlarnaInstantShoppingModal__label">
                  <FormattedMessage {...messages.chooseShippingMethod} />
                </Label>
                <Dropdown
                  isOpen={shippingDropdownOpen}
                  toggle={toggleShippingDropdown}
                >
                  <DropdownToggle
                    className="KlarnaInstantShoppingModal__shipping-method-dropdown-toggle"
                    caret
                  >
                    {instantShoppingCartStore.selectedShippingOptionName &&
                      shippingOptionName}
                  </DropdownToggle>
                  <DropdownMenu className="KlarnaInstantShoppingModal__shipping-method-dropdown-menu">
                    {instantShoppingCartStore.shippingOptions.map(
                      (shippingDetailOption) =>
                        shippingDetailOption && (
                          <DropdownItem
                            key={shippingDetailOption.id}
                            onClick={this.updateShippingMethod.bind(
                              this,
                              shippingDetailOption
                            )}
                          >
                            <div className="KlarnaInstantShoppingModal__shipping-method-price">
                              <span className="KlarnaInstantShoppingModal__shipping-method-name">
                                {shippingDetailOption.name}
                              </span>
                              <span className="KlarnaInstantShoppingModal__shipping-method-price-title">
                                {!uiStore.isSmallest && (
                                  <Fragment>
                                    <FormattedMessage
                                      {...globalTranslations.priceTitle}
                                    />
                                    {': '}
                                  </Fragment>
                                )}
                                <Price
                                  price={shippingDetailOption.getPrice()}
                                />
                              </span>
                            </div>
                          </DropdownItem>
                        )
                    )}
                  </DropdownMenu>
                </Dropdown>
              </Col>
              {selectedShippingOption.description && (
                <Col
                  sm={12}
                  className="KlarnaInstantShoppingModal__shipping-method-description"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: selectedShippingOption.description,
                    }}
                  />
                </Col>
              )}
            </Fragment>
          )
        )}
      </Fragment>
    );
  };

  updateShippingMethod = (shippingOption) => {
    const { instantShoppingCartStore } = this.props;
    instantShoppingCartStore.updateShippingOption(shippingOption);

    this.resetSelectionFieldOption(shippingOption);
    this.resetPickupPointOption(shippingOption);

    this.populateFormIfExistingValues();
    this.ifUpdatedShippingMethod = true;
  };

  resetSelectionFieldOption = (shippingOption) => {
    const { instantShoppingCartStore } = this.props;

    if (
      shippingOption.class === ProductShippingClass.DELIVERY &&
      shippingOption.requires_pickup_point
    ) {
      instantShoppingCartStore.updatePickupOption(
        instantShoppingCartStore.defaultPickupPoint
      );

      instantShoppingCartStore.updateSelectionFieldOption(null);
    }
  };

  resetPickupPointOption = (shippingOption) => {
    const { instantShoppingCartStore } = this.props;

    if (
      shippingOption.class === ProductShippingClass.PICKUP &&
      !shippingOption.requires_pickup_point
    ) {
      this.setState({ form: null });
      this.setDefaultSelectedFieldOption(shippingOption);
      instantShoppingCartStore.updatePickupOption(null);
    }

    if (
      shippingOption.class === ProductShippingClass.DELIVERY &&
      !shippingOption.requires_pickup_point
    ) {
      this.setState({ form: null });
      this.setDefaultSelectedFieldOption(shippingOption);
      instantShoppingCartStore.updatePickupOption(null);
    }
  };

  setDefaultSelectedFieldOption = (shippingOption) => {
    const { instantShoppingCartStore } = this.props;
    if (!shippingOption || shippingOption.selection_fields.length === 0) {
      return null;
    }

    const defaultSelectionFieldOption =
      shippingOption.getDefaultSelectionField();

    instantShoppingCartStore.updateSelectionFieldOption(
      defaultSelectionFieldOption
    );
  };

  populateFormIfExistingValues = () => {
    const { instantShoppingCartStore } = this.props;
    const { postalCode, streetAddress } =
      instantShoppingCartStore.instantShoppingOrderForm;
    if (!this.state.form) {
      return null;
    }

    postalCode && this.setPostalCodeField(postalCode);
    streetAddress && this.setStreetAddressField(streetAddress);
  };

  setPostalCodeField = (postalCode) => {
    const postalCodeField =
      postalCode && this.getFormField(formFieldNames.postalCode);
    postalCodeField.setValue(postalCode);
  };

  setStreetAddressField = (streetAddress) => {
    const streetAddressField =
      streetAddress && this.getFormField(formFieldNames.streetAddress);
    streetAddressField.setValue(streetAddress);
  };

  renderPickUpPointForm = (shippingOption) => {
    const { instantShoppingCartStore, merchantId } = this.props;

    const loaded =
      instantShoppingCartStore.shippingOptionState.get(merchantId) ===
      RequestState.LOADED;

    return (
      loaded && (
        <KlarnaInstantShoppingForm
          shippingOption={shippingOption}
          onFormInit={this.getForm}
          onChange={this.updateFieldValue}
          postalCode={
            this.props.instantShoppingCartStore.instantShoppingOrderForm
              .postalCode
          }
          streetAddress={
            this.props.instantShoppingCartStore.instantShoppingOrderForm
              .streetAddress
          }
        />
      )
    );
  };

  getForm = (form) => this.setState({ form });

  updateFieldValue = (fieldValues) => {
    this.resetTimeout();
    const formField = this.getFormField(fieldValues.name);
    this.setFormFieldValue(formField, fieldValues.name, fieldValues.value);
    this.state.form && this.state.form.validateField(fieldValues.name);
  };

  getFormField = (name) => this.state.form.fields.get(name);

  setFormFieldValue = (formField, name, value) => {
    const { instantShoppingCartStore } = this.props;
    instantShoppingCartStore.updateInstantShoppingOrderForm(name, value);
    formField.setValue(value);
  };

  fetchPickupPointOptions = () => {
    const { instantShoppingCartStore } = this.props;
    const shippingInfo = instantShoppingCartStore.formValues;
    const shippingOption = instantShoppingCartStore.selectedShippingOption;
    instantShoppingCartStore.loadPickupPointOptions(shippingOption).then(() => {
      this.resetTimeout();
      this.tempFormFieldValues = shippingInfo;
      this.ifUpdatedShippingMethod = false;
    });
  };

  renderPickupDropdown = () => {
    const {
      pickupDropdownOpen,
      togglePickupDropdown,
      instantShoppingCartStore,
    } = this.props;

    const shippingInfo =
      this.state.form && instantShoppingCartStore.instantShoppingOrderForm;

    const loadingPickUpPoints =
      shippingInfo &&
      shippingInfo.postalCode &&
      instantShoppingCartStore.pickupPointStates.get(
        shippingInfo.postalCode + ',' + shippingInfo.streetAddress
      ) === RequestState.LOADING;

    const loadedPickUpPoints =
      shippingInfo &&
      shippingInfo.postalCode &&
      instantShoppingCartStore.pickupPointStates.get(
        shippingInfo.postalCode + ',' + shippingInfo.streetAddress
      ) === RequestState.LOADED;

    const pickupPointOptions =
      shippingInfo &&
      shippingInfo.postalCode &&
      instantShoppingCartStore.pickupPointOptions.get(
        shippingInfo.postalCode + ',' + shippingInfo.streetAddress
      );

    const selectedPickupPointOption =
      instantShoppingCartStore.selectedPickupOption;

    return (
      <Fragment>
        {loadingPickUpPoints ? (
          <Col sm={12}>
            <Label className="KlarnaInstantShoppingModal__fetching-icon">
              <FormattedMessage
                id="klarnaInstantShoppingModal.fetchingPickupPoints"
                defaultMessage="Fetching pickup points..."
              />
              <span className="KlarnaInstantShoppingModal__fetching-pickup-points">
                <Icon name={'circle-o-notch'} spin={loadingPickUpPoints} />
              </span>
            </Label>
          </Col>
        ) : (
          <Fragment>
            {selectedPickupPointOption && (
              <Col
                sm={12}
                className="KlarnaInstantShoppingModal__pickup-point-option"
              >
                {selectedPickupPointOption && (
                  <Fragment>
                    <Label className="KlarnaInstantShoppingModal__label">
                      <FormattedMessage {...messages.choosePickupPoint} />
                    </Label>
                    <Dropdown
                      isOpen={pickupDropdownOpen}
                      toggle={togglePickupDropdown}
                    >
                      <DropdownToggle
                        className="KlarnaInstantShoppingModal__pickup-point-dropdown-toggle"
                        caret
                      >
                        <span className="KlarnaInstantShoppingModal__pickup-point-dropdown-name">
                          {selectedPickupPointOption.name}
                        </span>
                        <span className="KlarnaInstantShoppingModal__pickup-point-dropdown-address">
                          {this.trimPickupPointAddress(
                            selectedPickupPointOption.address
                          )}
                        </span>
                      </DropdownToggle>
                      {pickupPointOptions && pickupPointOptions.length > 0 && (
                        <DropdownMenu className="KlarnaInstantShoppingModal__pickup-point-dropdown-menu">
                          {pickupPointOptions.map(
                            (pickupPointMethodOption) =>
                              pickupPointMethodOption && (
                                <DropdownItem
                                  key={pickupPointMethodOption.unique_id}
                                  onClick={this.updatePickupPointMethod.bind(
                                    this,
                                    pickupPointMethodOption
                                  )}
                                >
                                  <div className="KlarnaInstantShoppingModal__pickup-point">
                                    <span className="KlarnaInstantShoppingModal__pickup-point-name">
                                      {pickupPointMethodOption.name}
                                    </span>
                                    <span className="KlarnaInstantShoppingModal__pickup-point-address">
                                      {pickupPointMethodOption.address}
                                    </span>
                                  </div>
                                </DropdownItem>
                              )
                          )}
                        </DropdownMenu>
                      )}
                    </Dropdown>
                    {selectedPickupPointOption && (
                      <div className="KlarnaInstantShoppingModal__pickup-point-external-info">
                        {selectedPickupPointOption.distance && (
                          <div className="KlarnaInstantShoppingModal__pickup-point-external-info-distance">
                            <FormattedMessage
                              {...messages.distance}
                              values={{
                                distance: selectedPickupPointOption.distance
                                  .toString()
                                  .replace('.', ','),
                                distanceUnit: 'km',
                              }}
                            />
                          </div>
                        )}
                        {selectedPickupPointOption.opening_hours && (
                          <div className="KlarnaInstantShoppingModal__pickup-point-external-info-opening-hours">
                            <FormattedMessage
                              {...messages.openingHours}
                              values={{
                                openingHours:
                                  selectedPickupPointOption.opening_hours,
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </Fragment>
                )}
              </Col>
            )}
            {loadedPickUpPoints &&
              (!pickupPointOptions || pickupPointOptions.length === 0) && (
                <Col
                  sm={12}
                  className="KlarnaInstantShoppingModal__no-pickup-points"
                >
                  <FormattedMessage {...messages.noPickupPoints} />
                </Col>
              )}
          </Fragment>
        )}
      </Fragment>
    );
  };

  trimPickupPointAddress = (pickupPointAddress) =>
    pickupPointAddress && pickupPointAddress.replace(',', '').trim();

  updatePickupPointMethod = async (pickupPointOption) => {
    const { instantShoppingCartStore } = this.props;
    await instantShoppingCartStore.updatePickupOption(pickupPointOption);
  };

  renderSelectionFields = () => {
    const {
      instantShoppingCartStore,
      selectionFieldDropdownOpen,
      toggleSelectionFieldDropdown,
    } = this.props;
    const selectionFields =
      instantShoppingCartStore.selectedShippingOption.selectionFields;

    return (
      <Col sm={12}>
        {selectionFields &&
          selectionFields.length > 0 &&
          selectionFields.map((selectionField) => (
            <Fragment key={selectionField.id}>
              {this.ifDropdownMenu(selectionField) && (
                <KlarnaInstantShoppingDropdownMenu
                  selectionField={selectionField}
                  selectionFieldDropdownOpen={selectionFieldDropdownOpen}
                  toggleSelectionFieldDropdown={toggleSelectionFieldDropdown}
                  onUpdateSelectionFieldOption={this.updateSelectionFieldOption}
                />
              )}
            </Fragment>
          ))}
      </Col>
    );
  };

  ifDropdownMenu = (selectionField) =>
    selectionField.type === ShippingSelectionFieldType.DROPDOWN_MENU;

  updateSelectionFieldOption = (selectionFieldOption) => {
    const { instantShoppingCartStore } = this.props;

    instantShoppingCartStore.updateSelectionFieldOption(selectionFieldOption);
  };

  renderAcceptableTerms = () => {
    const { configStore, merchantInfo } = this.props;

    const storeName = configStore.store.name;
    const merchantName = merchantInfo.site_name;
    const deliveryAndPaymentTerms = merchantInfo.deliveryTerms.link;
    const merchantPrivacyStatement = merchantInfo.privacyStatement.link;

    return (
      deliveryAndPaymentTerms &&
      merchantPrivacyStatement && (
        <Row className="KlarnaInstantShoppingModal__footer-info-page-links">
          <Col sm={12}>
            <FormattedMessage
              {...globalTranslations.shoppingCenterAcceptableTerms}
              values={{
                continuePurchase: (
                  <span className="KlarnaInstantShoppingModal__continue-purchase">
                    <FormattedMessage {...messages.orderProductsButton} />
                  </span>
                ),
                storeName: (
                  <span className="KlarnaInstantShoppingModal__store-name">
                    {storeName}
                  </span>
                ),
                termsOfUse: <TermsOfUseStatementLink />,
                privacyStatement: <PrivacyStatementLink />,
                merchantName: (
                  <span className="KlarnaInstantShoppingModal__merchant-name">
                    {merchantName}
                  </span>
                ),
                deliveryAndPaymentTerms: (
                  <a
                    href={deliveryAndPaymentTerms}
                    target="_blank"
                    rel="noopener"
                  >
                    <FormattedMessage
                      {...globalTranslations.shoppingCenterDeliveryAndPaymentTerms}
                    />
                  </a>
                ),
                merchantPrivacyStatement: (
                  <a
                    href={merchantPrivacyStatement}
                    target="_blank"
                    rel="noopener"
                  >
                    <FormattedMessage
                      {...globalTranslations.shoppingCenterPrivacyStatement}
                    />
                  </a>
                ),
              }}
            />
          </Col>
        </Row>
      )
    );
  };

  renderCancelButton = () => (
    <Button
      color="secondary"
      onClick={this.cancelOrder.bind(this)}
      className="KlarnaInstantShoppingModal__cancel-button"
      block
    >
      <FormattedMessage {...globalTranslations.cancelTitle} />
    </Button>
  );

  cancelOrder = () => {
    const { instantShoppingCartStore, onOrderCancel } = this.props;
    instantShoppingCartStore.resetPickupPoint();
    this.ifUpdatedShippingMethod = false;
    this.tempFormFieldValues = null;
    this.resetTimeout();
    onOrderCancel && onOrderCancel();
  };

  renderContinueButton = () => {
    const { instantShoppingCartStore, merchantId } = this.props;
    const selectedShippingOption =
      instantShoppingCartStore.selectedShippingOption;
    const selectedPickupOption = instantShoppingCartStore.selectedPickupOption;
    const ifRequiresPickupPoint =
      selectedShippingOption &&
      selectedShippingOption.requires_pickup_point &&
      !selectedPickupOption;

    const shippingInfo =
      this.state.form && instantShoppingCartStore.instantShoppingOrderForm;

    const loadingShippingMethods =
      instantShoppingCartStore.shippingOptionState.get(merchantId) ===
      RequestState.LOADING;

    const loadingPickupPoints =
      shippingInfo &&
      shippingInfo.postalCode &&
      instantShoppingCartStore.pickupPointStates.get(
        shippingInfo.postalCode + ',' + shippingInfo.streetAddress
      ) === RequestState.LOADING;

    const loading = instantShoppingCartStore.state === RequestState.LOADING;
    const ifInvalidPickupPointSelection =
      ifRequiresPickupPoint && this.state.form && !this.state.form.valid;

    return (
      <Button
        color="dark"
        className="KlarnaInstantShoppingModal__order-products-button"
        disabled={
          loading ||
          loadingShippingMethods ||
          loadingPickupPoints ||
          ifRequiresPickupPoint ||
          ifInvalidPickupPointSelection
        }
        onClick={this.orderProduct}
        block
      >
        <FormattedMessage {...messages.orderProductsButton} />
      </Button>
    );
  };

  orderProduct = () => {
    const { onProductOrder } = this.props;
    this.ifUpdatedShippingMethod = false;
    this.tempFormFieldValues = null;
    this.resetTimeout();
    onProductOrder && onProductOrder();
  };

  resetTimeout = () => {
    this.handleInputThrottled && this.handleInputThrottled.cancel();
    this.fetchingInterval && clearTimeout(this.fetchingInterval);
    this.fetchingInterval = null;
  };

  renderOrderStateMessage = () => {
    const { instantShoppingCartStore } = this.props;
    const instantShoppingInstanceId =
      instantShoppingCartStore.instanceShoppingInstanceId;

    return instantShoppingInstanceId ? (
      <FormattedMessage {...messages.updatingOrder} />
    ) : (
      <FormattedMessage {...messages.creatingOrder} />
    );
  };

  render() {
    const { merchantInfo, onOrderCancel, instantShoppingCartStore } =
      this.props;

    const loading = instantShoppingCartStore.state === RequestState.LOADING;
    const storeName = merchantInfo.site_name;
    const orderModalIsOpen = instantShoppingCartStore.isOpen.orderModal;

    return (
      <Modal
        isOpen={orderModalIsOpen}
        toggle={onOrderCancel}
        className="KlarnaInstantShoppingModal"
        contentClassName="KlarnaInstantShoppingModal__content"
      >
        <ModalHeader
          className="KlarnaInstantShoppingModal__header"
          toggle={onOrderCancel}
        >
          <FormattedMessage
            {...messages.shippingAndPickup}
            values={{
              storeName,
            }}
          />
        </ModalHeader>
        <ModalBody className="KlarnaInstantShoppingModal__shipping-and-pickup-dropdowns">
          {loading ? (
            <Label className="KlarnaInstantShoppingModal__order-status-text">
              {this.renderOrderStateMessage()}
              <span>
                <Icon name={'circle-o-notch'} spin={loading} />
              </span>
            </Label>
          ) : (
            this.renderShippingAndPickupDropdowns()
          )}
        </ModalBody>
        <ModalFooter className="KlarnaInstantShoppingModal__footer">
          {this.renderAcceptableTerms()}
          <Row>
            <Col xs={6}>{this.renderCancelButton()}</Col>
            <Col xs={6}>{this.renderContinueButton()}</Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

KlarnaInstantShoppingModal.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  instantShoppingCartStore: modelOf(InstantShoppingCartStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  merchantInfo: modelOf(ProductMerchantInfo).isRequired,
  merchantId: PropTypes.number.isRequired,
  shippingDropdownOpen: PropTypes.bool.isRequired,
  pickupDropdownOpen: PropTypes.bool.isRequired,
  toggleShippingDropdown: PropTypes.func.isRequired,
  togglePickupDropdown: PropTypes.func.isRequired,
  onProductOrder: PropTypes.func.isRequired,
  onOrderCancel: PropTypes.func.isRequired,
};

export default inject(
  'configStore',
  'currencyStore',
  'instantShoppingCartStore',
  'uiStore'
)(KlarnaInstantShoppingModal);
