import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { dropRight } from 'lodash';

import globalTranslations from '../../../i18n/globalTranslations';
import LocaleFlagIcon from '../LocaleFlagIcon';
import { modelOf } from '../../../prop-types';
import LanguageStore from '../../../store/LanguageStore';
import CurrencyStore from '../../../store/CurrencyStore';
import CountryStore from '../../../store/CountryStore';
import UIStore from '../../../store/UIStore';

// All flags will be shown when languages count <= max slots.
// Otherwise, max slots flags will be shown. By default, the first flags
// in the array will be displayed, but if the currently selected language is
// not among them, it will replace the last flag.
const MAX_VISIBLE_LANGUAGE_FLAG_SLOTS = 5;

@observer
export class MobileNavigationRegionalSettingsSummary extends Component {
  getLanguagesLabel = () => {
    const { languageStore } = this.props;

    const displayPartialFlagList =
      languageStore.languages.length > MAX_VISIBLE_LANGUAGE_FLAG_SLOTS;

    let displayedFlags = languageStore.languages;

    if (displayPartialFlagList) {
      displayedFlags = displayedFlags.slice(0, MAX_VISIBLE_LANGUAGE_FLAG_SLOTS);
      if (
        !displayedFlags.find(
          (item) => item.code === languageStore.activeLanguage.code
        )
      ) {
        displayedFlags = dropRight(displayedFlags);
        displayedFlags.push(languageStore.activeLanguage);
      }
    }

    return (
      <div>
        {displayedFlags.map((language) => {
          const isActive = languageStore.activeLanguage === language;

          return (
            <span
              className={classNames(
                'MobileNavigationRegionalSettingsSummary__flag-container',
                {
                  active: isActive,
                }
              )}
              key={language.code}
            >
              <LocaleFlagIcon locale={language.code} />
            </span>
          );
        })}
        {displayPartialFlagList && (
          <span className="MobileNavigationRegionalSettingsSummary__more">
            +
          </span>
        )}
      </div>
    );
  };

  render() {
    const { languageStore, currencyStore, countryStore, uiStore } = this.props;
    const items = [];

    if (countryStore.countries.length > 1) {
      items.push({
        key: 'country',
        label: (
          <React.Fragment>
            <FormattedMessage
              {...globalTranslations.deliveryCountryLabel}
              values={{ country: countryStore.activeCountry.name }}
            />{' '}
            <LocaleFlagIcon
              locale={countryStore.activeCountry.iso_code_2.toLowerCase()}
            />
          </React.Fragment>
        ),
      });
    }
    if (languageStore.languages.length > 1) {
      items.push({
        key: 'language',
        label: this.getLanguagesLabel(),
      });
    }
    if (currencyStore.currencies.length > 1) {
      items.push({
        key: 'currency',
        label: (
          <FormattedMessage
            {...globalTranslations.currencyLabel}
            values={{ currency: currencyStore.currencyCode }}
          />
        ),
      });
    }

    if (items.length === 0) {
      return null;
    }

    return (
      <div
        className="MobileNavigationRegionalSettingsSummary"
        role="button"
        onClick={(e) => {
          e.stopPropagation();
          uiStore.mobileRegionalSettingsModal.toggle();
        }}
      >
        {items.map((item) => (
          <div key={item.key}>{item.label}</div>
        ))}
      </div>
    );
  }
}

MobileNavigationRegionalSettingsSummary.propTypes = {
  languageStore: modelOf(LanguageStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  countryStore: modelOf(CountryStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
};

export default inject(
  'languageStore',
  'currencyStore',
  'countryStore',
  'uiStore'
)(MobileNavigationRegionalSettingsSummary);
