import {
  types,
  resolveIdentifier,
  getRoot,
  getEnv,
  flow,
} from 'mobx-state-tree';
import { head, some, get, every, sortBy } from 'lodash';

import Manufacturer from './Manufacturer';
import ProductImage from './ProductImage';
import ProductFile from './ProductFile';
import ProductMatrix from './ProductMatrix';
import ProductMulti from './ProductMulti';
import ProductClass, { ProductClassType } from '../types/ProductClass';
import ProductAvailabilityType, {
  ProductAvailabilityTypeType,
} from '../types/ProductAvailabilityType';
import ProductPriceInfo from './ProductPriceInfo';
import Category from './Category';
import generatePath from '../util/generatePath';
import Paths from '../types/Paths';
import Stock from './Stock';
import ProductExtraProperty from './ProductExtraProperty';
import ProductFeature from './ProductFeature';
import RequestState, { RequestStateType } from '../types/RequestState';
import ProductFreeTextField from './ProductFreeTextField';
import Review from './Review';
import ProductSeason from './ProductSeason';
import BundleProductInfo from './BundleProductInfo';
import ProductAdditionalTab from './ProductAdditionalTab';
import { stringify } from '../util/queryString';
import ShippingDetails from './ShippingDetails';
import SizeGuide from './SizeGuide';
import AdditionalService from './product/AdditionalService';
import PropertyImage from './PropertyImage';
import StartaxInfo from './StartaxInfo';
import RelatedProduct from './RelatedProduct';
import ExternalReview from './ExternalReview';
import ProductMerchantInfo from './ProductMerchantInfo';
import { roundWithPrecision } from '../util/number';
import { ProductLicenceTypeType } from '../types/ProductLicenceType';
import ProductRecurringOrderType, {
  ProductRecurringOrderTypeType,
} from '../types/ProductRecurringOrderType';
import Price from './Price';
import RecurringOrders from './RecurringOrders';
import QuantityDiscountDisplayStyles from '../types/QuantityDiscountDisplayStyles';
import QuantityDiscounts from './QuantityDiscounts';
import ProductTypeClass, { ProductType } from '../types/ProductTypeClass';

const Product = types
  .model('Product', {
    actual_code: types.string,
    additionalServices: types.optional(types.array(AdditionalService), []),
    additionalServicesState: types.optional(
      RequestStateType,
      RequestState.NONE
    ),
    additionalTabs: types.optional(types.array(ProductAdditionalTab), []),
    additionalTabsState: types.optional(RequestStateType, RequestState.NONE),
    all_category_ids: types.array(types.number),
    allow_file_upload: types.maybeNull(types.boolean),
    alsoPurchasedIds: types.array(types.string),
    alsoPurchasedState: types.optional(RequestStateType, RequestState.NONE),
    availability_html: types.maybeNull(types.string),
    availability_html_for_listing: types.maybeNull(types.string),
    availability_type: ProductAvailabilityTypeType,
    available_in_store: types.boolean,
    available_online: types.boolean,
    available_somewhere: types.boolean,
    bottle_deposit: types.maybeNull(Price),
    bundleProductInfos: types.optional(types.array(BundleProductInfo), []),
    bundleProductInfosStateInternal: types.optional(
      RequestStateType,
      RequestState.NONE
    ),
    can_be_ordered_out_of_stock: types.boolean,
    class: ProductClassType,
    collection: types.maybeNull(ProductMatrix),
    cross_selling_id: types.maybeNull(types.string),
    customer_group_visibilities: types.maybeNull(types.array(types.number)),
    date_added: types.string,
    date_available: types.string,
    description_long: types.maybeNull(types.string),
    description_short: types.optional(types.string, ''),
    display_weight: types.maybeNull(types.string),
    ean: types.string,
    external_reviews: types.maybeNull(types.array(ExternalReview)),
    extra_id: types.maybeNull(types.string),
    extra_properties: types.optional(types.array(ProductExtraProperty), []),
    features: types.optional(types.array(ProductFeature), []),
    files: types.optional(types.array(ProductFile), []),
    free_quantity: types.number,
    free_text_fields: types.array(ProductFreeTextField),
    has_required_services: types.maybeNull(types.boolean),
    id: types.identifier,
    images: types.optional(types.array(ProductImage), []),
    is_reservable_in_store: types.maybeNull(types.boolean),
    labels: types.optional(types.array(PropertyImage), []),
    main_category_id: types.maybeNull(types.number),
    main_section_id: types.maybeNull(types.number),
    manufacturer_id: types.maybeNull(types.string),
    manufacturer_product_id: types.maybeNull(types.string),
    merchant_info: types.maybeNull(ProductMerchantInfo),
    min_order_quantity: types.maybeNull(types.number),
    model: types.string,
    multi: types.maybeNull(ProductMulti),
    multiproduct_id: types.string,
    multiproduct_title: types.maybeNull(types.string),
    name: types.string,
    package_size: types.maybeNull(types.number),
    package_unit_name: types.maybeNull(types.string),
    price_info: types.maybeNull(ProductPriceInfo),
    product_type: ProductType,
    quantity_discounts: types.maybeNull(QuantityDiscounts),
    recommended_with_ids: types.optional(types.array(types.string), []),
    recurringOrders: types.optional(RecurringOrders, {}),
    recurringOrdersState: types.optional(RequestStateType, RequestState.NONE),
    recurring_order_price: types.maybeNull(Price),
    recurring_order_type: ProductRecurringOrderTypeType,
    required_license: ProductLicenceTypeType,
    relatedProducts: types.optional(RelatedProduct, {}),
    relatedProductsState: types.optional(RequestStateType, RequestState.NONE),
    reviews: types.optional(types.array(Review), []),
    reviews_average: types.number,
    reviews_count: types.number,
    reviewsState: types.optional(RequestStateType, RequestState.NONE),
    rotating_images: types.maybeNull(types.array(types.string)),
    rotatingImagesList: types.optional(
      types.map(types.array(types.string)),
      {}
    ),
    rotatingImagesListStates: types.optional(
      types.map(types.optional(RequestStateType, RequestState.NONE)),
      {}
    ),
    season: types.maybeNull(ProductSeason),
    section_ids: types.array(types.number),
    seo_description: types.maybeNull(types.string),
    shipping_html: types.maybe(types.string),
    shippingDetails: types.optional(types.map(ShippingDetails), {}),
    shippingStates: types.optional(types.map(RequestStateType), {}),
    singular_price_divider: types.maybeNull(types.number),
    singular_price_unit: types.maybeNull(types.string),
    sizeGuides: types.optional(types.array(SizeGuide), []),
    sizeGuidesState: types.optional(RequestStateType, RequestState.NONE),
    slug: types.string,
    sold_out: types.maybeNull(types.boolean),
    startaxInfo: types.maybeNull(StartaxInfo),
    stock_unit: types.string,
    stocks: types.optional(types.map(types.array(Stock)), {}),
    stockStates: types.optional(types.map(RequestStateType), {}),
    validatedServices: types.optional(
      types.map(types.array(AdditionalService)),
      {}
    ),
    validatedServicesState: types.optional(
      types.map(types.optional(RequestStateType, RequestState.NONE)),
      {}
    ),
    suitability_description: types.maybeNull(types.string),
    warranty: types.maybeNull(types.number),
  })
  .actions((self) => {
    const getLang = () => getRoot(self).languageStore.activeLanguage.code;

    const ifShoppingCenter = () =>
      getRoot(self).configStore.siteConfig.isShoppingCenter;

    const baseApi = (endpoint) =>
      ifShoppingCenter() ? `shopping-center/${endpoint}` : `${endpoint}`;

    const productsApiEndpoint = (api, productId) => {
      const ifProductId = productId ? productId : self.id;
      return ifShoppingCenter()
        ? `shopping-center/products/${ifProductId}/${api}`
        : `products/${ifProductId}/${api}`;
    };

    const fetchAdditionalServices = function* getAdditionalServices(params) {
      const additionalServicesApi = productsApiEndpoint('additional-services');

      return yield getEnv(self).apiWrapper.request(
        `${additionalServicesApi}`,
        {
          params,
        },
        { active_section: null }
      );
    };

    return {
      loadStocks: flow(function* loadStocks(extendedId) {
        self.stockStates.set(extendedId, RequestState.LOADING);
        try {
          const storageStocks = yield getEnv(self).apiWrapper.request(
            `${baseApi('stocks')}/${extendedId}`,
            {},
            { active_section: null }
          );
          self.setStocks(extendedId, storageStocks);
          self.stockStates.set(extendedId, RequestState.LOADED);
        } catch (e) {
          self.stockStates.set(extendedId, RequestState.ERROR);
          throw e;
        }
      }),
      setStocks: function setStocks(extendedId, stocks) {
        self.stocks.set(extendedId, stocks);
      },
      loadShippingDetails: flow(function* loadShippingDetails(activeId) {
        if (!self.isNotAvailable()) {
          self.shippingStates.set(activeId, RequestState.LOADING);
          const shippingApi = productsApiEndpoint('shipping', activeId);
          try {
            const shippingDetails = yield getEnv(self).apiWrapper.request(
              `${shippingApi}`,
              {},
              { active_section: null }
            );
            self.setShippingDetails(activeId, shippingDetails);
            self.shippingStates.set(activeId, RequestState.LOADED);
          } catch (e) {
            self.shippingStates.set(activeId, RequestState.ERROR);
            throw e;
          }
        }
      }),
      setShippingDetails: function setShippingDetails(
        activeId,
        shippingDetails
      ) {
        self.shippingDetails.set(activeId, shippingDetails);
      },
      loadPropertyImages: flow(function* loadPropertyImages() {
        if (!self.isNotAvailable()) {
          self.propertyImagesState = RequestState.LOADING;
          const propertyImagesApi = productsApiEndpoint('property-images');
          try {
            const propertyImages = yield getEnv(self).apiWrapper.request(
              `${propertyImagesApi}`
            );
            self.setPropertyImages(propertyImages);
            self.propertyImagesState = RequestState.LOADED;
          } catch (e) {
            self.propertyImagesState = RequestState.ERROR;
            throw e;
          }
        }
      }),
      setPropertyImages: function setPropertyImages(propertyImages) {
        self.propertyImages = propertyImages;
      },
      loadReviews: flow(function* loadReviews() {
        if (self.reviews_count > 0) {
          self.reviewsState = RequestState.LOADING;
          const reviewsApi = productsApiEndpoint('reviews');
          try {
            const reviews = yield getEnv(self).apiWrapper.request(
              `${reviewsApi}`,
              {},
              { active_section: null }
            );
            self.setReviews(reviews);
            self.reviewsState = RequestState.LOADED;
          } catch (e) {
            self.reviewsState = RequestState.ERROR;
            throw e;
          }
        } else {
          // No need to load reviews, so set state to loaded.
          self.reviewsState = RequestState.LOADED;
        }
      }),
      setReviews: function setReviews(reviews) {
        self.reviews = reviews;
      },
      loadAlsoPurchased: flow(function* loadAlsoPurchased(productId) {
        self.alsoPurchasedState = RequestState.LOADING;
        const alsoPurchasedApi = productsApiEndpoint(
          'also-purchased',
          productId
        );
        try {
          const alsoPurchasedIds = yield getEnv(self).apiWrapper.request(
            `${alsoPurchasedApi}`,
            {},
            { active_section: null }
          );
          self.setAlsoPurchased(alsoPurchasedIds);
          self.alsoPurchasedState = RequestState.LOADED;
        } catch (e) {
          self.alsoPurchasedState = RequestState.ERROR;
          throw e;
        }
      }),
      setAlsoPurchased: function setAlsoPurchased(alsoPurchasedIds) {
        self.alsoPurchasedIds = alsoPurchasedIds;
      },
      loadBundleProductInfos: flow(function* loadBundleProductInfos() {
        self.bundleProductInfosStateInternal = RequestState.LOADING;
        const bundleProductInfosApi = productsApiEndpoint('bundle-products');
        try {
          const bundleProductInfos = yield getEnv(self).apiWrapper.request(
            `${bundleProductInfosApi}`,
            {},
            { active_section: null }
          );
          self.setBundleProductInfos(bundleProductInfos);
          self.bundleProductInfosStateInternal = RequestState.LOADED;
        } catch (e) {
          self.bundleProductInfosStateInternal = RequestState.ERROR;
          throw e;
        }
      }),
      setBundleProductInfos: function setBundleProductInfos(
        bundleProductInfos
      ) {
        self.bundleProductInfos = bundleProductInfos;
      },
      loadAdditionalTabs: flow(function* loadAdditionalTabs() {
        self.additionalTabsState = RequestState.LOADING;
        const additionalTabsApi = productsApiEndpoint('additional-tabs');
        try {
          const additionalTabs = yield getEnv(self).apiWrapper.request(
            `${additionalTabsApi}`,
            {},
            { active_section: null }
          );
          self.setAdditionalTabs(additionalTabs);
          self.additionalTabsState = RequestState.LOADED;
        } catch (e) {
          self.additionalTabsState = RequestState.ERROR;
          throw e;
        }
      }),
      setAdditionalTabs: function setAdditionalTabs(additionalTabs) {
        self.additionalTabs = additionalTabs;
      },
      loadRotatingImagesList: flow(function* loadRotatingImagesList(filePath) {
        self.rotatingImagesListStates.set(filePath, RequestState.LOADING);
        const rotatingImagesListApi = productsApiEndpoint(
          `rotating-images?${stringify({
            filePath: filePath,
          })}`
        );
        try {
          const rotatingImagesList = yield getEnv(self).apiWrapper.request(
            `${rotatingImagesListApi}`,
            {},
            { active_section: null }
          );

          self.setRotatingImagesList(filePath, rotatingImagesList);
          self.rotatingImagesListStates.set(filePath, RequestState.LOADED);
        } catch (e) {
          self.rotatingImagesListStates.set(filePath, RequestState.ERROR);
          throw e;
        }
      }),
      setRotatingImagesList: function setRotatingImagesList(
        filePath,
        rotatingImagesList
      ) {
        self.rotatingImagesList.set(filePath, rotatingImagesList);
      },
      loadAdditionalServices: flow(function* loadAdditionalServices(category) {
        if (
          category &&
          !self.isNotAvailable() &&
          self.product_type !== ProductTypeClass.GIFT_VOUCHER
        ) {
          self.additionalServicesState = RequestState.LOADING;

          try {
            const additionalServices = yield* fetchAdditionalServices({
              category,
            });

            self.setAdditionalServices(additionalServices);
          } catch (e) {
            self.additionalServicesState = RequestState.ERROR;
            throw e;
          }
        }
      }),
      setAdditionalServices: function setAdditionalServices(
        additionalServices
      ) {
        self.additionalServices = additionalServices;
        self.additionalServicesState = RequestState.LOADED;
      },
      validateAdditionalServices: flow(function* loadAdditionalServices({
        category,
        postalCode,
      }) {
        if (category && postalCode && !self.isNotAvailable()) {
          self.validatedServicesState.set(
            `${self.id}--${postalCode}`,
            RequestState.LOADING
          );

          try {
            const additionalServices = yield* fetchAdditionalServices({
              category,
              postalCode,
            });

            self.setValidatedServices(additionalServices, postalCode);
          } catch (e) {
            self.validatedServicesState.set(
              `${self.id}--${postalCode}`,
              RequestState.ERROR
            );
            throw e;
          }
        }
      }),
      setValidatedServices: function setValidatedServices(
        additionalServices,
        postalCode
      ) {
        self.validatedServices.set(
          `${self.id}--${postalCode}`,
          additionalServices
        );
        self.validatedServicesState.set(
          `${self.id}--${postalCode}`,
          RequestState.LOADED
        );
      },
      submitReview: flow(function* submitReview(review) {
        const reviewsApi = productsApiEndpoint('reviews');
        return yield getEnv(self)
          .apiWrapper.apiAxios()
          .post(`${reviewsApi}`, review);
      }),
      uploadFile: flow(function* uploadFile(file) {
        const formData = new FormData();
        formData.append('file', file);
        yield getEnv(self)
          .apiWrapper.apiAxios()
          .post(`${baseApi('product-file-uploads')}/${self.id}`, formData);
      }),
      deleteFile: flow(function* deleteFile(fileName) {
        yield getEnv(self)
          .apiWrapper.apiAxios()
          .delete(`${baseApi('product-file-uploads')}/${self.id}`, {
            data: {
              fileName,
            },
          });
      }),
      loadSizeGuides: flow(function* loadSizeGuides(params) {
        if (!self.isNotAvailable()) {
          self.sizeGuidesState = RequestState.LOADING;
          const sizeGuidesApi = productsApiEndpoint('size-guides');
          try {
            const sizeGuides = yield getEnv(self).apiWrapper.request(
              `${sizeGuidesApi}`,
              {
                params: params,
              },
              { active_section: null }
            );
            self.setSizeGuides(sizeGuides);
            self.sizeGuidesState = RequestState.LOADED;
          } catch (e) {
            self.sizeGuidesState = RequestState.ERROR;
            throw e;
          }
        }
      }),
      setSizeGuides: function setSizeGuides(guides) {
        self.sizeGuides = guides;
      },
      loadRelatedProducts: flow(function* loadRelatedProducts() {
        self.relatedProductsState = RequestState.LOADING;
        try {
          const relatedProducts = yield getEnv(self).apiWrapper.request(
            `products/${self.id}/related-products?${getLang()}`
          );
          self.setRelatedProducts(relatedProducts);
          self.relatedProductsState = RequestState.LOADED;
        } catch (e) {
          self.relatedProductsState = RequestState.ERROR;
          throw e;
        }
      }),
      setRelatedProducts: (relatedProducts) => {
        self.relatedProducts = relatedProducts;
      },
      loadRecurringOrders: flow(function* loadRecurringOrders() {
        self.recurringOrdersState = RequestState.LOADING;
        try {
          const recurringOrders = yield getEnv(self).apiWrapper.request(
            `products/${self.id}/recurring-orders`
          );
          self.setRecurringOrders(recurringOrders);
          self.recurringOrdersState = RequestState.LOADED;
        } catch (e) {
          self.recurringOrdersState = RequestState.ERROR;
          throw e;
        }
      }),
      setRecurringOrders: (recurringOrders) => {
        self.recurringOrders = recurringOrders;
      },
      sortProductImages: (key) => {
        self.images = self.images && sortBy(self.images, [key]);
      },
    };
  })
  .views((self) => {
    const getActiveCurrencyPrecision = () =>
      getRoot(self).currencyStore.activeCurrency.precision;

    // Assigning the function as a reference so we would create less new Math-functions every time it's being used.
    const roundWithPrecisionRef = roundWithPrecision;

    return {
      belongsToSomeSection: (sectionIds) => {
        return self.section_ids.some(
          (sectionId) => sectionIds.indexOf(sectionId) !== -1
        );
      },
      belongsToSection: (sectionId) => {
        return self.section_ids.indexOf(sectionId) !== -1;
      },
      belongsToCategory(categoryId) {
        if (self.allCategories) {
          return some(self.allCategories, (category) => {
            return (
              category.hierarchy.findIndex(
                (category) => category.id === categoryId
              ) !== -1
            );
          });
        }
      },
      breadcrumbsForCategory(category) {
        let breadcrumbItems = [];

        if (category) {
          breadcrumbItems = breadcrumbItems.concat(category.breadcrumbs);
        }

        breadcrumbItems.push({
          text: self.name,
          url: self.path,
        });

        return breadcrumbItems;
      },
      canBeOrdered(activeProductId) {
        const actualProduct = self.getActualProduct(activeProductId);

        if (!actualProduct) {
          return false;
        }

        if (
          actualProduct.availability_type ===
          ProductAvailabilityType.ALLOW_BACKORDER
        ) {
          return true;
        }

        if (
          actualProduct.availability_type ===
          ProductAvailabilityType.ONLY_IN_SHOP
        ) {
          return false;
        }

        if (actualProduct.season && !actualProduct.season.isActive()) {
          return false;
        }

        if (actualProduct.isTemporarilyUnavailable(activeProductId)) {
          return false;
        }

        if (
          actualProduct.availability_type ===
            ProductAvailabilityType.CLOSEOUT &&
          !actualProduct.hasEnoughStockAvailable(activeProductId)
        ) {
          return false;
        }

        return true;
      },
      findStorageStock: function findStorageStock(extendedId, storageId) {
        return self.stocks.get(extendedId).find((stock) => {
          return stock.storage_id === storageId;
        });
      },
      getActualProduct(activeProductId) {
        let actualProduct = self;
        if (self.class === ProductClass.MULTI) {
          actualProduct = self.multi.findChild(activeProductId);
        }
        return actualProduct;
      },
      getDiscountAmount: (quantityDiscount) => {
        let discountAmount = 0;
        if (!self.getQuantityDiscounts().hasDiscounts) {
          discountAmount = self.price_info.discount_percentage;
        }

        if (self.getQuantityDiscounts().hasDiscounts) {
          discountAmount = quantityDiscount.discount;
        }

        return discountAmount;
      },
      getDiscountInfo: (includeTax, quantityDiscountDisplayStyle) => {
        let savings = null;
        let discountAmount = null;
        let hasPriceRange =
          self.class === ProductClass.MULTI &&
          self.multi.hasPriceRange(includeTax);
        let hasDiscount = false;
        let minPrice = null;
        let maxPrice = null;

        if (!self.hasDiscount) {
          savings = 0;
          discountAmount = 0;
        }

        const baseDiscountInfo = () => {
          savings = self.price_info?.getSavings(includeTax);
          discountAmount = self.price_info?.discount_percentage;
          hasDiscount = self.price_info?.is_discount;
        };

        if (!self.getQuantityDiscounts().hasDiscounts) {
          baseDiscountInfo();
        }

        if (self.getQuantityDiscounts().hasDiscounts && !self.isMulti()) {
          const quantityDiscount = self.quantity_discounts;

          switch (quantityDiscountDisplayStyle) {
            case QuantityDiscountDisplayStyles.LOWEST:
              discountAmount = self.getDiscountAmount(quantityDiscount.lowest);
              savings = quantityDiscount.lowest.getSavings(
                self.price_info?.getNormalPrice(includeTax),
                includeTax
              );
              hasDiscount = true;
              break;
            case QuantityDiscountDisplayStyles.RANGE:
              discountAmount = self.getDiscountAmount(quantityDiscount.lowest);
              hasDiscount = true;
              hasPriceRange = true;
              minPrice = quantityDiscount.lowest;
              maxPrice = quantityDiscount.highest;
              break;
            default:
              baseDiscountInfo();
              break;
          }
        }

        return {
          savings,
          discountAmount,
          hasDiscount,
          hasPriceRange,
          minPrice,
          maxPrice,
        };
      },
      getFreeQuantity(activeProductId) {
        const actualProduct = self.getActualProduct(activeProductId);
        if (!actualProduct) {
          return 0;
        }
        let freeQuantity = actualProduct.free_quantity;
        if (actualProduct.class === ProductClass.COLLECTION) {
          const collectionItem =
            actualProduct.collection.getItemWithProductId(activeProductId);
          freeQuantity = get(collectionItem, 'product.free_quantity', 0);
        }
        return freeQuantity;
      },
      getImage(productId) {
        return (
          self.images &&
          self.images.find((image) => image.product_id === productId)
        );
      },
      getImagesBySize: (size) => {
        return self.images && self.images.map((image) => image.sizes[size]);
      },
      getMainImage(activeProductId) {
        return (
          self.images.find((image) => image.product_id === activeProductId) ||
          head(self.images)
        );
      },
      getPackagePrice(withTax, activeProductId) {
        let product = self;
        if (activeProductId) {
          product = self.getActualProduct(activeProductId) || product;
        }

        if (!product.package_size) {
          return;
        }

        return roundWithPrecisionRef(
          product.package_size *
            roundWithPrecisionRef(
              product.getPrice(withTax, activeProductId),
              getActiveCurrencyPrecision()
            ),
          getActiveCurrencyPrecision()
        );
      },
      getPrice(withTax, activeProductId) {
        let product = self;
        if (activeProductId) {
          product = self.getActualProduct(activeProductId) || product;
        }

        return product.price_info ? product.price_info.getPrice(withTax) : 0;
      },
      getPriceWithPrecision(withTax, activeProductId) {
        let product = self;
        if (activeProductId) {
          product = self.getActualProduct(activeProductId) || product;
        }

        return product.price_info
          ? roundWithPrecisionRef(
              product.getPrice(withTax),
              getActiveCurrencyPrecision()
            )
          : 0;
      },
      getQuantityDiscounts: () => {
        return {
          hasDiscounts: !!self.quantity_discounts,
          discounts: self.quantity_discounts,
        };
      },
      getQuantityStep: (product) => {
        return product && self.sellInPackage ? self.package_size : 1;
      },
      getValidatedServices: ({ postalCode }) => {
        return self.validatedServices.get(`${self.id}--${postalCode}`);
      },
      getValidatedService: ({ postalCode, serviceId }) => {
        const validatedServices = self.getValidatedServices({
          postalCode,
        });

        return (
          self.ifValidatedServicesExists({ postalCode }) &&
          validatedServices.find((service) => service.id === serviceId)
        );
      },
      hasCustomerGroupVisibility(customerGroupId) {
        // Have to check if customer_group_visibilities doesn't exist because then backend response comes from cache and frontend cannot break.
        if (!self.customer_group_visibilities) {
          return true;
        }

        // When customer_group_visibilities is an empty list. It's hidden from everyone.
        if (
          self.customer_group_visibilities &&
          self.customer_group_visibilities.length === 0
        ) {
          return false;
        }

        return self.customer_group_visibilities.some(
          (id) => id === customerGroupId
        );
      },
      hasDeposit(activeProductId) {
        const actualProduct = self.getActualProduct(activeProductId);

        return (
          actualProduct &&
          actualProduct.bottle_deposit &&
          actualProduct.bottle_deposit.with_tax > 0
        );
      },
      hasEnoughStockAvailable(activeProductId) {
        const actualProduct = self.getActualProduct(activeProductId);

        let freeQuantity = actualProduct.getFreeQuantity(activeProductId);
        const requiredAmount = actualProduct.sellInPackage
          ? actualProduct.package_size
          : 1;
        return freeQuantity >= requiredAmount;
      },
      hasOnlyRecurringOrder() {
        return (
          self.recurring_order_type ===
          ProductRecurringOrderType.RECURRING_ORDERS_ONLY
        );
      },
      hasProperties: (propertyElementMap) => {
        return every(Object.keys(propertyElementMap), (propertyId) => {
          const valueId = propertyElementMap[propertyId];
          return some(
            self.extra_properties,
            (extraProperty) =>
              extraProperty.id === Number(propertyId) &&
              extraProperty.value_id === valueId
          );
        });
      },
      hasRecurringOrder() {
        return (
          self.recurring_order_type !== ProductRecurringOrderType.NOT_ALLOWED
        );
      },
      ifServiceIsRequiredAndRequiresPostalCodeValidation: () => {
        return self.additionalServices.some(
          (service) => service.is_required && service.requires_area_validation
        );
      },
      ifServiceRequiresPostalCodeValidation: () => {
        return self.additionalServices.some(
          (service) => service.requires_area_validation
        );
      },
      ifValidServices: ({ productId, postalCode }) => {
        const validatedServices = self.ifValidatedServicesExists({
          postalCode,
        });
        if (!validatedServices) {
          return;
        }

        const services = self.validatedServices.get(
          `${productId}--${postalCode}`
        );
        return services.some((service) => service.valid_postal_code);
      },
      ifValidatedServicesExists: ({ postalCode }) => {
        const services = self.validatedServices.get(
          `${self.id}--${postalCode}`
        );

        return !!services && services.length > 0;
      },
      isBlockedFromStoreReservation(excludePropertyId) {
        return (
          excludePropertyId &&
          (!!self.extra_properties.some(
            (property) => property.id === excludePropertyId
          ) ||
            !!self.features.some(
              (property) => property.id === excludePropertyId
            ))
        );
      },
      isMulti() {
        return self.class === ProductClass.MULTI && self.multi ? true : false;
      },
      isNotAvailable() {
        return (
          self.availability_type === ProductAvailabilityType.CLOSEOUT &&
          self.sold_out
        );
      },
      isProposalType(activeProductId, enquireForProposal = false) {
        const actualProduct = self.getActualProduct(activeProductId);
        let isProposalPrice = false;
        if (actualProduct) {
          isProposalPrice =
            !actualProduct.price_info ||
            actualProduct.price_info.getNormalPrice(true) <= 0;
        }

        return enquireForProposal && isProposalPrice;
      },
      isTemporarilyUnavailable(activeProductId) {
        const actualProduct = self.getActualProduct(activeProductId);

        return (
          actualProduct.availability_type === ProductAvailabilityType.NORMAL &&
          !actualProduct.hasEnoughStockAvailable(activeProductId) &&
          !actualProduct.can_be_ordered_out_of_stock
        );
      },
      pathWithActiveProductId(activeProductId) {
        return `${this.path}${activeProductId}/`;
      },
      get allCategories() {
        if (self.all_category_ids) {
          return self.all_category_ids
            .map((categoryId) =>
              resolveIdentifier(Category, getRoot(self), categoryId)
            )
            .filter((category) => !!category);
        }
      },
      get alternativeIds() {
        return (
          self.relatedProducts &&
          self.relatedProducts.alternative_ids &&
          self.relatedProducts.alternative_ids
        );
      },
      get breadcrumbs() {
        return self.breadcrumbsForCategory(self.mainCategory);
      },
      get bundleProductInfosState() {
        const cannotHaveAnyBundleProducts =
          self.recommended_with_ids.length === 0;
        if (cannotHaveAnyBundleProducts) {
          return RequestState.LOADED;
        }
        return self.bundleProductInfosStateInternal;
      },
      get canBeOrderedOutOfStock() {
        return (
          self.availability_type === ProductAvailabilityType.ALLOW_BACKORDER ||
          self.can_be_ordered_out_of_stock
        );
      },
      get canonicalCategoryId() {
        if (self.main_category_id) {
          return self.main_category_id;
        }
        return self.all_category_ids.length > 0
          ? self.all_category_ids[0]
          : null;
      },
      get extraPropertiesMap() {
        let extraPropertiesMap = {};
        self.extra_properties.forEach((property) => {
          extraPropertiesMap = {
            ...extraPropertiesMap,
            [property.id]: property.value_id,
          };
        });

        return extraPropertiesMap;
      },
      get hasDiscount() {
        if (!self.price_info) {
          return false;
        }

        return (
          self.getQuantityDiscounts().hasDiscounts ||
          self.price_info?.is_discount
        );
      },
      get listingAvailability() {
        return self.availability_html_for_listing || self.availability_html;
      },
      get manufacturer() {
        return (
          self.manufacturer_id &&
          resolveIdentifier(Manufacturer, getRoot(self), self.manufacturer_id)
        );
      },
      get mainCategory() {
        return self.main_category_id
          ? resolveIdentifier(Category, getRoot(self), self.main_category_id)
          : null;
      },
      get merchantInfo() {
        return self.merchant_info;
      },
      get merchantId() {
        return (
          self.merchant_info && self.merchant_info.id && self.merchant_info.id
        );
      },
      get merchantSiteUrl() {
        return (
          self.merchant_info &&
          self.merchant_info.site_url &&
          self.merchant_info.site_url
        );
      },
      get path() {
        let id = self.multiproduct_id || self.id;

        // Fix the multi product ID in case of case differences
        if (
          self.multi &&
          self.multiproduct_id &&
          !Object.keys(self.multi.children).includes(id)
        ) {
          const actualMultiItem = Object.values(self.multi.children).find(
            (multiChild) =>
              multiChild.id.toUpperCase() === self.multiproduct_id.toUpperCase()
          );

          id = actualMultiItem ? actualMultiItem.id : self.id;
        }

        const slug = self.slug || id;

        return generatePath(Paths.Product, {
          slug,
          id,
        });
      },
      get productCode() {
        return self.actual_code;
      },
      get recommendedIds() {
        return (
          self.relatedProducts &&
          self.relatedProducts.recommended_ids &&
          self.relatedProducts.recommended_ids
        );
      },
      get sellInPackage() {
        return (
          self.package_size && self.package_size > 0 && self.package_size !== 1
        );
      },
      get seoDescription() {
        return self.seo_description;
      },
      get seoTitle() {
        const modelInSeoTitle = self.class !== ProductClass.MULTI && self.model;
        const nameTitle =
          self.multiproduct_title !== null
            ? self.multiproduct_title
            : self.name;
        return modelInSeoTitle ? `${nameTitle} ${self.model}` : nameTitle;
      },
      get unitName() {
        return self.sellInPackage ? self.package_unit_name : self.stock_unit;
      },
      get uploadedFiles() {
        const productFiles = getRoot(self).productStore.uploadedFiles.find(
          (entry) => entry.id === self.id
        );
        return productFiles ? productFiles.files : [];
      },
    };
  });

export default Product;
