import {
  CommonEventNames,
  CustomEventNames,
  GiosgEventNames,
  GoogleAnalytics4EventNames,
} from '../AnalyticsEventNames';

/**
 * @namespace GoogleAnalytics4Events
 */

/**
 * @typedef AnalyticsItem
 * @type {Object}
 * @property {Product} product
 * @property {number} quantity
 * @property {number} position
 * @property {number|string} price
 * @property {number} newQuantity
 * @property {string} activeProductId
 * @property {string} itemListId
 * @property {string} itemListName
 * @property {string} affiliation
 * @property {string} creativeName
 * @property {string} creativeSlot
 */

export default class GoogleAnalytics4Events {
  constructor(currencyStore, uiStore) {
    this.currencyStore = currencyStore;
    this.uiStore = uiStore;
  }

  /**
   * Dispatch analytics events for integrations to use.
   *
   * @param {string} name
   * @param {Object} payload
   */
  dispatchEvent(name, payload) {
    const detail = {
      detail: {
        name,
        payload: {
          currencyCode: this.currencyStore.currencyCode,
          ...payload,
        },
      },
    };

    // Dispatch a specific event for integrations that only need specific events.
    this.dispatchSpecificEvent(name, detail);
    // Dispatch a catch-all event for integrations that use all events.
    this.dispatchAnalyticsEvent(detail);
  }

  /**
   *
   * @param {string} name
   * @param {Object} detail
   */
  dispatchSpecificEvent(name, detail) {
    const event = new CustomEvent(`analytics:${name}`, detail);
    document.dispatchEvent(event);
  }

  /**
   *
   * @param {Object} detail
   */
  dispatchAnalyticsEvent(detail) {
    const event = new CustomEvent('analytics', detail);
    document.dispatchEvent(event);
  }

  /**
   * @param {Array.<AnalyticsItem>} productList
   * @param {number} value
   */
  addToCart(productList, value) {
    this.dispatchEvent(GoogleAnalytics4EventNames.addToCart, {
      productList,
      value,
    });
  }

  /**
   * @param {Array.<AnalyticsItem>} productList
   * @param {number} value
   */
  addToProposal(productList, value) {
    this.dispatchEvent(GoogleAnalytics4EventNames.addToProposal, {
      productList,
      value,
    });
  }

  /**
   * @param {Array.<AnalyticsItem>} productList
   * @param {number} value
   */
  addToWishlist(productList, value) {
    this.dispatchEvent(GoogleAnalytics4EventNames.addToWishlist, {
      productList,
      value,
    });
  }

  /**
   * @param {Array.<AnalyticsItem>} productList
   * @param {Object} actionField
   * @param {number} actionField.step
   * @param {string} actionField.option
   * @param {number} value
   */
  beginProposal(productList, actionField, value) {
    this.dispatchEvent(GoogleAnalytics4EventNames.beginProposal, {
      productList,
      actionField,
      value,
    });
  }

  /**
   * Send all products from the cart.
   */
  cartStatus(cart) {
    this.dispatchEvent(GiosgEventNames.cartStatus, {
      cart,
    });
  }

  /**
   * Clear all products from the cart.
   */
  clearCart() {
    this.dispatchEvent(GiosgEventNames.clearCart, {});
  }

  /**
   * @param {Array.<AnalyticsItem>} productList
   * @param {Object} actionField
   * @param {number} actionField.step
   * @param {string} actionField.option
   * @param {number} value
   * @param {string} coupon
   */
  checkout(productList, actionField, value, coupon) {
    this.dispatchEvent(GoogleAnalytics4EventNames.beginCheckout, {
      productList,
      actionField,
      value,
      coupon,
    });
  }

  checkoutOption() {
    throw notImplementedError;
  }

  /**
   * @param {number} value
   */
  generateLead(value) {
    this.dispatchEvent(GoogleAnalytics4EventNames.generateLead, {
      value,
    });
  }

  /**
   * @param {string} groupId
   */
  joinGroup(groupId) {
    this.dispatchEvent(GoogleAnalytics4EventNames.joinGroup, {
      groupId,
    });
  }

  /**
   * @param {string} method
   */
  login(method) {
    this.dispatchEvent(GoogleAnalytics4EventNames.login, {
      method,
    });
  }

  /**
   * @param {CurrentOrder} currentOrder Current order model.
   */
  proposalSent(currentOrder) {
    this.dispatchEvent(GoogleAnalytics4EventNames.proposalSent, {
      currentOrder,
    });
  }

  /**
   * @param {string} listName
   * @param {Array.<AnalyticsItem>} productList
   * @param {string} listId
   */
  productClick(listName, productList, listId) {
    this.dispatchEvent(GoogleAnalytics4EventNames.selectItem, {
      listName,
      productList,
      listId,
    });
  }

  /**
   * @param {Array.<AnalyticsItem>} bannerList
   */
  promotionClick(bannerList) {
    this.dispatchEvent(GoogleAnalytics4EventNames.selectPromotion, {
      bannerList,
    });
  }

  /**
   * @param {CurrentOrder} currentOrder Current order model.
   */
  purchase(currentOrder) {
    this.dispatchEvent(GoogleAnalytics4EventNames.purchase, {
      currentOrder,
    });
  }

  /**
   * @param {Object} gdprInfo
   * @param {string} gdprInfo.first_name
   * @param {string} gdprInfo.last_name
   * @param {string} gdprInfo.email
   * @param {string} gdprInfo.street_address
   * @param {string} gdprInfo.international_number
   * @param {boolean} gdprInfo.marketing_ban
   */
  sendGdprData(gdprInfo) {
    this.dispatchEvent(CustomEventNames.sendGdprData, gdprInfo);
  }

  /**
   * Not implemented in GA4
   * @param {string} searchText
   * @param {number} resultCounts
   */
  quickSearch(searchText, resultCounts) {
    this.dispatchEvent(GoogleAnalytics4EventNames.quickSearch, {
      searchText,
      resultCounts,
    });
  }

  /**
   * @param {Object} data
   * @param {Array<CartProduct>} data.productList List of removed products
   * @param {number} data.value a Monetary value of the event
   */
  removeFromCart({ productList, value }) {
    this.dispatchEvent(GoogleAnalytics4EventNames.removeFromCart, {
      productList,
      value,
    });
  }

  /**
   * @param {number} value
   * @param {Array.<Product>} productList
   */
  removeFromProposal({ value, productList }) {
    this.dispatchEvent(GoogleAnalytics4EventNames.removeFromProposal, {
      value,
      productList,
    });
  }

  /**
   * @param {string} searchTerm
   */
  search(searchTerm) {
    this.dispatchEvent(GoogleAnalytics4EventNames.search, {
      searchTerm,
    });
  }

  /**
   * @param {Object} content
   * @param {string} content.method
   * @param {string} content.content_type
   * @param {string} content.item_id
   */
  share(content) {
    this.dispatchEvent(GoogleAnalytics4EventNames.share, {
      content,
    });
  }

  /**
   * @param {string} method
   */
  signup(method) {
    this.dispatchEvent(GoogleAnalytics4EventNames.signup, {
      method,
    });
  }

  /**
   * @param {Object} data
   * @param {number} data.value
   * @param {Array.<AnalyticsItem>} data.productList
   */
  productDetail({ value, productList }) {
    this.dispatchEvent(GoogleAnalytics4EventNames.viewItem, {
      value,
      productList,
    });
  }

  /**
   * @param {string} listName
   * @param {Array.<AnalyticsItem} productList
   * @param {string} listId
   */
  productImpressions(listName, productList, listId) {
    this.dispatchEvent(GoogleAnalytics4EventNames.viewItemList, {
      listId,
      listName,
      productList,
      pageName: this.uiStore.currentPage,
    });
  }

  /**
   * @param {Array.<AnalyticsItem>} bannerList An array with objects in the format { bannerZone, banner }.
   */
  promoView(bannerList) {
    this.dispatchEvent(GoogleAnalytics4EventNames.viewPromotion, {
      bannerList,
    });
  }

  refund() {
    throw notImplementedError;
  }

  updateDimension(dimensionKey, dimensionValue) {
    this.dispatchEvent(CustomEventNames.updateDimension, {
      dimensionKey,
      dimensionValue,
    });
  }

  pageNotFound(payload) {
    this.dispatchEvent(CustomEventNames.pageNotFound, payload);
  }

  /**
   * @param {string} pageName
   */
  setPage(pageName) {
    this.dispatchEvent(CommonEventNames.setPage, {
      pageName,
    });
  }

  /**
   * When quick search suggestions are selected, the information is sent through this function.
   * Can be used to train suggestions.
   *
   * @param searchText
   * @param suggestionType
   * @param suggestionValue
   */
  suggestionSelection(searchText, suggestionType, suggestionValue) {
    this.dispatchEvent(GoogleAnalytics4EventNames.suggestionSelection, {
      searchText,
      suggestionType,
      suggestionValue,
    });
  }

  /**
   * @param {number} value
   * @param {Array.<AnalyticsItem>} productList
   */
  viewCart(value, productList) {
    this.dispatchEvent(GoogleAnalytics4EventNames.viewCart, {
      value,
      productList,
    });
  }

  /**
   * @param {number} value
   * @param {Array.<AnalyticsItem>} productList
   */
  viewProposal(value, productList) {
    this.dispatchEvent(GoogleAnalytics4EventNames.viewProposal, {
      value,
      productList,
    });
  }
}
