import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import Stars from '../../review/Stars';
import globalTranslations from '../../../i18n/globalTranslations';
import ConfigStore from '../../../store/ConfigStore';
import ExternalReviewType from '../../../types/ExternalReviewType';

@observer
export class ProductReviewWidget extends Component {
  getWitViewRating = () => {
    const { product } = this.props;

    if (!product.external_reviews) {
      return;
    }

    return product.external_reviews.find(
      ({ type }) => type === ExternalReviewType.WITVIEW
    );
  };

  render() {
    const { product, compact, configStore, useWitViewRating } = this.props;

    if (!configStore.reviews.enabled) {
      return null;
    }

    let amount;
    let reviewCount;
    let witViewRating = null;

    if (useWitViewRating) {
      witViewRating = this.getWitViewRating();
    }

    if (useWitViewRating && witViewRating) {
      amount = witViewRating.reviews_average;
      reviewCount = witViewRating.reviews_count;
    } else {
      amount = product.reviews_average;
      reviewCount = product.reviews_count;
    }

    return (
      <div className="ProductReviewWidget">
        <Stars amount={amount} />{' '}
        <span className="ProductReviewWidget__text">
          {compact ? (
            <FormattedMessage
              {...globalTranslations.reviewCountCompact}
              values={{ count: reviewCount }}
            />
          ) : (
            <FormattedMessage
              {...globalTranslations.reviewCount}
              values={{ count: reviewCount }}
            />
          )}
        </span>
      </div>
    );
  }
}

ProductReviewWidget.propTypes = {
  configStore: modelOf(ConfigStore),
  compact: PropTypes.bool,
  product: modelOf(Product).isRequired,
  reserveSpace: PropTypes.bool,
  useWitViewRating: PropTypes.bool
};

export default inject('configStore')(ProductReviewWidget);
